import { Button, CircularProgress, Grid, Paper, Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useState, useCallback, useMemo, useContext } from 'react';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { requestApi } from 'src/mocks/request';
import { initDynFormValues } from 'src/utils/initDynFormValues';
import Scrollbar from 'src/components/Scrollbar';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import useRefMounted from 'src/hooks/useRefMounted';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

export const handlePoCreate = async (
  values,
  schema,
  prSchema,
  fieldNames,
  entity,
  currentPurchaseReq,
  updateCurrentPrItems,
  items,
  handleModal
) => {
  const { createData } = sanitizeFormData(values, schema, fieldNames);
  const payload = { endPoint: entity, data: createData };

  try {
    const poCreateRes = await requestApi.createData(payload);
    if (poCreateRes?.status === 'SUCCESS') {
      const createdPo = poCreateRes.data;
      // Update the Purchase Request (PR) with the new PO ID
      const updatedItems = currentPurchaseReq.items.map((item) => {
        // Check if this item is in the items array we received
        const matchedItem = items.find((i) => i.id === item.id);

        if (matchedItem) {
          const existingPoArray = item.PO || [];

          return { ...item, PO: [...existingPoArray, createdPo] };
        }
        return item;
      });

      const { createData } = sanitizeFormData(
        { items: updatedItems },
        prSchema,
        fieldNames
      );

      const prUpdateRes = await requestApi.updateData({
        endPoint: 'purchase_request',
        id: currentPurchaseReq.id,
        data: createData
      });

      if (prUpdateRes.status === 'SUCCESS') {
        // Use the context function to update the currentPrItemsPo
        updateCurrentPrItems(updatedItems);
        handleModal();
      }
    }
  } catch (error) {
    console.error('Error during PO creation:', error);
  }
};

export const handlePoUpdate = async (
  submitData,
  schema,
  fieldNames,
  prSchema,
  items,
  currentPurchaseReq,
  updateCurrentPrItems,
  entity,
  handleModal
) => {
  const { fetchedData, values } = submitData;
  const mergedValues = {
    ...values,
    items: [...(fetchedData.items || []), ...values.items]
  };
  const { set, unset } = sanitizeFormData(mergedValues, schema, fieldNames);

  try {
    const payload = {
      endPoint: entity,
      id: fetchedData.id,
      data: { $set: set, $unset: unset }
    };
    const poUpdateRes = await requestApi.updateData(payload);
    if (poUpdateRes.status === 'SUCCESS') {
      const updatedPo = poUpdateRes.data;
      // Update the Purchase Request (PR) with the new PO ID
      const updatedItems = currentPurchaseReq.items.map((item) => {
        // Check if this item is in the items array we received
        const matchedItem = items.find((i) => i.id === item.id);

        if (matchedItem) {
          const existingPoArray = item.PO || [];

          return { ...item, PO: [...existingPoArray, updatedPo] };
        }
        return item;
      });

      const { createData } = sanitizeFormData(
        { items: updatedItems },
        prSchema,
        fieldNames
      );

      console.log(updatedItems, 'updatedItems');

      const prUpdateRes = await requestApi.updateData({
        endPoint: 'purchase_request',
        id: currentPurchaseReq.id,
        data: createData
      });

      if (prUpdateRes.status === 'SUCCESS') {
        // Use the context function to update the currentPrItemsPo
        updateCurrentPrItems(updatedItems);
        handleModal();
      }
    }
  } catch (error) {
    console.error('Error during PO update:', error);
  }
};

const CreatePoForm = ({
  schema,
  formData,
  items,
  entity,
  handleModal,
  entitySetting
}) => {
  const isMounted = useRefMounted();
  const { schema: prSchema } = useSelector(selectDynamicPageConfig);
  const [submitData, setSubmitData] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState({
    create: false,
    update: false
  });
  const { currentPurchaseReq, updateCurrentPrItems } =
    useContext(ProcessReqContext);
  const fieldNames = useMemo(() => {
    if (!schema) return [];
    return Object.keys(schema).filter(
      (fieldName) => !REMOVE_FIELDS.includes(fieldName)
    );
  }, [schema]);

  const { initialValues, validationSchema } = useMemo(
    () => initDynFormValues({ schema, fieldNames, formData }),
    [schema, fieldNames, formData]
  );

  const handleConfirmDialog = () => setConfirmDialog((prev) => !prev);

  const handleFormikSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await requestApi.getData({
        endPoint: entity,
        populate: 'items.product items.uom',
        query: { supplier: formData.supplier.id, status: 'draft' }
      });

      if (response?.data?.data?.length > 0) {
        const fetchedData = response.data.data[0];
        setSubmitData({ fetchedData, values });
        handleConfirmDialog();
      } else {
        await handlePoCreate(
          values,
          schema,
          prSchema,
          fieldNames,
          entity,
          currentPurchaseReq,
          updateCurrentPrItems,
          items,
          handleModal
        );
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    } finally {
      if (isMounted.current) setSubmitting(false);
    }
  };

  const handleConfirmation = useCallback(
    async (action) => {
      setConfirmLoading((prev) => ({
        ...prev,
        [action]: true
      }));
      try {
        if (action === 'update') {
          await handlePoUpdate(
            submitData,
            schema,
            fieldNames,
            prSchema,
            items,
            currentPurchaseReq,
            updateCurrentPrItems,
            entity,
            handleModal
          );
        } else {
          await handlePoCreate(
            submitData.values,
            schema,
            prSchema,
            fieldNames,
            entity,
            currentPurchaseReq,
            updateCurrentPrItems,
            items,
            handleModal
          );
        }
      } catch (error) {
        console.error('Error during confirmation action:', error);
      } finally {
        if (isMounted.current) {
          setConfirmLoading({
            create: false,
            update: false
          });
          setConfirmDialog(false);
        }
      }
    },
    [
      submitData,
      schema,
      fieldNames,
      entity,
      // currentPurchaseReq,
      items,
      handleModal
    ]
  );

  return (
    <>
      <Paper variant="outlined" elevation={0} sx={{ p: 2, m: 'auto' }}>
        {!initialValues || !validationSchema ? (
          <CircularProgress />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormikSubmit}
          >
            {({ errors, handleSubmit, isSubmitting, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Scrollbar maxHeight={400}>
                  <Grid container spacing={1}>
                    {fieldNames.map((fieldName, index) => (
                      <Grid
                        item
                        xs={12}
                        md={
                          schema[fieldName]?.instance === 'Array' &&
                          schema[fieldName]?.$embeddedSchemaType?.schema &&
                          Object.keys(
                            schema[fieldName]?.$embeddedSchemaType?.schema
                              ?.paths
                          )?.length > 0
                            ? 12
                            : schema[fieldName]?.instance === 'String' &&
                              schema[fieldName]?.options?.max === null
                            ? 12
                            : schema[fieldName]?.instance === 'Object'
                            ? 12
                            : 6
                        }
                        key={`${index}_${fieldName}_checkfield`}
                      >
                        <GetFieldBasedOnSchema
                          name={fieldName}
                          entitySetting={entitySetting}
                          fieldName={fieldName}
                          schema={schema}
                          value={values[fieldName]}
                          error={errors[fieldName]}
                          touched={touched[fieldName]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Scrollbar>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  spacing={1}
                  mt={1}
                >
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        )}
      </Paper>
      <ConfirmationDialog
        open={confirmDialog}
        title={'Existing Draft PO Alert'}
        description={
          'A purchase order (PO) for this supplier is already in draft mode. Would you like to create a new PO or update the existing draft?'
        }
        handleConfirmDialog={handleConfirmDialog}
      >
        <Button
          onClick={() => handleConfirmation('create')}
          startIcon={
            confirmLoading.create ? <CircularProgress size="1rem" /> : null
          }
          disabled={confirmLoading.create}
        >
          Create New PO
        </Button>
        <Button
          onClick={() => handleConfirmation('update')}
          startIcon={
            confirmLoading.update ? <CircularProgress size="1rem" /> : null
          }
          disabled={confirmLoading.update}
        >
          Update Existing PO
        </Button>
      </ConfirmationDialog>
    </>
  );
};

export default CreatePoForm;

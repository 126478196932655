import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip
} from '@mui/material';
import { FieldArray } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AllFieldToShow from './AllFieldToShow';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const PopulateFields = ({
  values,
  setFieldValue,
  fieldNames,
  touched,
  handleBlur,
  errors,
  schema
}) => {
  return (
    <>
      {fieldNames.length > 0 && (
        <Grid item xs={12}>
          <Stack rowGap={3} alignItems={'start'}>
            <FieldArray
              name="data"
              render={({ insert, remove, push }) => (
                <>
                  {values.data.map((dataItem, index) => (
                    <Paper
                      elevation={2}
                      sx={{
                        paddingY: 3,
                        paddingX: 2,
                        width: '100%',
                        border: 1,
                        color: '#ccc'
                      }}
                      key={`dataItem_${index}`}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.data &&
                                touched.data[index]?.populate_field &&
                                errors.data &&
                                errors.data[index]?.populate_field
                            )}
                          >
                            <InputLabel id={`data_${index}_populate_field`}>
                              Populate Field
                            </InputLabel>
                            <Select
                              labelId={`data_${index}_populate_field`}
                              value={dataItem.populate_field}
                              name={`data[${index}].populate_field`}
                              label="Populate Field"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue(
                                  `data[${index}].populate_field`,
                                  e.target.value
                                );
                                // getAllFieldNAmeToShow(e.target.value);
                              }}
                              MenuProps={MenuProps}
                            >
                              {fieldNames
                                .filter((fieldName) => {
                                  // Check if fieldName is not selected in any row other than the current one
                                  const isFieldNameSelected = values.data.some(
                                    (rowData, rowIndex) =>
                                      rowData.populate_field === fieldName &&
                                      rowIndex !== index
                                  );

                                  // If the field is not selected in any other row, include it in the options
                                  return !isFieldNameSelected;
                                })
                                .map((filteredFieldName) => (
                                  <MenuItem
                                    key={`filteredFieldName_${filteredFieldName}`}
                                    value={filteredFieldName}
                                  >
                                    {filteredFieldName}
                                  </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                              {touched.data &&
                                touched.data[index]?.populate_field &&
                                errors.data &&
                                errors.data[index]?.populate_field}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {dataItem.populate_field !== '' && (
                          <AllFieldToShow
                            populateFieldName={dataItem.populate_field}
                            setFieldValue={setFieldValue}
                            dataItem={dataItem}
                            index={index}
                            touched={touched}
                            handleBlur={handleBlur}
                            errors={errors}
                            schema={schema}
                          />
                        )}
                        <Grid item xs={12} md={4}>
                          <Tooltip title="Remove Row">
                            <Button
                              sx={{ mt: 1 }}
                              color="error"
                              variant="contained"
                              onClick={() => remove(index)}
                            >
                              <CloseIcon size="small" />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                  {/* {Boolean(
                values.data.length > 0 &&
                  values.data[values.data.length - 1].old_name !== ''
              ) && ( */}
                  <Tooltip title="Add Row">
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        push({ populate_field: '', field_show: '' })
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                  {/* )} */}
                </>
              )}
            />
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default PopulateFields;

import { Stack, Typography } from '@mui/material';
import React from 'react';
import ItemsTable from 'src/components/ViewComponents/ItemTable';
import AddressBlock from 'src/components/ViewComponents/AddressBlock';
import VerticalLabelValue from 'src/components/ViewComponents/VerticalLabelValue';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const GRNView = ({ data }) => {
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const {
    number: docNumber,
    delivery_date,
    items = [],
    country_code,
    received_by,
    approval_id,
    supplier,
    warehouse
  } = data;
  return (
    <Stack rowGap={2}>
      <Typography textAlign={'center'} color={'primary'} variant={'h3'} mb={1}>
        {capitalizeAndRemoveChar(entity)}
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        rowGap={1}
      >
        <VerticalLabelValue label={'GRN Number'} value={docNumber} />
        <VerticalLabelValue label={'Receipt Date'} value={'1 Aug 2024'} />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        rowGap={1}
      >
        <VerticalLabelValue
          label={'Lorry Receipt Number'}
          value={'LRN-00001'}
        />
        <VerticalLabelValue label={'Delivery Date'} value={delivery_date} />
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        rowGap={1}
      >
        {supplier?.address && (
          <AddressBlock
            countryCode={country_code}
            addressTitle={'Supplier Address'}
            address={data?.supplier?.address}
          />
        )}
        {warehouse?.address && (
          <AddressBlock
            addressTitle={'Driver Address'}
            countryCode={country_code}
            address={data?.warehouse?.address}
          />
        )}
      </Stack>
      {items.length && <ItemsTable schema={schema} data={data} />}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        rowGap={1}
      >
        {received_by && (
          <AddressBlock
            countryCode={country_code}
            addressTitle={'Received By'}
            address={received_by}
          />
        )}
        {approval_id && (
          <AddressBlock
            addressTitle={'Checked By'}
            countryCode={country_code}
            address={approval_id}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default GRNView;

// RenameFields.js
import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { FieldArray } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const RenameFields = ({
  values,
  setFieldValue,
  fieldNames,
  touched,
  handleBlur,
  errors
}) => {
  // console.log(touched, 'touched');
  // console.log(errors, 'erros');
  const fieldNameIsSelected = (fieldName, currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (values.data[i].old_name === fieldName) {
        return true; // fieldName is already selected in a previous row
      }
    }
    return false; // fieldName is not selected in any previous row
  };
  return (
    <Grid item xs={12}>
      <Stack rowGap={3} alignItems={'start'}>
        <FieldArray
          name="data"
          render={({ insert, remove, push }) => (
            <>
              {values.data.map((dataItem, index) => (
                <Paper
                  elevation={2}
                  sx={{
                    paddingY: 3,
                    paddingX: 2,
                    width: '100%',
                    border: 1,
                    color: '#ccc'
                  }}
                  key={`dataItem_${index}`}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        fullWidth
                        error={Boolean(
                          touched.data &&
                            touched.data[index]?.old_name &&
                            errors.data &&
                            errors.data[index]?.old_name
                        )}
                      >
                        <InputLabel id={`data_${index}_old_name`}>
                          Rename Field
                        </InputLabel>
                        <Select
                          labelId={`data_${index}_old_name`}
                          value={dataItem.old_name}
                          name={`data[${index}].old_name`}
                          label="Rename Field"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            setFieldValue(
                              `data[${index}].old_name`,
                              e.target.value
                            )
                          }
                          MenuProps={MenuProps}
                        >
                          {fieldNames.length > 0 &&
                            fieldNames
                              .filter(
                                (fieldName) =>
                                  !fieldNameIsSelected(fieldName, index)
                              )
                              .map((filteredFieldName) => (
                                <MenuItem
                                  key={`filteredFieldName_${filteredFieldName}`}
                                  value={filteredFieldName}
                                >
                                  {filteredFieldName}
                                </MenuItem>
                              ))}
                        </Select>
                        <FormHelperText>
                          {touched.data &&
                            touched.data[index]?.old_name &&
                            errors.data &&
                            errors.data[index]?.old_name}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        label="New Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        fullWidth
                        name={`data[${index}].new_name`}
                        value={dataItem.new_name}
                        onChange={(e) =>
                          setFieldValue(
                            `data[${index}].new_name`,
                            e.target.value
                          )
                        }
                        error={Boolean(
                          touched.data &&
                            touched.data[index]?.new_name &&
                            errors.data &&
                            errors.data[index]?.new_name
                        )}
                        helperText={
                          touched.data &&
                          touched.data[index]?.new_name &&
                          errors.data &&
                          errors.data[index]?.new_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Tooltip title="Remove Row">
                        <Button
                          sx={{ mt: 1 }}
                          color="error"
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          <CloseIcon size="small" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              {/* {Boolean(
                values.data.length > 0 &&
                  values.data[values.data.length - 1].old_name !== ''
              ) && ( */}
              <Tooltip title="Add Row">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => push({ old_name: '', new_name: '' })}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
              {/* )} */}
            </>
          )}
        />
      </Stack>
    </Grid>
  );
};

export default RenameFields;

// // RenameFields.js
// import React from 'react';
// import {
//   Button,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Stack,
//   Switch,
//   TextField,
//   Tooltip
// } from '@mui/material';
// import { FieldArray } from 'formik';
// import CloseIcon from '@mui/icons-material/Close';
// import AddIcon from '@mui/icons-material/Add';
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };
// const RenameFields = ({ values, setFieldValue, fieldNames }) => {
//   const fieldNameIsSelected = (fieldName, currentIndex) => {
//     for (let i = 0; i < currentIndex; i++) {
//       if (values.renameArr[i].old_name === fieldName) {
//         return true; // fieldName is already selected in a previous row
//       }
//     }
//     return false; // fieldName is not selected in any previous row
//   };
//   return (
//     <Grid item xs={12}>
//       <Stack rowGap={3} alignItems={'start'}>
//         <FieldArray
//           name="renameArr"
//           render={({ insert, remove, push }) => (
//             <>
//               {values.renameArr.map((renameArrItem, index) => (
//                 <Paper
//                   elevation={2}
//                   sx={{
//                     paddingY: 3,
//                     paddingX: 2,
//                     width: '100%',
//                     border: 1,
//                     color: '#ccc'
//                   }}
//                   key={`renameArrItem_${index}`}
//                 >
//                   <Grid container spacing={3}>
//                     <Grid item xs={12} md={4}>
//                       <FormControl fullWidth>
//                         <InputLabel id={`renameArr_${index}_old_name`}>
//                           Rename Field
//                         </InputLabel>
//                         <Select
//                           labelId={`renameArr_${index}_old_name`}
//                           value={renameArrItem.old_name}
//                           name={`renameArr[${index}].old_name`}
//                           label="Rename Field"
//                           onChange={(e) =>
//                             setFieldValue(
//                               `renameArr[${index}].old_name`,
//                               e.target.value
//                             )
//                           }
//                           MenuProps={MenuProps}
//                         >
//                           {fieldNames.length > 0 &&
//                             fieldNames
//                               .filter(
//                                 (fieldName) =>
//                                   !fieldNameIsSelected(fieldName, index)
//                               )
//                               .map((filteredFieldName, index) => (
//                                 <MenuItem
//                                   key={`filteredFieldName_${index}`}
//                                   value={filteredFieldName}
//                                 >
//                                   {filteredFieldName}
//                                 </MenuItem>
//                               ))}
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                       <TextField
//                         label="New Name"
//                         variant="outlined"
//                         fullWidth
//                         name={`renameArr[${index}].new_name`}
//                         value={renameArrItem.new_name}
//                         onChange={(e) =>
//                           setFieldValue(
//                             `renameArr[${index}].new_name`,
//                             e.target.value
//                           )
//                         }
//                       />
//                     </Grid>
//                     <Grid
//                       item
//                       xs={12}
//                       md={4}
//                       alignItems={'center'}
//                       display={'flex'}
//                     >
//                       <Tooltip title="Remove Row">
//                         <Button
//                           color="error"
//                           variant="contained"
//                           onClick={() => remove(index)}
//                         >
//                           <CloseIcon size="small" />
//                         </Button>
//                       </Tooltip>
//                     </Grid>
//                   </Grid>
//                 </Paper>
//               ))}
//               {Boolean(
//                 values.renameArr.length > 0 &&
//                   values.renameArr[values.renameArr.length - 1].old_name !== ''
//               ) && (
//                 <Tooltip title="Add Row">
//                   <Button
//                     type="button"
//                     color="primary"
//                     variant="contained"
//                     // disabled={
//                     //   values.renameArr.length === 0 ||
//                     //   Boolean(
//                     //     values.renameArr[values.renameArr.length - 1]
//                     //       .old_name === ''
//                     //   )
//                     // }
//                     onClick={() => push({ old_name: '', new_name: '' })}
//                   >
//                     <AddIcon />
//                   </Button>
//                 </Tooltip>
//               )}
//             </>
//           )}
//         />
//       </Stack>
//     </Grid>
//   );
// };

// export default RenameFields;

// old part
// RenameFields.js
// import React from 'react';
// import {
//   Button,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Paper,
//   Select,
//   Stack,
//   Switch,
//   TextField,
//   Tooltip
// } from '@mui/material';
// import { FieldArray } from 'formik';
// import CloseIcon from '@mui/icons-material/Close';
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };
// const RenameFields = ({ values, setFieldValue, fieldNames }) => {
//   return (
//     <Grid item xs={12}>
//       <Stack rowGap={3}>
//         <FieldArray
//           name="renameArr"
//           render={({ insert, remove, push }) => (
//             <>
//               {values.renameArr.map((renameArrItem, index) => (
//                 <Paper
//                   elevation={3}
//                   sx={{ padding: 2 }}
//                   key={`renameArrItem_${index}`}
//                 >
//                   <Grid container spacing={3}>
//                     <Grid item xs={12} md={4}>
//                       <FormControl fullWidth>
//                         <InputLabel id={`renameArr_${index}_old_name`}>
//                           Rename Field
//                         </InputLabel>
//                         <Select
//                           labelId={`renameArr_${index}_old_name`}
//                           value={renameArrItem.old_name}
//                           name={`renameArr[${index}].old_name`}
//                           label="Rename Field"
//                           onChange={(e) =>
//                             setFieldValue(
//                               `renameArr[${index}].old_name`,
//                               e.target.value
//                             )
//                           }
//                           MenuProps={MenuProps}
//                         >
//                           {fieldNames.length > 0 &&
//                             fieldNames.map((name) => (
//                               <MenuItem key={name} value={name}>
//                                 {name}
//                               </MenuItem>
//                             ))}
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                       <TextField
//                         label="New Name"
//                         variant="outlined"
//                         fullWidth
//                         name={`renameArr[${index}].new_name`}
//                         value={renameArrItem.new_name}
//                         onChange={(e) =>
//                           setFieldValue(
//                             `renameArr[${index}].new_name`,
//                             e.target.value
//                           )
//                         }
//                       />
//                     </Grid>
//                     <Grid
//                       item
//                       xs={12}
//                       md={4}
//                       alignItems={'center'}
//                       display={'flex'}
//                     >
//                       {/* <FormControlLabel
//                         control={
//                           <Switch
//                             checked={renameArrItem.isRename}
//                             name={`renameArr[${index}].isRename`}
//                             onChange={(e) =>
//                               setFieldValue(
//                                 `renameArr[${index}].isRename`,
//                                 e.target.checked
//                               )
//                             }
//                           />
//                         }
//                         label="Is Rename"
//                       /> */}
//                       <Tooltip title="Remove">
//                         <Button
//                           color="error"
//                           variant="text"
//                           onClick={() => remove(index)}
//                         >
//                           <CloseIcon size="small" />
//                         </Button>
//                       </Tooltip>
//                     </Grid>
//                   </Grid>
//                 </Paper>
//               ))}
//               <Button
//                 type="button"
//                 color="primary"
//                 variant="contained"
//                 disabled={
//                   values.renameArr.length === 0 ||
//                   Boolean(
//                     values.renameArr[values.renameArr.length - 1].old_name ===
//                       ''
//                   )
//                 }
//                 onClick={() => push({ old_name: '', new_name: '' })}
//               >
//                 Add More
//               </Button>
//             </>
//           )}
//         />
//       </Stack>
//     </Grid>
//   );
// };

// export default RenameFields;

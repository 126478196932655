import {
  Avatar,
  AvatarGroup,
  Box,
  ImageList,
  ImageListItem,
  Tooltip
} from '@mui/material';
import { useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import Scrollbar from 'src/components/Scrollbar';

// Custom renderer function for surplus avatars
const renderSurplusAvatars = (surplus, handleModal) => {
  if (surplus === 0) {
    return null; // No surplus avatars, return nothing
  } else {
    return (
      <Tooltip arrow placement="top" title={`View All`}>
        <Box
          sx={{ cursor: 'pointer' }}
          component={'span'}
          onClick={handleModal}
        >
          +{surplus}
        </Box>
      </Tooltip>
    );
  }
};

const ImageGallery = ({ images, fieldName }) => {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  const maxAvatarsToShow = 3;

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        {images.length > 0 && (
          <AvatarGroup
            max={maxAvatarsToShow}
            renderSurplus={(surplus) =>
              renderSurplusAvatars(surplus, handleModal)
            }
          >
            {images.map((image, imageIdx) => (
              <Tooltip
                arrow
                placement="top"
                key={`image_${imageIdx}`}
                title={image.name}
              >
                <Avatar
                  sx={{
                    width: 30,
                    height: 30
                  }}
                  src={image.path}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        )}
      </Box>
      <CommonModal
        open={open}
        handleModal={handleModal}
        btnName={'Close'}
        title={fieldName}
      >
        <Scrollbar maxHeight={300}>
          <ImageList cols={3} rowHeight={150} gap={5}>
            {images.map((image, imageIdx) => (
              <ImageListItem key={`image_${imageIdx}`}>
                <img
                  // srcSet={`${image.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image.path}`}
                  alt={image.name}
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Scrollbar>
      </CommonModal>
    </>
  );
};

export default ImageGallery;

import { NoteAddTwoTone } from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requestApi } from 'src/mocks/request';
import { useNoteEditorContext } from '../../contexts/NoteEditorContext';
import Scrollbar from '../Scrollbar';
import NotesList from './NotesList';

const Notes = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [notes, setNotes] = useState(null);
  const { handleCreateNote } = useNoteEditorContext();
  const { isEdit } = useNoteEditorContext();

  useEffect(() => {
    const handleFetchNotes = async () => {
      try {
        const response = await requestApi.getData({
          endPoint: 'note',
          sort: { updatedAt: -1 }
        });
        if (response.status === 'SUCCESS') {
          setNotes(response?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleFetchNotes();
  }, [isEdit]);

  return (
    <Box height={'100%'} sx={{ position: 'relative' }}>
      {/* <Box display="flex" justifyContent="flex-end" alignItems="center" p={1}>
        <Tooltip arrow placement="top" title={t('Add new note')}>
          <IconButton
            onClick={handleCreateNote}
            color="primary"
            sx={{
              backgroundColor: `${theme.colors.primary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`,
              '&:hover': {
                backgroundColor: `${theme.colors.primary.dark}`
              },
              '.MuiSvgIcon-root': {
                transition: `${theme.transitions.create(['color'])}`
              }
            }}
          >
            <NoteAddTwoTone />
          </IconButton>
        </Tooltip>
      </Box> */}
      <Divider />
      <Scrollbar>
        <NotesList notes={notes} />
      </Scrollbar>
      <Box sx={{ position: 'absolute', bottom: 100, right: 20 }}>
        <Tooltip arrow placement="top" title={t('Add new note')}>
          <IconButton
            onClick={handleCreateNote}
            color="primary"
            sx={{
              backgroundColor: `${theme.colors.primary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`,
              '&:hover': {
                backgroundColor: `${theme.colors.primary.dark}`
              },
              '.MuiSvgIcon-root': {
                transition: `${theme.transitions.create(['color'])}`
              }
            }}
          >
            <NoteAddTwoTone />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Notes;

/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'src/mocks/auth';
import { requestApi } from 'src/mocks/request';
import { getSubdomain } from 'src/utils/common';
import { deleteCookie, getCookie, setCookie } from 'src/utils/cookie';
import { v4 as uuidv4 } from 'uuid';

const { subdomain, domain, tld } = getSubdomain();

let token =
  typeof window !== 'undefined' &&
  localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN) || getCookie(process.env.REACT_APP_ACCESS_TOKEN);
let workspace =
  typeof window !== 'undefined' && localStorage.getItem('workspace') || getCookie('workspace');

// transfer token and workspace from cookies to local storage
if (!token && subdomain) {
  token =
    typeof window !== 'undefined' &&
    getCookie(process.env.REACT_APP_ACCESS_TOKEN);
  // if (workspace === subdomain || domain == 'localhost' || subdomain == 'staging') {
  localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token);
  // }
}
if (!workspace && subdomain) {
  workspace = typeof window !== 'undefined' && getCookie('workspace');
  if (workspace) {
    // if (workspace === subdomain || domain == 'localhost' || subdomain == 'staging') {
    localStorage.setItem('workspace', workspace);
    // }
  }
}
const processLoginData = (token, workspace) => {
  if (token) {
    // we are storing the token at three places - redux , localstorage & cookie (only for root domain)
    if (!subdomain && domain === 'erpz') {
      setCookie(process.env.REACT_APP_ACCESS_TOKEN, token, 10);
    } else {
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, token);
    }
    // setting device if for FCM token identification purposes
    localStorage.setItem('deviceId', uuidv4());
  }
  if (workspace) {
    if (!subdomain && domain === 'erpz') {
      setCookie('workspace', workspace, 10);
    } else {
      localStorage.setItem('workspace', workspace);
    }
  }
}

let initialState = {
  isAuthenticated: !!token,
  token: token,
  loggedUserData: null,
  isInitialized: false,
  workspaceName: workspace,
  createdWorkSpace: null,
  status: {
    login: false,
    register: false
  },
  message: { success: null, error: null, for: null }
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authApi.login(data);
      if (response?.status === 'SUCCESS') {
        const userData = response?.data;
        processLoginData(userData?.token, userData?.workspace_name);
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserData = createAsyncThunk(
  'auth/update',
  async (data, { rejectWithValue }) => {
    try {
      // console.log('from data', data);
      const endPoint = 'user';
      const id = data.id;
      const response = await requestApi.updateData({ data, endPoint, id });

      if (response?.status === 'SUCCESS') {
        return response?.data;
      }

      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerAsync = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authApi.register(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLoggedUserDataAsync = createAsyncThunk(
  'auth/me',
  async (token, { rejectWithValue }) => {
    try {
      const response = await authApi.me(token);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPushNotification = createAsyncThunk(
  'auth/push-notification/addPlayerId',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authApi.pushNotification(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message = { success: null, error: null, for: null };
    },
    logout: (state) => {
      console.log('logout in authslice fired')
      localStorage.clear();
      deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
      deleteCookie('workspace');
      state.isAuthenticated = false;
      state.loggedUserData = null;
      state.workspaceName = null;
      state.token = null;
    },
    setIsInitialized: (state) => {
      state.isInitialized = true;
    },
    googleLogin: (state, { payload }) => {
      const userData = payload?.data;
      processLoginData(userData?.token, userData?.workspace_name);
      state.status.login = false;
      state.message.for = 'login';
      state.message.success = 'Login Successfully';
      state.isAuthenticated = true;
      state.loggedUserData = userData;
      if (userData?.token) {
        state.token = userData?.token;
      }
      if (userData?.workspace_name) {
        state.workspaceName = userData?.workspace_name;
      }
    },
    createdWorkSpace: (state, { payload }) => {
      state.createdWorkSpace = payload.id;
      state.workspaceName = payload.name;
    },
    setToken: (state, { payload }) => {
      state.isAuthenticated = payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status.login = true;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.status.login = false;
        state.isInitialized = true;
        state.message.for = 'login';
        state.message.success = 'Login Successfully';
        state.loggedUserData = payload || null;
        state.isAuthenticated = !!payload;
        if (payload?.token) {
          state.token = payload?.token;
        }
        if (payload?.workspace_name) {
          state.workspaceName = payload?.workspace_name;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status.login = false;
        state.message.error = action.payload;
      })
      .addCase(registerAsync.pending, (state) => {
        state.status.register = true;
      })
      .addCase(registerAsync.fulfilled, (state) => {
        state.status.register = false;
        state.message.for = 'register';
        state.message.success = 'Registered Successfully';
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status.register = false;
        state.message.error = action.payload;
      })
      .addCase(getLoggedUserDataAsync.pending, (state, action) => {
        state.isInitialized = false;
      })
      .addCase(getLoggedUserDataAsync.fulfilled, (state, action) => {
        state.status.login = false;
        state.loggedUserData = action.payload || null;
        state.isInitialized = true;
        if (action?.payload?.workspace) {
          state.workspaceName = action.payload.workspace_name;
        }
      })
      .addCase(getLoggedUserDataAsync.rejected, (state, action) => {
        state.isInitialized = true;
      });
  }
});

export const {
  cleanMessageLog,
  logout,
  googleLogin,
  createdWorkSpace,
  setToken,
  setIsInitialized
} = slice.actions;

// selectors
export const selectIsAuthenticated = (state) => state.authState.isAuthenticated;
export const selectLoggedUserData = (state) => state.authState.loggedUserData;
export const selectIsInitialized = (state) => state.authState.isInitialized;
export const selectMessage = (state) => state.authState.message;
export const selectStatus = (state) => state.authState.status;
export const selectWorkspaceName = (state) => state.authState.workspaceName;
export const selectCreatedWorkSpace = (state) =>
  state.authState.createdWorkSpace;
export const selectToken = (state) => state.authState.token;

export default slice.reducer;

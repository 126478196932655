import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NoAuthAxios } from 'src/services/axios';

export const getWorkspaceAsync = createAsyncThunk(
  'public/getWorkspace',
  async (endPoint, { rejectWithValue }) => {
    try {
      const response = await NoAuthAxios.get(`/public/${endPoint}`);
      if (response?.statusText === 'OK') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'public',
  initialState: {
    workspace: {},
    status: {
      getWorkspace: true
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceAsync.pending, (state) => {
        state.status.getWorkspace = true;
      })
      .addCase(getWorkspaceAsync.fulfilled, (state, action) => {
        state.status.getWorkspace = false;
        state.workspace = action?.payload || {};
      })
      .addCase(getWorkspaceAsync.rejected, (state) => {
        state.workspace = {};
        state.status.getWorkspace = false;
      });
  }
});
export const selectWorkspace = (state) => state.public.workspace;
export default slice.reducer;

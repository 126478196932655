import React from 'react';
import { closestCorners, DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import DraggableChip from './DraggableChip';
import { useFormikContext } from 'formik';

const SelectedChips = ({ value, getTagProps, requiredFieldNames }) => {
  const { setFieldValue } = useFormikContext();
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = value.indexOf(active.id);
      const newIndex = value.indexOf(over.id);

      setFieldValue('data', arrayMove(value, oldIndex, newIndex));
    }
  };

  return (
    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
      <SortableContext items={value} strategy={rectSortingStrategy}>
        {value.map((option, index) => (
          <DraggableChip
            key={option}
            option={option}
            index={index}
            requiredFieldNames={requiredFieldNames}
            getTagProps={getTagProps}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SelectedChips;

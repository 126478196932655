import React from 'react';
import LabelValueDisplay from './LableValueDisplay';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddressBlock = ({ addressTitle, address = {}, countryCode }) => {
  const { formatted_address, phone, email, name } = address;
  const { t } = useTranslation();
  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle1">{t(addressTitle)} :</Typography>
      <Typography variant="body1" mb={1}>
        {formatted_address}
        {/* P & C Factory, 21, Red Street, Mumbai Maharashtra 400001, India */}
      </Typography>
      <Box>
        <LabelValueDisplay label={t('Contact Person')} value={name} />
        <LabelValueDisplay
          label={t('Phone No')}
          value={`${countryCode} ${phone}`}
        />
        <LabelValueDisplay label={t('Email')} value={email} />
        {/* <LabelValueDisplay label={t('GSTIN')} value="36AADCN9740LLLL" /> */}
      </Box>
    </Stack>
  );
};

export default AddressBlock;

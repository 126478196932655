import { getFieldType } from 'src/services/commonService';

export const sanitizeFormData = (values, schema, fieldNames) => {
  const set = {};
  const unset = {};
  const createData = {};
  const filteredFieldNames = fieldNames.filter((field) => field !== 'number');
  filteredFieldNames.forEach((field) => {
    const sanitizedValue = processData(field, values[field], schema);
    if (sanitizedValue !== null) {
      set[field] = sanitizedValue;
      createData[field] = sanitizedValue;
    } else {
      unset[field] = ''; // Use an empty string to indicate removal
    }
  });

  return { set, unset, createData };
};

export const processData = (field, value, schema) => {
  const {
    isObjectID,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    isObject
  } = getFieldType(field, schema);
  if (isObjectID) {
    return value?.id || null;
  }

  if (isArrayWithObjectID) {
    return Boolean(value.length) ? value.map((val) => val.id) : null;
  }

  if (isArrayWithMultipleProperties) {
    let nestedData = value;
    const nestedSchema = schema[field]?.$embeddedSchemaType?.schema?.paths;
    const nestedSchemaKeys = Object.keys(nestedSchema).filter(
      (key) => key !== '_id'
    );
    nestedSchemaKeys.forEach((nestedSchemaKey) => {
      const {
        isObjectID: isNestedObjectID,
        isArrayWithObjectID: isNestedArrayWithObjectID
      } = getFieldType(nestedSchemaKey, nestedSchema);

      if (isNestedObjectID) {
        nestedData = nestedData.map((nestedItem) => ({
          ...nestedItem,
          [nestedSchemaKey]: nestedItem[nestedSchemaKey]?.id || ''
        }));
      }

      if (isNestedArrayWithObjectID) {
        nestedData = nestedData.map((nestedItem) => ({
          ...nestedItem,
          [nestedSchemaKey]: nestedItem[nestedSchemaKey]?.map((val) => val.id)
        }));
      }
    });

    nestedData = nestedData
      ?.map((nestedItem) =>
        nestedSchemaKeys.reduce((sanitizedItem, key) => {
          const value = nestedItem[key];
          if (Array.isArray(value) && Boolean(value.length)) {
            sanitizedItem[key] = value;
          } else if (
            !Array.isArray(value) &&
            value !== '' &&
            value !== undefined &&
            value !== null
          ) {
            sanitizedItem[key] = value;
          }
          return sanitizedItem;
        }, {})
      )
      .filter((nestedItem) => Object.keys(nestedItem).length > 0);
    return nestedData.length > 0 ? nestedData : null;
  }

  if (isObject) {
    let objectData = value;
    const objectSchema = schema[field]?.schema;
    const objectSchemaKeys = Object.keys(objectSchema).filter(
      (key) => key !== '_id'
    );
    objectSchemaKeys.forEach((objectSchemaKey) => {
      const {
        isObjectID: isObjectFieldID,
        isArrayWithObjectID: isArrayWithObjectIDField
      } = getFieldType(objectSchemaKey, objectSchema);

      if (isObjectFieldID) {
        objectData = {
          ...objectData,
          [objectSchemaKey]: objectData[objectSchemaKey].id
        };
      }

      if (isArrayWithObjectIDField) {
        objectData = {
          ...objectData,
          [objectSchemaKey]: objectData?.[objectSchemaKey]?.map((val) => val.id)
        };
      }
    });

    // Remove empty values
    objectData = objectSchemaKeys.reduce((acc, key) => {
      const value = objectData[key];
      if (Array.isArray(value) && value.length > 0) {
        acc[key] = value;
      } else if (
        !Array.isArray(value) &&
        value !== '' &&
        value !== undefined &&
        value !== null
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return Object.keys(objectData).length > 0 ? objectData : null;
  }

  // For other types of fields (like strings, numbers, etc.)
  if (
    value !== '' &&
    value !== undefined &&
    value !== null &&
    (!Array.isArray(value) || value.length > 0)
  ) {
    return value;
  }

  return null;
};

import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNoteEditorContext } from '../../contexts/NoteEditorContext';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

      .ql-editor {
        height: calc(100vh - 280px);
      }

      .ql-snow .ql-picker {
        color: ${theme.colors.alpha.black[100]};
      }

      .ql-snow .ql-stroke {
        stroke: ${theme.colors.alpha.black[100]};
      }

      .ql-toolbar.ql-snow {
        border-top-left-radius: ${theme.general.borderRadius};
        border-top-right-radius: ${theme.general.borderRadius};
      }

      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[30]};
      }

      .ql-container.ql-snow {
        border-bottom-left-radius: ${theme.general.borderRadius};
        border-bottom-right-radius: ${theme.general.borderRadius};
      }

      &:hover {
        .ql-toolbar.ql-snow,
        .ql-container.ql-snow {
          border-color: ${theme.colors.alpha.black[50]};
        }
      }
  `
);

const NoteEditor = () => {
  const theme = useTheme();
  const {
    state,
    isEdit,
    handleChange,
    handleNote,
    isCreateNote,
    setIsCreateNote,
    handleDeleteNote
  } = useNoteEditorContext();

  useEffect(() => {
    return () => {
      setIsCreateNote(false);
    };
  }, []);

  return (
    <Slide
      direction="right"
      in={isCreateNote}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          width: { sm: 400, lg: 600 },
          height: '100vh',
          borderRight: `1px solid ${theme.palette.divider}`
        }}
        p={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4"> {isEdit ? 'Edit' : 'Add'} Note</Typography>
          <IconButton onClick={() => setIsCreateNote(false)}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        <TextField
          fullWidth
          name="title"
          label="Title"
          required
          value={state?.title || ''}
          onChange={(e) => {
            const { name, value } = e.target;
            handleChange(name, value);
          }}
          sx={{ mb: 2 }}
        />
        <EditorWrapper>
          <ReactQuill
            theme="snow"
            value={state?.content}
            onChange={(value) => handleChange('content', value)}
          />
        </EditorWrapper>
        <Stack direction="row" gap={2}>
          {isEdit && (
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: 2 }}
              color="error"
              onClick={handleDeleteNote}
            >
              Delete
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleNote}
            disabled={!state.title || state.title === ''}
          >
            {isEdit ? 'Update' : 'Add'}
          </Button>
        </Stack>
      </Box>
    </Slide>
  );
};

export default NoteEditor;

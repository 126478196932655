import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeScreen,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';

const ExpandScreen = () => {
  const { fullScreen } = useSelector(selectCustomSettingData);
  const dispatch = useDispatch();

  useEffect(() => {
    const toggleFullScreen = () => {
      if (!document.fullscreenElement && fullScreen) {
        document.documentElement.requestFullscreen().catch((err) => {
          console.error('Failed to enter fullscreen mode:', err);
        });
      } else if (document.fullscreenElement && !fullScreen) {
        document.exitFullscreen().catch((err) => {
          console.error('Failed to exit fullscreen mode:', err);
        });
      }
    };
    toggleFullScreen();
  }, [fullScreen]);

  return (
    <Button
      variant="outlined"
      sx={{
        width: '100%',
        padding: '15px'
      }}
      onClick={() => {
        dispatch(changeScreen());
      }}
    >
      {fullScreen ? (
        <>
          <FullscreenExitIcon /> Exit Full Screen
        </>
      ) : (
        <>
          <FullscreenIcon /> Full Screen
        </>
      )}
    </Button>
  );
};

export default ExpandScreen;

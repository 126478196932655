import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import ApprovalActionBottomSheet from './ApprovalActionBottomSheet';

const ViewPageLayout = ({ data, children }) => {
  const { entity } = useSelector(selectDynamicPageConfig);
  return (
    <>
      <Helmet>
        <title>{entity} Details</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader data={data} />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        mb={'2vh'}
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      <ApprovalActionBottomSheet data={data} />
    </>
  );
};

export default ViewPageLayout;

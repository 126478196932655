import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from 'src/services/axios';

let initialState = {
  employee: [],
  department: [],
  newHires: [],
  Announcement: [],
  status: {
    getEmployee: false,
    getDepartement: false,
    getNewHires: false,
    getAnnouncement: false
  },
  message: { success: null, error: null, to: null }
};

export const getAllEmployee = createAsyncThunk(
  'hr/getAllEmployee',
  async (data, { rejectWithValue }) => {
    try {
      let response = await Axios.post('/employee/list', data);
      if (response?.data.status == 'SUCCESS') {
        return response?.data?.data;
      } else {
        return response.message;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllDepartement = createAsyncThunk(
  'hr/getAllDepartement',
  async (data, { rejectWithValue }) => {
    try {
      let response = await Axios.post('/departments/list', data);
      if (response?.data.status == 'SUCCESS') {
        return response?.data?.data;
      } else {
        return response.message;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllNewHires = createAsyncThunk(
  'hr/getAllNewHires',
  async (data, { rejectWithValue }) => {
    try {
      let response = await Axios.post('/employee/list', data);
      if (response?.data.status == 'SUCCESS') {
        return response?.data?.data;
      } else {
        return response.message;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmployee = createAsyncThunk(
  'hr/createEmployee',
  async (data, { rejectWithValue }) => {
    try {
      let response = await Axios.post('employee/create', data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: 'hr',
  initialState,
  reducers: {
    cleanMessageLog(state, action) {
      state.message.success = null;
      state.message.error = null;
      state.message.to = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployee.pending, (state) => {
        state.status.getEmployee = true;
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.status.getEmployee = false;
        state.message.to = 'getAllEmployee';
        state.message.success = 'SUCCESS';
        state.employee = action.payload;
      })
      .addCase(getAllEmployee.rejected, (state, action) => {
        state.status.getEmployee = false;
        state.message.error = action.payload;
      })

      .addCase(getAllNewHires.pending, (state) => {
        state.status.getNewHires = true;
      })
      .addCase(getAllNewHires.fulfilled, (state, action) => {
        state.status.getNewHires = false;
        state.message.to = 'getAllNewHires';
        state.message.success = 'SUCCESS';
        state.newHires = action.payload;
      })
      .addCase(getAllNewHires.rejected, (state, action) => {
        state.status.getNewHires = false;
        state.message.error = action.payload;
      })

      .addCase(getAllDepartement.pending, (state) => {
        state.status.getDepartement = true;
      })
      .addCase(getAllDepartement.fulfilled, (state, action) => {
        state.status.getDepartement = false;
        state.message.to = 'getAllDepartement';
        state.message.success = 'SUCCESS';
        state.department = action.payload;
      })
      .addCase(getAllDepartement.rejected, (state, action) => {
        state.message.error = action.payload;
      });

    // .addCase(getAllAnnouncement.pending, (state) => {
    //     state.status.getAnnouncement = true
    // })
    // .addCase(getAllAnnouncement.fulfilled, (state, action) => {
    //     state.status.getAnnouncement = false;
    //     state.message.success = "SUCCESS";
    //     state.message.to = "getAllAnnouncement";
    //     state.Announcement = action.payload
    // })
    // .addCase(getAllAnnouncement.rejected, (state, action) => {
    //     state.message.error = action.payload
    // })
  }
});

export const { cleanMessageLog } = slice.actions;
export default slice.reducer;

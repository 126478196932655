import { Backdrop } from '@mui/material';
import React from 'react';

const BackDropLoader = ({ sx = {}, isShow, children }) => {
  return (
    <Backdrop
      sx={{ ...sx, color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isShow}
    >
      {children}
    </Backdrop>
  );
};

export default BackDropLoader;

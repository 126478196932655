import { CloseRounded } from '@mui/icons-material';
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import ImportFields from 'src/components/ImportFields';

const DialogBody = ({ handleDialog, itemSchema, fieldName, name, value }) => {
  const { setFieldValue } = useFormikContext();
  const handleSetValue = (uploadData) => {
    setFieldValue(name, [...value, ...uploadData]);
    handleDialog();
  };
  return (
    <>
      <AppBar sx={{ position: 'relative', px: 3, py: 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDialog}>
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
            Upload CSV File
          </Typography>
          <Button autoFocus color="inherit" onClick={handleDialog}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <ImportFields
        schema={itemSchema}
        fieldName={fieldName}
        handleSetValue={handleSetValue}
      />
    </>
  );
};

export default DialogBody;

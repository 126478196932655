import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import CustomPopover from 'src/components/CustomPopover';
import ChipComponent from 'src/components/DynamicComponents/ChipComponent';
import Scrollbar from 'src/components/Scrollbar';

const ArrayCell = ({ value, props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {value.slice(0, 2).map((item, index) => (
        <ChipComponent key={index} value={item} {...props} />
      ))}
      {value.length > 3 && (
        <>
          <Button
            color="primary"
            size="small"
            style={{ minWidth: 'auto', padding: '4px' }}
            onClick={handlePopoverOpen}
          >
            +{value.length - 2}
          </Button>
          <CustomPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handlePopoverClose}
          >
            <Box sx={{ maxWidth: 300 }}>
              <Scrollbar maxHeight={300}>
                <List>
                  {value.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Scrollbar>
            </Box>
          </CustomPopover>
        </>
      )}
    </>
  );
};

export default ArrayCell;

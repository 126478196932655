import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import codeMessage from './codeMessage';

const successHandler = (
  response,
  options = { notifyOnSuccess: false, notifyOnFailed: true }
) => {
  const { data } = response;

  if (response.status === 200) {
    // console.log('insidesucess', response);
    if (response.data) {
      const message = response.data && data.message;
      const successText = message || codeMessage[response.status];

      if (options.notifyOnSuccess) {
        toast.success(`${successText}`, { containerId: 'first' });
      }
    }
    if (data.data === null) {
      const message = response.data && data.message;
      const errorText = message || codeMessage[response.status];
      const { status } = response;
      if (options.notifyOnSuccess) {
        toast.info(`${errorText}`, { containerId: 'first' });
      }
    }
  } else {
    // console.log('insidefailure', response);
    const message = response.data && data.message;
    const errorText = message || codeMessage[response.status];
    const { status } = response;
    if (options.notifyOnFailed) {
      toast.error(`${errorText}`, { containerId: 'first' });
    }
  }
};

export default successHandler;

import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/LogoSign';

import { Box, Divider, Drawer, styled, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';
import { useLocation } from 'react-router-dom';
import SidebarFooter from './SidebarFooter.js';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import { useSelector } from 'react-redux';
import SidebarLoader from 'src/components/SkeltonLoaders/SidebarLoader.js';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.spacing(12)};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.md}px) {
          top: 0;
          left: 0;
          position: fixed;
          z-index: 10;
          border-top-right-radius: ${theme.general.borderRadius};
          border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: ${theme.spacing(0, 2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const location = useLocation();
  // Check if the pathname starts with 'workspace-setting'
  const showFooter = location.pathname.startsWith('/workspace-setting');
  const { status } = useSelector(selectAppConfigData);
  const { getAppConfig, getComEntitySetting } = status;
  if (getAppConfig && getComEntitySetting) return <SidebarLoader />;
  return (
    <>
      <SidebarWrapper
        sx={{
          display: { xs: 'none', md: 'inline-block' }
        }}
      >
        <TopSection>
          <Logo />
        </TopSection>
        <Box
          sx={{
            height: showFooter ? 'calc(100% - 140px)' : 'calc(100% - 80px)'
          }}
        >
          <Scrollbar>
            <SidebarMenu />
          </Scrollbar>
        </Box>
        {showFooter && (
          <>
            <Divider
              sx={{
                background: theme.colors.alpha.black[30]
              }}
            />
            <SidebarFooter />
          </>
        )}
      </SidebarWrapper>
      <Drawer
        sx={{
          display: { md: 'none', xs: 'inline-block' }
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Box sx={{ height: showFooter ? 'calc(100% - 60px)' : '100%' }}>
            <Scrollbar>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu />
            </Scrollbar>
          </Box>
          {showFooter && (
            <>
              <Divider
                sx={{
                  background: theme.colors.alpha.black[30]
                }}
              />
              <SidebarFooter />
            </>
          )}
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;

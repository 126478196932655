import { Button, Grid, IconButton, Paper } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldArray } from 'formik';
import { DESIGN_TYPE_OPTIONS } from 'src/constant/constant';
import CustomComponent from './CustomComponent';
import TextFormatting from './TextFormatting';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const EnumValueDesign = ({
  dataItem,
  setFieldValue,
  enumValues,
  index,
  schema,
  handleBlur
}) => {
  const IsOptionSelected = (enumValue, enumIndex) => {
    for (let i = 0; i < enumIndex; i++) {
      if (dataItem?.enum_setting[i]?.enum_name === enumValue) {
        return true;
      }
    }
    return false;
  };

  const enumSettingArr = dataItem?.enum_setting || [];
  console.log(enumValues, 'emlmlsklkslk');
  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        sx={{
          paddingY: 3,
          paddingX: 2,
          width: '100%',
          // border: 1,
          color: '#ccc'
        }}
      >
        <FieldArray name={`data[${index}].enum_setting`}>
          {({ push, remove }) => (
            <>
              {enumSettingArr.map((enumItem, enumIndex) => (
                <Grid container spacing={2} key={enumIndex}>
                  <Grid item xs={12} md={2}>
                    <CustomSelectFieldWithLabel
                      label="Enum Name"
                      value={enumItem?.enum_name || ''}
                      options={enumValues
                        .filter(
                          (enumValue) => !IsOptionSelected(enumValue, enumIndex)
                        )
                        .map((filterEnumValue) => ({
                          label: filterEnumValue,
                          value: filterEnumValue
                        }))}
                      onChange={(e) => {
                        console.log(dataItem, 'dataitem');
                        const updatedEnumSetting = {
                          ...dataItem.enum_setting[enumIndex],
                          enum_name: e.target.value
                        };

                        if (dataItem?.design_type !== undefined) {
                          updatedEnumSetting.design_type = dataItem.design_type;
                        }
                        if (dataItem?.componentConfig?.component) {
                          updatedEnumSetting.component =
                            dataItem.componentConfig.component;
                        }
                        if (dataItem?.componentConfig?.props) {
                          updatedEnumSetting.props =
                            dataItem.componentConfig.props;
                        }
                        if (dataItem?.format) {
                          updatedEnumSetting.format = dataItem.format;
                        }

                        setFieldValue(
                          `data[${index}].enum_setting[${enumIndex}]`,
                          updatedEnumSetting
                        );
                      }}
                      onBlur={handleBlur}
                      name={`data[${index}].enum_setting[${enumIndex}].enum_name`}
                    />
                  </Grid>

                  {enumItem.enum_name && (
                    <Grid item xs={12} md={2}>
                      <CustomSelectFieldWithLabel
                        label="Design Type"
                        value={
                          enumItem?.design_type !== undefined
                            ? enumItem?.design_type
                            : ''
                        }
                        options={DESIGN_TYPE_OPTIONS}
                        onChange={(e) =>
                          setFieldValue(
                            `data[${index}].enum_setting[${enumIndex}].design_type`,
                            e.target.value
                          )
                        }
                        onBlur={handleBlur}
                        name={`data[${index}].enum_setting[${enumIndex}].design_type`}
                      />
                    </Grid>
                  )}
                  {enumItem.enum_name && enumItem.design_type === true && (
                    <CustomComponent
                      dataItem={enumItem}
                      fieldName={dataItem?.design_field}
                      itemPath={enumItem}
                      propPath={`data[${index}].enum_setting[${enumIndex}]`}
                      index={enumIndex}
                      setFieldValue={setFieldValue}
                      schema={schema}
                      name={`enumItem_${enumIndex}`}
                    />
                  )}
                  {enumItem.enum_name && enumItem.design_type === false && (
                    <TextFormatting
                      dataItem={enumItem}
                      initialPath={`data[${index}].enum_setting[${enumIndex}]`}
                      name={`enumItem_format_${enumIndex}`}
                      // touched={touched}
                      // errors={errors}
                      index={enumIndex}
                      setFieldValue={setFieldValue}
                    />
                  )}

                  <Grid item xs={12} md={1}>
                    <IconButton
                      color="secondary"
                      onClick={() => remove(enumIndex)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12} mt={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    dataItem?.enum_setting?.length === enumValues.length
                  }
                  onClick={() => push({ enum_name: '', design_type: '' })}
                >
                  Add New Enum Design
                </Button>
              </Grid>
            </>
          )}
        </FieldArray>
      </Paper>
    </Grid>
  );
};

export default EnumValueDesign;

// TODO : make the domain selection also dynamic
export const setCookie = (name, value, minutes) => {
  const expirationDate = new Date();
  // set date to expire in 10 minutes
  expirationDate.setMinutes(expirationDate.getMinutes() + minutes); 
  document.cookie = `${name}=${value}; domain=.erpz.in; expires=${expirationDate.toUTCString()}; path=/; secure; SameSite=None`;
};

export const getCookie = (name) => {
  const cookies = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`));
  return cookies ? cookies.split('=')[1] : null;
};

// TODO : make the domain selection also dynamic
export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.erpz.in`;
};

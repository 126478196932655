import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import RenderValueField from './RenderValueField';
import { toast } from 'react-toastify';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const FilterRow = ({
  filterIdx,
  filter,
  filters,
  setFilters,
  getOperators,
  availableFieldNames,
  handleRemoveRow,
  updateQuery,
  queryData
}) => {
  const { schema } = useSelector(selectDynamicPageConfig);
  const [isFilterApplied, setIsFilterApplied] = useState(
    queryData.some((item) => item.index === filterIdx)
  );

  const handleColumnChange = (e) => {
    const updatedFilters = [...filters];
    const defaultOperators = getOperators(schema, e.target.value);
    updatedFilters[filterIdx] = {
      ...updatedFilters[filterIdx],
      column: e.target.value,
      operator: defaultOperators[0],
      value: ''
    };
    setFilters(updatedFilters);
  };

  const handleOperatorChange = (e) => {
    const updatedFilters = [...filters];
    const operator = e.target.value;
    updatedFilters[filterIdx] = {
      ...updatedFilters[filterIdx],
      operator,
      value:
        operator === 'is any of'
          ? []
          : operator === 'in between'
          ? { start: '', end: '' }
          : ''
    };
    setFilters(updatedFilters);
  };

  const handleValueChange = (e, key = null, value) => {
    const updatedFilters = [...filters];
    const newValue = key
      ? { ...updatedFilters[filterIdx].value, [key]: e.target.value }
      : value?.length > 0
      ? value
      : e.target.value;
    updatedFilters[filterIdx] = {
      ...updatedFilters[filterIdx],
      value: newValue
    };
    setFilters(updatedFilters);
  };

  const handleApplyFilter = () => {
    if (filter.operator !== 'is empty' && filter.operator !== 'is not empty') {
      if (Array.isArray(filter.value) && filter.value.length === 0) {
        toast.info('Please enter a value for the filter.');
        return;
      }

      if (
        typeof filter.value === 'object' &&
        !Array.isArray(filter.value) &&
        (!filter.value.start || !filter.value.end)
      ) {
        toast.info('Start and end both are required values.');
        return;
      }

      if (!filter.value) {
        toast.info('Please enter a value for the filter.');
        return;
      }
    }

    // Find the index in queryData where index matches filterIdx
    const dataIndex = queryData.findIndex((item) => item.index === filterIdx);

    // If dataIndex is found and filter value is the same, toggle filter application state
    if (
      dataIndex !== -1 &&
      JSON.stringify(queryData[dataIndex].filter) === JSON.stringify(filter)
    ) {
      setIsFilterApplied(true);
      console.log(
        'not chnaged',
        JSON.stringify(queryData[dataIndex].filter),
        JSON.stringify(filter)
      );
      return;
    }

    updateQuery(filter, filterIdx);
    setIsFilterApplied(true);
  };
  const handleEditFilter = () => {
    setIsFilterApplied(false);
  };

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Box sx={{ width: 200 }}>
        <CustomSelectField
          name="column"
          label="Column"
          disabled={isFilterApplied}
          value={filter.column}
          options={availableFieldNames}
          onChange={handleColumnChange}
        />
      </Box>
      <Box sx={{ width: 200 }}>
        <CustomSelectField
          name="operator"
          label="Operator"
          disabled={isFilterApplied}
          value={filter.operator}
          options={getOperators(schema, filter.column, filterIdx)}
          onChange={handleOperatorChange}
        />
      </Box>
      <RenderValueField
        label="Value"
        disabled={isFilterApplied}
        filter={filter}
        handleValueChange={handleValueChange}
      />
      <Tooltip title="Remove">
        <IconButton onClick={() => handleRemoveRow(filterIdx)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={isFilterApplied ? 'Edit' : 'Apply'}>
        {isFilterApplied ? (
          <EditIcon
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={handleEditFilter}
          />
        ) : (
          <IconButton onClick={handleApplyFilter}>
            <DoneIcon fontSize="small" sx={{ cursor: 'pointer' }} />
          </IconButton>
        )}
      </Tooltip>
    </Stack>
  );
};

export default FilterRow;

import React, { useState } from 'react';
import { CircularProgress, MenuItem } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import {
  createDataAsync,
  selectDynamicPageConfig,
  selectStatus
} from 'src/slices/dynamicSlice';
import { useSelector, useDispatch } from 'react-redux';
import { requestApi } from 'src/mocks/request';
import useEntitySchemaAndSetting from 'src/hooks/useEntitySchemaAndSetting';

const DuplicateDocument = ({ rowData }) => {
  const dispatch = useDispatch();
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const { createData } = useSelector(selectStatus);
  const { getEntityConfig } = useEntitySchemaAndSetting();
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle deep copy if needed
  const handleDeepCopy = async (key, value) => {
    // Check for the reference endpoint
    const refEndpoint =
      schema[key]?.options?.ref ||
      schema[key]?.$embeddedSchemaType?.options?.ref;

    // If no ref is found, return the original value
    if (!refEndpoint) {
      return value;
    }

    // Fetch schema and setting for the reference endpoint
    const refSchema = await getEntityConfig(refEndpoint);

    const keys = Object.keys(value).filter(
      (key) =>
        !refSchema?.[key]?.options?.no_copy &&
        !refSchema[key]?.$embeddedSchemaType?.options?.no_copy &&
        !REMOVE_FIELDS.includes(key) &&
        key !== 'id'
    );
    const filteredData = keys.reduce((obj, key) => {
      obj[key] = value[key];
      return obj;
    }, {});

    try {
      const response = await requestApi.createData({
        endPoint: refEndpoint,
        data: filteredData
      });

      // Return the data of the created deep copy document
      return response?.data;
    } catch (error) {
      console.error(`Error during deep copy for field ${key}:`, error);
      return value; // Return original value on error
    }
  };

  const handleDuplicateDoc = async () => {
    setIsLoading(true); // Start loading

    const keys = Object.keys(rowData).filter(
      (key) =>
        !schema?.[key]?.options?.no_copy &&
        !schema[key]?.$embeddedSchemaType?.options?.no_copy &&
        !REMOVE_FIELDS.includes(key) &&
        key !== 'id'
    );

    // Separate keys that need deep copying from those that don't
    const deepCopyPromises = keys.map(async (key) => {
      if (schema[key]?.options?.deep_copy) {
        const response = await handleDeepCopy(key, rowData[key]);
        return { [key]: response };
      }
      // Directly return the value if no deep copy is needed
      return { [key]: rowData[key] };
    });

    try {
      // Resolve all deep copy promises
      const deepCopyResults = await Promise.all(deepCopyPromises);
      // Combine all results into a single object
      const filteredData = deepCopyResults.reduce(
        (acc, cur) => ({ ...acc, ...cur }),
        {}
      );

      // Sanitize the data before creating a new document
      const { createData: sanitizedData } = sanitizeFormData(
        filteredData,
        schema,
        keys
      );

      // Prepare payload for creating the duplicated document
      const payload = {
        endPoint: entity,
        data: sanitizedData,
        localUpdate: filteredData
      };

      // Dispatch the action to create the duplicated document
      await dispatch(createDataAsync(payload));
    } catch (error) {
      console.error('Error duplicating document:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <MenuItem onClick={handleDuplicateDoc} disableRipple>
      {isLoading || createData ? (
        <CircularProgress size={'1rem'} sx={{ mr: 1 }} />
      ) : (
        <ContentCopyIcon />
      )}
      Duplicate
    </MenuItem>
  );
};

export default DuplicateDocument;

import React from 'react';
import { Grid, CardHeader, CardContent, Card, Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const OrderDetailsCard = () => {
  const fieldNames = ['purchase_order', 'delivery_note_number'];
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();
  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Order Details" />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            {fieldNames.map((fieldName) => (
              <Grid item xs={12} md={6} key={fieldName}>
                <GetFieldBasedOnSchema
                  name={fieldName}
                  fieldName={fieldName}
                  entitySetting={entitySetting}
                  schema={schema}
                  value={values[fieldName]}
                  error={errors[fieldName]}
                  touched={touched[fieldName]}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default OrderDetailsCard;

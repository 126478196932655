import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ItemsTable from './ItemsTable';
import AddItemForm from './AddItemForm';
import useGetItemById from 'src/hooks/useGetItemById';
import { useFormikContext } from 'formik';

const ItemDetails = () => {
  const { values, setFieldValue } = useFormikContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { currentItem: purchaseReq, isFetching } = useGetItemById(
    values?.purchase_order?.id,
    {
      endPoint: 'purchase_order',
      populate: 'items.product items.uom',
      query: { _id: values?.purchase_order?.id }
    }
  );

  const { items } = purchaseReq || {};

  useEffect(() => {
    if (items && items.length > 0) {
      const mappedItems = items.reduce(
        (acc, item) => {
          // Check if the product already exists in the current items
          const existingItem = acc.find(
            (existing) => existing.product.id === item.product.id
          );

          if (!existingItem) {
            acc.push({
              product: item.product,
              uom: item.uom,
              quantity: item.quantity
            });
          }
          // If the item already exists, we do nothing and simply return acc
          return acc;
        },
        // Initialize with the current values.items to avoid duplication
        [...(values.items || [])]
      );

      // Set the updated items array to the form state
      setFieldValue('items', mappedItems);
    }
  }, [items, setFieldValue]);

  useEffect(() => {
    if (isMobile && values?.items?.[currentIndex]) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [currentIndex, isMobile, values]);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ItemsTable
          items={items}
          isFetching={isFetching}
          isMobile={isMobile}
          currentIndex={currentIndex}
          setCurrentIndex={(index) => {
            setCurrentIndex(index);
            if (isMobile) {
              setIsDrawerOpen(true);
            }
          }}
        />
      </Grid>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          PaperProps={{
            style: { height: '70vh', padding: theme.spacing(2) }
          }}
        >
          <AddItemForm
            items={items}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </Drawer>
      ) : (
        values?.items?.[currentIndex] && (
          <Grid item xs={12} md={6}>
            <AddItemForm
              items={items}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default ItemDetails;

import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { OPERATOR_MAPS } from 'src/constant/constant';
import FieldByTypeOp from 'src/components/FieldByTypeOp';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
const getOperators = (column, schema) => {
  const columnType = schema[column]?.instance;
  return OPERATOR_MAPS[columnType] || [];
};

const ConditionFields = ({
  conditionPath,
  conditionIndex,
  condition,
  handlFieldNameChange,
  conditionArr,
  entity,
  gridXs = 2
}) => {
  const { handleChange } = useFormikContext();
  const { schema } = useEntityPageConfig(entity);
  const fieldNames = schema ? Object.keys(schema) : [];
  return (
    <>
      <Grid item xs={gridXs}>
        <CustomSelectField
          name={`${conditionPath}.${conditionIndex}.field_name`}
          label="Field Name"
          value={fieldNames.length > 0 ? condition?.field_name || '' : ''}
          options={fieldNames}
          onChange={(e) => handlFieldNameChange(e, conditionIndex, schema)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <CustomSelectField
          name={`${conditionPath}.${conditionIndex}.field_operator`}
          label="Field Operator"
          value={schema ? condition?.field_operator || '' : ''}
          disabled={!condition?.field_name}
          options={
            schema
              ? getOperators(conditionArr[conditionIndex].field_name, schema)
              : []
          }
          onChange={handleChange}
        />
      </Grid>
      {fieldNames.length > 0 &&
        condition?.field_name &&
        condition?.field_operator && (
          <>
            <Grid item xs={gridXs}>
              <FieldByTypeOp
                name={`${conditionPath}.${conditionIndex}.field_value`}
                label={'Field Value'}
                value={condition?.field_value}
                operator={condition?.field_operator}
                fieldName={condition?.field_name}
                schema={schema}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelectField
                name={`${conditionPath}.${conditionIndex}.field_join`}
                label="Field Join"
                value={condition?.field_join || ''}
                options={['AND', 'OR']}
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
    </>
  );
};

export default ConditionFields;

/* eslint-disable react/prop-types */
import {
  DeleteTwoTone,
  DoneRounded,
  StarOutlineRounded,
  StarRounded
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteTodo, updateTodo } from 'src/slices/todosSlice';

const filter = createFilterOptions();

const TodosCard = ({ todo }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [editedTodo, setEditedTodo] = useState({ ...todo });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTodo((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newTag = inputValue.trim();
      console.log(newTag);
      if (!editedTodo?.tags.includes(newTag)) {
        const newTags = [...editedTodo.tags, newTag];
        setEditedTodo((prevTodo) => ({ ...prevTodo, tags: newTags }));
      }
      setInputValue('');
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    const newTags = editedTodo?.tags?.filter((tag) => tag !== tagToDelete);
    setEditedTodo((prevTodo) => ({ ...prevTodo, tags: newTags }));
  };

  const onClickCheckBox = () => {
    dispatch(
      updateTodo({
        id: editedTodo?.id,
        data: { ...editedTodo, isComplete: !editedTodo?.isComplete }
      })
    );
  };

  const handleDelete = () => {
    dispatch(deleteTodo({ id: todo?.id }));
  };

  const handleImportant = () => {
    dispatch(
      updateTodo({
        data: { ...todo, isImportant: !todo.isImportant },
        id: todo?.id
      })
    );
  };

  const handleUpdateTodo = () => {
    setIsEditing(false);
    dispatch(updateTodo({ data: editedTodo, id: editedTodo?.id }));
  };

  console.log(inputValue);
  console.log(isEditing);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mb: 2,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderRadius: 1,
        border: `1px solid ${theme.colors.alpha.black[10]}`,
        background: theme.colors.alpha.black[5]
        // background: theme.palette.background.paper,
      }}
    >
      <Box>
        <Checkbox
          sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
          checked={todo?.isComplete}
          onChange={onClickCheckBox}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        {isEditing ? (
          <TextField
            fullWidth
            name="title"
            value={editedTodo?.title}
            onChange={handleChange}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            size="small"
            sx={{
              borderRadius: 1,
              mb: 1,
              p: 1,
              background: theme.palette.background.default
            }}
          />
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              sx={{
                textDecoration: todo?.isComplete ? 'line-through' : 'none',
                cursor: 'pointer',
                fontSize: 18
              }}
              onClick={() => setIsEditing(true)}
            >
              {todo?.title}
            </Typography>
            <IconButton onClick={handleImportant} size="small">
              {todo?.isImportant ? (
                <StarRounded color="primary" />
              ) : (
                <StarOutlineRounded color="secondary" />
              )}
            </IconButton>
          </Stack>
        )}
        {isEditing ? (
          <TextField
            value={editedTodo?.description || ''}
            onChange={handleChange}
            fullWidth
            multiline
            name="description"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            size="small"
            sx={{
              borderRadius: 1,
              mb: 1,
              p: 1,
              background: theme.palette.background.default
            }}
          />
        ) : (
          <Typography
            variant="subtitle2"
            sx={{ cursor: 'pointer', width: '100%', minHeight: '10px' }}
            onClick={() => setIsEditing(true)}
          >
            {todo?.description}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
          {isEditing ? (
            <Autocomplete
              fullWidth
              multiple
              freeSolo
              defaultValue={[]}
              value={editedTodo?.tags || []}
              onChange={(event, newValues) => {
                setEditedTodo((prevTodo) => ({
                  ...prevTodo,
                  tags: newValues
                }));
              }}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              options={[]}
              filterOptions={(options, params) => {
                const filtered = filter(options, params) || [];
                if (params?.inputValue !== '') {
                  filtered?.push(params?.inputValue);
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return `Add ${option}`;
                }
              }}
              limitTags={5}
              renderTags={(value, getTagProps) =>
                (value || [])?.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    onDelete={handleDeleteTag(tag)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("Click 'Enter' to add tag")}
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  sx={{
                    borderRadius: 1,
                    p: 1,
                    background: theme.palette.background.default
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: editedTodo?.tags?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        onDelete={handleDeleteTag(tag)}
                      />
                    ))
                  }}
                />
              )}
            />
          ) : (
            <Stack direction="row" width="100%" flexWrap="wrap">
              {todo?.tags?.length > 0 ? (
                todo?.tags?.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onClick={() => setIsEditing(true)}
                    sx={{ mr: 0.2, mb: 0.4 }}
                    size="small"
                  />
                ))
              ) : (
                <Box
                  sx={{ width: '100%', height: '20px', cursor: 'pointer' }}
                  onClick={() => setIsEditing(true)}
                ></Box>
              )}
            </Stack>
          )}

          {!isEditing && (
            <IconButton onClick={handleDelete} size="small">
              <DeleteTwoTone color="secondary" />
            </IconButton>
          )}
        </Stack>
        {isEditing && (
          <Stack>
            <Button
              variant="contained"
              onClick={handleUpdateTodo}
              sx={{ mx: 'auto' }}
              size="small"
              startIcon={<DoneRounded />}
            >
              Save
            </Button>
          </Stack>
        )}
      </Box>
    </Paper>
  );
};

export default TodosCard;

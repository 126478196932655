import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

let initialState = {
  roles: [],
  status: { getRole: false, createRole: false },
  message: { success: 'null', error: null, ref: null }
};
export const fetchRoleAsync = createAsyncThunk(
  'rolemanagement/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData({ endPoint: 'role' });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createRoleAsync = createAsyncThunk(
  'rolemanagement/createRole',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const slice = createSlice({
  name: 'rolemanagement',
  initialState,
  reducers: {
    cleanMessageLog: (state, action) => {
      state.message.success = null;
      state.message.error = null;
      state.message.ref = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleAsync.pending, (state) => {
        state.status.getRole = true;
      })
      .addCase(fetchRoleAsync.fulfilled, (state, action) => {
        state.status.getRole = false;
        // state.ref = "getRole";
        state.roles = action?.payload?.data || [];
      })
      .addCase(fetchRoleAsync.rejected, (state, action) => {
        state.status.getRole = false;
        state.message.error = action.payload;
      })
      .addCase(createRoleAsync.pending, (state) => {
        state.status.createRole = true;
      })
      .addCase(createRoleAsync.fulfilled, (state, action) => {
        state.status.createRole = false;
        state.roles = [...state.roles, action?.payload];
        state.message.success = 'New Role Created';
        state.message.ref = 'rolePageHeader';
      })
      .addCase(createRoleAsync.rejected, (state, action) => {
        state.status.createRole = false;
        state.message.error = action.payload;
        state.message.ref = 'rolePageHeader';
      });
  }
});

export const { cleanMessageLog } = slice.actions;

// selectors
// export const selectEndPoints = (state) => state.roleAccessState.endPoints;
export const selectRoles = (state) => state.roleManagementState.roles;
export const selectMessage = (state) => state.roleManagementState.message;
export const selectStatus = (state) => state.roleManagementState.status;

export default slice.reducer;

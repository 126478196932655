import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import DyFormBody from './DyFormBody';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const DynamicForm = ({ currentItem }) => {
  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <DyFormBody currentItem={currentItem} />
        </Grid>
      </Grid>
    </>
  );
};

export default DynamicForm;

import { Typography } from '@mui/material';
import React from 'react';

const TermsAndCondition = ({ payment_terms, delivery_terms }) => {
  return (
    <>
      <Typography textAlign={'center'} variant={'subtitle1'}>
        All Request will be governed with below term and conditions
      </Typography>
      {payment_terms && payment_terms}
      {delivery_terms && delivery_terms}
    </>
  );
};

export default TermsAndCondition;

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Grid, Button, CircularProgress } from '@mui/material';

import { useSelector } from 'react-redux';
import {
  createDataAsync,
  selectDynamicPageConfig,
  selectStatus,
  updateDataAsync,
  setApiSuccess,
  selectApiSuccess
} from 'src/slices/dynamicSlice';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import ReactWorkFlowComponent from './ReactWorkflowComponent';
import { getSanitizedValue } from 'src/services/commonService';

const WorkflowFormBody = ({ currentItem }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const { entity } = useSelector(selectDynamicPageConfig);
  const navigate = useNavigate();
  const isApiSuccess = useSelector(selectApiSuccess);
  useEffect(() => {
    if (isApiSuccess) {
      navigate(-1);
    }
    return () => dispatch(setApiSuccess());
  }, [isApiSuccess, navigate, dispatch]);

  return (
    <Formik
      initialValues={{
        entity: currentItem?.entity || '',
        crud_type: currentItem?.crud_type || [],
        conditions: currentItem?.conditions || [],
        actions: currentItem?.actions || [],
        data: currentItem?.data || {
          nodes: [
            {
              id: 'trigger',
              data: {
                addNode: () => {},
                setEdges: () => {},
                setNodes: () => {}
              },
              position: { x: 0, y: 0 },
              type: 'trigger'
            }
          ],
          edges: []
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        console.log('Form Values:', values);
        let data = { ...values };

        data.actions.forEach((action, actionIndex) => {
          // Remove schema property from conditions array
          action.conditions.forEach((condition, conditionIndex) => {
            if (condition.schema) {
              delete condition.schema;
            }
          });

          // Remove schema property from payload array
          action.payload.forEach((payloadItem, payloadIndex) => {
            if (payloadItem?.value_type === 'Custom input') {
              const sanitizedValue = getSanitizedValue(
                payloadItem.field_name,
                payloadItem.field_value,
                payloadItem.schema
              );
              if (sanitizedValue !== '') {
                payloadItem.field_value = sanitizedValue;
              }
            }

            if (payloadItem.schema) {
              delete payloadItem.schema;
            }
          });
        });

        if (currentItem) {
          dispatch(
            updateDataAsync({
              endPoint: entity,
              data: { ...values },
              id: currentItem.id
            })
          );
        } else {
          dispatch(
            createDataAsync({
              endPoint: entity,
              data: { ...values }
            })
          );
        }
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReactWorkFlowComponent />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                startIcon={
                  currentItem ? (
                    status.updateData ? (
                      <CircularProgress size="1rem" />
                    ) : null
                  ) : status.createData ? (
                    <CircularProgress size="1rem" />
                  ) : null
                }
                disabled={currentItem ? status.updateData : status.createData}
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default WorkflowFormBody;

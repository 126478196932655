import React from 'react';
import { Stack, Typography } from '@mui/material';

const VerticalLabelValue = ({
  label,
  value,
  labelVariant = 'subtitle1',
  valueVariant = 'h5'
}) => (
  <Stack spacing={0.5}>
    <Typography variant={labelVariant}>{label}</Typography>
    <Typography variant={valueVariant}>{value}</Typography>
  </Stack>
);

export default VerticalLabelValue;

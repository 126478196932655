import React from 'react';
import { Chip, Stack, List, ListItem, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MoreItemsPopover from 'src/components/MoreItemsPopover';

const ItemList = ({ items, routePrefix, itemLabelKey }) => {
  if (!Boolean(items.length)) return '-';
  return (
    <Stack gap={1} direction={'row'} flexWrap={'wrap'}>
      {items?.slice(0, 3).map((item) => (
        <Chip
          clickable
          key={item.id}
          size="small"
          color={'primary'}
          variant="outlined"
          component={RouterLink}
          to={`/${routePrefix}/edit/${item.id}`}
          label={item[itemLabelKey]}
        />
      ))}
      {items?.length > 3 && (
        <MoreItemsPopover items={items} btnNum={items.length - 3}>
          <List>
            {items?.map((item) => (
              <ListItem key={item.id} size="small">
                <ListItemText
                  primary={item[itemLabelKey]}
                  component={RouterLink}
                  to={`/${routePrefix}/edit/${item.id}`}
                />
              </ListItem>
            ))}
          </List>
        </MoreItemsPopover>
      )}
    </Stack>
  );
};

export default ItemList;

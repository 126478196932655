import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import GrnFormBody from './GrnFormBody';
import { useState } from 'react';

const GrnForm = ({ currentItem }) => {
  const [activeTab, setActiveTab] = useState('order');

  const handleActiveTab = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      <PageTitleWrapper>
        <PageHeader activeTab={activeTab} handleActiveTab={handleActiveTab} />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <GrnFormBody
            currentItem={currentItem}
            activeTab={activeTab}
            handleActiveTab={handleActiveTab}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GrnForm;

import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import IndirectChargeCard from './IndirectChargeCard';
import OrderDetailsCard from './OrderDetailsCard';
import WarehouseCard from './WarehouseCard';
import DeliveryDetailCard from './DeliveryDetailCard';
import ReceiptCard from './ReceiptCard';

const OrderInformation = () => {
  return (
    <>
      <Stack gap={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <OrderDetailsCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <WarehouseCard />
          </Grid>
        </Grid>
        <DeliveryDetailCard />

        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <IndirectChargeCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReceiptCard />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default OrderInformation;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

let initialState = {
  settingData: [],
  paginator: null,
  status: {
    getSetting: false,
    createSetting: false,
    deleteSetting: false,
    updateSetting: false
  },
  message: { success: null, error: null, ref: null }
};

export const getSettingsAsync = createAsyncThunk(
  'setting/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSettingsAsync = createAsyncThunk(
  'setting/createData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSettingAsync = createAsyncThunk(
  'setting/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return {
          selectedEntity: data.selectedEntity,
          response: response?.data
        };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSettingsAsync = createAsyncThunk(
  'setting/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      if (response?.status === 'SUCCESS') {
        return {
          selectedEntity: data.selectedEntity,
          setting_type: data.setting_type
        };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message.success = null;
      state.message.error = null;
      state.message.ref = null;
    },
    refreshCollectionSettingState: (state, action) => {
      state.collectionSetting = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSettingsAsync.pending, (state) => {
        state.status.createSetting = true;
      })
      .addCase(createSettingsAsync.fulfilled, (state, action) => {
        state.status.createSetting = false;
        state.message.ref = 'create';
        state.settingData.push(action.payload);
        state.message.success = 'Setting created';
      })
      .addCase(createSettingsAsync.rejected, (state, action) => {
        state.status.createSetting = false;
        state.message.error = action.payload;
      })
      .addCase(getSettingsAsync.pending, (state) => {
        state.status.getSetting = true;
      })
      .addCase(getSettingsAsync.fulfilled, (state, action) => {
        state.status.getSetting = false;
        state.message.ref = 'read';
        state.settingData = action?.payload?.data || [];
        state.paginator = action?.payload?.paginator || null;
      })
      .addCase(getSettingsAsync.rejected, (state, action) => {
        state.status.getSetting = false;
        state.message.error = action.payload;
      })
      .addCase(updateSettingAsync.pending, (state) => {
        state.status.updateSetting = true;
      })
      .addCase(updateSettingAsync.fulfilled, (state, action) => {
        state.status.updateSetting = false;
        state.message.ref = 'update';
        state.message.success = 'Service Updated';
        let index = state.settingData.findIndex(
          (ele) => ele.id === action.payload.response.id
        );
        state.settingData.splice(index, 1, action.payload.response);
      })
      .addCase(updateSettingAsync.rejected, (state, action) => {
        state.status.updateSetting = false;
        state.message.error = action.payload;
      })
      .addCase(deleteSettingsAsync.pending, (state) => {
        state.status.deleteData = true;
      })
      .addCase(deleteSettingsAsync.fulfilled, (state, action) => {
        state.status.deleteData = false;
        state.message.ref = 'delete';
        const data = state.settingData.filter(
          (item) => item?.setting_type !== action.payload.setting_type
        );
        state.settingData = data;
      })
      .addCase(deleteSettingsAsync.rejected, (state, action) => {
        state.status.deleteData = false;
        state.message.error = action.payload;
      });
  }
});

export const { cleanMessageLog, refreshCollectionSettingState } = slice.actions;

// selectors
export const selectMessage = (state) => state.settingState.message;
export const selectSettingData = (state) => state.settingState.settingData;
export const selectStatus = (state) => state.settingState.status;
export const selectPaginator = (state) => state.settingState.paginator;
export default slice.reducer;

import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useSelector } from 'react-redux';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';

const AccentSidebarLayout = () => {
  const theme = useTheme();
  const { expand } = useSelector(selectCustomSettingData);

  return (
    <>
      <Header />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            pl: `${theme.sidebar.width}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1} p={theme.spacing(expand ? 2 : 4)}>
            <Outlet />
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentSidebarLayout;

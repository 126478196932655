/* eslint-disable react/prop-types */
import { ClearRounded, SearchRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';

const SearchBar = ({ setSearchQuery, searchQuery, setPage }) => {
  const theme = useTheme();

  const handleClearInput = () => {
    setSearchQuery('');
    setPage(0);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder={`type 'title' to search...`}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      // sx={{
      //   background: `${theme.colors.alpha.black[5]}`
      // }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded />
          </InputAdornment>
        ),
        endAdornment: searchQuery?.length > 0 && (
          <InputAdornment position="end">
            <IconButton onClick={handleClearInput} edge="end">
              <ClearRounded />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchBar;

import React, { useState } from 'react';
import {
  Grid,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Switch
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CardFieldSelect from './CardFieldSelect';
import CheckFieldInstance from './CheckFieldInstance';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
// import AddPropModal from './AddPropModal';

const elementOptions = [
  { value: 'avatar', label: 'Avatar' },
  { value: 'title', label: 'Title' },
  { value: 'description', label: 'Description' }
];

const CardDesignSetting = ({
  fieldNames,
  propPath,
  itemPath,
  handleBlur,
  schema,
  dataItem
}) => {
  const { setFieldValue } = useFormikContext();
  const cardDesignArray = itemPath?.cardDesign || [];
  const [openModal, setOpenModal] = useState(false);
  const isOptionSelected = (option, index) => {
    return cardDesignArray.some(
      (item, i) => i !== index && item.element_name === option.value
    );
  };
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <FieldArray name={`${propPath}.cardDesign`}>
        {({ push, remove }) => (
          <>
            {cardDesignArray.map((cardDesignItem, index) => (
              <Grid item xs={12} key={index}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={2}>
                    <CustomSelectFieldWithLabel
                      label="Select Element"
                      value={cardDesignItem?.element_name || ''}
                      options={elementOptions.filter(
                        (option) => !isOptionSelected(option, index)
                      )}
                      onChange={(e) => {
                        setFieldValue(
                          `${propPath}.cardDesign[${index}].element_name`,
                          e.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      name={`${propPath}.cardDesign[${index}].element_name`}
                    />
                  </Grid>
                  {cardDesignItem?.element_name && (
                    <CardFieldSelect
                      fieldNames={fieldNames}
                      cardEleItem={cardDesignItem}
                      cardEleIndex={index}
                      schema={schema}
                      propPath={propPath}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  )}
                  {cardDesignItem?.fieldName && (
                    <CheckFieldInstance
                      handleBlur={handleBlur}
                      schema={schema}
                      cardEleItem={cardDesignItem}
                      fieldname={cardDesignItem?.fieldName}
                      setFieldValue={setFieldValue}
                      propPath={propPath}
                      cardEleIndex={index}
                    />
                  )}
                  <Grid item xs={12} md={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {cardDesignItem?.fieldName &&
                        (schema[cardDesignItem?.fieldName].instance !==
                          'ObjectID' ||
                          cardDesignItem?.nestedField) && (
                          <>
                            {/* <Tooltip title="Prop Setting">
                              <IconButton
                                color="secondary"
                                onClick={handleOpenModal}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </Tooltip> */}
                            {cardDesignItem.element_name === 'avatar' && (
                              <Tooltip title="Is Logo">
                                <Switch
                                  checked={!!cardDesignItem.isLogo}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `${propPath}.cardDesign[${index}].isLogo`,
                                      e.target.checked
                                    );
                                  }}
                                  color="primary"
                                />
                              </Tooltip>
                            )}
                          </>
                        )}
                      <Tooltip title="Remove">
                        <IconButton
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                  {/* <AddPropModal
                    title={cardDesignItem?.element_name}
                    handleOpenModal={handleOpenModal}
                    openModal={openModal}
                    dataItem={dataItem}
                    index={index}
                    itemPath={itemPath}
                    // propsOptions={}
                    setFieldValue={setFieldValue}
                    propPath={propPath}
                    handleBlur={handleBlur}
                  /> */}
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                disabled={cardDesignArray.length === elementOptions.length}
                startIcon={<AddIcon />}
                onClick={() => push({ element_name: '', fieldName: '' })}
              >
                Add Element
              </Button>
            </Grid>
          </>
        )}
      </FieldArray>
    </>
  );
};

export default CardDesignSetting;

import { Box, IconButton, Tooltip, styled } from '@mui/material';
import HeaderNotifications from './Notifications';
// import LanguageSwitcher from './LanguageSwitcher';
import CustomThemeSettings from './CustomThemeSetting';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { Link } from 'react-router-dom';
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);
function HeaderButtons() {
  return (
    <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
      <Tooltip arrow title={'Projects'}>
        <IconButtonWrapper component={Link} to="/projects" color="primary">
          <AccountTreeTwoToneIcon fontSize="small" />
        </IconButtonWrapper>
      </Tooltip>
      <HeaderNotifications />
      <CustomThemeSettings />
    </Box>
  );
}

export default HeaderButtons;

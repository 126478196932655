import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import WorkspaceAdminAuth from 'src/components/WorkspaceAdminAuth';
import EntityVerifier from '../components/EntityVerifier';
import DynamicListPage from 'src/content/management/DynamicListPage';
import DynamicPageWrapper from 'src/components/DynamicPageWrapper';
import DynamicEntityForm from 'src/components/DynamicEntityForm';
import { workspaceSettingRoutes } from './workspaceSettingRoutes';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const Status403 = Loader(lazy(() => import('src/components/Status403')));
const HrManagement = Loader(lazy(() => import('src/content/hrManagement')));
const Users = Loader(lazy(() => import('src/content/management/Users')));
const Profile = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Setting = Loader(lazy(() => import('src/content/setting')));
const RoleManagement = Loader(lazy(() => import('src/content/roleManagement')));
const Dashboard = Loader(lazy(() => import('src/content/dashboard')));

const ProjectsBoard = Loader(
  lazy(() => import('src/content/applications/ProjectsBoard'))
);
// authenticated and has workspace
export const workspaceRoutes = [
  {
    path: '',
    element: <Navigate to="/dashboard" replace />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/:endPoint',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper>
          <DynamicListPage />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/:endPoint/:type',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper>
          <DynamicEntityForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/:endPoint/:type/:id',
    element: (
      <EntityVerifier>
        <DynamicPageWrapper>
          <DynamicEntityForm />
        </DynamicPageWrapper>
      </EntityVerifier>
    )
  },
  {
    path: '/roleManagement',
    element: <RoleManagement />
  },
  {
    path: '/projects',
    element: <ProjectsBoard />
  },
  {
    path: '/management/users',
    element: <Users />
  },
  {
    path: '/management/user/profile',
    element: <Profile />
  },

  {
    path: '/setting',
    element: <Setting />
  },
  {
    path: '/hr-management/:id',
    element: <HrManagement />
  },
  {
    path: '/hr-management/:id',
    element: <HrManagement />
  },
  {
    path: '/workspace-setting',
    element: <WorkspaceAdminAuth element={<Outlet />} />,
    children: workspaceSettingRoutes
  },
  {
    path: '/forbidden',
    element: <Status403 />
  }
];

// import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router';
// import { Outlet } from 'react-router-dom';
// import SuspenseLoader from 'src/components/SuspenseLoader';
// import { workspaceSettingRoutes } from './workspaceSettingRoutes';
// import WorkspaceAdminAuth from 'src/components/WorkspaceAdminAuth';
// import EntityVerifier from '../components/EntityVerifier';

// // eslint-disable-next-line react/display-name
// const Loader = (Component) => (props) => {
//   return (
//     <Suspense fallback={<SuspenseLoader />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

// const Status403 = Loader(lazy(() => import('src/components/Status403')));
// const DynamicPage = Loader(
//   lazy(() => import('src/content/management/DynamicPage'))
// );
// const HrManagement = Loader(lazy(() => import('src/content/hrManagement')));
// const Users = Loader(lazy(() => import('src/content/management/Users')));
// const Profile = Loader(
//   lazy(() => import('src/content/management/Users/single'))
// );
// const Setting = Loader(lazy(() => import('src/content/setting')));
// const RoleManagement = Loader(lazy(() => import('src/content/roleManagement')));
// const Dashboard = Loader(lazy(() => import('src/content/dashboard')));

// const ProjectsBoard = Loader(
//   lazy(() => import('src/content/applications/ProjectsBoard'))
// );
// // const ViewPage = Loader(lazy(() => import('src/content/management/ViewPage')));
// // authenticated and has workspace
// export const workspaceRoutes = [
//   {
//     path: '',
//     element: <Navigate to="/dashboard" replace />
//   },
//   {
//     path: '/dashboard',
//     element: <Dashboard />
//   },
//   {
//     path: '/:endPoint',
//     element: (
//       <EntityVerifier>
//         <DynamicPage />
//       </EntityVerifier>
//     )
//   },
//   {
//     path: '/:endPoint/:type',
//     element: (
//       <EntityVerifier>
//         <DynamicPage />
//       </EntityVerifier>
//     )
//   },
//   {
//     path: '/:endPoint/:type/:id',
//     element: (
//       <EntityVerifier>
//         <DynamicPage />
//       </EntityVerifier>
//     )
//   },
//   {
//     path: '/roleManagement',
//     element: <RoleManagement />
//   },
//   {
//     path: '/projects',
//     element: <ProjectsBoard />
//   },
//   {
//     path: '/management/users',
//     element: <Users />
//   },
//   {
//     path: '/management/user/profile',
//     element: <Profile />
//   },

//   {
//     path: '/setting',
//     element: <Setting />
//   },
//   {
//     path: '/hr-management/:id',
//     element: <HrManagement />
//   },
//   {
//     path: '/hr-management/:id',
//     element: <HrManagement />
//   },
//   {
//     path: '/forbidden',
//     element: <Status403 />
//   },
//   {
//     path: '/workspace-setting',
//     element: <WorkspaceAdminAuth element={<Outlet />} />,
//     children: workspaceSettingRoutes
//   }
// ];

import React, { useState } from 'react';
import { Card, CardHeader, Stack, IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Handle, Position } from 'reactflow';
import { useFormikContext } from 'formik';
import CustomDialogComponent from 'src/components/CustomDialogComponent';
import AddIcon from '@mui/icons-material/Add';
import AddActionForm from '../ActionCard/AddActionForm';
import AddTrigger from './AddTrigger';

const TriggerCard = ({ id, data }) => {
  const { addNode, setEdges, setNodes } = data;
  const { values } = useFormikContext();
  const [actionDialog, setActionDialog] = useState(false);
  const [triggerDialog, setTriggerDialog] = useState(false);

  const handleAddNode = () => {
    const position = { x: 35, y: 170 };
    addNode(id, position);
    handleActionDialog();
  };

  const handleActionDialog = () => {
    setActionDialog(!actionDialog);
  };

  const handleTriggerDialog = () => {
    setTriggerDialog(!triggerDialog);
  };

  return (
    <>
      <Stack spacing={2} alignItems="center">
        <Card
          sx={{
            width: 400
          }}
        >
          <CardHeader
            title="Trigger"
            subheader="Configure when this trigger will activate"
            action={
              <>
                <Tooltip title={'Click to configure'}>
                  <IconButton onClick={handleTriggerDialog}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Action">
                  <IconButton onClick={handleAddNode}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          />
        </Card>
        <Handle type="source" position={Position.Bottom} />
      </Stack>
      <CustomDialogComponent
        open={actionDialog}
        fullScreen={true}
        // handleOpen={handleActionDialog}
      >
        <AddActionForm
          handleDialog={handleActionDialog}
          actionIndex={values.actions.length - 1}
          action={values.actions[values.actions.length - 1]}
          setEdges={setEdges}
          setNodes={setNodes}
          sourceId={id}
        />
      </CustomDialogComponent>
      <CustomDialogComponent
        open={triggerDialog}
        fullScreen={true}
        // handleDialog={handleTriggerDialog}
      >
        <AddTrigger handleDialog={handleTriggerDialog} />
      </CustomDialogComponent>
    </>
  );
};

export default TriggerCard;

// import React, { useState } from 'react';
// import { Card, CardHeader, Stack, IconButton, Tooltip } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
// import { Handle, Position } from 'reactflow';
// import CustomDialogComponent from 'src/components/CustomDialogComponent';
// import AddIcon from '@mui/icons-material/Add';
// import { useFormikContext } from 'formik';
// import AddActionForm from './AddActionForm';
// import AddTrigger from './AddTrigger';

// const TriggerCard = ({ id, data }) => {
//   const { setEdges, setNodes, addNode } = data;
//   const { values, setFieldValue } = useFormikContext();
//   const [actionDialog, setActionDialog] = useState(false);
//   const [triggerDialog, setTriggerDialog] = useState(false);

//   const handleActionDialog = () => {
//     const actionLength = values.actions.length;
//     const payload = {
//       action_id: actionLength + 1,
//       run_action: '',
//       name: '',
//       action_type: '',
//       entity: '',
//       crud_type: '',
//       query: [],
//       payload: [],
//       conditions: [],
//       depends_on: []
//     };
//     setFieldValue(`actions[${actionLength}]`, payload);
//     setActionDialog(true);
//   };
//   const handlAddNode = () => {
//     const position = { x: 35, y: 170 };
//     addNode(id, position);
//     setActionDialog(false);
//   };

//   const handleTriggerDialog = () => {
//     setTriggerDialog(!triggerDialog);
//   };

//   return (
//     <>
//       <Stack spacing={2} alignItems="center">
//         <Card
//           sx={{
//             width: 400
//           }}
//         >
//           <CardHeader
//             title="Trigger"
//             subheader="Configure when this trigger will activate"
//             action={
//               <>
//                 <Tooltip title={'Click to configure'}>
//                   <IconButton onClick={handleTriggerDialog}>
//                     <SettingsIcon />
//                   </IconButton>
//                 </Tooltip>
//                 <Tooltip title="Add Action">
//                   <IconButton onClick={handleActionDialog}>
//                     <AddIcon />
//                   </IconButton>
//                 </Tooltip>
//               </>
//             }
//           />
//         </Card>
//         <Handle type="source" position={Position.Bottom} />
//       </Stack>
//       <CustomDialogComponent open={actionDialog} fullScreen={true}>
//         {values?.actions?.[values.actions.length - 1] && (
//           <AddActionForm
//             handleDialog={handlAddNode}
//             actionIndex={values.actions.length - 1}
//             action={values.actions[values.actions.length - 1]}
//             setEdges={setEdges}
//             setNodes={setNodes}
//             addNode={addNode}
//           />
//         )}
//       </CustomDialogComponent>
//       <CustomDialogComponent
//         open={triggerDialog}
//         fullScreen={true}
//         handleOpen={handleTriggerDialog}
//       >
//         <AddTrigger handleDialog={handleTriggerDialog} />
//       </CustomDialogComponent>
//     </>
//   );
// };

// export default TriggerCard;

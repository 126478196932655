import { Autocomplete, TableCell, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import useRefMounted from 'src/hooks/useRefMounted';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const AccordianAutoComplete = ({
  fieldName,
  handleBlur,
  errors,
  touched,
  schema,
  itemTableHead,
  dataItem,
  index,
  setFieldValue
}) => {
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const { entitySetting } = useSelector(selectDynamicPageConfig);
  const [loading, setLoading] = useState(false);
  const isMounted = useRefMounted();
  const getOption = async (
    searchValue,
    populateFieldName,
    fieldToSearch,
    select
  ) => {
    setLoading(true);
    const endPoint = schema[populateFieldName]?.options?.ref;

    const queryObject = searchValue
      ? { [fieldToSearch]: { $regex: searchValue, $options: 'i' } }
      : {};

    try {
      const response = await requestApi.getData({
        endPoint,
        query: queryObject,
        select
      });

      if (isMounted.current) {
        if (response?.status === 'SUCCESS') {
          setOptions((prevOptions) => {
            let newOptions = response?.data?.data || [];
            if (
              dataItem[itemTableHead] &&
              Array.isArray(dataItem[itemTableHead])
            ) {
              let filterOption = newOptions.filter(
                (newOption) =>
                  !dataItem[itemTableHead].some(
                    (existingOption) => existingOption.id === newOption.id
                  )
              );
              newOptions = [...filterOption, ...dataItem[itemTableHead]];
            }

            return newOptions;
          });
        } else {
          if (isMounted.current) {
            setOptions((preValue) =>
              dataItem[itemTableHead] && Array.isArray(dataItem[itemTableHead])
                ? dataItem[itemTableHead]
                : []
            );
            console.error(response?.message);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const propertyName = useMemo(() => {
    return entitySetting?.populate_fields?.find(
      (populateFieldItem) => populateFieldItem.populate_field === fieldName
    );
  }, [entitySetting, fieldName]);

  useEffect(() => {
    let clearTime = setTimeout(() => {
      if (propertyName) {
        // if (query !== '') {
        let select = [propertyName.field_show];
        getOption(
          query,
          propertyName.populate_field,
          propertyName.field_show,
          select
        );
        // }
      } else {
        if (options.length === 0) {
          let select = ['id'];
          getOption(null, itemTableHead, null, select);
        }
      }
    }, 500);
    return () => {
      clearTimeout(clearTime);
    };
  }, [entitySetting, query, fieldName, propertyName]);

  return (
    <TableCell>
      <Autocomplete
        disablePortal
        // multiple={isArrayWithObjectID}
        id={fieldName}
        options={options}
        value={dataItem[itemTableHead] || null}
        inputValue={query}
        onBlur={handleBlur}
        onChange={(event, value) =>
          setFieldValue(
            // Update the specific item at the given index
            `${fieldName}.${index}.${itemTableHead}`,
            value
          )
        }
        onInputChange={(event, value) => setQuery(value)}
        loading={loading}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) =>
          option
            ? option?.[propertyName && propertyName.field_show] ||
              option?.id ||
              ''
            : ''
        }
        renderInput={(params) => (
          <TextField
            // error={Boolean(touched[fieldName] && errors[fieldName]?.id)}
            // helperText={(touched[fieldName] && errors[fieldName]?.id) || ''}
            {...params}
            label={fieldName}
          />
        )}
      />
    </TableCell>
  );
};

export default AccordianAutoComplete;

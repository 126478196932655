import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSetting,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';
import Contrast from './Contrast';
import Mode from './Mode';
import Expand from './Expand';
import ExpandScreen from './ExpandScreen';
import Layouts from './Layouts';
import Presets from './Presets';
const CustomThemeSettingDrawer = ({ toggleDrawer, openDrawer }) => {
  const dispatch = useDispatch();
  const { reset } = useSelector(selectCustomSettingData);
  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 300
        }
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={2}
        py={1.5}
      >
        <Typography variant="h4">Settings</Typography>
        <Box>
          <IconButton onClick={() => dispatch(resetSetting())}>
            <Badge color="error" variant={reset ? 'dot' : ''}>
              <RefreshIcon fontSize="small" />
            </Badge>
          </IconButton>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Stack rowGap={2} p={2} height={'80%'} sx={{ overflowY: 'auto' }}>
        <Mode />
        <Contrast />
        {/* <Direction /> */}
        <Layouts />
        <Expand />
        <Presets />
      </Stack>
      <Stack p={2}>
        <ExpandScreen />
      </Stack>
    </Drawer>
  );
};

export default CustomThemeSettingDrawer;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  Modal,
  styled,
  Button,
  Grid,
  Stack
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import FileGrid from 'src/components/FileGrid'; // Adjust the path as necessary
import ImageGrid from 'src/components/ImageGrid'; // Adjust the path as necessary
import useFileUpload from 'src/hooks/useFileUpload';
import ImageGallery from 'src/components/ImageGallery';

const BoxUploadWrapper = styled(Grid)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
  padding: ${theme.spacing(3)};
  background: ${theme.colors.alpha.black[5]};
  border: 1px dashed ${theme.colors.alpha.black[30]};
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: ${theme.transitions.create(['border', 'background'])};
  height: 100%;

  &:hover {
    background: ${theme.colors.alpha.white[100]};
    border-color: ${theme.colors.primary.main};
  }
`
);

const MultipleFileUpload = ({ fieldName, value, name, isFile = false }) => {
  const { setFieldValue } = useFormikContext();
  const [files, setFiles] = useState(value || []);
  const [open, setOpen] = useState(false);
  const { uploadFiles, loading, deleteFile } = useFileUpload();

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.warning(
          `Only ${isFile ? 'document' : 'image'} files are allowed!`
        );
        return;
      }

      const newFiles = acceptedFiles.map((file) => ({
        file,
        path: URL.createObjectURL(file),
        upload: true,
        type: file.type
      }));

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    },
    [isFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: isFile
      ? {
          'application/*': ['.pdf', '.doc', '.docx', '.xls', '.xlsx']
        }
      : { 'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg'] }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (file, index) => {
    if (!file?.upload) {
      try {
        await deleteFile(file.path);
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        setFieldValue(name, updatedFiles);
      } catch (error) {
        console.log('Failed to delete file:', error);
      }
    } else {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
      setFieldValue(name, updatedFiles);
    }
  };

  const handleUpload = async () => {
    const filesToUpload = files
      .filter((file) => file.upload)
      .map((file) => file.file);

    const updateFiles = (uploadedFiles) => {
      const updatedFiles = [
        ...files.filter((file) => !file.upload),
        ...uploadedFiles
      ];
      setFiles(updatedFiles);
      setFieldValue(name, updatedFiles);
    };

    await uploadFiles(filesToUpload, updateFiles);
  };

  const allFilesUploaded =
    files.length > 0 && files.every((file) => !file.upload);

  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Upload {fieldName}
        </Button>
        <Box>
          {files.length > 0 ? (
            isFile ? (
              <Typography variant="body2" color="textSecondary">
                {files.length} {files.length > 1 ? 'files' : 'file'} selected
              </Typography>
            ) : (
              <ImageGallery images={files} fieldName={fieldName} />
            )
          ) : (
            <Typography variant="body2" color="textSecondary">
              No {isFile ? 'files' : 'images'} uploaded
            </Typography>
          )}
        </Box>
      </Stack>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            display: 'flex'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h2" textAlign="center" mb={2}>
                Upload {fieldName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={isFile ? 12 : 6}>
              <BoxUploadWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                <CloudUploadIcon sx={{ fontSize: 60, mb: 2 }} />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  textAlign="center"
                >
                  {isDragActive
                    ? `Drop the ${fieldName} ${
                        isFile ? 'file' : 'image'
                      } here...`
                    : `Drag 'n' drop ${isFile ? 'a file' : 'an image'} here,`}
                </Typography>
                {!isDragActive && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    textAlign="center"
                  >
                    or{' '}
                    <Typography
                      component="span"
                      variant="body1"
                      color="primary"
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      click to select one
                    </Typography>
                  </Typography>
                )}
              </BoxUploadWrapper>
            </Grid>
            {files.length > 0 ? (
              isFile ? (
                <FileGrid
                  files={files}
                  handleDelete={handleDelete}
                  loading={loading}
                />
              ) : (
                <ImageGrid
                  images={files}
                  fieldName={fieldName}
                  handleDelete={handleDelete}
                  loading={loading}
                />
              )
            ) : (
              <Grid item xs={12} md={isFile ? 12 : 6}>
                <Typography variant="h6" align="center" color="textSecondary">
                  No {isFile ? 'files' : 'images'} uploaded
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} textAlign={'center'}>
              <Button
                onClick={
                  files.length === 0
                    ? handleClose
                    : allFilesUploaded
                    ? handleClose
                    : handleUpload
                }
                variant="contained"
                startIcon={
                  files.length === 0 || allFilesUploaded ? null : (
                    <CloudUploadIcon fontSize="1rem" />
                  )
                }
                disabled={loading}
              >
                {files.length === 0
                  ? 'Close'
                  : allFilesUploaded
                  ? 'Done'
                  : 'Upload'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default MultipleFileUpload;

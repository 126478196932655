import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { getAllRequiredFields } from 'src/services/commonService';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { requestApi } from 'src/mocks/request';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';
import { initDynamicFormValues } from 'src/utils/initDynamicFormValues';

const AddOptionForm = ({
  schema,
  entitySetting,
  fieldNames: allfieldName,
  entity,
  inputValue,
  isModal,
  handleDrawer,
  handleModal,
  fieldShow,
  setOptions
}) => {
  const isMounted = useRefMounted();
  const [loading, setLoading] = useState(false);

  const fieldNames = useMemo(() => {
    let filterFieldNames = allfieldName.filter(
      (fieldName) => !REMOVE_FIELDS.includes(fieldName)
    );
    let requiredFieldNames = getAllRequiredFields(filterFieldNames, schema);
    if (isModal) {
      if (!requiredFieldNames.includes(fieldShow)) {
        requiredFieldNames.push(fieldShow);
      }
      return requiredFieldNames;
    }

    filterFieldNames = entitySetting?.create_fields || filterFieldNames;
    return [...new Set([...requiredFieldNames, ...filterFieldNames])];
  }, [entitySetting, allfieldName, isModal]);
  const { initialValues, validationSchema } = useMemo(
    () =>
      initDynamicFormValues({
        schema,
        fieldNames,
        inputValue,
        fieldShow
      }),
    [schema, fieldNames, inputValue]
  );

  const handleAllDetails = () => {
    handleModal();
    handleDrawer();
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const { createData } = sanitizeFormData(values, schema, fieldNames);
      const payload = {
        endPoint: entity,
        data: createData
      };
      const response = await requestApi.createData(payload);
      if (response.data) {
        setOptions((prevOptions) => [...prevOptions, response.data]);
        if (isModal) {
          handleModal();
        } else {
          handleDrawer();
        }
      }
    } catch (error) {
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, handleSubmit, touched, values }) => {
        console.log(errors, touched, 'touched');
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fieldNames.map((fieldName, index) => (
                <Grid item xs={12} key={`${index}_${fieldName}_fieldName`}>
                  <GetFieldBasedOnSchema
                    name={fieldName}
                    fieldName={fieldName}
                    schema={schema}
                    entitySetting={entitySetting}
                    value={values[fieldName]}
                    error={errors[fieldName]}
                    touched={touched[fieldName]}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack
                  justifyContent={isModal ? 'flex-start' : 'center'}
                  spacing={1}
                  mt={isModal ? 1 : 0}
                >
                  <Button
                    type="submit"
                    startIcon={
                      loading ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={loading}
                    variant="contained"
                  >
                    Add New {entity}
                  </Button>
                  {isModal && (
                    <Button
                      sx={{ p: 0, alignSelf: 'center' }}
                      type="button"
                      variant="text"
                      color="primary"
                      onClick={handleAllDetails}
                    >
                      Add all {entity} details
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddOptionForm;

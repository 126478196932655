import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import randomId from 'src/utils/randomId';
import { useRef } from 'react';
import ViewAction from '../ViewAction';

const componentMap = {
  Container: (props) => <Container {...props}>{props.children}</Container>,
  Text: (props) => <Typography {...props}>{props.value}</Typography>,
  PageRow: (props) => (
    <Box display="flex" justifyContent={props.justifyContent} {...props}>
      {props.children}
    </Box>
  ),
  DataGrid: (props) => (
    <DataGrid {...props} autoHeight getRowId={(row) => randomId()} />
  ),
  Grid: (props) => <Grid {...props}>{props.children}</Grid>,
  Paper: (props) => <Paper {...props}>{props.children}</Paper>,
  Card: (props) => (
    <Card {...props}>
      <CardContent>{props.children}</CardContent>
    </Card>
  )
};

const safeEval = (expression, context) => {
  try {
    // Ensure the expression is wrapped in a function to execute it safely
    const func = new Function(
      'context',
      `with(context) { return ${expression}; }`
    );

    return func(context);
  } catch (e) {
    console.error('Error evaluating expression:', e);
    return '';
  }
};

const processProps = (props, context) => {
  const processedProps = { ...props };
  for (const key in processedProps) {
    if (
      processedProps[key] &&
      typeof processedProps[key] === 'object' &&
      processedProps[key]?.$$jsExpression
    ) {
      processedProps[key] = safeEval(
        processedProps[key].$$jsExpression,
        context
      );
    }
  }
  return processedProps;
};

const processStyles = (layout) => {
  const styles = {};
  if (layout) {
    if (layout.horizontalAlign) {
      styles.textAlign = layout.horizontalAlign;
    }
    if (layout.verticalAlign) {
      styles.verticalAlign = layout.verticalAlign;
    }
    if (layout.columnSize) {
      styles.flex = layout.columnSize;
    }
  }
  return styles;
};

const renderComponent = (componentConfig, index, context) => {
  const { component, props, children, layout } = componentConfig;

  const Component = componentMap[component];

  if (!Component) {
    return null;
  }

  const processedProps = processProps(props, context);
  const styles = processStyles(layout);

  const childElements = children
    ? children.map((child, idx) => renderComponent(child, idx))
    : null;

  return (
    <Component key={index} {...processedProps} style={styles}>
      {childElements}
    </Component>
  );
};

const TemplateViewPage = ({ data, template }) => {
  const { schema } = useSelector(selectDynamicPageConfig);
  const componentRef = useRef(null);
  return (
    <>
      <Container maxWidth={template?.content?.spec?.maxWidth}>
        <ViewAction componentRef={componentRef} />
        <Box ref={componentRef} mt={0.5}>
          {template?.content?.spec?.content?.map((content, index) =>
            renderComponent(content, index, {
              getschema: schema,
              getData: data
            })
          )}
        </Box>
      </Container>
    </>
  );
};

export default TemplateViewPage;

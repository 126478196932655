import { AddTwoTone } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { forwardRef, useState } from 'react';
import SettingForm from './SettingForm';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.primary.contrastText,
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.primary.main, 0.8)
//   }
// }));

const PageHeader = ({ selectedEntity = null, handleSettingModel = null }) => {
  const [showDialog, setShowDialog] = useState(false);
  const handleDialog = () => {
    setShowDialog(!showDialog);
  };
  const formattedTitle = selectedEntity
    ? capitalizeAndRemoveChar(selectedEntity)
    : '';

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {selectedEntity ? `${formattedTitle} Setting` : 'Website Setting'}
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleDialog}
              endIcon={<AddTwoTone fontSize="small" />}
            >
              Create Setting
            </Button>
            {selectedEntity && (
              <Tooltip title={'Cancel'}>
                <IconButton
                  onClick={() => handleSettingModel((prev) => !prev)}
                  color="primary"
                >
                  <ArrowBackTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        open={showDialog}
        onClose={handleDialog}
        sx={{ zIndex: '1000' }}
        TransitionComponent={Transition}
        fullScreen
      >
        <SettingForm
          handleDialog={handleDialog}
          selectedEntity={selectedEntity}
        />
      </Dialog>
    </>
  );
};
export default PageHeader;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  refreshDynamicSliceState,
  selectDynamicPageConfig,
  setApprovalSetting,
  setDefaultQuery,
  setDynamicPageConfig,
  setTitle
} from 'src/slices/dynamicSlice';
import {
  capitalizeAndRemoveChar,
  getAllRequiredFields,
  getPopulateString
} from 'src/services/commonService';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
import useEntity from 'src/hooks/useEntity';
import { requestApi } from 'src/mocks/request';
import TablePageLoader from 'src/components/SkeltonLoaders/TablePageLoader';
import ViewPageLoader from 'src/components/SkeltonLoaders/ViewPageLoader';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';

const DynamicPageWrapper = ({ children }) => {
  const { endPoint, type } = useParams();
  const dispatch = useDispatch();
  const { menuItems, companyEntitySettings } = useSelector(selectAppConfigData);
  const dynamicPageConfig = useSelector(selectDynamicPageConfig);
  const { entity } = useEntity(endPoint);
  const { schema, entitySetting } = useEntityPageConfig(entity);

  useEffect(() => {
    return () => {
      dispatch(refreshDynamicSliceState());
    };
  }, [entity]);

  // Check if approval setting exists for this entity
  useEffect(() => {
    // Define the async function inside useEffect
    const fetchData = async () => {
      try {
        const payload = {
          endPoint: 'approval_setting',
          query: { entity }
        };
        const response = await requestApi.getData(payload);
        if (Boolean(response?.data?.data?.length)) {
          dispatch(setApprovalSetting(response?.data?.data[0]));
        }
        console.log(response, 'responseresponse');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the async function
    fetchData();
  }, [dispatch, entity]); // Make sure entity is defined in the scope

  useEffect(() => {
    const formattedTitle = capitalizeAndRemoveChar(entity, '_');

    if (companyEntitySettings && companyEntitySettings.length > 0) {
      const data = companyEntitySettings.find(
        (entitySetting) => entitySetting.entity_name === entity
      );

      const title = data && data.new_name ? data.new_name : formattedTitle;
      dispatch(setTitle(title));
    } else {
      dispatch(setTitle(formattedTitle));
    }
  }, [companyEntitySettings, entity, endPoint, dispatch]);

  useEffect(() => {
    if (schema) {
      const fieldNames = Object.keys(schema);
      let filterFieldNames = fieldNames.filter(
        (fieldName) => !REMOVE_FIELDS.includes(fieldName)
      );
      let requiredFieldNames = getAllRequiredFields(filterFieldNames, schema);
      const populateString = getPopulateString(schema, fieldNames);
      dispatch(
        setDynamicPageConfig({
          fieldNames: fieldNames.filter(
            (fieldName) =>
              !['approval_status', 'approval_id'].includes(fieldName)
          ),
          populateString,
          requiredFieldNames,
          entity,
          schema,
          entitySetting
        })
      );
    }
  }, [schema, entitySetting, dispatch]);
  useEffect(() => {
    if (menuItems.length && entity) {
      const foundMenuItem = menuItems
        .flatMap((menuItem) => menuItem.items)
        .find((item) => item.link === endPoint);

      const defaultQuery = foundMenuItem ? foundMenuItem.query : null;
      dispatch(setDefaultQuery(defaultQuery));
    }
  }, [menuItems, entity, endPoint, dispatch]);
  if (!dynamicPageConfig) {
    return type === 'view' ? (
      <ViewPageLoader />
    ) : type === 'edit' || type === 'create' ? (
      <CreateEditPageLoader />
    ) : (
      <TablePageLoader />
    );
  }
  return children;
};

export default DynamicPageWrapper;

import React from 'react';
import { Grid, IconButton, Button, Stack } from '@mui/material';
import { FieldArray } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ColorPickerComponent from 'src/components/dynamicFields/ColorPickerComponent';
import CustomTextField from 'src/components/CustomFields/CustomTextField';

const RangeFields = ({
  propPath,
  propIndex,
  handleBlur,
  setFieldValue,
  ranges
}) => (
  <FieldArray
    name={`${propPath}.props[${propIndex}].ranges`}
    render={(arrayHelpers) => (
      <>
        {ranges?.map((range, rangeIndex) => (
          <Grid item xs={12} key={rangeIndex}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <CustomTextField
                  label="Start Value"
                  inputProps={{ min: 0, max: 100, type: 'number' }}
                  value={range?.startValue || ''}
                  onChange={(e) => {
                    setFieldValue(
                      `${propPath}.props[${propIndex}].ranges[${rangeIndex}].startValue`,
                      Number(e.target.value)
                    );
                  }}
                  onBlur={handleBlur}
                  name={`${propPath}.props[${propIndex}].ranges[${rangeIndex}].startValue`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomTextField
                  label="End Value"
                  inputProps={{ min: 0, max: 100, type: 'number' }}
                  value={range?.endValue || ''}
                  onChange={(e) => {
                    setFieldValue(
                      `${propPath}.props[${propIndex}].ranges[${rangeIndex}].endValue`,
                      Number(e.target.value)
                    );
                  }}
                  onBlur={handleBlur}
                  name={`${propPath}.props[${propIndex}].ranges[${rangeIndex}].endValue`}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <ColorPickerComponent
                    title={'Select Bar Color'}
                    color={
                      range?.barColor?.rgb || {
                        r: 255,
                        g: 255,
                        b: 255,
                        a: 1
                      }
                    }
                    onChange={(color) =>
                      setFieldValue(
                        `${propPath}.props[${propIndex}].ranges[${rangeIndex}].barColor`,
                        { ...color }
                      )
                    }
                    onBlur={handleBlur}
                  />
                  <IconButton
                    onClick={() => arrayHelpers.remove(rangeIndex)}
                    aria-label="remove"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            onClick={() =>
              arrayHelpers.push({
                startValue: '',
                endValue: '',
                barColor: ''
              })
            }
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add Range
          </Button>
        </Grid>
      </>
    )}
  />
);

export default RangeFields;

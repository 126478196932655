import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const WorkspaceSetting = Loader(
  lazy(() => import('src/content/WorkspaceSetting'))
);
const DepartmentManage = Loader(
  lazy(() => import('src/content/WorkspaceSetting/DepartmentManage'))
);

const ApprovalManage = Loader(
  lazy(() => import('src/content/WorkspaceSetting/ApprovalManage'))
);

const UserManagement = Loader(
  lazy(() => import('src/content/WorkspaceSetting/UserManagement'))
);
const DocumentNumberConfig = Loader(
  lazy(() => import('src/content/WorkspaceSetting/DocumentNumberConfig'))
);
const ViewTemplates = Loader(lazy(() => import('src/content/viewTemplates')));

export const workspaceSettingRoutes = [
  {
    path: '',
    element: <Navigate to="/workspace-setting/dashboard" replace />
  },
  {
    path: 'dashboard',
    element: <WorkspaceSetting />
  },
  {
    path: 'employee',
    element: <UserManagement />
  },
  {
    path: 'departments',
    element: <DepartmentManage />
  },
  {
    path: 'approval',
    element: <ApprovalManage />
  },
  {
    path: 'document_number_cofig',
    element: <DocumentNumberConfig />
  },
  {
    path: 'view-templates',
    element: <ViewTemplates />
  }
];

import { Skeleton, TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const DynamicTableLoader = ({ rowsNum, fieldNames }) => {
  const { endPoint } = useParams();
  const { entitySetting } = useSelector(selectDynamicPageConfig);
  const [colNumber, setColNumber] = useState(0);

  useEffect(() => {
    const localStorageFieldname = localStorage.getItem(
      `filteredFieldNames_${endPoint}`
    );
    let columnCount = 0;

    if (localStorageFieldname) {
      columnCount = JSON.parse(localStorageFieldname)?.length;
    } else if (entitySetting?.show_fields) {
      columnCount = entitySetting?.show_fields.length;
    } else {
      columnCount = fieldNames.length;
    }

    setColNumber(columnCount);
  }, [entitySetting, fieldNames]);

  return (
    <>
      {[...Array(rowsNum)].map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="rounded" height={20} />
          </TableCell>
          {[...Array(colNumber)].map((_, innerIndex) => (
            <TableCell key={innerIndex}>
              <Skeleton variant="rounded" width={'100%'} height={20} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default DynamicTableLoader;

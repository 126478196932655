import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { requestApi } from 'src/mocks/request';
import { Axios } from 'src/services/axios';

const initialState = {
  tasks: [],
  selectedTask: {},
  status: {
    createTask: false,
    deleteTask: false,
    updateTask: false,
    getTask: false,
    getOneTask: false
  }
  // message: { success: null, error: null, for: null, isOptionCreate: false }
};

// Async thunk to create a task
export const createTaskAsync = createAsyncThunk(
  'tasks/createData',
  async (createData, { rejectWithValue }) => {
    try {
      const response = await requestApi.createData(createData);
      if (response?.status === 'SUCCESS') {
        if (response?.data?.is_sub_task) {
          const updateRes = await Axios.put(
            `/project_tasks/update/${createData?.data?.parentTask}`,
            {
              options: {
                populate: 'sub_tasks'
              },
              $push: { sub_tasks: response?.data?.id }
            }
          );
          if (updateRes?.data?.status === 'SUCCESS') {
            let modifiedRes = updateRes?.data?.data;
            return {
              parent: modifiedRes,
              created: response.data.data,
              isSubTask: true
            };
          }
        } else {
          console.log(response?.data);
          return response?.data;
        }
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk to get tasks
export const getTaskAsync = createAsyncThunk(
  'tasks/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      console.log(response, 'responsegettask');
      if (response?.status === 'SUCCESS') {
        return response.data.data; // Assuming response.data contains the task data
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaskAsync = createAsyncThunk(
  'tasks/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        console.log(response?.data, 'updated sidedrawer');
        return response.data; // Assuming response.data contains the task data
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTaskManyAsync = createAsyncThunk(
  'tasks/updateBulk',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateBulk(data);
      if (response?.status === 'SUCCESS') {
        console.log(response?.data, 'updated tasks');
        const dispatch = useDispatch();
        dispatch(updateTaskState({ payload: data.data }));
        return response.data; // Assuming response.data contains the task data
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Async thunk to delete a task
export const deleteTaskAsync = createAsyncThunk(
  'tasks/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      console.log(response, 'responsetaskdelete');
      if (response?.status === 'SUCCESS') {
        return data.id; // Returning the deleted task ID
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOneTaskAsync = createAsyncThunk(
  'project/getOneTaskAsync',
  async (subTaskId, { rejectWithValue }) => {
    try {
      let response = await Axios.get(`/project_tasks/${subTaskId}`);
      if (response?.data?.status === 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'dynamicSlice',
  initialState,
  reducers: {
    updateTaskState: (state, action) => {
      const { payload } = action.payload;
      state.tasks = state.tasks.map((task) => {
        const updatedTask = payload.find((t) => t.id === task.id);
        if (updatedTask) {
          return {
            ...task,
            start: updatedTask.start_date,
            end: updatedTask.due_date
          };
        }
        return task;
      });
    }
    // cleanMessageLog: (state) => {
    //   state.message.success = null;
    //   state.message.error = null;
    //   state.message.for = null;
    //   state.message.isOptionCreate = false;
    // },
    // setSelectedTask: (state, action) => {

    //   state?.selectedTask = action?.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTaskAsync.pending, (state) => {
        state.status.createTask = true;
      })
      .addCase(createTaskAsync.fulfilled, (state, action) => {
        if (action.payload?.isSubTask === true) {
          if (action.payload?.parent) {
            let updatedMainTasks = state.tasks.map((t) => {
              if (t.id === action.payload.parent.id) {
                // state.selectedTask = action.payload.parentTask
                return action.payload.parent;
              } else {
                return t;
              }
            });
            state.tasks = updatedMainTasks;
            if (state.selectedTask.id === action.payload.parent.id) {
              state.selectedTask = action.payload.parent;
            }
          } else {
            state.selectedTask = action.payload;
          }
          if (action.payload?.created) {
            let newArray = [...state.tasks, action.payload.created];
            state.tasks = newArray;
          }
        } else {
          console.log(action?.payload);
          state.tasks.push(action.payload);
        }
      })

      .addCase(createTaskAsync.rejected, (state) => {
        state.status.createTask = false;
      })
      .addCase(getTaskAsync.pending, (state) => {
        state.status.getTask = true;
      })
      .addCase(getTaskAsync.fulfilled, (state, action) => {
        state.status.getTask = false;
        state.tasks = action.payload;
      })
      .addCase(getTaskAsync.rejected, (state) => {
        state.status.getTask = false;
      })
      .addCase(deleteTaskAsync.pending, (state) => {
        state.status.deleteTask = true;
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        state.status.deleteTask = false;
        state.tasks = state.tasks.filter((task) => task.id !== action.payload); // Removing the deleted task
      })
      .addCase(deleteTaskAsync.rejected, (state) => {
        state.status.deleteTask = false;
      })
      .addCase(updateTaskAsync.pending, (state) => {
        state.status.updateTask = true;
      })
      .addCase(updateTaskAsync.fulfilled, (state, action) => {
        state.status.updateTask = false;
        state.tasks = state.tasks.map((task) => {
          if (task?.id === action?.payload?.id) {
            return action?.payload;
          }
          return task;
        });
        if (state.selectedTask.id == action.payload.id) {
          state.selectedTask = action.payload;
        } else {
          let updatedTask = state.selectedTask?.sub_tasks?.map((subTask) => {
            if (subTask.id === action.payload.id) {
              return action.payload;
            } else {
              return subTask;
            }
          });
          state.selectedTask = {
            ...state.selectedTask,
            sub_tasks: updatedTask
          };
        }
      })
      .addCase(updateTaskAsync.rejected, (state) => {
        state.status.updateTask = false;
      })
      .addCase(updateTaskManyAsync.pending, (state) => {
        state.status.updateTask = true;
      })
      .addCase(updateTaskManyAsync.fulfilled, (state, action) => {
        state.status.updateTask = false;
      })
      .addCase(updateTaskManyAsync.rejected, (state) => {
        state.status.updateTask = false;
      })
      .addCase(getOneTaskAsync.pending, (state) => {
        state.status.getOneTask = true;
      })

      .addCase(getOneTaskAsync.fulfilled, (state, action) => {
        state.getOneTask = false;
        console.log(action?.payload, 'selectedTask before data');
        state.selectedTask = action.payload;
      })

      .addCase(getOneTaskAsync.rejected, (state, action) => {
        state.status.getOneTask = false;
      });
  }
});
export const selectTaskData = (state) => state.projectTaskState;
export const selectTasks = (state) => state.tasks;
export const { updateTaskState } = slice.actions;
export default slice.reducer;

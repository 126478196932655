import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { REMOVE_FIELDS } from 'src/constant/constant';
import {
  selectDynamicPageConfig,
  createDataAsync,
  updateDataAsync,
  selectApiSuccess,
  setApiSuccess
} from 'src/slices/dynamicSlice';
import { initDynamicFormValues } from 'src/utils/initDynamicFormValues';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

const useDynamicFormLogic = ({
  currentItem,
  isModal,
  handleModal,
  isCustomForm
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isApiSuccess = useSelector(selectApiSuccess);
  const {
    fieldNames: allfieldName,
    requiredFieldNames,
    entity,
    schema,
    entitySetting
  } = useSelector(selectDynamicPageConfig);

  const fieldNames = useMemo(() => {
    if (isModal) return requiredFieldNames;

    let fields = allfieldName.filter(
      (fieldName) => !REMOVE_FIELDS.includes(fieldName)
    );

    if (isCustomForm) return fields;

    fields = currentItem
      ? entitySetting?.edit_fields || fields
      : entitySetting?.create_fields || fields;
    return [...new Set([...requiredFieldNames, ...fields])];
  }, [entitySetting, currentItem, allfieldName, isModal]);

  const { initialValues, validationSchema } = useMemo(
    () =>
      initDynamicFormValues({
        schema,
        fieldNames,
        currentItem
      }),
    [schema, fieldNames]
  );

  const handleFormSubmit = async (values) => {
    const { set, unset, createData } = sanitizeFormData(
      values,
      schema,
      fieldNames
    );

    let payload = {
      endPoint: entity,
      data: currentItem
        ? {
            $set: set,
            $unset: unset
          }
        : createData
    };
    if (currentItem) {
      dispatch(
        updateDataAsync({
          ...payload,
          id: currentItem.id
        })
      );
    } else {
      if (!Object.keys(createData).length) {
        toast.error('Cannot create: no valid data provided.');
        return;
      }
      dispatch(createDataAsync(payload));
    }
  };

  useEffect(() => {
    if (isApiSuccess) {
      if (isModal) {
        handleModal();
      } else {
        navigate(-1);
      }
    }
    return () => dispatch(setApiSuccess());
  }, [isApiSuccess, isModal, handleModal, navigate, dispatch]);

  return {
    fieldNames,
    initialValues,
    validationSchema,
    entity,
    entitySetting,
    schema,
    handleFormSubmit
  };
};

export default useDynamicFormLogic;

import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { TEXT_FORMAT } from 'src/constant/constant';
import ColorPickerComponent from 'src/components/dynamicFields/ColorPickerComponent';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const TextFormatting = ({
  dataItem,
  // errors,
  // touched,
  setFieldValue,
  handleBlur,
  initialPath,
  name
  // MenuProps
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorBoxClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorChange = (color) => {
    setFieldValue(`${initialPath}.format.color`, color.hex);
  };

  return (
    <>
      <Grid item xs={12} md={2}>
        <CustomSelectFieldWithLabel
          label="Font Style"
          value={dataItem?.format?.fontStyle || ''}
          options={TEXT_FORMAT.fontStyleOptions}
          // error={(errors.data && errors.data[index]?.format?.fontStyle) || null}
          // touched={
          //   (touched.data && touched.data[index]?.format?.fontStyle) || null
          // }
          onChange={(e) => {
            setFieldValue(`${initialPath}.format.fontStyle`, e.target.value);
          }}
          onBlur={handleBlur}
          name={name}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <CustomSelectFieldWithLabel
          label="Font Weight"
          value={dataItem?.format?.fontWeight || ''}
          options={TEXT_FORMAT.fontWeightOptions}
          // error={
          //   (errors.data && errors.data[index]?.format?.fontWeight) || null
          // }
          // touched={
          //   (touched.data && touched.data[index]?.format?.fontWeight) || null
          // }
          onChange={(e) => {
            setFieldValue(`${initialPath}.format.fontWeight`, e.target.value);
          }}
          onBlur={handleBlur}
          name={name}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <CustomSelectFieldWithLabel
          label="Font Family"
          value={dataItem?.format?.fontFamily || ''}
          options={TEXT_FORMAT.fontFamilyOptions}
          // error={
          //   (errors.data && errors.data[index]?.format?.fontFamily) || null
          // }
          // touched={
          //   (touched.data && touched.data[index]?.format?.fontFamily) || null
          // }
          onChange={(e) => {
            setFieldValue(`${initialPath}.format.fontFamily`, e.target.value);
          }}
          onBlur={handleBlur}
          name={name}
        />
      </Grid>
      <Grid item xs={12} md={2} my={'auto'}>
        <ColorPickerComponent
          title={'Select Font Color'}
          color={
            dataItem?.format?.color?.rgb || {
              r: 255,
              g: 255,
              b: 255,
              a: 1
            }
          }
          onChange={(color) =>
            setFieldValue(`${initialPath}.format.color`, { ...color })
          }
          onBlur={handleBlur}
        />
      </Grid>
    </>
  );
};

export default TextFormatting;

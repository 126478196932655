import { Stack } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';
import Chat from './Chat';
import CustomThemeSettings from './CustomThemeSettings';

function HeaderButtons() {
  return (
    <Stack spacing={1} direction={'row'}>
      <>
        <HeaderNotifications />
        <LanguageSwitcher />
        <Chat />
        <CustomThemeSettings />
      </>
    </Stack>
  );
}

export default HeaderButtons;

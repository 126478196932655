import {
  AppBar,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomCheckboxGroupField from 'src/components/CustomFields/CustomCheckboxGroupField';
import { CRUD_TYPES } from 'src/constant/constant';
import ConditionComponent from '../ConditionComponent';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const AddTrigger = ({ handleDialog }) => {
  const { entities } = useSelector(selectAppConfigData);
  const { values, setFieldValue } = useFormikContext();
  const handleCancel = () => {
    setFieldValue('entity', '');
    setFieldValue('crud_type', []);
    setFieldValue('conditions', []);
    handleDialog();
  };
  return (
    <>
      <AppBar sx={{ position: 'relative', py: 1 }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add Trigger Config
          </Typography>
          <Button autoFocus variant="contained" onClick={handleDialog}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        py={4}
        alignItems="center"
      >
        <Grid item md={8} xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Choose Entity:
            </Typography>
            <Autocomplete
              fullWidth
              options={entities}
              getOptionLabel={(option) => option}
              value={values?.entity || null}
              onChange={(e, value) => setFieldValue('entity', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="entity"
                  label="Entity"
                  variant="outlined"
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              CRUD Types:
            </Typography>
            <CustomCheckboxGroupField
              name="crud_type"
              row={true}
              value={values?.crud_type || []}
              options={CRUD_TYPES}
              onChange={(value) => setFieldValue('crud_type', value)}
            />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardHeader title="Conditions" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <ConditionComponent
                  conditionArr={values?.conditions}
                  conditionPath={`conditions`}
                  entity={values.entity}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTrigger;

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeColor,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';

const Presets = () => {
  const { color: activeColor } = useSelector(selectCustomSettingData);
  const dispatch = useDispatch();
  const preset = [
    {
      iconColor: '#57CA22' // green
    },
    {
      iconColor: '#33C2FF' // skyblue
    },
    {
      iconColor: '#9C27B0' // purple
    },
    {
      iconColor: '#2196F3' // blue
    },
    {
      iconColor: '#FF9800' // orange
    },
    {
      iconColor: '#FF1943' // red
    }
  ];

  return (
    <Stack rowGap={1}>
      <Typography variant="subtitle1">Color Pallets</Typography>
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={1}
      >
        {preset.map((each, index) => (
          <Box
            key={`preset_${index}`}
            height={'70px'}
            width={'30%'}
            border={'1px solid lightGray'}
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={1}
          >
            <ButtonBase
              onClick={() => dispatch(changeColor(each.iconColor))}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1
              }}
            >
              <FiberManualRecordIcon
                sx={{ color: each.iconColor }}
                fontSize={each.iconColor === activeColor ? 'large' : 'medium'}
              />
            </ButtonBase>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default Presets;

// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// //import { useState } from 'react';
// import { Box, IconButton, Stack, Typography } from '@mui/material';
// import { selectColor, changeColor } from 'src/slices/CustomThemeSettingSlice';
// import { useDispatch, useSelector } from 'react-redux';
// const Presets = () => {
//   //const [activeColor,setColor] = useState("green")
//   const activeColor = useSelector(selectColor);
//   const dispatch = useDispatch();
//   const preset = [
//     {
//       iconColor: 'green'
//     },
//     {
//       iconColor: 'skyblue'
//     },
//     {
//       iconColor: 'purple'
//     },
//     {
//       iconColor: 'blue'
//     },
//     {
//       iconColor: 'orange'
//     },
//     {
//       iconColor: 'red'
//     }
//   ];
//   return (
//     <Stack rowGap={1}>
//       <Typography variant="subtitle1">Color Pallets</Typography>
//       <Box
//         display={'flex'}
//         flexWrap={'wrap'}
//         justifyContent={'center'}
//         alignItems={'center'}
//         gap={1}
//       >
//         {preset.map((each, index) => (
//           <Box
//             key={`preset_${index}`}
//             onClick={() => dispatch(changeColor(each.iconColor))}
//             height={'70px'}
//             width={'30%'}
//             border={'1px solid lightGray'}
//             display={'flex'}
//             flexWrap={'wrap'}
//             justifyContent={'center'}
//             alignItems={'center'}
//             borderRadius={1}
//           >
//             <IconButton>
//               <FiberManualRecordIcon
//                 sx={{ color: each.iconColor }}
//                 fontSize={each.iconColor === activeColor ? 'large' : 'medium'}
//               />
//             </IconButton>
//           </Box>
//         ))}
//       </Box>
//     </Stack>
//   );
// };

// export default Presets;

import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import CustomPopover from 'src/components/CustomPopover';
import Scrollbar from 'src/components/Scrollbar';

const MoreItemsPopover = ({ items, children, btnNum }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        style={{ minWidth: 'auto', padding: '4px' }}
        onClick={handlePopoverOpen}
      >
        +{btnNum}
      </Button>
      <CustomPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
      >
        <Box sx={{ maxWidth: 300 }}>
          <Scrollbar maxHeight={300}>{children}</Scrollbar>
        </Box>
      </CustomPopover>
    </>
  );
};

export default MoreItemsPopover;

// services/validationService.js

import * as Yup from 'yup';

const settingsValidationSchema = Yup.object().shape({
  entity: Yup.string().required('Entity is required'),
  setting_type: Yup.string().required('Setting type is required'),
  companyGeneralSettings: Yup.object().when('setting_type', {
    is: 'company_general_settings',
    then: Yup.object().shape({
      companyName: Yup.string().required('Company Name is required'),
      companyLogo: Yup.string().required('Company Logo is required')
      // Add more properties and their validation rules as needed
    }),
    otherwise: Yup.object().notRequired() // Fields not required for other setting types
  }),
  data: Yup.mixed()
    .when('setting_type', {
      is: 'rename_fields',
      then: Yup.array()
        .of(
          Yup.object().shape({
            old_name: Yup.string().required('Old name is required'),
            new_name: Yup.string().required('New name is required')
            // Add more properties and their validation rules as needed
          })
        )
        .required('Data is required')
        .min(1, 'At least one item in data is required')
    })
    .when('setting_type', {
      is: 'populate_fields',
      then: Yup.array()
        .of(
          Yup.object().shape({
            populate_field: Yup.string().required('Old name is required'),
            field_show: Yup.string().required('New name is required')
            // Add more properties and their validation rules as needed
          })
        )
        .required('Data is required')
        .min(1, 'At least one item in data is required')
    })
    .when('setting_type', {
      is: 'company_entity_settings',
      then: Yup.array()
        .of(
          Yup.object().shape({
            entity_name: Yup.string().required('Entity name is required'),
            // show: Yup.boolean().required('Show is required'),
            // isRenamed: Yup.boolean().required(
            //   'Is Renamed is required'
            // ),
            // new_name: Yup.string().required('New name is required'),
            // icon: Yup.string().required('Icon is required'),
            allowed_user_types: Yup.array()
              .of(Yup.string())
              .required('At least one allowed user type is required')
              .min(1, 'At least one allowed user type is required'),

            allowed_platform_types: Yup.array()
              .of(Yup.string())
              .required('At least one allowed platform type is required')
              .min(1, 'At least one allowed platform type is required')
            // Add more properties and their validation rules as needed
          })
        )
        .required('Data is required')
        .min(1, 'At least one item in data is required')
    })
});

export default settingsValidationSchema;

import {
  alpha,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import { useSelector } from 'react-redux';
import {
  deleteManyDataAsync,
  selectDynamicPageConfig,
  selectStatus
} from 'src/slices/dynamicSlice';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import EditIcon from '@mui/icons-material/Edit';
import { checkCellValue } from 'src/services/exportTableDataService';
import * as XLSX from 'xlsx';
import CustomPopover from 'src/components/CustomPopover';
import BulkEdit from './BulkEdit';
import Scrollbar from 'src/components/Scrollbar';
import { useDispatch } from 'react-redux';
import DynamicBulkActions from './DynamicBulkActions';

const StyledMenu = styled((props) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 130,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

const BulkActions = ({ handleClearSelectedRow, selectedItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const { schema, fieldNames, entitySetting, entity } = useSelector(
    selectDynamicPageConfig
  );
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [editAnchor, setEditAnchor] = useState(null);

  const handleBulkEditPopover = (event) => {
    setEditAnchor((prevValue) => (!prevValue ? event.currentTarget : null));
  };

  const handleMenu = (event) => {
    setMenuAnchor((prevValue) => (!prevValue ? event.currentTarget : null));
  };

  const handleExportData = async () => {
    const filterSelectedItems = (data) => {
      return data?.map((row) => {
        return fieldNames?.map((fieldName) =>
          checkCellValue(row[fieldName], fieldName, schema, entitySetting)
        );
      });
    };

    const filterItems = filterSelectedItems(selectedItems);

    const headers = fieldNames;
    const transformedData = filterItems?.map((item) => {
      const rowData = {};
      item?.forEach((cell, index) => {
        rowData[headers[index]] = cell;
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${entity}.csv` || 'data.csv');
  };

  const handleDelete = async () => {
    const ids = { ids: selectedItems.map((selectedItem) => selectedItem.id) };
    await dispatch(deleteManyDataAsync({ endPoint: entity, ids }));
    handleClearSelectedRow();
  };

  const bulkActions =
    entitySetting?.actions?.filter(
      (action) => action.actionLocation === 'bulk'
    ) || [];

  const editPopoverId = Boolean(editAnchor) ? 'bulk-popover' : undefined;

  return (
    <>
      <Stack
        p={2}
        direction={'row'}
        justifyContent={'space-between'}
        alignContent={'center'}
      >
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Typography variant="h5" color="text.primary">
            {t('Bulk actions')}:
          </Typography>
          <Button
            variant="contained"
            color="error"
            disabled={status.deleteMany}
            startIcon={
              status.deleteMany ? (
                <CircularProgress size={'1rem'} />
              ) : (
                <DeleteTwoTone />
              )
            }
            onClick={handleDelete}
          >
            {t('Delete')}
          </Button>
          {bulkActions.map((action, actIndex) => (
            <DynamicBulkActions
              key={`action_${actIndex}`}
              action={action}
              handleClearSelectedRow={handleClearSelectedRow}
              selectedItems={selectedItems}
            />
          ))}
        </Stack>
        <IconButton
          aria-describedby={editPopoverId}
          color="primary"
          onClick={handleMenu}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Stack>
      <StyledMenu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem disableRipple onClick={handleBulkEditPopover}>
          <EditIcon />
          Bulk Edit
        </MenuItem>
        <MenuItem disableRipple onClick={handleExportData}>
          <UploadFileIcon fontSize="small" />
          Export
        </MenuItem>
      </StyledMenu>
      <CustomPopover
        id={editPopoverId}
        anchorEl={editAnchor}
        open={Boolean(editAnchor)}
        onClose={() => setEditAnchor(null)}
      >
        <Scrollbar maxHeight={300}>
          <BulkEdit
            handleBulkEditPopover={handleBulkEditPopover}
            selectedItems={selectedItems}
          />
        </Scrollbar>
      </CustomPopover>
    </>
  );
};

export default BulkActions;

import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/setting/PageHeader';
import SettingTable from 'src/content/setting/SettingTable';

function Setting({ selectedEntity = null, handleSettingModel = null }) {
  const title = selectedEntity
    ? `${selectedEntity} Setting`
    : 'Website Setting';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          selectedEntity={selectedEntity}
          handleSettingModel={handleSettingModel}
        />
      </PageTitleWrapper>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <SettingTable
            selectedEntity={selectedEntity}
            handleSettingModel={handleSettingModel}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Setting;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const useEntity = (endPoint) => {
  const { menuItems } = useSelector(selectAppConfigData);

  const entity = useMemo(() => {
    if (endPoint && menuItems?.length) {
      const foundData = menuItems
        .flatMap((menuItem) => menuItem.items)
        .find((item) => item.link === endPoint);
      return foundData ? foundData.entity : endPoint;
    }
    return null;
  }, [endPoint, menuItems]);

  return { entity };
};

export default useEntity;

import React from 'react';
import { Box } from '@mui/material';
import AddOptionForm from './AddOptionForm';
import GetLayout from './GetLayout';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const AddOptions = ({
  handleDrawer,
  handleModal,
  entity,
  inputValue,
  isModal,
  fieldShow,
  setOptions
}) => {
  const { schema, entitySetting } = useEntityPageConfig(entity);
  return (
    <GetLayout isModal={isModal} entity={entity} handleDrawer={handleDrawer}>
      <Box p={isModal ? 1 : 3}>
        {schema && (
          <AddOptionForm
            schema={schema}
            fieldNames={Object.keys(schema)}
            entity={entity}
            entitySetting={entitySetting}
            inputValue={inputValue}
            fieldShow={fieldShow}
            isModal={isModal}
            setOptions={setOptions}
            handleDrawer={handleDrawer}
            handleModal={handleModal}
          />
        )}
      </Box>
    </GetLayout>
  );
};

export default AddOptions;

import React, { useMemo, useState } from 'react';
import {
  TableCell,
  Stack,
  styled,
  Typography,
  IconButton
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  capitalizeAndRemoveChar,
  getFieldType
} from 'src/services/commonService';
import CustomPopover from 'src/components/CustomPopover';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'transparent' // Remove background color on hover
  },
  '&:active': {
    backgroundColor: 'transparent' // Remove background color on active
  },
  '& .MuiIconButton-label': {
    transition: 'transform 0.3s ease'
  },
  '&:hover .MuiIconButton-label': {
    transform: 'scale(1.1)' // Example: Scale effect on hover
  }
}));

const StyledArrowIcon = styled(({ isSort, sort, ...props }) => (
  <ArrowRightIcon {...props} />
))(({ isSort, sort }) => ({
  transform: isSort
    ? `rotate(${sort.direction === 1 ? -90 : 90}deg)`
    : 'rotate(0deg)',
  transition: 'transform 0.3s ease',
  opacity: isSort ? 1 : 0
}));

const StyledTableCell = styled(TableCell)(() => ({
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  '&:hover': {
    '& .StyledArrowIcon': {
      opacity: 1,
      pointerEvents: 'auto' // Enable pointer events on hover
    }
  },
  '& .StyledArrowIcon': {
    opacity: 0,
    pointerEvents: 'none', // Disable pointer events by default
    transition: 'opacity 0.3s ease'
  }
}));

const TableHeadcell = ({ availableField, handleSort, sort }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);

  const handlePopoverOpen = (event) => {
    event.stopPropagation(); // Stop event propagation
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation(); // Stop event propagation
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isFieldRenamed = (fieldName) => {
    const renamedField = entitySetting?.rename_fields?.find(
      (field) => field.old_name === fieldName
    );
    return renamedField ? renamedField.new_name : fieldName;
  };

  const description = useMemo(() => {
    let description = null;
    const {
      isArray,
      isObjectID,
      isString,
      isNumber,
      isBoolean,
      isDate,
      isArrayWithObjectID
    } = getFieldType(availableField, schema);
    if (isString || isNumber || isBoolean || isDate || isObjectID) {
      description = schema[availableField]?.options?.description;
    } else if (isArrayWithObjectID || isArray) {
      description =
        schema[availableField]?.$embeddedSchemaType?.options?.description;
    }
    return description;
  }, [availableField, schema]);

  return (
    <>
      <StyledTableCell onClick={() => handleSort(availableField)}>
        <Stack direction={'row'} alignItems={'center'}>
          {description && (
            <StyledIconButton
              className="StyledIconButton"
              onClick={handlePopoverOpen} // Add your click handler here
            >
              <InfoOutlinedIcon sx={{ fontSize: '16px', opacity: 1 }} />
            </StyledIconButton>
          )}
          <span>{capitalizeAndRemoveChar(isFieldRenamed(availableField))}</span>
          <StyledArrowIcon
            className="StyledArrowIcon"
            isSort={sort.fieldName === availableField}
            sort={sort}
          />
        </Stack>
      </StyledTableCell>
      <CustomPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
      >
        <Typography sx={{ p: 2 }} variant="body1">
          {description}
        </Typography>
      </CustomPopover>
    </>
  );
};

export default TableHeadcell;

import { combineReducers } from '@reduxjs/toolkit';
import CustomThemeSettingReducer from 'src/slices/CustomThemeSettingSlice';
import activityReducer from 'src/slices/activitySlice';
import approvalReducer from 'src/slices/approvalSlice';
import authReducer from 'src/slices/authSlice';
import { reducer as calendarReducer } from 'src/slices/calendar';
import departmentReducer from 'src/slices/departmentSlice';
import dynamicReducer from 'src/slices/dynamicSlice';
import emailReducer from 'src/slices/emailSlice';
import employeeReducer from 'src/slices/employeeSlice';
import hrReducer from 'src/slices/hrSlice';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import moduleReducer from 'src/slices/moduleSlice';
import notificationReducer from 'src/slices/notificationSlice';
import projectReducer from 'src/slices/projectSlice';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import publicReducer from 'src/slices/publicSlice';
import roleManagementReducer from 'src/slices/roleManagementSlice';
import roleReducer from 'src/slices/rolesSlice';
import sectionReducer from 'src/slices/sectionSlice';
import settingReducer from 'src/slices/settingSlice';
import projectTaskReducer from 'src/slices/taskSlice';
import todoReducer from 'src/slices/todosSlice';
import widgetReducer from 'src/slices/widgetSlice';
import workspaceSettingReducer from 'src/slices/workspaceSetting';
import approvalSettingReducer from 'src/slices/approvalSettingSlice';
import docNumConfigReducer from 'src/slices/docNumConfigSlice';
import appConfigReducer from 'src/slices/appConfigSlice';
import approvalReqReducer from 'src/slices/approvalReqSlice';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  authState: authReducer,
  dynamicState: dynamicReducer,
  settingState: settingReducer,
  roleManagementState: roleManagementReducer,
  emailState: emailReducer,
  projectReducer: projectReducer,
  hrReducer: hrReducer,
  notification: notificationReducer,
  todo: todoReducer,
  CustomThemeSetting: CustomThemeSettingReducer,
  projectTaskState: projectTaskReducer,
  sectionState: sectionReducer,
  department: departmentReducer,
  employee: employeeReducer,
  roles: roleReducer,
  activity: activityReducer,
  approvals: approvalReducer,
  widgets: widgetReducer,
  modules: moduleReducer,
  public: publicReducer,
  workspaceSetting: workspaceSettingReducer,
  approvalSetting: approvalSettingReducer,
  docNumConfig: docNumConfigReducer,
  appConfig: appConfigReducer,
  approvalReq: approvalReqReducer
});

export default rootReducer;

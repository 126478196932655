/* eslint-disable react/prop-types */
import { Box, Divider, List, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useNoteEditorContext } from '../../contexts/NoteEditorContext';
import { ListItemWrapper } from '../Styled';

const useStyles = makeStyles({
  truncateText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3, // Limit to 3 lines
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
  }
});

function sanitizeHTML(htmlString) {
  const text = htmlString?.replace(/<[^>]*>/g, '');
  return text?.replace(/&nbsp;/g, ' ');
}

const NotesList = ({ notes }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setState, setIsEdit, setIsCreateNote } = useNoteEditorContext();

  const handleEditNote = (note) => {
    setState(note);
    setIsEdit(true);
    setIsCreateNote(true);
  };

  return (
    <Stack gap={2} pb={16}>
      <List disablePadding sx={{ p: 1 }}>
        {notes?.map((note, index) => (
          <>
            <ListItemWrapper
              key={index}
              sx={{ alignItems: 'flex-start', p: 1, gap: 2, borderRadius: 1 }}
              onClick={() => handleEditNote(note)}
            >
              <Box p={1}>
                <Typography variant="h6" fontSize={16}>
                  {t(note?.title)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={14}
                  className={classes.truncateText}
                >
                  {sanitizeHTML(note?.content)}
                </Typography>
              </Box>
            </ListItemWrapper>
            {index < notes?.length - 1 && (
              <Divider sx={{ my: 1 }} component="li" />
            )}
          </>
        ))}
      </List>
    </Stack>
  );
};

export default NotesList;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

// export const createApproval = createAsyncThunk(
//   'approvals/create',
//   async (payload, { rejectWithValue }) => {
//     try {
//       const response = await Axios.post('/approvals/create', payload);
//       if (response?.statusText === 'OK') {
//         return response?.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error?.message);
//     }
//   }
// );

// export const getApprovalById = createAsyncThunk(
//   'approvals/getById',
//   async (id, { rejectWithValue }) => {
//     try {
//       const response = await Axios.get(`/approvals/${id}`);
//       if (response?.statusText === 'OK') {
//         return response?.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error?.message);
//     }
//   }
// );

// export const updateApprovals = createAsyncThunk(
//   'approvals/update',
//   async ({ id, data }, { rejectWithValue }) => {
//     try {
//       const response = await Axios.put(`/approvals/update/${id}`, data);
//       if (response?.statusText === 'OK') {
//         return response?.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error?.message);
//     }
//   }
// );

const initialState = {
  approvals: [],
  loading: false,
  error: null
};
export const getAllApprovals = createAsyncThunk(
  'approvals/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.statusText === 'OK') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

const slice = createSlice({
  name: 'approvals',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllApprovals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllApprovals.fulfilled, (state, { payload }) => {
        state.approvals = payload?.data?.data || [];
        state.loading = false;
      })
      .addCase(getAllApprovals.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   .addCase(createApproval.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(createApproval.fulfilled, (state, { payload }) => {
    //     state.approvals.push(payload?.data);
    //     state.loading = false;
    //   })
    //   .addCase(createApproval.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload;
    //   })
    //   .addCase(getApprovalById.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getApprovalById.fulfilled, (state, { payload }) => {
    //     state.approval = payload?.data;
    //     state.loading = false;
    //   })
    //   .addCase(getApprovalById.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload;
    //   })
    //   .addCase(updateApprovals.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(updateApprovals.fulfilled, (state, { payload }) => {
    //     state.approvals = state?.approvals?.filter(
    //       ({ id }) => id !== payload?.data?.id
    //     );
    //     state.loading = false;
    //   })
    //   .addCase(updateApprovals.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload;
    //   });
  }
});

export const selectApprovals = (state) => state.approvals;
export default slice.reducer;

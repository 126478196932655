import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectColumnFilterFieldname,
  selectDynamicPageConfig,
  setQuery as setSearchQuery,
  setStatus,
  setTableData
} from 'src/slices/dynamicSlice';
import SearchFieldSelector from './SearchFieldSelector';
import SearchInput from './SearchInput.js';
import { requestApi } from 'src/mocks/request';
import {
  escapeRegexSpecialChars,
  getFieldType
} from 'src/services/commonService';
import { toast } from 'react-toastify';

const SearchFilter = () => {
  const dispatch = useDispatch();
  const columnFilterFieldname = useSelector(selectColumnFilterFieldname);
  const { fieldNames, entitySetting, schema } = useSelector(
    selectDynamicPageConfig
  );

  const isInitialRender = useRef(true);
  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchField, setSearchField] = useState('');

  const fieldsToDisplay = () => {
    const defaultFields =
      entitySetting?.search_fields?.length > 0
        ? entitySetting.search_fields
        : fieldNames;

    const fields =
      columnFilterFieldname !== undefined
        ? columnFilterFieldname
        : defaultFields;

    if (entitySetting?.show_fields?.length > 0) {
      return fields.filter((field) =>
        entitySetting.show_fields.includes(field)
      );
    }

    return fields;
  };
  // console.log('fieldsToDisplay', fieldsToDisplay());
  useEffect(() => {
    const defaultFields = fieldsToDisplay();
    const foundField = defaultFields?.find((field) => {
      const fieldType = schema[field]?.instance;
      if (fieldType === 'String') return true;
      if (fieldType === 'ObjectID') {
        const propertyName = entitySetting?.populate_fields?.find(
          (populate_field) => populate_field.populate_field === field
        );
        return !!propertyName;
      }
      if (fieldType === 'Array') {
        const isArrayWithObjectID =
          schema[field]?.$embeddedSchemaType?.instance === 'ObjectID'; // Check if the array field contains ObjectIDs
        const propertyName = entitySetting?.populate_fields?.find(
          (populate_field) => populate_field.populate_field === field
        );
        return isArrayWithObjectID && !!propertyName;
      }
      return false;
    });

    setSearchField(foundField || '');
    setQuery('');
  }, [entitySetting, fieldNames, schema, columnFilterFieldname]);

  const handleShowSearchFieldMenu = (event) => {
    setAnchorEl(
      anchorEl ? null : event.currentTarget.closest('div').parentNode.parentNode
    );
  };

  const handleChange = (value) => {
    setSearchField(value);
    setQuery('');
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleQueryChange = (e) => {
    if (searchField) {
      setQuery(e.target.value);
    } else {
      toast.warning('please select field to search');
    }
  };
  const fetchObjectIdData = async (endPoint) => {
    try {
      setStatus(true);
      const propertyName = entitySetting?.populate_fields?.find(
        (populate_field) => populate_field.populate_field === searchField
      );

      if (!propertyName) {
        toast.warn(`Property name not found for search field: ${searchField}`);
        return;
      }

      const response = await requestApi.getData({
        endPoint,
        query: {
          [propertyName.field_show]: {
            $regex: escapeRegexSpecialChars(query),
            $options: 'i'
          }
        }
      });

      if (response.status === 'SUCCESS' && response?.data?.data) {
        const ids = response.data.data.map((item) => item.id);
        return {
          [searchField]: {
            $in: ids
          }
        };
      } else {
        console.error('Failed to fetch data', response);
        dispatch(setTableData());
        return { [searchField]: { $in: [] } };
      }
    } catch (error) {
      console.error('Error fetching ObjectId data:', error);
      dispatch(setTableData());
      setStatus(false);
      return { [searchField]: { $in: [] } };
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      const fetchData = async () => {
        let queryData = null;
        const {
          isObjectID,
          isArray,
          isArrayWithObjectID,
          isArrayWithMultipleProperties
        } = getFieldType(searchField, schema);
        if (query) {
          if (isObjectID) {
            queryData = await fetchObjectIdData(
              schema[searchField].options.ref
            );
          } else if (isArray) {
            if (isArrayWithObjectID) {
              queryData = await fetchObjectIdData(
                schema[searchField]?.$embeddedSchemaType?.options?.ref
              );
            } else if (!isArrayWithMultipleProperties) {
              queryData = { [searchField]: { $in: query } };
            }
          } else {
            queryData = {
              [searchField]: {
                $regex: escapeRegexSpecialChars(query),
                $options: 'i'
              }
            };
          }
        }
        dispatch(setSearchQuery({ filterType: 'searchQuery', queryData }));
      };
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      isInitialRender.current = false;
    }
  }, [query, dispatch]);

  const open = Boolean(anchorEl);

  return (
    <>
      <SearchInput
        query={query}
        handleQueryChange={handleQueryChange}
        label={
          searchField ? `Search by ${searchField}` : ' Select a field to search'
        }
        open={open}
        onToggle={handleShowSearchFieldMenu}
        // id={id}
      />
      <SearchFieldSelector
        open={open}
        anchorEl={anchorEl}
        fields={fieldsToDisplay()}
        selectedField={searchField}
        onChange={handleChange}
        onClose={handleClickAway}
      />
    </>
  );
};

export default SearchFilter;

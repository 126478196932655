import React from 'react';
import {
  Paper,
  Popper,
  ClickAwayListener,
  List,
  ListItemText,
  ListItemButton
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const SearchFieldSelector = ({
  open,
  anchorEl,
  fields,
  selectedField,
  onChange,
  onClose
}) => {
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const filteredFields = fields.filter((field) => {
    const fieldType = schema[field]?.instance;
    if (fieldType === 'String') return true;
    if (fieldType === 'ObjectID') {
      const propertyName = entitySetting?.populate_fields?.find(
        (populate_field) => populate_field.populate_field === field
      );
      return !!propertyName;
    }
    if (fieldType === 'Array') {
      const isArrayWithObjectID =
        schema[field]?.$embeddedSchemaType?.instance === 'ObjectID'; // Check if the array field contains ObjectIDs
      const propertyName = entitySetting?.populate_fields?.find(
        (populate_field) => populate_field.populate_field === field
      );
      return isArrayWithObjectID && !!propertyName;
    }
    return false;
  });

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{
        zIndex: 10,
        maxWidth: 250,
        width: '100%'
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper style={{ maxHeight: 250, overflowY: 'auto' }}>
          <List>
            {filteredFields.map((field) => (
              <ListItemButton
                key={`searchFieldName_${field}`}
                onClick={() => onChange(field)}
                selected={field === selectedField}
              >
                <ListItemText primary={capitalizeAndRemoveChar(field)} />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default SearchFieldSelector;

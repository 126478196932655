import React from 'react';
import MultipleFileUpload from 'src/components/CustomFields/MultipleFileUpload';
import SingleImageUpload from 'src/components/CustomFields/SingleFileUpload';
import { FIELD_TO_OVERRIDE } from 'src/constant/constant';

const FieldOverrideHandler = ({ fieldName, name, value }) => {
  //   if (FIELD_TO_OVERRIDE.includes(fieldName)) {
  if (['gallery', 'images'].includes(fieldName)) {
    return (
      <MultipleFileUpload fieldName={fieldName} name={name} value={value} />
    );
  } else {
    return (
      <SingleImageUpload fieldName={fieldName} name={name} value={value} />
    );
  }
};
// };

export default FieldOverrideHandler;

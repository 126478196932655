import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  FormLabel
} from '@mui/material';

const CustomCheckboxGroupField = ({
  label,
  row = false,
  value = [],
  options,
  error,
  touched,
  disabled = false,
  onChange,
  onBlur,
  name
}) => {
  // Check if touched and error are present
  const hasError = touched && error;

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newValue = checked
      ? [...value, name]
      : value.filter((v) => v !== name);
    onChange(newValue);
  };

  return (
    <FormControl fullWidth error={hasError} component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup row={row}>
        {options.map((option, index) => (
          <FormControlLabel
            key={`${name}_${index}`}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                onChange={handleCheckboxChange}
                onBlur={onBlur}
                name={option.value}
                disabled={disabled}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomCheckboxGroupField;

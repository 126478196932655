import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Badge,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  alpha,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomThemeSettingDrawer from 'src/components/CustomThemeSettingDrawer';

const CustomThemeSettingBadge = styled(Badge)(
  ({ theme }) => `
      
      .MuiBadge-badge {
          background-color: ${alpha(theme.palette.error.main, 0.1)};
          color: ${theme.palette.error.main};
          min-width: 16px; 
          height: 16px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

const CustomThemeSettings = ({ isSm }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      {isSm ? (
        <ListItem onClick={toggleDrawer} button>
          <SettingsIcon fontSize="small" />
          <ListItemText primary={t('Theme Setting')} />
        </ListItem>
      ) : (
        <Tooltip arrow title={t('Settings')}>
          <IconButtonWrapper color="primary" onClick={toggleDrawer}>
            <CustomThemeSettingBadge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <SettingsIcon />
            </CustomThemeSettingBadge>
          </IconButtonWrapper>
        </Tooltip>
      )}
      <CustomThemeSettingDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

export default CustomThemeSettings;

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const SelectBoxField = ({
  label,
  value,
  options,
  error,
  touched,
  onChange,
  onBlur,
  name,
  menuProps
}) => {
  return (
    <FormControl fullWidth error={Boolean(touched && error)}>
      <InputLabel id={`${name}Label`}>{label}</InputLabel>
      <Select
        labelId={`${name}Label`}
        value={value}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        MenuProps={menuProps}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={`${name}_${index}`}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBoxField;

import React, { useState } from 'react';
//import SettingsIconButton from './SettingsButtonIcon';
import CustomThemeSettingDrawer from 'src/components/CustomThemeSettingDrawer';
import { useSelector } from 'react-redux';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';
import {
  Badge,
  IconButton,
  Tooltip,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const CustomThemeSettings = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { reset } = useSelector(selectCustomSettingData);
  const theme = useTheme();
  const { t } = useTranslation();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      {/* <SettingsIconButton onClick={toggleDrawer} reset={reset} /> */}
      <Tooltip arrow title={t('Theme Setting')}>
        <Badge
          variant={reset ? 'dot' : 'standard'}
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              background: theme.colors.error.main
            }
          }}
        >
          <IconButtonWrapper
            color="success"
            onClick={toggleDrawer}
            sx={{
              background: alpha(theme.colors.success.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.success.main,

              '&:hover': {
                background: alpha(theme.colors.success.main, 0.2)
              }
            }}
          >
            <SettingsIcon />
          </IconButtonWrapper>
        </Badge>
      </Tooltip>
      <CustomThemeSettingDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

export default CustomThemeSettings;

// const SettingsIconButton = ({ onClick, reset }) => {
//   const theme = useTheme();
//   const { t } = useTranslation();

//   return (
//     <Tooltip arrow title={t('Theme Setting')}>
//       <Badge
//         variant={reset ? 'dot' : 'standard'}
//         overlap="circular"
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right'
//         }}
//         sx={{
//           '.MuiBadge-badge': {
//             background: theme.colors.error.main
//           }
//         }}
//       >
//         <IconButtonWrapper
//           color="success"
//           onClick={onClick}
//           sx={{
//             background: alpha(theme.colors.success.main, 0.1),
//             transition: `${theme.transitions.create(['background'])}`,
//             color: theme.colors.success.main,

//             '&:hover': {
//               background: alpha(theme.colors.success.main, 0.2)
//             }
//           }}
//         >
//           <SettingsIcon />
//         </IconButtonWrapper>
//       </Badge>
//     </Tooltip>
//   );
// };

// export default SettingsIconButton;

// import SettingsIcon from '@mui/icons-material/Settings';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import CloseIcon from '@mui/icons-material/Close';
// import {
//   Badge,
//   Box,
//   Divider,
//   Drawer,
//   IconButton,
//   Stack,
//   Tooltip,
//   Typography,
//   alpha,
//   styled,
//   useTheme
// } from '@mui/material';
// import { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { resetSetting, selectReset } from 'src/slices/CustomThemeSettingSlice';
// import Direction from './Direction';
// import Contrast from './Contrast';
// import Mode from './Mode';
// import { useTranslation } from 'react-i18next';
// const IconButtonWrapper = styled(IconButton)(
//   ({ theme }) => `
//   width: ${theme.spacing(4)};
//   height: ${theme.spacing(4)};
//   border-radius: ${theme.general.borderRadiusLg};
// `
// );
// const CustomThemeSettings = () => {
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const reset = useSelector(selectReset);
//   const toggleDrawer = () => {
//     setOpenDrawer(!openDrawer);
//   };
//   return (
//     <>
//       <Tooltip arrow title={t('Theme Setting')}>
//         <Badge
//           variant={reset ? 'dot' : 'standard'}
//           overlap="circular"
//           anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right'
//           }}
//           sx={{
//             '.MuiBadge-badge': {
//               background: theme.colors.error.main
//             }
//           }}
//         >
//           <IconButtonWrapper
//             color="success"
//             onClick={toggleDrawer}
//             sx={{
//               background: alpha(theme.colors.success.main, 0.1),
//               transition: `${theme.transitions.create(['background'])}`,
//               color: theme.colors.success.main,

//               '&:hover': {
//                 background: alpha(theme.colors.success.main, 0.2)
//               }
//             }}
//           >
//             <SettingsIcon />
//           </IconButtonWrapper>
//         </Badge>
//       </Tooltip>
//       <Drawer
//         anchor="right"
//         open={openDrawer}
//         onClose={toggleDrawer}
//         PaperProps={{ sx: { width: '100%', maxWidth: 300 } }}
//       >
//         <Box
//           display={'flex'}
//           justifyContent={'space-between'}
//           alignItems={'center'}
//           px={2}
//           py={1.5}
//         >
//           <Typography variant="h4">Settings</Typography>
//           <Box>
//             <IconButton onClick={() => dispatch(resetSetting())}>
//               <Badge color="error" variant={reset ? 'dot' : ''}>
//                 <RefreshIcon fontSize="small" />
//               </Badge>
//             </IconButton>
//             <IconButton onClick={toggleDrawer}>
//               <CloseIcon fontSize="small" />
//             </IconButton>
//           </Box>
//         </Box>
//         <Divider />
//         <Stack rowGap={2} p={2} height={'80%'} sx={{ overflowY: 'auto' }}>
//           <Mode />
//           <Contrast />
//           <Direction />
//         </Stack>
//       </Drawer>
//     </>
//   );
// };

// export default CustomThemeSettings;

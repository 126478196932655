import { createSlice } from '@reduxjs/toolkit';

const LOCAL_STORAGE_KEY = 'customTheme';

const loadFromLocalStorage = () => {
  const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedValue ? JSON.parse(storedValue) : null;
};

const saveToLocalStorage = (state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

const initialState = loadFromLocalStorage() || {
  mode: '',
  contrast: '',
  direction: '',
  layout: '',
  expand: true,
  color: '',
  fullScreen: false,
  reset: false
};

const CustomThemeSetting = createSlice({
  name: 'CustomThemeSetting',
  initialState,
  reducers: {
    changeMode: (state, { payload }) => {
      state.mode = payload;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeContrast: (state, { payload }) => {
      state.contrast = payload;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeDirection: (state, { payload }) => {
      state.direction = payload;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeLayout: (state, { payload }) => {
      state.layout = payload;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeExpand: (state) => {
      state.expand = !state.expand;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeColor: (state, { payload }) => {
      state.color = payload;
      state.reset = true;
      saveToLocalStorage(state);
    },
    changeScreen: (state) => {
      state.fullScreen = !state.fullScreen;
      state.reset = true;
      saveToLocalStorage(state);
    },
    resetSetting: (state) => {
      state.reset = false;
      state.mode = '';
      state.contrast = '';
      state.direction = '';
      state.layout = '';
      state.expand = true;
      state.color = '';
      state.fullScreen = false;
      saveToLocalStorage(state);
    }
  }
});

export const {
  changeColor,
  changeExpand,
  changeLayout,
  changeDirection,
  changeContrast,
  changeMode,
  changeScreen,
  resetSetting
} = CustomThemeSetting.actions;

export const selectCustomSettingData = (state) => state.CustomThemeSetting;

export default CustomThemeSetting.reducer;

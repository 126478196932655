import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  Stack,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBulkDataAsync,
  selectDynamicPageConfig
} from 'src/slices/dynamicSlice';

export default function NonExistingDocumentsPreview({
  open,
  handleClose,
  errors,
  path
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uniqueErrorValues, setUniqueErrorValues] = useState({});
  const { schema } = useSelector(selectDynamicPageConfig);

  useEffect(() => {
    const uniqueValues = {};
    errors?.forEach((error) => {
      const uniqueValuesForField = new Set(
        error?.errors?.map((err) => err.value)
      );
      const filteredValues = [...uniqueValuesForField].filter(Boolean); // Remove empty values
      uniqueValues[error?.fieldName] = filteredValues;
    });
    setUniqueErrorValues(uniqueValues);
  }, [errors]);

  const handleDeleteValue = (fieldName, index) => {
    const updatedUniqueErrorValues = { ...uniqueErrorValues };
    updatedUniqueErrorValues[fieldName].splice(index, 1);
    setUniqueErrorValues(updatedUniqueErrorValues);
  };

  const handleBulkCreate = useCallback(async () => {
    try {
      setLoading(true);

      for (const fieldName in uniqueErrorValues) {
        const values = uniqueErrorValues[fieldName];
        const endPoint = schema?.[path]?.schema?.paths[fieldName]?.options?.ref;
        const data = [];

        values?.forEach((value) => {
          data.push({ name: value });
        });

        await dispatch(createBulkDataAsync({ endPoint, data: { data } }));
      }

      setUniqueErrorValues({});
    } catch (error) {
      console.error('Error bulk creating data:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  }, [dispatch, schema, uniqueErrorValues]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack gap={2} padding={4}>
        <Typography variant="h3" gutterBottom>
          Bulk create Non-Existing documents
        </Typography>
        <Alert severity="error">
          Non Existing field values found. Create them before importing or use
          existing one.
        </Alert>

        <Stack gap={2}>
          {Object.entries(uniqueErrorValues).map(([fieldName, values]) => (
            <Box key={fieldName}>
              <Typography
                variant="h5"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                {fieldName}
              </Typography>
              <Box>
                {values.map((uniqueValue, index) => (
                  <Chip
                    sx={{ margin: 0.2 }}
                    key={index}
                    label={uniqueValue}
                    onDelete={() => handleDeleteValue(fieldName, index)}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Stack>

        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          onClick={handleBulkCreate}
        >
          Create
        </LoadingButton>

        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Dialog>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Automation at Its Best',
    description:
      'Automate repetitive tasks and processes with ERPZ, allowing your team to focus on what truly matters. From order processing to inventory management, our intelligent automation features save time and reduce errors.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Seamless Integration',
    description:
      'ERPZ seamlessly integrates with your existing systems, ensuring a smooth transition and uninterrupted business operations. Connect with popular tools and platforms effortlessly.',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Real-Time Analytics',
    description:
      'Make informed decisions with real-time data and insights. ERPZ’s powerful analytics engine provides detailed reports and dashboards, helping you monitor performance and identify opportunities for improvement.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Customizable',
    description:
      'Tailor ERPZ to fit your unique business requirements. Our flexible modules and features allow you to customize workflows, reports, and user interfaces, ensuring a perfect fit for your operations.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Scalable',
    description:
      'Grow your business without limits. ERPZ scales with your business, supporting you at every stage of growth.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Secure',
    description:
      'Our robust security measures ensure your data is protected at all times.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

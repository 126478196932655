import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  todos: [],
  paginator: null,
  status: {
    getTodos: false
  },
  message: { success: null, error: null },
  hasMore: false
};

export const fetchTodos = createAsyncThunk(
  'todos/fetch',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData({
        endPoint: 'todo',
        pagination: true,
        limit: 5,
        sort: {  dueDate: 1 },
        ...data
      });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTodo = createAsyncThunk(
  'todos/create',
  async (data, { rejectWithValue }) => {
    try {
      const endPoint = 'todo';
      const response = await requestApi.createData({ endPoint, ...data });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTodo = createAsyncThunk(
  'todos/update',
  async (data, { rejectWithValue }) => {
    try {
      const endPoint = 'todo';
      const response = await requestApi.updateData({ endPoint, ...data });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTodo = createAsyncThunk(
  'todos/delete',
  async (data, { rejectWithValue }) => {
    try {
      const endPoint = 'todo';
      const response = await requestApi.deleteData({ endPoint, ...data });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message.success = null;
      state.message.error = null;
    },
    setTodos: (state) => {
      state.todos = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodos.pending, (state) => {
        state.status.getTodos = true;
      })
      .addCase(fetchTodos.fulfilled, (state, { payload }) => {
        state.status.getTodos = false;
        const newTodos = payload?.data || [];
        const paginator = payload?.paginator;
        state.paginator = paginator;
        state.todos = [...state.todos, ...newTodos];
        state.hasMore = paginator?.hasNextPage ?? false;
      })
      .addCase(fetchTodos.rejected, (state) => {
        state.status.getTodos = false;
        state.todos = [];
      })
      .addCase(createTodo.fulfilled, (state, { payload }) => {
        const newTodo = payload;
        state.todos.unshift(newTodo);
      })
      .addCase(updateTodo.fulfilled, (state, { payload }) => {
        const updatedTodo = payload; // Assuming payload is the updated todo
        const index = state.todos.findIndex(
          (todo) => todo.id === updatedTodo.id
        );
        if (index !== -1) {
          state.todos[index] = updatedTodo; // Replace the old todo with updated todo
        }
      })
      .addCase(deleteTodo.fulfilled, (state, { payload }) => {
        const deletedTodo = payload; // Assuming payload is the updated todo
        state.todos = state.todos.filter(
          (todo) => todo?.id !== deletedTodo?.id
        );
      });
  }
});

export const { cleanMessageLog, setTodos } = slice.actions;

// selectors
export const selectTodos = (state) => state.todo.todos;
export const selectMessage = (state) => state.todo.message;
export const selectStatus = (state) => state.todo.status;
export const selectHasMore = (state) => state.todo.hasMore;
export const selectPaginator = (state) => state.todo.paginator;

export default slice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';
import { structureSchema } from 'src/utils/structureSchema';

const structureEntitySetting = (settings) => {
  return settings.reduce((result, setting) => {
    const { setting_type, data } = setting;
    if (Array.isArray(data)) {
      result[setting_type] = data || [];
    } else {
      result[setting_type] = data || {};
    }
    return result;
  }, {});
};

const initialState = {
  entityPageConfigs: {},
  companyEntitySettings: [],
  companyGeneralSetting: null,
  entities: [],
  userTypes: null,
  platform: null,
  platformType: null,
  menuItems: [],
  workspaceMenuItems: [],
  documents: [],
  status: {
    getAppConfig: false,
    getEntitySchema: false,
    getEntitySetting: false,
    getComEntitySetting: false
  }
};

// Thunk to fetch all initial app configuration settings
export const getAppConfigAsync = createAsyncThunk(
  'configApp/getAppConfig',
  async (_, { rejectWithValue }) => {
    try {
      const response = await requestApi.getAppConfig();

      if (response?.statusText === 'OK') {
        return response?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk to fetch the schema for entity pages
export const getEntityPageSchemaAsync = createAsyncThunk(
  'configApp/getEntityPageSchema',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getSchema(data);
      if (response?.status === 200) {
        return { storekey: data.storekey, response: response?.data };
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk to fetch settings for entity pages
export const getEntityPageSettingAsync = createAsyncThunk(
  'configApp/getEntityPageSetting',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return {
          storekey: data.storekey,
          response: response?.data?.data || []
        };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to fetch settings for company entities
export const getCompanyEntitySettingAsync = createAsyncThunk(
  'configApp/getCompanyEntitySetting',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data?.data[0].data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk to fetch general settings for the company
// export const getCompanyGeneralSettingAsync = createAsyncThunk(
//   'configApp/getCompanyGeneralSetting',
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await requestApi.getData(data);
//       if (response?.status === 'SUCCESS') {
//         return response?.data?.data[0].data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const slice = createSlice({
  name: 'configApp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppConfigAsync.pending, (state) => {
        state.status.getAppConfig = true;
      })
      .addCase(getAppConfigAsync.fulfilled, (state, { payload }) => {
        state.status.getAppConfig = false;
        state.entities = payload.schemas;
        state.userTypes = payload.USER_TYPES;
        state.platform = payload.PLATFORM;
        state.platformType = payload.PLATFORM_ENDPOINTS;
        state.menuItems = payload.MENU_ITEMS;
        state.workspaceMenuItems = payload.WORKSPACE_SETTINGS;
        state.documents = payload.DOCUMENTS;
      })
      .addCase(getAppConfigAsync.rejected, (state) => {
        state.status.getAppConfig = false;
      })

      .addCase(getEntityPageSchemaAsync.pending, (state) => {
        state.status.getEntitySchema = true;
      })
      .addCase(getEntityPageSchemaAsync.fulfilled, (state, { payload }) => {
        state.status.getEntitySchema = false;
        const { storekey, response } = payload;
        state.entityPageConfigs[storekey] = {
          schema: structureSchema(response),
          ...state.entityPageConfigs[storekey]
        };
      })
      .addCase(getEntityPageSchemaAsync.rejected, (state) => {
        state.status.getEntitySchema = false;
      })

      .addCase(getEntityPageSettingAsync.pending, (state) => {
        state.status.getEntitySetting = true;
      })
      .addCase(getEntityPageSettingAsync.fulfilled, (state, { payload }) => {
        state.status.getEntitySetting = false;
        const { storekey, response } = payload;
        state.entityPageConfigs[storekey] = {
          ...state.entityPageConfigs[storekey],
          setting: structureEntitySetting(response)
        };
      })
      .addCase(getEntityPageSettingAsync.rejected, (state) => {
        state.status.getEntitySetting = false;
      })

      .addCase(getCompanyEntitySettingAsync.pending, (state) => {
        state.status.getComEntitySetting = true;
      })
      .addCase(getCompanyEntitySettingAsync.fulfilled, (state, { payload }) => {
        state.status.getComEntitySetting = true;
        state.companyEntitySettings = payload || [];
      })
      .addCase(getCompanyEntitySettingAsync.rejected, (state) => {
        state.status.getComEntitySetting = false;
      });
    // .addCase(
    //   getCompanyGeneralSettingAsync.fulfilled,
    //   (state, { payload }) => {
    //     state.companyGeneralSetting = payload || [];
    //   }
    // );
  }
});
// export const {} = slice.actions;
export const selectAppConfigData = (state) => state.appConfig;
export default slice.reducer;

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const useLinkGenerator = () => {
  const { menuItems } = useSelector(selectAppConfigData);
  const location = useLocation();

  const generateLink = (entity, type, id) => {
    let foundMenuItem = menuItems
      .flatMap((menuItem) => menuItem.items)
      .find((item) => item.entity === entity);
    if (!foundMenuItem) {
      foundMenuItem = { link: entity };
    }

    // Construct the base link based on the type
    let baseLink = `/${foundMenuItem.link}/${type}`;

    if (id) baseLink = `${baseLink}/${id}`;

    // Append search params if available
    const searchParams = location.search;
    if (searchParams) baseLink = `${baseLink}/${searchParams}`;

    return baseLink;
  };

  return generateLink;
};

export default useLinkGenerator;

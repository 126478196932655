import { Skeleton, Stack } from '@mui/material';

const ApprovalStatusLoader = () => {
  return (
    <Stack spacing={2}>
      <Stack direction={'row'} spacing={1}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} variant="rounded" width={100} height={40} />
        ))}
      </Stack>

      <Skeleton variant="rounded" width={'100%'} height={150} />
    </Stack>
  );
};

export default ApprovalStatusLoader;

import {
  Grid,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const TablePageLoader = () => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <>
      <PageTitleWrapper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Skeleton variant="text" width={250} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={150} height={40} />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignContent={'center'}
              mb={1}
              gap={2}
            >
              <Skeleton variant="rounded" width={300} height={56} />
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                {Array.from({ length: xsScreen ? 1 : 3 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={40}
                    height={40}
                  />
                ))}
              </Stack>
            </Stack>
            <Skeleton variant="rounded" width={'100%'} height={200} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TablePageLoader;

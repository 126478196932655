import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emailRequestApi } from 'src/mocks/email';
import { requestApi } from 'src/mocks/request';

let initialState = {
  emailUserData: null,
  clientData: null,
  emailLeads: [],
  is_connected: false,
  clientEmailSelectedData: null,
  UserEmails: null,
  selectEmailIdData: null,
  loading: false,
  status: {
    login: false,
    register: false
  },
  message: { success: null, error: null, for: null }
};

export const loginEmailAsync = createAsyncThunk(
  'email/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await emailRequestApi.sendParamsCode(data);
      if (response?.status === 'SUCCESS') {
        return response?.content;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientEmailAsync = createAsyncThunk(
  'email/client',
  async (data, { rejectWithValue }) => {
    try {
      const endPoint = 'client';
      const populate = 'people company';

      const response = await requestApi.getData({ endPoint, populate });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientUserEmailAsync = createAsyncThunk(
  'email/user',
  async (data, { rejectWithValue }) => {
    try {
      const response = await emailRequestApi.UserEmail(data);
      if (response?.status === 'SUCCESS') {
        return response?.content;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientEmailFullDataAsync = createAsyncThunk(
  'email/userData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await emailRequestApi.UserEmailData(data);
      if (response?.status === 'SUCCESS') {
        return response?.content;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const sentEmailToClientAsync = createAsyncThunk(
  'email/sent',
  async ({ payload, threadId }, { rejectWithValue }) => {
    try {
      const response = emailRequestApi.replyToEmailThread(threadId, payload);
      if (response?.status === 'SUCCESS') {
        return response?.content;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setClientEmail: (state, action) => {
      state.clientEmailSelectedData = action.payload;
    },
    setEmailLeads: (state, action) => {
      state.emailLeads.push(action.payload);
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginEmailAsync.pending, (state) => {
        state.status.login = true;
      })
      .addCase(loginEmailAsync.fulfilled, (state, { payload }) => {
        state.status.login = false;
        state.message.for = 'login';
        state.message.success = 'Login Successfully';
        state.emailUserData = payload.data;
        state.is_connected = true;
        localStorage.setItem('EmailToken', payload.data.token);
      })
      .addCase(loginEmailAsync.rejected, (state, action) => {
        state.status.login = false;
        state.message.error = action.payload;
      })
      // clientEmailAsync
      .addCase(clientEmailAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(clientEmailAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message.for = 'client';
        state.message.success = 'fetch Successfully';
        state.clientData = payload.data || [];
      })
      .addCase(clientEmailAsync.rejected, (state, action) => {
        state.loading = false;
        state.message.error = action.payload;
      })
      .addCase(clientUserEmailAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(clientUserEmailAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message.for = 'user';
        state.message.success = 'fetch Successfully';
        state.UserEmails = payload.data || [];
      })
      .addCase(clientUserEmailAsync.rejected, (state, action) => {
        state.loading = false;
        state.message.error = action.payload;
      })
      .addCase(clientEmailFullDataAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message.for = 'user';
        state.message.success = 'fetch Successfully';
        state.selectEmailIdData = payload.data || [];
      });
    //
  }
});

export const { setClientEmail, setEmailLeads } = slice.actions;

export const isEmailUserConnected = (state) => state.emailState.is_connected;
export const selectedClientData = (state) => state.emailState.clientData;
export const clientEmailSelectedData = (state) =>
  state.emailState.clientEmailSelectedData;
export const selectedUserEmails = (state) => state.emailState.UserEmails;
export const selectedEmailIdData = (state) =>
  state.emailState.selectEmailIdData;
export default slice.reducer;

export const emailleadData = (state) => state.emailState.emailLeads;

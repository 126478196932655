import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { useCallback } from 'react';
import { requestApi } from 'src/mocks/request';
import { useEffect } from 'react';
import TemplateViewPage from './TemplateViewPage';
import ComingSoonPage from 'src/components/ComingSoonPage';
import CustomViewPage from './CustomViewPage';
import ViewPageLayout from './ViewPageLayout';
import ViewPageLoader from 'src/components/SkeltonLoaders/ViewPageLoader';

const customViewPageEntities = [
  'purchase_request',
  'material_request',
  'request_for_quotation',
  'grn',
  'purchase'
];
function ViewPage({ currentItem }) {
  const isMounted = useRefMounted();
  const [isLoading, setloading] = useState(true);
  const { entity, entitySetting } = useSelector(selectDynamicPageConfig);
  const [template, setTemplate] = useState(null);
  const isCustomPage = customViewPageEntities.includes(entity);
  const fetchTemplate = useCallback(async () => {
    if (isCustomPage) {
      setloading(false);
      return;
    }
    setloading(true);
    try {
      const viewTemplateId =
        entitySetting?.view_template_settings?.[0]?.default || null;
      const res = await requestApi.getData({
        endPoint: 'view_template',
        query: viewTemplateId
          ? { _id: viewTemplateId }
          : { entity, isDefault: true }
      });
      if (res?.data?.data?.length > 0) {
        setTemplate(res.data.data[0]);
      } else {
        const res2 = await requestApi.getData({
          endPoint: 'view_template',
          query: { entity }
        });
        if (res2?.data?.data?.length > 0) {
          setTemplate(res2.data.data[0]);
        }
      }
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    } finally {
      if (isMounted.current) setloading(false);
    }
  }, [entity]);
  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);
  if (isLoading) return <ViewPageLoader />;

  // CUSTOM PAGE
  if (isCustomPage)
    return (
      <ViewPageLayout data={currentItem}>
        <CustomViewPage data={currentItem} />
      </ViewPageLayout>
    );

  // template page
  if (template)
    return (
      <ViewPageLayout data={currentItem}>
        <TemplateViewPage data={currentItem} template={template} />
      </ViewPageLayout>
    );

  // View Page Not Found
  if (!isCustomPage && !template) return <ComingSoonPage pageName={entity} />;
}

export default ViewPage;

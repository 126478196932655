import { Box, List, Divider, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from './item';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { Fragment } from 'react';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
  background: ${theme.sidebar.menuItemIconColor};
  margin-left: ${theme.spacing(3)};
  margin-right: ${theme.spacing(3)};
  opacity: .25;
`
);
const MenuWrapper = styled(Box)(
  ({ theme }) => `

  &:last-of-type + .MuiDivider-root {
      height: 0;
  }

  .MuiList-root {
    padding: ${theme.spacing(1, 0)};

    .MuiDivider-root {
      background: ${theme.sidebar.menuItemIconColor};
      margin-left: ${theme.spacing(3)};
      margin-right: ${theme.spacing(3)};
    }

    & > .MuiList-root {
      padding: 0;
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 1px 0;
        justify-content: center;

        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: ${theme.spacing(6)};
          height: ${theme.spacing(6)};
          transition: ${theme.transitions.create(['all'])};
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
          }

          .MuiBadge-root {
            position: absolute;
            right: 10px;
            top: 11px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }

          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { companyEntitySettings, menuItems, workspaceMenuItems } =
    useSelector(selectAppConfigData);
  const user = useSelector(selectLoggedUserData);
  const isWorkspaceRoute = location.pathname.startsWith('/workspace-setting');
  function checkEntity(entity) {
    if (!entity) {
      return { isEntity: true, name: null };
    }

    const filterData = companyEntitySettings.find(
      (item) => item.entity_name === entity
    );

    if (filterData) {
      let isAllowed = filterData.allowed_user_types.includes(user?.userType);
      if (isAllowed) {
        const newName =
          filterData?.new_name !== '' ? filterData?.new_name : entity;
        return { isEntity: true, name: newName };
      } else {
        return false;
      }
    }
    return { isEntity: true, name: null };
  }
  const renderSidebarMenuItems = ({ items, path }) => (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
  );
  const reduceChildRoutes = ({ ev, path, item }) => {
    const key = item.name;
    let exactMatch = item?.link
      ? !!matchPath(
          {
            path: item?.link,
            end: true
          },
          path
        )
      : false;

    const entityName = !item?.items ? checkEntity(item?.entity) : false;
    if (entityName?.isEntity) {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={entityName.name ? entityName.name : item.name}
          link={
            isWorkspaceRoute
              ? `/workspace-setting/${item.entity}`
              : `/${item.link}`
          }
          icon={item?.icon}
        />
      );
    }
    if (item?.items) {
      const partialMatch = item?.link
        ? !!matchPath(
            {
              path: item?.link,
              end: false
            },
            path
          )
        : false;
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item?.icon}
          link={
            isWorkspaceRoute
              ? `/workspace-setting/${item.entity}`
              : `/${item.link}`
          }
        >
          {renderSidebarMenuItems({
            path,
            items: item?.items
          })}
        </SidebarMenuItem>
      );
    }
    return ev;
  };
  if (isWorkspaceRoute) {
    return workspaceMenuItems.map((section) => (
      <Fragment key={section.heading}>
        <MenuWrapper>
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
        <DividerWrapper />
      </Fragment>
    ));
  } else {
    return menuItems.map((section) => (
      <Fragment key={section.heading}>
        <MenuWrapper>
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
        <DividerWrapper />
      </Fragment>
    ));
  }
}

export default SidebarMenu;

/* eslint-disable no-undef */
import axios from 'axios';
import { getSubdomain } from 'src/utils/common';
import { getCookie } from 'src/utils/cookie';

let token;

// Common configuration for both open and authenticated APIs
const baseConfig = {
  headers: {
    'Content-Type': 'application/json'
  }
};

// Open API's
const openAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PLATFORM}/auth/`,
  ...baseConfig
});

// Not Authenticated API
const axiosWithoutAuth = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  ...baseConfig
});

// Authenticated API
let versionURI = `${process.env.REACT_APP_PLATFORM}/api/v1`;
if (process.env.REACT_APP_PLATFORM === 'admin') {
  versionURI = `${process.env.REACT_APP_PLATFORM}`;
}
const axiosWithAuth = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${versionURI}`,
  ...baseConfig
});

const axiosWithAuthEmail = axios.create({
  baseURL: `http://localhost:5292/api`,
  ...baseConfig
});

axiosWithAuthEmail.interceptors.request.use(
  async (config) => {
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('EmailToken');
    }

    if (token && token !== 'undefined' && token !== '') {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosWithAuth.interceptors.request.use(
  async (config) => {
    if (typeof window !== 'undefined') {
      // TODO: stored token in cookies if there is token and has no workspace
      // get token from cookies if user has no workspace
      // based on th domain name get token from cookies

      const { subdomain, domain, tld } = getSubdomain();

      if (!subdomain && domain === 'erpz') {
        token = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
      } else {
        token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);
      }
    }

    if (token && token !== 'undefined' && token !== '') {
      // replace authorisation token only if not present
      if (!config.headers.common['Authorization']) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${token}`
        };
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosWithAuthEmail.interceptors.response.use(
  (response) => response,

  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401) {
      localStorage.setItem(
        'EmailToken',
        error.response.data.message.access_token
      );
      // window.location.reload();
    }
  }
);

axiosWithAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    // if (error?.response?.status === 403) {
    //   // Store the attempted path or resource
    //   window.location.href = '/forbidden';
    // }
    return Promise.reject(error);
  }
);
export {
  axiosWithAuth as Axios,
  axiosWithAuthEmail as emailAxios,
  axiosWithoutAuth as NoAuthAxios,
  openAxios as OpenAxios
};

/* eslint-disable react/prop-types */
import { Box, Tab, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabsWrapper } from '../Styled';

const RenderTabs = ({
  activeTab,
  unreadCount,
  readCount,
  handleTabsChange
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const tabs = [
    {
      label: t('All'),
      count: readCount + unreadCount || 0
    },
    {
      label: t('Read'),
      count: readCount
    },
    {
      label: t('Unread'),
      count: unreadCount
    }
  ];

  return (
    <Box>
      <TabsWrapper
        centered
        onChange={handleTabsChange}
        value={activeTab}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab?.label}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span>{tab.label}</span>
                <Box
                  sx={{
                    backgroundColor: theme.colors.alpha.black[50],
                    color: theme.palette.common.white,
                    ml: 0.5,
                    px: 0.5,
                    py: 0.2,
                    borderRadius: 0.5,
                    fontSize: '0.8rem'
                  }}
                >
                  {tab.count}
                </Box>
              </Box>
            }
            value={tab?.label}
          />
        ))}
      </TabsWrapper>
    </Box>
  );
};

export default RenderTabs;

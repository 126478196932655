import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ConditionFields from './ConditionField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ConditionComponent = ({
  conditionArr,
  conditionPath,
  entity = null,
  btnName = null,
  children
}) => {
  const { setFieldValue } = useFormikContext();

  const handlFieldNameChange = (event, conditionIndex) => {
    const value = event.target.value;
    const payload = {
      field_name: value,
      field_operator: '',
      field_value: '',
      field_join: ''
    };
    setFieldValue(`${conditionPath}.${conditionIndex}`, payload);
  };

  return (
    <FieldArray name={conditionPath}>
      {({ push, remove }) => (
        <>
          {conditionArr.length > 0 &&
            conditionArr.map((condition, conditionIndex) => (
              <Grid item xs={12} key={conditionIndex}>
                <Grid container spacing={2} alignItems={'center'}>
                  <ConditionFields
                    conditionPath={conditionPath}
                    conditionIndex={conditionIndex}
                    condition={condition}
                    handlFieldNameChange={handlFieldNameChange}
                    conditionArr={conditionArr}
                    entity={entity}
                  />
                  <Grid item xs={1}>
                    <IconButton onClick={() => remove(conditionIndex)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            {/* <Stack direction={'row'} alignItems={'center'} spacing={1}> */}
            <Tooltip title={btnName || 'Add Condition'}>
              <IconButton
                onClick={() =>
                  push({
                    field_name: '',
                    field_operator: '',
                    field_value: '',
                    field_join: ''
                  })
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            {children}
            {/* </Stack> */}
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ConditionComponent;

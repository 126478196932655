import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorWrapper = styled(Box)(
  ({ theme }) => `
    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: var(--ql-border-color, ${theme.colors.alpha.black[30]});
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: var(--ql-border-color-hover, ${theme.colors.alpha.black[50]});
      }
    }

    &[data-error="true"] {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.palette.error.main};
      }

      &:hover {
        .ql-toolbar.ql-snow,
        .ql-container.ql-snow {
          border-color: ${theme.palette.error.main};
        }
      }
    }
  `
);

const QuillEditorField = ({
  value,
  name = '',
  label,
  onChange,
  touched,
  error,
  placeholder = '',
  onBlur = () => {}
}) => {
  const handleEditorBlur = () => {
    onBlur({ target: { name } });
  };

  const hasError = touched && Boolean(error);

  return (
    <>
      {label && (
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ textTransform: 'capitalize' }}
        >
          {label}
        </Typography>
      )}

      <EditorWrapper data-error={hasError}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={onChange}
          onBlur={handleEditorBlur}
          name={name}
          placeholder={placeholder}
        />
      </EditorWrapper>
      {touched && error && (
        <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default QuillEditorField;

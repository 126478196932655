/* eslint-disable react/prop-types */
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  selectHasMore,
  selectStatus,
  selectTodos
} from 'src/slices/todosSlice';
import TodosCard from './TodosCard';
import TodosCardSkeleton from './TodosSkeleton';

const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).format(date);
};

const RenderTodos = ({ fetchMore, searchQuery }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hasMore = useSelector(selectHasMore);
  const isLoading = useSelector(selectStatus);
  const todos = useSelector(selectTodos);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const groupedTodos = {};
  todos?.forEach((todo) => {
    let dueDate;

    if (todo?.dueDate) {
      const todoDueDate = new Date(todo?.dueDate.slice(0, 10)); // Extract YYYY-MM-DD
      dueDate = todoDueDate;
    } else {
      dueDate = null; // Handle todos without due date
    }

    if (!groupedTodos[dueDate]) {
      groupedTodos[dueDate] = [];
    }

    groupedTodos?.[dueDate].push(todo);
  });

  const sortedDueDates = Object.keys(groupedTodos)
    .filter((key) => key !== null)
    .sort((a, b) => new Date(a) - new Date(b));

  if (todos?.length === 0 && !isLoading?.getTodos) {
    return (
      <Box
        height="90%"
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          height="200px"
          width="300px"
          component="img"
          src="/notificationsImage.jpg"
          alt="No Todos Yet"
        />
        <Typography variant="h4" color={'gray'} fontWeight={'bold'}>
          {t('No Todos Yet')}
        </Typography>
      </Box>
    );
  }

  console.log(groupedTodos);

  return (
    <Box height="100%">
      <InfiniteScroll
        dataLength={todos?.length || 0}
        next={fetchMore}
        hasMore={hasMore}
        loader={<TodosCardSkeleton value={6} />}
        endMessage={
          <Typography textAlign="center" py={2}>
            {t('No more todos')}
          </Typography>
        }
        height={`calc(100vh - 220px)`}
      >
        <Box p={1}>
          {sortedDueDates?.map((dueDate, index) => {
            const dueDateObj = new Date(dueDate);
            dueDateObj.setHours(0, 0, 0, 0);

            const isDueToday = dueDateObj.getTime() === today.getTime();
            const isDueTomorrow = dueDateObj.getTime() === tomorrow.getTime();
            const isOverdue = dueDateObj.getTime() < today.getTime();
            return (
              <div key={index}>
                <Typography
                  variant="body2"
                  color={isOverdue ? 'error' : theme.palette.text.secondary}
                  mb={1}
                >
                  {dueDate === 'null'
                    ? 'No Due Date'
                    : isDueToday
                    ? 'Today'
                    : isDueTomorrow
                    ? 'Tomorrow'
                    : formatDate(new Date(dueDate))}
                  {isOverdue && (
                    <Chip
                      label="Overdue"
                      size="small"
                      color="error"
                      sx={{ ml: 0.8, fontSize: 12 }}
                    />
                  )}
                </Typography>
                {groupedTodos?.[dueDate]?.map((todo) => (
                  <TodosCard key={todo?.id} todo={todo} />
                ))}
              </div>
            );
          })}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};

export default RenderTodos;

import { useState, useEffect } from 'react';
import { requestApi } from 'src/mocks/request';

const useFetchMultiple = (payload) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await requestApi.getData(payload);
        if ((response.status = 'SUCCESS')) {
          setData(response.data.data);
        } // Adjust based on the actual response structure
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading };
};

export default useFetchMultiple;

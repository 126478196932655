import { useEffect } from 'react';

const WorkspaceRedirect = ({ workspace = null, tld, domain }) => {
  const redirectTo = workspace
    ? `${workspace}.${domain}.${tld}`
    : `${domain}.${tld}`;
  useEffect(() => {
    window.location.href = `https://${redirectTo}/`;
  }, []);

  return <div>Redirecting to {redirectTo} ..</div>;
};

export default WorkspaceRedirect;

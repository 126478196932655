export const REMOVE_FIELDS = [
  '__v',
  '_id',
  'updatedBy',
  'createdAt',
  'updatedAt',
  'addedBy',
  'isActive',
  'isDeleted',
  // ERP specific fields
  'workspace',
  'approval_status',
  'approval_id'
];

export const FIELD_TO_OVERRIDE = [
  'image',
  'logo',
  'icon',
  'hero',
  'avatar',
  'photo',
  'profile_image',
  'gallery',
  'images'
];

export const STRING = [
  'contains',
  'equal',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
  'is any of'
];
// export const BOOLEAN = ['equal', 'is empty', 'is not empty'];
export const BOOLEAN = ['equal'];
export const NUMBERORDATE = [
  'equal',
  'is empty',
  'is not empty',
  'greater than',
  'less than',
  'in between'
];
export const ARRAY = ['contains'];

const stringObjOpe = [
  'equal',
  'contains',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
  'is any of'
];

export const OPERATOR_MAPS = {
  String: stringObjOpe,
  // Boolean: ['equal', 'is empty', 'is not empty'],
  Boolean: ['equal'],
  Array: ['contains', 'is any of'],
  Number: ['equal', 'greater than', 'less than', 'in between'],
  Date: ['greater than', 'less than', 'in between'],
  ObjectID: stringObjOpe
};

export const COMPONENTS_LIST = {
  Boolean: [
    {
      label: 'Switch',
      value: 'switch'
    }
  ],
  Number: [
    {
      label: 'Progress',
      value: 'progress',
      props: [
        {
          name: 'color'
        }
      ]
    }
  ],
  String: [
    {
      label: 'Chip',
      value: 'chip',
      props: [
        { name: 'color' },
        { name: 'variant', options: ['outlined', 'filled'] },
        { name: 'size', options: ['small', 'medium'] }
      ]
    },
    {
      label: 'Card',
      value: 'card'
    }
  ]
};

export const TEXT_FORMAT = {
  fontStyleOptions: [
    { label: 'Normal', value: 'normal' },
    { label: 'Italic', value: 'italic' },
    { label: 'Oblique', value: 'oblique' }
  ],
  fontWeightOptions: [
    { label: 'Normal', value: 'normal' },
    { label: 'Bold', value: 'bold' },
    { label: 'Bolder', value: 'bolder' },
    { label: 'Lighter', value: 'lighter' },
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' },
    { label: '900', value: '900' }
  ],
  fontFamilyOptions: [
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Helvetica', value: 'Helvetica, sans-serif' },
    { label: 'Times New Roman', value: 'Times New Roman, serif' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Garamond', value: 'Garamond, serif' },
    { label: 'Courier New', value: 'Courier New, monospace' },
    { label: 'Lucida Console', value: 'Lucida Console, monospace' },
    { label: 'Roboto', value: "'Roboto', sans-serif" },
    { label: 'Open Sans', value: "'Open Sans', sans-serif" },
    { label: 'Lato', value: "'Lato', sans-serif" },
    { label: 'Montserrat', value: "'Montserrat', sans-serif" },
    { label: 'Source Sans Pro', value: "'Source Sans Pro', sans-serif" },
    { label: 'Calibri', value: 'Calibri, sans-serif' },
    { label: 'Cambria', value: 'Cambria, serif' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Trebuchet MS', value: "'Trebuchet MS', sans-serif" },
    { label: 'Gill Sans', value: "'Gill Sans', sans-serif" },
    { label: 'Optima', value: 'Optima, sans-serif' },
    { label: 'Futura', value: 'Futura, sans-serif' }
  ]
};
export const DESIGN_TYPE_OPTIONS = [
  { label: 'Custom Component', value: true },
  { label: 'Standard Text Formatting', value: false }
];
export const TEXT_MODERATION = [
  { label: 'Prefix', value: 'prefix' },
  { label: 'Suffix', value: 'suffix' }
];

export const countries = [
  {
    country: 'Afghanistan',
    code: 'AF',
    icon: '🇦🇫',
    phone: '+93',
    phoneLength: 9
  },
  { country: 'Albania', code: 'AL', icon: '🇦🇱', phone: '+355', phoneLength: 9 },
  { country: 'Algeria', code: 'DZ', icon: '🇩🇿', phone: '+213', phoneLength: 9 },
  { country: 'Andorra', code: 'AD', icon: '🇦🇩', phone: '+376', phoneLength: 6 },
  { country: 'Angola', code: 'AO', icon: '🇦🇴', phone: '+244', phoneLength: 9 },
  {
    country: 'Antigua and Barbuda',
    code: 'AG',
    icon: '🇦🇬',
    phone: '+1-268',
    phoneLength: 10
  },
  {
    country: 'Argentina',
    code: 'AR',
    icon: '🇦🇷',
    phone: '+54',
    phoneLength: [6, 7, 8]
  },
  { country: 'Armenia', code: 'AM', icon: '🇦🇲', phone: '+374', phoneLength: 6 },
  {
    country: 'Australia',
    code: 'AU',
    icon: '🇦🇺',
    phone: '+61',
    phoneLength: 9
  },
  {
    country: 'Austria',
    code: 'AT',
    icon: '🇦🇹',
    phone: '+43',
    phoneLength: [10, 11]
  },
  {
    country: 'Azerbaijan',
    code: 'AZ',
    icon: '🇦🇿',
    phone: '+994',
    phoneLength: 9
  },
  {
    country: 'Bahamas',
    code: 'BS',
    icon: '🇧🇸',
    phone: '+1-242',
    phoneLength: 10
  },
  { country: 'Bahrain', code: 'BH', icon: '🇧🇭', phone: '+973', phoneLength: 8 },
  {
    country: 'Bangladesh',
    code: 'BD',
    icon: '🇧🇩',
    phone: '+880',
    phoneLength: 10
  },
  {
    country: 'Barbados',
    code: 'BB',
    icon: '🇧🇧',
    phone: '+1-246',
    phoneLength: 10
  },
  { country: 'Belarus', code: 'BY', icon: '🇧🇾', phone: '+375', phoneLength: 9 },
  { country: 'Belgium', code: 'BE', icon: '🇧🇪', phone: '+32', phoneLength: 9 },
  { country: 'Belize', code: 'BZ', icon: '🇧🇿', phone: '+501', phoneLength: 7 },
  { country: 'Benin', code: 'BJ', icon: '🇧🇯', phone: '+229', phoneLength: 8 },
  {
    country: 'Bermuda',
    code: 'BM',
    icon: '🇧🇲',
    phone: '+1-441',
    phoneLength: 10
  },
  { country: 'Bhutan', code: 'BT', icon: '🇧🇹', phone: '+975', phoneLength: 7 },
  { country: 'Bolivia', code: 'BO', icon: '🇧🇴', phone: '+591', phoneLength: 9 },
  {
    country: 'Bosnia and Herzegovina',
    code: 'BA',
    icon: '🇧🇦',
    phone: '+387',
    phoneLength: 8
  },
  {
    country: 'Botswana',
    code: 'BW',
    icon: '🇧🇼',
    phone: '+267',
    phoneLength: 7
  },
  { country: 'Brazil', code: 'BR', icon: '🇧🇷', phone: '+55', phoneLength: 11 },
  {
    country: 'Brunei Darussalam',
    code: 'BN',
    icon: '🇧🇳',
    phone: '+673',
    phoneLength: 7
  },
  {
    country: 'Bulgaria',
    code: 'BG',
    icon: '🇧🇬',
    phone: '+359',
    phoneLength: 9
  },
  {
    country: 'Burkina Faso',
    code: 'BF',
    icon: '🇧🇫',
    phone: '+226',
    phoneLength: 8
  },
  { country: 'Burundi', code: 'BI', icon: '🇧🇮', phone: '+257', phoneLength: 8 },
  {
    country: 'Cabo Verde',
    code: 'CV',
    icon: '🇨🇻',
    phone: '+238',
    phoneLength: 7
  },
  {
    country: 'Cambodia',
    code: 'KH',
    icon: '🇰🇭',
    phone: '+855',
    phoneLength: 9
  },
  {
    country: 'Cameroon',
    code: 'CM',
    icon: '🇨🇲',
    phone: '+237',
    phoneLength: 9
  },
  { country: 'Canada', code: 'CA', icon: '🇨🇦', phone: '+1', phoneLength: 10 },
  {
    country: 'Cayman Islands',
    code: 'KY',
    icon: '🇰🇾',
    phone: '+1-345',
    phoneLength: 10
  },
  {
    country: 'Central African Republic',
    code: 'CF',
    icon: '🇨🇫',
    phone: '+236',
    phoneLength: 8
  },
  { country: 'Chad', code: 'TD', icon: '🇹🇩', phone: '+235', phoneLength: 8 },
  { country: 'Chile', code: 'CL', icon: '🇨🇱', phone: '+56', phoneLength: 9 },
  { country: 'China', code: 'CN', icon: '🇨🇳', phone: '+86', phoneLength: 11 },
  {
    country: 'Colombia',
    code: 'CO',
    icon: '🇨🇴',
    phone: '+57',
    phoneLength: 10
  },
  { country: 'Comoros', code: 'KM', icon: '🇰🇲', phone: '+269', phoneLength: 7 },
  {
    country: 'Congo (Republic of the)',
    code: 'CG',
    icon: '🇨🇬',
    phone: '+242',
    phoneLength: 9
  },
  {
    country: 'Congo (Democratic Republic of the)',
    code: 'CD',
    icon: '🇨🇩',
    phone: '+243',
    phoneLength: 9
  },
  {
    country: 'Costa Rica',
    code: 'CR',
    icon: '🇨🇷',
    phone: '+506',
    phoneLength: 8
  },
  { country: 'Croatia', code: 'HR', icon: '🇭🇷', phone: '+385', phoneLength: 9 },
  { country: 'Cuba', code: 'CU', icon: '🇨🇺', phone: '+53', phoneLength: 8 },
  { country: 'Cyprus', code: 'CY', icon: '🇨🇾', phone: '+357', phoneLength: 8 },
  {
    country: 'Czech Republic',
    code: 'CZ',
    icon: '🇨🇿',
    phone: '+420',
    phoneLength: 9
  },
  { country: 'Denmark', code: 'DK', icon: '🇩🇰', phone: '+45', phoneLength: 8 },
  {
    country: 'Djibouti',
    code: 'DJ',
    icon: '🇩🇯',
    phone: '+253',
    phoneLength: 8
  },
  {
    country: 'Dominica',
    code: 'DM',
    icon: '🇩🇲',
    phone: '+1-767',
    phoneLength: 10
  },
  {
    country: 'Dominican Republic',
    code: 'DO',
    icon: '🇩🇴',
    phone: '+1-809, +1-829, +1-849',
    phoneLength: 10
  },
  { country: 'Ecuador', code: 'EC', icon: '🇪🇨', phone: '+593', phoneLength: 9 },
  { country: 'Egypt', code: 'EG', icon: '🇪🇬', phone: '+20', phoneLength: 10 },
  {
    country: 'El Salvador',
    code: 'SV',
    icon: '🇸🇻',
    phone: '+503',
    phoneLength: 8
  },
  {
    country: 'Equatorial Guinea',
    code: 'GQ',
    icon: '🇬🇶',
    phone: '+240',
    phoneLength: 6
  },
  { country: 'Eritrea', code: 'ER', icon: '🇪🇷', phone: '+291', phoneLength: 7 },
  { country: 'Estonia', code: 'EE', icon: '🇪🇪', phone: '+372', phoneLength: 7 },
  {
    country: 'Eswatini',
    code: 'SZ',
    icon: '🇸🇿',
    phone: '+268',
    phoneLength: 8
  },
  {
    country: 'Ethiopia',
    code: 'ET',
    icon: '🇪🇹',
    phone: '+251',
    phoneLength: 9
  },
  { country: 'Fiji', code: 'FJ', icon: '🇫🇯', phone: '+679', phoneLength: 7 },
  {
    country: 'Finland',
    code: 'FI',
    icon: '🇫🇮',
    phone: '+358',
    phoneLength: 10
  },
  { country: 'France', code: 'FR', icon: '🇫🇷', phone: '+33', phoneLength: 9 },
  { country: 'Gabon', code: 'GA', icon: '🇬🇦', phone: '+241', phoneLength: 7 },
  { country: 'Gambia', code: 'GM', icon: '🇬🇲', phone: '+220', phoneLength: 7 },
  { country: 'Georgia', code: 'GE', icon: '🇬🇪', phone: '+995', phoneLength: 9 },
  { country: 'Germany', code: 'DE', icon: '🇩🇪', phone: '+49', phoneLength: 11 },
  { country: 'Ghana', code: 'GH', icon: '🇬🇭', phone: '+233', phoneLength: 9 },
  { country: 'Greece', code: 'GR', icon: '🇬🇷', phone: '+30', phoneLength: 10 },
  {
    country: 'Grenada',
    code: 'GD',
    icon: '🇬🇩',
    phone: '+1-473',
    phoneLength: 10
  },
  {
    country: 'Guatemala',
    code: 'GT',
    icon: '🇬🇹',
    phone: '+502',
    phoneLength: 8
  },
  { country: 'Guinea', code: 'GN', icon: '🇬🇳', phone: '+224', phoneLength: 8 },
  {
    country: 'Guinea-Bissau',
    code: 'GW',
    icon: '🇬🇼',
    phone: '+245',
    phoneLength: 7
  },
  { country: 'Guyana', code: 'GY', icon: '🇬🇾', phone: '+592', phoneLength: 7 },
  { country: 'Haiti', code: 'HT', icon: '🇭🇹', phone: '+509', phoneLength: 8 },
  {
    country: 'Honduras',
    code: 'HN',
    icon: '🇭🇳',
    phone: '+504',
    phoneLength: 8
  },
  {
    country: 'Hong Kong',
    code: 'HK',
    icon: '🇭🇰',
    phone: '+852',
    phoneLength: 8
  },
  { country: 'Hungary', code: 'HU', icon: '🇭🇺', phone: '+36', phoneLength: 9 },
  { country: 'Iceland', code: 'IS', icon: '🇮🇸', phone: '+354', phoneLength: 7 },
  { country: 'India', code: 'IN', icon: '🇮🇳', phone: '+91', phoneLength: 10 },
  {
    country: 'Indonesia',
    code: 'ID',
    icon: '🇮🇩',
    phone: '+62',
    phoneLength: 10
  },
  { country: 'Iran', code: 'IR', icon: '🇮🇷', phone: '+98', phoneLength: 10 },
  { country: 'Iraq', code: 'IQ', icon: '🇮🇶', phone: '+964', phoneLength: 10 },
  { country: 'Ireland', code: 'IE', icon: '🇮🇪', phone: '+353', phoneLength: 9 },
  { country: 'Israel', code: 'IL', icon: '🇮🇱', phone: '+972', phoneLength: 9 },
  { country: 'Italy', code: 'IT', icon: '🇮🇹', phone: '+39', phoneLength: 9 },
  {
    country: 'Jamaica',
    code: 'JM',
    icon: '🇯🇲',
    phone: '+1-876',
    phoneLength: 10
  },
  { country: 'Japan', code: 'JP', icon: '🇯🇵', phone: '+81', phoneLength: 10 },
  { country: 'Jordan', code: 'JO', icon: '🇯🇴', phone: '+962', phoneLength: 9 },
  {
    country: 'Kazakhstan',
    code: 'KZ',
    icon: '🇰🇿',
    phone: '+7',
    phoneLength: 10
  },
  { country: 'Kenya', code: 'KE', icon: '🇰🇪', phone: '+254', phoneLength: 9 },
  {
    country: 'Kiribati',
    code: 'KI',
    icon: '🇰🇮',
    phone: '+686',
    phoneLength: 5
  },
  {
    country: 'Korea (North)',
    code: 'KP',
    icon: '🇰🇵',
    phone: '+850',
    phoneLength: 9
  },
  {
    country: 'Korea (South)',
    code: 'KR',
    icon: '🇰🇷',
    phone: '+82',
    phoneLength: 10
  },
  { country: 'Kuwait', code: 'KW', icon: '🇰🇼', phone: '+965', phoneLength: 8 },
  {
    country: 'Kyrgyzstan',
    code: 'KG',
    icon: '🇰🇬',
    phone: '+996',
    phoneLength: 9
  },
  { country: 'Laos', code: 'LA', icon: '🇱🇦', phone: '+856', phoneLength: 8 },
  { country: 'Latvia', code: 'LV', icon: '🇱🇻', phone: '+371', phoneLength: 8 },
  { country: 'Lebanon', code: 'LB', icon: '🇱🇧', phone: '+961', phoneLength: 8 },
  { country: 'Lesotho', code: 'LS', icon: '🇱🇸', phone: '+266', phoneLength: 8 },
  { country: 'Liberia', code: 'LR', icon: '🇱🇷', phone: '+231', phoneLength: 7 },
  { country: 'Libya', code: 'LY', icon: '🇱🇾', phone: '+218', phoneLength: 9 },
  {
    country: 'Liechtenstein',
    code: 'LI',
    icon: '🇱🇮',
    phone: '+423',
    phoneLength: 7
  },
  {
    country: 'Lithuania',
    code: 'LT',
    icon: '🇱🇹',
    phone: '+370',
    phoneLength: 8
  },
  {
    country: 'Luxembourg',
    code: 'LU',
    icon: '🇱🇺',
    phone: '+352',
    phoneLength: 9
  },
  { country: 'Macao', code: 'MO', icon: '🇲🇴', phone: '+853', phoneLength: 8 },
  {
    country: 'Madagascar',
    code: 'MG',
    icon: '🇲🇬',
    phone: '+261',
    phoneLength: 9
  },
  { country: 'Malawi', code: 'MW', icon: '🇲🇼', phone: '+265', phoneLength: 9 },
  { country: 'Malaysia', code: 'MY', icon: '🇲🇾', phone: '+60', phoneLength: 9 },
  {
    country: 'Maldives',
    code: 'MV',
    icon: '🇲🇻',
    phone: '+960',
    phoneLength: 7
  },
  { country: 'Mali', code: 'ML', icon: '🇲🇱', phone: '+223', phoneLength: 8 },
  { country: 'Malta', code: 'MT', icon: '🇲🇹', phone: '+356', phoneLength: 8 },
  {
    country: 'Marshall Islands',
    code: 'MH',
    icon: '🇲🇭',
    phone: '+692',
    phoneLength: 7
  },
  {
    country: 'Mauritania',
    code: 'MR',
    icon: '🇲🇷',
    phone: '+222',
    phoneLength: 8
  },
  {
    country: 'Mauritius',
    code: 'MU',
    icon: '🇲🇺',
    phone: '+230',
    phoneLength: 7
  },
  { country: 'Mexico', code: 'MX', icon: '🇲🇽', phone: '+52', phoneLength: 10 },
  {
    country: 'Micronesia',
    code: 'FM',
    icon: '🇫🇲',
    phone: '+691',
    phoneLength: 7
  },
  { country: 'Moldova', code: 'MD', icon: '🇲🇩', phone: '+373', phoneLength: 8 },
  { country: 'Monaco', code: 'MC', icon: '🇲🇨', phone: '+377', phoneLength: 8 },
  {
    country: 'Mongolia',
    code: 'MN',
    icon: '🇲🇳',
    phone: '+976',
    phoneLength: 8
  },
  {
    country: 'Montenegro',
    code: 'ME',
    icon: '🇲🇪',
    phone: '+382',
    phoneLength: 8
  },
  { country: 'Morocco', code: 'MA', icon: '🇲🇦', phone: '+212', phoneLength: 9 },
  {
    country: 'Mozambique',
    code: 'MZ',
    icon: '🇲🇿',
    phone: '+258',
    phoneLength: 9
  },
  { country: 'Myanmar', code: 'MM', icon: '🇲🇲', phone: '+95', phoneLength: 9 },
  { country: 'Namibia', code: 'NA', icon: '🇳🇦', phone: '+264', phoneLength: 9 },
  { country: 'Nauru', code: 'NR', icon: '🇳🇷', phone: '+674', phoneLength: 5 },
  { country: 'Nepal', code: 'NP', icon: '🇳🇵', phone: '+977', phoneLength: 10 },
  {
    country: 'Netherlands',
    code: 'NL',
    icon: '🇳🇱',
    phone: '+31',
    phoneLength: 9
  },
  {
    country: 'New Zealand',
    code: 'NZ',
    icon: '🇳🇿',
    phone: '+64',
    phoneLength: 9
  },
  {
    country: 'Nicaragua',
    code: 'NI',
    icon: '🇳🇮',
    phone: '+505',
    phoneLength: 8
  },
  { country: 'Niger', code: 'NE', icon: '🇳🇪', phone: '+227', phoneLength: 8 },
  {
    country: 'Nigeria',
    code: 'NG',
    icon: '🇳🇬',
    phone: '+234',
    phoneLength: 10
  },
  {
    country: 'North Macedonia',
    code: 'MK',
    icon: '🇲🇰',
    phone: '+389',
    phoneLength: 8
  },
  { country: 'Norway', code: 'NO', icon: '🇳🇴', phone: '+47', phoneLength: 8 },
  { country: 'Oman', code: 'OM', icon: '🇴🇲', phone: '+968', phoneLength: 8 },
  {
    country: 'Pakistan',
    code: 'PK',
    icon: '🇵🇰',
    phone: '+92',
    phoneLength: 10
  },
  { country: 'Palau', code: 'PW', icon: '🇵🇼', phone: '+680', phoneLength: 7 },
  { country: 'Panama', code: 'PA', icon: '🇵🇦', phone: '+507', phoneLength: 8 },
  {
    country: 'Papua New Guinea',
    code: 'PG',
    icon: '🇵🇬',
    phone: '+675',
    phoneLength: 7
  },
  {
    country: 'Paraguay',
    code: 'PY',
    icon: '🇵🇾',
    phone: '+595',
    phoneLength: 9
  },
  { country: 'Peru', code: 'PE', icon: '🇵🇪', phone: '+51', phoneLength: 9 },
  {
    country: 'Philippines',
    code: 'PH',
    icon: '🇵🇭',
    phone: '+63',
    phoneLength: 10
  },
  { country: 'Poland', code: 'PL', icon: '🇵🇱', phone: '+48', phoneLength: 9 },
  {
    country: 'Portugal',
    code: 'PT',
    icon: '🇵🇹',
    phone: '+351',
    phoneLength: 9
  },
  { country: 'Qatar', code: 'QA', icon: '🇶🇦', phone: '+974', phoneLength: 8 },
  { country: 'Romania', code: 'RO', icon: '🇷🇴', phone: '+40', phoneLength: 10 },
  { country: 'Russia', code: 'RU', icon: '🇷🇺', phone: '+7', phoneLength: 10 },
  { country: 'Rwanda', code: 'RW', icon: '🇷🇼', phone: '+250', phoneLength: 9 },
  {
    country: 'Saint Kitts and Nevis',
    code: 'KN',
    icon: '🇰🇳',
    phone: '+1-869',
    phoneLength: 10
  },
  {
    country: 'Saint Lucia',
    code: 'LC',
    icon: '🇱🇨',
    phone: '+1-758',
    phoneLength: 10
  },
  {
    country: 'Saint Vincent and the Grenadines',
    code: 'VC',
    icon: '🇻🇨',
    phone: '+1-784',
    phoneLength: 10
  },
  { country: 'Samoa', code: 'WS', icon: '🇼🇸', phone: '+685', phoneLength: 7 },
  {
    country: 'San Marino',
    code: 'SM',
    icon: '🇸🇲',
    phone: '+378',
    phoneLength: 7
  },
  {
    country: 'Sao Tome and Principe',
    code: 'ST',
    icon: '🇸🇹',
    phone: '+239',
    phoneLength: 7
  },
  {
    country: 'Saudi Arabia',
    code: 'SA',
    icon: '🇸🇦',
    phone: '+966',
    phoneLength: 9
  },
  { country: 'Senegal', code: 'SN', icon: '🇸🇳', phone: '+221', phoneLength: 9 },
  { country: 'Serbia', code: 'RS', icon: '🇷🇸', phone: '+381', phoneLength: 9 },
  {
    country: 'Seychelles',
    code: 'SC',
    icon: '🇸🇨',
    phone: '+248',
    phoneLength: 7
  },
  {
    country: 'Sierra Leone',
    code: 'SL',
    icon: '🇸🇱',
    phone: '+232',
    phoneLength: 8
  },
  {
    country: 'Singapore',
    code: 'SG',
    icon: '🇸🇬',
    phone: '+65',
    phoneLength: 8
  },
  {
    country: 'Slovakia',
    code: 'SK',
    icon: '🇸🇰',
    phone: '+421',
    phoneLength: 9
  },
  {
    country: 'Slovenia',
    code: 'SI',
    icon: '🇸🇮',
    phone: '+386',
    phoneLength: 8
  },
  {
    country: 'Solomon Islands',
    code: 'SB',
    icon: '🇸🇧',
    phone: '+677',
    phoneLength: 5
  },
  { country: 'Somalia', code: 'SO', icon: '🇸🇴', phone: '+252', phoneLength: 8 },
  {
    country: 'South Africa',
    code: 'ZA',
    icon: '🇿🇦',
    phone: '+27',
    phoneLength: 9
  },
  {
    country: 'South Sudan',
    code: 'SS',
    icon: '🇸🇸',
    phone: '+211',
    phoneLength: 9
  },
  { country: 'Spain', code: 'ES', icon: '🇪🇸', phone: '+34', phoneLength: 9 },
  {
    country: 'Sri Lanka',
    code: 'LK',
    icon: '🇱🇰',
    phone: '+94',
    phoneLength: 9
  },
  { country: 'Sudan', code: 'SD', icon: '🇸🇩', phone: '+249', phoneLength: 9 },
  {
    country: 'Suriname',
    code: 'SR',
    icon: '🇸🇷',
    phone: '+597',
    phoneLength: 7
  },
  { country: 'Sweden', code: 'SE', icon: '🇸🇪', phone: '+46', phoneLength: 10 },
  {
    country: 'Switzerland',
    code: 'CH',
    icon: '🇨🇭',
    phone: '+41',
    phoneLength: 9
  },
  { country: 'Syria', code: 'SY', icon: '🇸🇾', phone: '+963', phoneLength: 9 },
  { country: 'Taiwan', code: 'TW', icon: '🇹🇼', phone: '+886', phoneLength: 9 },
  {
    country: 'Tajikistan',
    code: 'TJ',
    icon: '🇹🇯',
    phone: '+992',
    phoneLength: 9
  },
  {
    country: 'Tanzania',
    code: 'TZ',
    icon: '🇹🇿',
    phone: '+255',
    phoneLength: 9
  },
  {
    country: 'Thailand',
    code: 'TH',
    icon: '🇹🇭',
    phone: '+66',
    phoneLength: 10
  },
  {
    country: 'Timor-Leste',
    code: 'TL',
    icon: '🇹🇱',
    phone: '+670',
    phoneLength: 7
  },
  { country: 'Togo', code: 'TG', icon: '🇹🇬', phone: '+228', phoneLength: 8 },
  { country: 'Tonga', code: 'TO', icon: '🇹🇴', phone: '+676', phoneLength: 5 },
  {
    country: 'Trinidad and Tobago',
    code: 'TT',
    icon: '🇹🇹',
    phone: '+1-868',
    phoneLength: 10
  },
  { country: 'Tunisia', code: 'TN', icon: '🇹🇳', phone: '+216', phoneLength: 8 },
  { country: 'Turkey', code: 'TR', icon: '🇹🇷', phone: '+90', phoneLength: 10 },
  {
    country: 'Turkmenistan',
    code: 'TM',
    icon: '🇹🇲',
    phone: '+993',
    phoneLength: 8
  },
  { country: 'Tuvalu', code: 'TV', icon: '🇹🇻', phone: '+688', phoneLength: 5 },
  { country: 'Uganda', code: 'UG', icon: '🇺🇬', phone: '+256', phoneLength: 9 },
  { country: 'Ukraine', code: 'UA', icon: '🇺🇦', phone: '+380', phoneLength: 9 },
  {
    country: 'United Arab Emirates',
    code: 'AE',
    icon: '🇦🇪',
    phone: '+971',
    phoneLength: 9
  },
  {
    country: 'United Kingdom',
    code: 'GB',
    icon: '🇬🇧',
    phone: '+44',
    phoneLength: 10
  },
  {
    country: 'United States of America',
    code: 'US',
    icon: '🇺🇸',
    phone: '+1',
    phoneLength: 10
  },
  { country: 'Uruguay', code: 'UY', icon: '🇺🇾', phone: '+598', phoneLength: 8 },
  {
    country: 'Uzbekistan',
    code: 'UZ',
    icon: '🇺🇿',
    phone: '+998',
    phoneLength: 9
  },
  { country: 'Vanuatu', code: 'VU', icon: '🇻🇺', phone: '+678', phoneLength: 7 },
  {
    country: 'Vatican City',
    code: 'VA',
    icon: '🇻🇦',
    phone: '+379',
    phoneLength: 7
  },
  {
    country: 'Venezuela',
    code: 'VE',
    icon: '🇻🇪',
    phone: '+58',
    phoneLength: 10
  },
  { country: 'Vietnam', code: 'VN', icon: '🇻🇳', phone: '+84', phoneLength: 10 },
  { country: 'Yemen', code: 'YE', icon: '🇾🇪', phone: '+967', phoneLength: 9 },
  { country: 'Zambia', code: 'ZM', icon: '🇿🇲', phone: '+260', phoneLength: 9 },
  { country: 'Zimbabwe', code: 'ZW', icon: '🇿🇼', phone: '+263', phoneLength: 9 }
];

// workflows
export const ACTION_TYPES = ['NOTIFY', 'DATA', 'SCRIPT', 'WEBHOOK'];
export const CRUD_TYPES = [
  { label: 'Create', value: 'create' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' }
];
export const VALUE_TYPES = [
  'Constants',
  'Custom input',
  'values from trigger document',
  'values from previous actions'
];
// workflow end

export const INDUSTRY_TYPE_ENUM = {
  TECHNOLOGY: 'TECHNOLOGY',
  FINANCE: 'FINANCE',
  HEALTHCARE: 'HEALTHCARE',
  ENTERTAINMENT: 'ENTERTAINMENT',
  EDUCATION: 'EDUCATION',
  RETAIL: 'RETAIL',
  MANUFACTURING: 'MANUFACTURING',
  TRANSPORTATION: 'TRANSPORTATION',
  HOSPITALITY: 'HOSPITALITY',
  ENERGY: 'ENERGY',
  TELECOMMUNICATIONS: 'TELECOMMUNICATIONS',
  AGRICULTURE: 'AGRICULTURE',
  MEDIA: 'MEDIA',
  AUTOMOTIVE: 'AUTOMOTIVE',
  CONSTRUCTION: 'CONSTRUCTION',
  PHARMACEUTICAL: 'PHARMACEUTICAL',
  BIOTECHNOLOGY: 'BIOTECHNOLOGY',
  CONSULTING: 'CONSULTING',
  SPORTS: 'SPORTS',
  REAL_ESTATE: 'REAL_ESTATE',
  NON_PROFIT: 'NON_PROFIT',
  LEGAL: 'LEGAL',
  ARTS_AND_CULTURE: 'ARTS_AND_CULTURE',
  FOOD_AND_BEVERAGE: 'FOOD_AND_BEVERAGE',
  OTHER: 'OTHER'
};

import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { object } from 'prop-types';
import { requestApi } from 'src/mocks/request';
import { Axios } from 'src/services/axios';

let initialState = {
  projects: [],
  employee: [],
  selectedProject: null,
  sections: [],
  tasks: [],
  taskLoading: true,
  taskDetailsLoading: true,
  selectedTask: {},
  isTask: false,
  subTaskOptionId: null,
  status: {
    getAllProjects: false,
    getProject: false,
    createProject: false,
    getEmployee: false,
    updateProject: false,
    getUser: false
  }
};

initialState = {
  ...initialState,
  selectedProject:
    initialState.projects.length > 0 ? initialState.projects[0] : null
};

export const getAllProjects = createAsyncThunk(
  'project/getAllProjects',
  async (data, { rejectWithValue }) => {
    try {
      let response = await requestApi.getData(data);
      console.log(response, 'project');
      if (response?.status === 'SUCCESS') {
        return response.data.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createProject = createAsyncThunk(
  'project/createProject',
  async (data, { rejectWithValue }) => {
    try {
      let response = await requestApi.createData(data);
      if (response?.status == 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProjectAsync = createAsyncThunk(
  'project/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return data.updateData;
        // return { ...data, response: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProject = createAsyncThunk(
  'project/getProject',
  async (id, { rejectWithValue }) => {
    try {
      let response = await Axios.get(`/project/${id}`);
      console.log(response);
      if (response?.data?.status == 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFileAsync = createAsyncThunk(
  'section/uploadFileAsync',
  async (data, { rejectWithValue }) => {
    try {
      console.log(data, 'updloadfile');
      let response = await requestApi.uploadFile(data);
      console.log(response, 'uploadresponse');
    } catch (error) {
      console.log(error, 'error');
    }
  }
);

export const getAllEmployee = createAsyncThunk(
  'hr/getAllEmployee',
  async (data, { rejectWithValue }) => {
    try {
      let response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    cleanMessageLog(state) {
      state.message.success = null;
      state.message.error = null;
      state.message.to = null;
    },
    setSelectedProject(state, { payload }) {
      state.selectedProject = payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.status.getAllProjects = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.status.getAllProjects = false;
        state.projects = action.payload;
        state.selectedProject = action.payload[0];
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.status.getAllProjects = false;
      })
      .addCase(getProject.pending, (state) => {
        state.status.getProject = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.status.getProject = false;
        state.selectedProject = action.payload;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.status.getProject = false;
      })
      .addCase(createProject.pending, (state) => {
        state.status.createProject = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.status.createProject = false;
        state.projects.push(action.payload);
      })
      .addCase(createProject.rejected, (state, action) => {
        state.status.getProject = false;
      })
      .addCase(getAllEmployee.pending, (state) => {
        state.status.getEmployee = true;
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.status.getEmployee = false;
        state.employee = action.payload;
      })
      .addCase(getAllEmployee.rejected, (state, action) => {
        state.status.getEmployee = false;
      })
      .addCase(updateProjectAsync.pending, (state) => {
        state.status.updateProject = true;
      })
      .addCase(updateProjectAsync.fulfilled, (state, action) => {
        state.status.updateProject = false;
        const replaceData = action.payload;
        state.projects = state.projects.map((project) =>
          project.id === replaceData.id ? replaceData : project
        );
      })
      .addCase(updateProjectAsync.rejected, (state) => {
        state.status.updateProject = false;
      });
  }
});

export const {
  cleanMessageLog,
  cleanSubTaskMessageLog,
  addselectedTask,
  checkIsTask,
  setSelectedProject
} = slice.actions;
export const selectProjectData = (state) => state.projectReducer;
export default slice.reducer;

// import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
// import { requestApi } from 'src/mocks/request';
// import { Axios } from 'src/services/axios';

// let initialState = {
//   projects: [],
//   employee: [],
//   selectedProjectId: '',
//   selectedProject: null,
//   sections: [],
//   selectedSectionId: null,
//   tasks: [],
//   taskLoading: true,
//   taskDetailsLoading: true,
//   selectedTask: {},
//   isTask: false,
//   subTaskOptionId: null,
//   status: {
//     getAllProjects: false,
//     getProject: false,
//     getSection: false,
//     getTasks: false,
//     loadingProject: true,
//     getOneTask: false,
//     createTasks: false,
//     updateTask: false,
//     deleteTask: false,
//     getSubTask: false,
//     updateSection: false,
//     createProject: false
//   },
//   message: { success: null, error: null, to: null },
//   subTaskMessage: { success: null, error: null, to: null }
// };

// export const getAllProjects = createAsyncThunk(
//   'project/getAllProjects',
//   async (data, { rejectWithValue }) => {
//     try {
//       let response = await requestApi.getData(data);
//       console.log(response, 'project');
//       if (response?.status === 'SUCCESS') {
//         return response.data.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const createProject = createAsyncThunk(
//   'project/createProject',
//   async (data, { rejectWithValue }) => {
//     try {
//       let response = await requestApi.createData(data);
//       if (response?.status == 'SUCCESS') {
//         return response?.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// // export const createSection = createAsyncThunk(
// //   'project/createSection',
// //   async (data, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.post('project_section/create', data);
// //       if (response?.data.status == 'SUCCESS') {
// //         return response?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// export const getProject = createAsyncThunk(
//   'project/getProject',
//   async (id, { rejectWithValue }) => {
//     try {
//       let response = await Axios.get(`/project/${id}`);
//       console.log(response);
//       if (response?.data?.status == 'SUCCESS') {
//         return response?.data?.data;
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// // export const getSection = createAsyncThunk(
// //   'project/getSection',
// //   async (id, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.post(`/project_section/list`, {
// //         query: { project: id }
// //       });
// //       if (response?.data?.status === 'SUCCESS') {
// //         return response?.data?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const getTasks = createAsyncThunk(
// //   'project/getTask',
// //   async (sectionId, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.post(`/project_tasks/list`, {
// //         query: { project: sectionId },
// //         options: {
// //           pagination: false
// //         }
// //       });
// //       if (response?.data?.status == 'SUCCESS') {
// //         console.log(response?.data?.data?.data, 'axios tasks');
// //         return response?.data?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const getOneTask = createAsyncThunk(
// //   'project/getOneTask',
// //   async (subTaskId, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.get(`/project_tasks/${subTaskId}`);
// //       if (response?.data?.status === 'SUCCESS') {
// //         return response?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const updateTask = createAsyncThunk(
// //   'section/updateTask',
// //   async (data, { rejectWithValue }) => {
// //     let { update, taskId } = data;
// //     try {
// //       if (taskId) {
// //         const response = await Axios.put(
// //           `/project_tasks/update/${taskId}`,
// //           update
// //         );
// //         if (response?.data?.status == 'SUCCESS') {
// //           return { taskId: taskId, data: response.data?.data };
// //         }
// //         return rejectWithValue(response?.message);
// //       }
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const updateSection = createAsyncThunk(
// //   'section/updateSection',
// //   async (data, { rejectWithValue }) => {
// //     let { update, sectionId } = data;
// //     try {
// //       if (sectionId) {
// //         const response = await Axios.put(
// //           `project_section/update/${sectionId}`,
// //           update
// //         );
// //         if (response?.data?.status == 'SUCCESS') {
// //           console.log(response?.data?.data, 'section');
// //           console.log(sectionId, update);
// //           return { sectionId: sectionId, data: response.data?.data };
// //         }
// //         return rejectWithValue(response?.message);
// //       }
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const createTasks = createAsyncThunk(
// //   'project/createTask',
// //   async (data, { rejectWithValue }) => {
// //     try {
// //       let response = await requestApi.createData(data);
// //       console.log(response, 'createTask');
// //       // if (response?.data?.status == 'SUCCESS') {
// //       //   if (response?.data?.data?.is_sub_task) {
// //       //     const updateRes = await Axios.put(
// //       //       `/project_tasks/update/${newTask.parentTask}`,
// //       //       {
// //       //         options: {
// //       //           populate: 'sub_tasks'
// //       //         },
// //       //         $push: { sub_tasks: response?.data?.data?.id }
// //       //       }
// //       //     );
// //       //     if (updateRes?.data?.status === 'SUCCESS') {
// //       //       let modifiedRes = updateRes?.data?.data;
// //       //       return {
// //       //         parent: modifiedRes,
// //       //         created: response.data.data,
// //       //         isSubTask: true
// //       //       };
// //       //     }
// //       //   } else {
// //       //     return response?.data?.data;
// //       //   }
// //       // }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );
// // export const createTasks = createAsyncThunk(
// //   'project/createTask',
// //   async (newTask, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.post('/project_tasks/create', newTask);
// //       if (response?.data?.status == 'SUCCESS') {
// //         if (response?.data?.data?.is_sub_task) {
// //           const updateRes = await Axios.put(
// //             `/project_tasks/update/${newTask.parentTask}`,
// //             {
// //               options: {
// //                 populate: 'sub_tasks'
// //               },
// //               $push: { sub_tasks: response?.data?.data?.id }
// //             }
// //           );
// //           if (updateRes?.data?.status === 'SUCCESS') {
// //             let modifiedRes = updateRes?.data?.data;
// //             return {
// //               parent: modifiedRes,
// //               created: response.data.data,
// //               isSubTask: true
// //             };
// //           }
// //         } else {
// //           return response?.data?.data;
// //         }
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const deleteTask = createAsyncThunk(
// //   'project/deleteTask',
// //   async (taskId, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.delete(`/project_tasks/delete/${taskId}`);
// //       if (response?.data?.status === 'SUCCESS') {
// //         return response?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// // export const getSubTask = createAsyncThunk(
// //   'section/getSubTask',
// //   async (SubTaskId, { rejectWithValue }) => {
// //     try {
// //       let response = await Axios.get(`/project_tasks/${SubTaskId}`);
// //       if (response?.data?.status == 'SUCCESS') {
// //         return response?.data?.data;
// //       }
// //       return rejectWithValue(response?.message);
// //     } catch (error) {
// //       return rejectWithValue(error);
// //     }
// //   }
// // );

// export const uploadFileAsync = createAsyncThunk(
//   'section/uploadFileAsync',
//   async (data, { rejectWithValue }) => {
//     try {
//       console.log(data, "updloadfile")
//       let response = await requestApi.uploadFile(data);
//       console.log(response, "uploadresponse");
//     } catch (error) { console.log(error, "error") }
//   }
// );

// export const getAllEmployee = createAsyncThunk(
//   'hr/getAllEmployee',
//   async (data, { rejectWithValue }) => {
//     try {
//       let response = await requestApi.getData(data);
//       if (response?.status === 'SUCCESS') {
//         return response?.data?.data
//       }
//       return rejectWithValue(response?.message);
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// const slice = createSlice({
//   name: 'project',
//   initialState,
//   reducers: {
//     cleanMessageLog(state, action) {
//       state.message.success = null;
//       state.message.error = null;
//       state.message.to = null;
//     },
//     setSelectedProjectId(state, action) {
//       state.selectedProjectId = action.payload;
//     }
//   },

//   extraReducers: (builder) => {
//     builder
//       .addCase(getAllProjects.pending, (state) => {
//         state.status.getAllProjects = true;
//       })
//       .addCase(getAllProjects.fulfilled, (state, action) => {
//         state.status.getAllProjects = false;
//         state.message.to = 'getAllProjects';
//         state.status.loadingProject = false;
//         // state.message.success = 'SUCCESS';
//         console.log(action?.payload, 'action?.payload');
//         state.projects = action.payload;
//       })
//       .addCase(getAllProjects.rejected, (state, action) => {
//         state.status.getAllProjects = false;
//         state.message.error = action.payload;
//       })
//       .addCase(getProject.pending, (state) => {
//         state.status.getProject = true;
//       })
//       .addCase(getProject.fulfilled, (state, action) => {
//         state.status.getProject = false;
//         state.message.to = 'getProject';
//         state.message.success = 'SUCCESS';
//         state.selectedProject = action.payload;
//       })
//       .addCase(getProject.rejected, (state, action) => {
//         state.status.getProject = false;
//         state.message.error = action.payload;
//       })
//       .addCase(createProject.pending, (state) => {
//         state.status.createProject = true;
//       })
//       .addCase(createProject.fulfilled, (state, action) => {
//         state.status.createProject = false;
//         // state.message.to = 'getProject';
//         // state.message.success = 'SUCCESS';
//         state.projects.push(action.payload);
//       })
//       .addCase(createProject.rejected, (state, action) => {
//         state.status.getProject = false;
//         state.message.error = action.payload;
//       })
//       // .addCase(getSection.pending, (state) => {
//       //   state.status.getSection = true;
//       // })
//       // .addCase(getSection.fulfilled, (state, action) => {
//       //   state.status.getSection = false;
//       //   state.message.to = 'getSection';
//       //   state.message.success = 'SUCCESS';
//       //   console.log(action.payload, 'sections');
//       //   state.sections = action.payload;
//       // })
//       // .addCase(getSection.rejected, (state, action) => {
//       //   state.status.getSection = false;
//       //   state.message.error = action.payload;
//       // })

//       // .addCase(updateSection.pending, (state) => {
//       //   state.status.updateSection = true; // Setting to true as action is pending
//       // })
//       // .addCase(updateSection.fulfilled, (state, action) => {
//       //   state.status.updateSection = false;
//       //   state.message.to = 'getSection';
//       //   state.message.success = 'SUCCESS';
//       //   state.sections = state.sections.map((data) => {
//       //     if (data?.id === action?.payload?.sectionId) {
//       //       return {
//       //         ...data,
//       //         name: action?.payload?.data?.name
//       //       };
//       //     }
//       //     return data;
//       //   });
//       //   console.log(state?.sections);
//       // })
//       // .addCase(updateSection.rejected, (state, action) => {
//       //   state.status.updateSection = false; // Setting back to false as action is rejected
//       //   state.message.error = action.payload;
//       // })

//       // .addCase(getTasks.pending, (state) => {
//       //   state.status.getTasks = false;
//       // })

//       // .addCase(getTasks.fulfilled, (state, action) => {
//       //   state.taskLoading = false;
//       //   state.status.getTasks = false;
//       //   state.message.to = 'getTasks';
//       //   state.message.success = 'SUCCESS';
//       //   state.tasks = action?.payload;
//       //   console.log(state?.tasks, 'state tasks');
//       // })
//       // .addCase(getTasks.rejected, (state, action) => {
//       //   state.message.error = action.payload;
//       // })

//       // .addCase(getOneTask.pending, (state) => {
//       //   state.status.getOneTask = true;
//       // })

//       // .addCase(getOneTask.fulfilled, (state, action) => {
//       //   state.taskDetailsLoading = false;
//       //   state.message.success = 'SUCCESS';
//       //   state.getOneTask = false;
//       //   state.selectedTask = action.payload;
//       // })

//       // .addCase(getOneTask.rejected, (state, action) => {
//       //   state.status.getOneTask = false;
//       //   state.message.error = action.payload;
//       // })
//       // .addCase(createTasks.pending, (state) => {
//       //   state.createTasks = true;
//       // })

//       // .addCase(createTasks.fulfilled, (state, action) => {
//       //   state.message.to = 'createTasks';
//       //   state.message.success = 'SUCCESS';
//       //   if (action.payload.isSubTask === true) {
//       //     if (action.payload?.parent) {
//       //       let updatedMainTasks = state.tasks.map((t) => {
//       //         if (t.id === action.payload.parent.id) {
//       //           // state.selectedTask = action.payload.parentTask
//       //           return action.payload.parent;
//       //         } else {
//       //           return t;
//       //         }
//       //       });
//       //       state.tasks = updatedMainTasks;
//       //       if (state.selectedTask.id === action.payload.parent.id) {
//       //         state.selectedTask = action.payload.parent;
//       //       }
//       //     } else {
//       //       state.selectedTask = action.payload;
//       //     }
//       //     if (action.payload?.created) {
//       //       let newArray = [...state.tasks, action.payload.created];
//       //       state.tasks = newArray;
//       //     }
//       //   } else {
//       //     let newArray = [...state.tasks, action.payload];
//       //     state.tasks = newArray;
//       //   }
//       // })
//       // .addCase(createTasks.rejected, (state, action) => {
//       //   // state.isLoading = false;
//       //   state.message.error = action.payload;
//       // })
//       // .addCase(updateTask.pending, (state) => {
//       //   state.status.updateTask = true;
//       // })

//       // .addCase(updateTask.fulfilled, (state, action) => {
//       //   state.status.updateTask = false;
//       //   if (action.payload?.taskId) {
//       //     state.message.success = 'SUCCESS task';
//       //     state.message.to = 'update task';
//       //     let newtasks = state.tasks.map((t) => {
//       //       if (t.id === action.payload.data.id) {
//       //         return action.payload.data;
//       //       } else {
//       //         return t;
//       //       }
//       //     });
//       //     if (state.selectedTask.id == action.payload.data.id) {
//       //       state.selectedTask = action.payload.data;
//       //     } else {
//       //       let updatedTask = state.selectedTask?.sub_tasks?.map((subTask) => {
//       //         if (subTask.id === action.payload.data.id) {
//       //           return action.payload.data;
//       //         } else {
//       //           return subTask;
//       //         }
//       //       });
//       //       state.selectedTask = {
//       //         ...state.selectedTask,
//       //         sub_tasks: updatedTask
//       //       };
//       //     }

//       //     state.tasks = newtasks;
//       //   }
//       // })
//       // .addCase(updateTask.rejected, (state, action) => {
//       //   state.status.updateTask = false;
//       //   state.message.error = action.payload;
//       // })
//       // .addCase(deleteTask.pending, (state) => {
//       //   state.status.deleteTask = true;
//       // })

//       // .addCase(deleteTask.fulfilled, (state, action) => {
//       //   state.status.deleteTask = false;
//       //   state.message.to = 'deleteTask';
//       //   state.message.success = 'SUCCESS';
//       //   let filteredTasks = state.tasks.filter(
//       //     ({ id }) => id !== action.payload.id
//       //   );
//       //   state.tasks = filteredTasks;
//       // })

//       // .addCase(deleteTask.rejected, (state, action) => {
//       //   state.status.deleteTask = false;
//       // })

//       // .addCase(getSubTask.pending, (state) => {
//       //   state.status.getSubTask = false;
//       // })
//       // .addCase(getSubTask.fulfilled, (state, action) => {
//       //   state.status.getSubTask = false;
//       //   state.subTaskMessage.to = 'getSubTask';
//       //   state.subTaskMessage.success = 'Sucess';
//       //   state.selectedTask = action.payload;
//       // })
//       // .addCase(getSubTask.rejected, (state, action) => {
//       //   state.subTaskMessage.error = action.payload;
//       // })
//       .addCase(getAllEmployee.pending, (state) => {
//         state.status.getEmployee = true;
//       })
//       .addCase(getAllEmployee.fulfilled, (state, action) => {
//         state.status.getEmployee = false;
//         state.message.to = 'getAllEmployee';
//         state.message.success = 'SUCCESS';
//         state.employee = action.payload;
//       })
//       .addCase(getAllEmployee.rejected, (state, action) => {
//         state.status.getEmployee = false;
//         state.message.error = action.payload;
//       });
//   }
// });

// export const {
//   cleanMessageLog,
//   cleanSubTaskMessageLog,
//   addselectedTask,
//   checkIsTask,
//   setSelectedProjectId
// } = slice.actions;
// export const selectProjectData = (state) => state.projectReducer;
// export default slice.reducer;

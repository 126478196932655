import React from 'react';
import GetFieldBasedOnSchema from '..';
import { Grid } from '@mui/material';

const AccordianGrid = ({
  itemTableHeads,
  fieldName,
  schema,
  entitySetting,
  value,
  touched,
  error
}) => {
  return (
    <>
      <Grid container spacing={2} p={1}>
        {itemTableHeads.map((itemTableHead, itemIndex) => (
          <Grid item xs={6} key={itemTableHead}>
            <GetFieldBasedOnSchema
              name={`${fieldName}.${itemTableHead}`}
              fieldName={fieldName}
              entitySetting={entitySetting}
              itemTableHead={itemTableHead}
              schema={schema}
              value={value[itemTableHead]}
              touched={touched?.[itemTableHead]}
              error={error?.[itemTableHead]}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AccordianGrid;

import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Skeleton,
  Stack,
  styled
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const TableWrapper = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(4)} 0;
      `
);
const ViewPageLoader = () => {
  return (
    <>
      <PageTitleWrapper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={400} />
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={40} height={40} />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Container maxWidth="md">
          <Stack direction="row" spacing={1} mb={1} justifyContent={'flex-end'}>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" width={100} height={40} />
            ))}
          </Stack>
          <Card
            sx={{
              p: 5,
              mb: 3
            }}
          >
            <Divider>
              <Skeleton variant="rounded" width={200} height={35} />
            </Divider>
            <Stack mt={4} spacing={1}>
              <Skeleton variant="text" width={100} />

              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexWrap={'wrap'}
                gap={1}
              >
                {Array.from({ length: 9 }).map((_, index) => (
                  <Skeleton key={index} variant="text" width={200} />
                ))}
              </Stack>
            </Stack>
            <TableWrapper>
              <Skeleton variant="rounded" width={'100%'} height={200} />
            </TableWrapper>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
              gap={1}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} variant="text" width={200} />
              ))}
            </Stack>
          </Card>
        </Container>
      </Grid>
    </>
  );
};

export default ViewPageLoader;

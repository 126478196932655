import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  workspaceSettings: [],
  paginator: null,
  currentWorkspaceSetting: null,
  status: {
    getData: false,
    updateData: false
  }
};
export const getWorkspaceSettingAsync = createAsyncThunk(
  'workspaceSetting/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return { isFetchByID: data.isFetchByID, response: response?.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateWorkspaceSettingAsync = createAsyncThunk(
  'workspaceSetting/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      console.log(response, 'responseupdateworkspace');
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'workspaceSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceSettingAsync.pending, (state) => {
        state.status.getData = true;
      })
      .addCase(getWorkspaceSettingAsync.fulfilled, (state, { payload }) => {
        state.status.getData = false;
        if (!payload?.isFetchByID) {
          state.workspaceSettings = payload?.response?.data || [];
          state.paginator = payload?.response?.paginator || null;
        } else {
          state.currentWorkspaceSetting = payload?.response?.data?.[0] || [];
        }
      })
      .addCase(getWorkspaceSettingAsync.rejected, (state) => {
        state.workspaceSettings = [];
        state.status.getData = false;
        // state.message.error = action.payload;
      })
      .addCase(updateWorkspaceSettingAsync.pending, (state) => {
        state.status.updateData = true;
      })
      .addCase(updateWorkspaceSettingAsync.fulfilled, (state, { payload }) => {
        state.status.updateData = false;
        state.currentWorkspaceSetting = payload;
      })
      .addCase(updateWorkspaceSettingAsync.rejected, (state) => {
        state.status.updateData = false;
      });
  }
});
export const selectWorkspaceSetting = (state) => state.workspaceSetting;

export default slice.reducer;

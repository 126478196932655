import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepConnector } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider
  },
  [`&.Mui-active`]: {
    [`& .MuiStepConnector-line`]: {
      borderTopColor: theme.palette.primary.main
    }
  },
  [`&.Mui-completed`]: {
    [`& .MuiStepConnector-line`]: {
      borderTopColor: theme.palette.primary.main
    }
  }
}));

const TableStapper = ({ steps, activeStep }) => {
  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomStepConnector />}
        sx={{
          padding: 0,
          backgroundColor: 'transparent',
          border: 'none'
        }}
      >
        {Array.from({ length: steps }, (_, index) => (
          <Step key={index}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TableStapper;

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import SelectBoxFieldWithLabel from 'src/components/dynamicFields/SelectBoxFieldWithLabel';
import { COMPONENTS_LIST } from 'src/constant/constant';
import AddPropComponent from './AddPropComponent';
import CardDesignSetting from './CardDesignSetting';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const CustomComponent = ({
  dataItem,
  index,
  fieldName = null,
  itemPath,
  handleBlur,
  propPath,
  schema,
  setFieldValue,
  fieldNames = [],
  name
}) => {
  const [propsOptions, setPropsOptions] = useState([]);

  const getComponentsOption = (fieldName) => {
    switch (schema?.[fieldName]?.instance) {
      case 'Boolean':
        return COMPONENTS_LIST['Boolean'];
      case 'Number':
        return COMPONENTS_LIST['Number'];
      case 'String':
      case 'ObjectID':
        return COMPONENTS_LIST['String'];
      default:
        return [];
    }
  };

  useEffect(() => {
    const selectedComponent = itemPath?.component;
    const componentOptions = getComponentsOption(
      fieldName || dataItem.design_field
    );
    const component = componentOptions.find(
      (option) => option.value === selectedComponent
    );
    setPropsOptions(component ? component.props : []);
  }, [itemPath?.component, dataItem.design_field, fieldName]);

  return (
    <>
      <Grid item xs={12} md={2}>
        <CustomSelectFieldWithLabel
          label="Select Component"
          value={itemPath?.component || ''}
          options={getComponentsOption(
            fieldName ? fieldName : dataItem.design_field
          ).map((option) => ({
            label: option.label,
            value: option.value
          }))}
          onChange={(e) => {
            setFieldValue(`${propPath}.component`, e.target.value);
          }}
          onBlur={handleBlur}
          name={name}
        />
      </Grid>
      {itemPath?.component === 'card' && (
        <CardDesignSetting
          fieldNames={fieldNames}
          dataItem={dataItem}
          schema={schema}
          index={index}
          itemPath={itemPath}
          propsOptions={propsOptions}
          setFieldValue={setFieldValue}
          propPath={propPath}
          handleBlur={handleBlur}
        />
      )}
      {itemPath?.component &&
        itemPath?.component !== 'switch' &&
        itemPath?.component !== 'card' && (
          <AddPropComponent
            dataItem={dataItem}
            index={index}
            itemPath={itemPath}
            propsOptions={propsOptions}
            setFieldValue={setFieldValue}
            propPath={propPath}
            handleBlur={handleBlur}
          />
        )}
    </>
  );
};

export default CustomComponent;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';
const initialState = {
  employees: [],
  loggedEmployee: null,
  paginator: null,
  status: {
    deleteEmployee: false,
    updateEmployee: false,
    getEmployee: true
  }
};

export const getEmployeeAsync = createAsyncThunk(
  'employee/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return { ...data, response: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmployeeAsync = createAsyncThunk(
  'employee/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return data.localUpdate;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteEmployeeAsync = createAsyncThunk(
  'employee/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      if (response?.status === 'SUCCESS') {
        return data.id; // Returning the deleted task ID
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees: (state, { payload }) => {
      state.employees = payload;
    },
    setGetEmployeeStatus: (state, { payload }) => {
      state.getEmployee = payload;
    },
    resetEmployeeState: (state) => {
      const { loggedEmployee } = state;
      return { ...initialState, loggedEmployee };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeAsync.pending, (state) => {
        state.status.getEmployee = true;
      })
      .addCase(getEmployeeAsync.fulfilled, (state, { payload }) => {
        state.status.getEmployee = false;
        if (payload.isSingleEmployee) {
          state.loggedEmployee = payload?.response?.data?.[0] || null;
        } else {
          state.employees = payload?.response?.data || [];
          state.paginator = payload?.response?.paginator || null;
        }
      })
      .addCase(getEmployeeAsync.rejected, (state) => {
        state.employees = [];
        state.status.getEmployee = false;
      })
      .addCase(updateEmployeeAsync.pending, (state) => {
        state.status.updateEmployee = true;
      })
      .addCase(updateEmployeeAsync.fulfilled, (state, action) => {
        state.status.updateEmployee = false;
        const replaceData = action.payload;
        state.employees = state.employees.map((employee) =>
          employee.id === replaceData.id ? replaceData : employee
        );
      })
      .addCase(updateEmployeeAsync.rejected, (state) => {
        state.status.updateEmployee = false;
      })
      .addCase(deleteEmployeeAsync.pending, (state) => {
        state.status.deleteEmployee = true;
      })
      .addCase(deleteEmployeeAsync.fulfilled, (state, action) => {
        state.status.deleteEmployee = false;
        state.employees = state.employees.filter(
          (employee) => employee.id !== action.payload
        );
      })
      .addCase(deleteEmployeeAsync.rejected, (state) => {
        state.status.deleteEmployee = false;
      });
  }
});
export const { setEmployees, setGetEmployeeStatus, resetEmployeeState } =
  slice.actions;
export const selectEmployeeData = (state) => state.employee;
export default slice.reducer;

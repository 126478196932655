import React, { useState, useRef } from 'react';
//import SettingsIconButton from './SettingsButtonIcon';
import CustomThemeSettingsDrawer from 'src/components/CustomThemeSettingDrawer';
import { useSelector } from 'react-redux';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';
import {
  Badge,
  IconButton,
  Tooltip,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const CustomThemeSettings = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { reset } = useSelector(selectCustomSettingData);
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef(null);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
       margin-left: ${theme.spacing(1)};
       background: ${theme.colors.alpha.trueWhite[10]};
       color: ${theme.colors.alpha.trueWhite[70]};
       padding: 0;
       width: 42px;
       height: 42px;
       border-radius: 100%;
       transition: ${theme.transitions.create(['background', 'color'])};
  
       &.active,
       &:active,
       &:hover {
         background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
         color: ${theme.colors.alpha.trueWhite[100]};
       }
   `
  );

  return (
    <>
      {/* <SettingsIconButton onClick={toggleDrawer} reset={reset} /> */}
      {/* <Tooltip arrow title={t('Theme Setting')}>
      <Badge
        variant={reset ? 'dot' : 'standard'}
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiBadge-badge': {
            background: theme.colors.error.main
          }
        }}
      >
        <IconButtonWrapper
          color="success"
          onClick={toggleDrawer}
          sx={{
            background: alpha(theme.colors.success.main, 0.1),
            transition: `${theme.transitions.create(['background'])}`,
            color: theme.colors.success.main,

            '&:hover': {
              background: alpha(theme.colors.success.main, 0.2)
            }
          }}
        >
          <SettingsIcon />
        </IconButtonWrapper>
      </Badge>
    </Tooltip> */}
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={toggleDrawer}>
          <SettingsIcon />
        </IconButtonPrimary>
      </Tooltip>
      <CustomThemeSettingsDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

export default CustomThemeSettings;

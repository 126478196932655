import { Stack } from '@mui/material';
import React from 'react';
import LabelValueDisplay from './LableValueDisplay';
import { useTranslation } from 'react-i18next';

const ViewFooter = ({ subTotal, taxTotal, grandTotal }) => {
  const { t } = useTranslation();
  return (
    <Stack gap={1} alignItems={'flex-end'}>
      {subTotal !== undefined && subTotal !== null && (
        <LabelValueDisplay label={t('Sub Total')} value={subTotal} />
      )}
      {taxTotal !== undefined && taxTotal !== null && (
        <LabelValueDisplay label={t('Tax Total')} value={taxTotal} />
      )}
      {grandTotal !== undefined && grandTotal !== null && (
        <LabelValueDisplay label={t('Grand Total')} value={grandTotal} />
      )}
    </Stack>
  );
};

export default ViewFooter;

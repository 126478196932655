import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Stack,
  CardHeader,
  Divider,
  TextField,
  Autocomplete
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { ACTION_TYPES, CRUD_TYPES } from 'src/constant/constant';
import CustomRadioField from 'src/components/CustomFields/CustomRadioField';
import CustomMultiSelectFieldWithLabel from 'src/components/CustomFields/CustomMultiSelectFieldWithLabel';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import ActionCheck from './ActionCheck';
import ActionPayload from './ActionPayload';
import ConditionComponent from '../ConditionComponent';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const AddActionForm = ({ action, actionIndex, handleDialog, setEdges }) => {
  const { entities } = useSelector(selectAppConfigData);
  const { setFieldValue, values, handleChange } = useFormikContext();
  const getDependsOnArr = () => {
    const triggerOption = { value: 'trigger', label: 'Trigger' };
    let dependsOnArr = values.actions
      .filter(
        (act) =>
          act.action_id !== action.action_id &&
          !act.depends_on.includes(action.action_id)
      )
      .map((act) => ({
        value: act.action_id,
        label: act.name
      }));

    return [triggerOption, ...dependsOnArr];
  };
  const handledependOnChange = (e) => {
    const dependsOn = e.target.value;
    const targetActionId = action.action_id;

    setFieldValue(`actions.${actionIndex}.depends_on`, dependsOn);

    const newEdges = dependsOn.map((sourceActionId) => ({
      id: `e${sourceActionId}-${targetActionId}`,
      source: `${sourceActionId}`,
      target: `${targetActionId}`,
      animated: true
    }));

    if (newEdges.length > 0) {
      setEdges((eds) => {
        let filteredEdges = eds.filter(
          (edge) => edge.target !== `${targetActionId}`
        );

        return [...filteredEdges, ...newEdges];
      });
    }
  };
  const generateConditionLabel = (condition) => {
    const { field_name, field_operator, field_value, start_value, end_value } =
      condition;
    if (!field_name || !field_operator || !field_value) return '';

    switch (field_operator) {
      case 'equal':
        return `${field_name} is equal to ${field_value}`;
      case 'contains':
        return `${field_name} contains ${field_value}`;
      case 'is empty':
        return `${field_name} is empty`;
      case 'is not empty':
        return `${field_name} is not empty`;
      case 'greater than':
        return `${field_name} is greater than ${field_value}`;
      case 'less than':
        return `${field_name} is less than ${field_value}`;
      case 'in between':
        return `${field_name} is between ${start_value} and ${end_value}`;
      case 'is any of':
        return `${field_name} is any of ${field_value}`;
      default:
        return `${field_name} ${field_operator} ${field_value}`;
    }
  };

  const getUpdatedEdges = (conditions, actionId) => {
    return conditions.map((condition) => {
      const sourceActionId = condition.action;
      const targetActionId = actionId;
      const label = generateConditionLabel(condition);

      return {
        id: `e${sourceActionId}-${targetActionId}`,
        source: `${sourceActionId}`,
        target: `${targetActionId}`,
        animated: true,
        label: label,
        labelStyle: {
          fontSize: '14px',
          fontWeight: 'bold',
          backgroundColor: '#ffffff',
          padding: '10px',
          borderRadius: '4px',
          border: '1px solid #000000'
        }
      };
    });
  };

  const handleSave = () => {
    if (action.conditions?.length > 0) {
      const updatedEdges = getUpdatedEdges(action.conditions, action.action_id);

      if (updatedEdges.length > 0) {
        setEdges((eds) => {
          let filteredEdges = eds.filter(
            (edge) => edge.target !== `${action.action_id}`
          );
          return [...filteredEdges, ...updatedEdges];
        });
      }
    }
    handleDialog();
  };

  const handleCRUDTypeChange = (event) => {
    const { value } = event.target;
    setFieldValue(`actions.${actionIndex}`, {
      ...action,
      crud_type: value,
      query: [],
      payload: []
    });
  };

  const handleEntityChange = (event, newValue) => {
    setFieldValue(`actions.${actionIndex}`, {
      ...action,
      entity: newValue,
      crud_type: '',
      query: [],
      payload: [],
      conditions: []
    });
  };
  const handleRunActionChange = (e) => {
    setFieldValue(`actions.${actionIndex}`, {
      ...action,
      depends_on: [],
      run_action: e.target.value,
      conditions: []
    });
  };
  return (
    <>
      <AppBar sx={{ position: 'sticky', py: 1 }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add New Action
          </Typography>
          <Button autoFocus variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container spacing={4} justifyContent={'center'} py={4}>
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Run this action :
            </Typography>
            <CustomRadioField
              name={`actions.${actionIndex}.run_action`}
              value={action.run_action}
              row={true}
              options={[
                { value: 'always', label: 'Always' },
                { value: 'condition', label: 'On Condition' }
              ]}
              onChange={handleRunActionChange}
            />
          </Stack>
        </Grid>
        {action?.run_action === 'condition' && (
          <>
            <Grid item xs={8}>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography
                  variant="subtitle1"
                  sx={{ maxWidth: '50%', width: '200px' }}
                >
                  Depends On :
                </Typography>
                <CustomMultiSelectFieldWithLabel
                  name={`actions.${actionIndex}.depends_on`}
                  label={'Depends On'}
                  value={action.depends_on}
                  options={getDependsOnArr()}
                  onChange={handledependOnChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Card>
                <CardHeader title="Conditions" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <ActionCheck
                      action={action}
                      conditionPath={`actions.${actionIndex}.conditions`}
                      btnName={'Add Checks'}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Action Type :
            </Typography>
            <CustomSelectField
              name={`actions.${actionIndex}.action_type`}
              label={'Action Type'}
              value={action?.action_type || ''}
              options={ACTION_TYPES}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Action Name :
            </Typography>
            <CustomTextField
              name={`actions.${actionIndex}.name`}
              label={'Action Name'}
              fullWidth={true}
              value={action?.name || ''}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Entity :
            </Typography>
            <Autocomplete
              fullWidth
              options={entities}
              getOptionLabel={(option) => option}
              value={action?.entity || null}
              onChange={handleEntityChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={`actions.${actionIndex}.entity`}
                  label="Entity"
                  variant="outlined"
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ maxWidth: '50%', width: '200px' }}
            >
              Crud Type :
            </Typography>
            <CustomRadioField
              name={`actions.${actionIndex}.crud_type`}
              value={action?.crud_type || ''}
              row={true}
              options={CRUD_TYPES}
              onChange={handleCRUDTypeChange}
            />
          </Stack>
        </Grid>
        {action?.crud_type === 'create' && (
          <Grid item xs={8}>
            <Card>
              <CardHeader title="Payloads" />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <ActionPayload
                    actionIndex={actionIndex}
                    payload={action.payload}
                    entity={action.entity}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {action?.crud_type === 'update' && (
          <>
            <Grid item xs={8}>
              <Card>
                <CardHeader title="Payloads" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <ActionPayload
                      actionIndex={actionIndex}
                      payload={action.payload}
                      entity={action.entity}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <Card>
                <CardHeader title="Query" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <ConditionComponent
                      conditionArr={action?.query}
                      conditionPath={`actions.${actionIndex}.query`}
                      entity={action.entity}
                      btnName={'Add Query'}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        {action?.crud_type === 'delete' && (
          <>
            <Grid item xs={8}>
              <Card>
                <CardHeader title="Query" />
                <Divider />
                <CardContent>
                  <Grid container spacing={2}>
                    <ConditionComponent
                      conditionArr={action?.query}
                      conditionPath={`actions.${actionIndex}.query`}
                      entity={action.entity}
                      btnName={'Add Query'}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default AddActionForm;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button, CircularProgress, Stack } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationModal = ({
  title,
  open,
  onClose,
  description,
  confirmBtnConfig = { name: 'Confirm', variant: 'contained', color: 'error' },
  wayOutBtnConfig = { name: 'Cancel', variant: 'outlined', color: 'primary' },
  onConfirm,
  isLoading
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Button
            sx={{ minWidth: 100 }}
            variant={wayOutBtnConfig.variant}
            color={wayOutBtnConfig.color}
            onClick={onClose}
            disabled={isLoading}
          >
            {wayOutBtnConfig.name}
          </Button>
          <Button
            sx={{ ml: 1, minWidth: 100 }}
            variant={confirmBtnConfig.variant}
            disabled={isLoading}
            color={confirmBtnConfig.color}
            onClick={onConfirm}
            startIcon={isLoading ? <CircularProgress size={'1rem'} /> : null}
          >
            {confirmBtnConfig.name}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationModal;

// import {
//   Box,
//   Button,
//   CircularProgress,
//   Modal,
//   Typography
// } from '@mui/material';
// import { useTheme } from '@mui/material';

// const ConfirmationModal = ({
//   title,
//   open,
//   onClose,
//   description,
//   confirmBtnConfig,
//   wayOutBtnConfig,
//   onConfirm,
//   isLoading
// }) => {
//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '90%',
//     maxWidth: 450,
//     bgcolor: 'background.paper',
//     border: 'none',
//     py: 2,
//     px: 2,
//     borderRadius: 2
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={(e) => !isLoading && onClose(e)}
//       aria-labelledby="confirmation-modal-title"
//       aria-describedby="confirmation-modal-description"
//     >
//       <Box sx={modalStyle}>
//         <Typography variant="h4" color="primary" mt={1} fontSize={17}>
//           {title}
//         </Typography>
//         <Typography variant="body1" fontSize="15px" mt={1}>
//           {description}.
//         </Typography>
//         <Box mt={4} textAlign="right">
//           <Button
//             sx={{ minWidth: 100 }}
//             variant={wayOutBtnConfig.variant}
//             color={wayOutBtnConfig.color}
//             onClick={onClose}
//             disabled={isLoading}
//           >
//             {wayOutBtnConfig.name}
//           </Button>
//           <Button
//             sx={{ ml: 1, minWidth: 100 }}
//             variant={confirmBtnConfig.variant}
//             disabled={isLoading}
//             color={confirmBtnConfig.color}
//             onClick={onConfirm}
//             startIcon={isLoading ? <CircularProgress size={'1rem'} /> : null}
//           >
//             {confirmBtnConfig.name}
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default ConfirmationModal;

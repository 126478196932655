import {
  Box,
  IconButton,
  List,
  ListItem,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SortFilter from './SortFilter';
import ColumnFilter from './ColumnFilter';
import SearchFilter from './SearchFilter';
import ExportData from './ExportData';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPopover from '../CustomPopover';

const TableFilters = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [isXs]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={1}
      >
        <SearchFilter />
        {!isXs ? (
          <Box display={'flex'} gap={2}>
            <ColumnFilter />
            <SortFilter />
            <ExportData />
          </Box>
        ) : (
          <IconButton onClick={handlePopoverOpen}>
            <MoreVertIcon />
          </IconButton>
        )}
        <CustomPopover
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
        >
          <List
            sx={{
              p: 1
            }}
            component="nav"
          >
            <ListItem>
              <ColumnFilter />
            </ListItem>
            <ListItem>
              <SortFilter />
            </ListItem>
            <ListItem>
              <ExportData />
            </ListItem>
          </List>
        </CustomPopover>
      </Stack>
    </>
  );
};

export default TableFilters;

import { useState } from 'react';
import { toast } from 'react-toastify';
import { requestApi } from 'src/mocks/request';

const useFileUpload = () => {
  const [loading, setLoading] = useState(false);

  const uploadFile = async ({ file, updateState, image, entity }) => {
    setLoading(true);
    try {
      // First delete the existing image (if any)
      if (image) {
        await deleteFile(image);
      }

      const formData = new FormData();
      formData.append('files', file, entity ? `${entity}.pdf` : file.name);
      let response = await requestApi.uploadFile(formData);

      if (
        response?.status === 'SUCCESS' &&
        response?.data?.uploadSuccess?.length > 0
      ) {
        updateState(response.data.uploadSuccess[0]);
      }
    } catch (error) {
      console.log('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadFiles = async (filesToUpload, updateState) => {
    if (filesToUpload.length === 0) {
      toast.info('All images have been uploaded');
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append('files', file, file.name);
      });

      let response = await requestApi.uploadFile(formData);

      if (
        response?.status === 'SUCCESS' &&
        response?.data?.uploadSuccess?.length > 0
      ) {
        console.log(response?.data, 'response?.data');
        const filterData = response.data.uploadSuccess.map((data) => ({
          name: data.name,
          path: encodeURI(data.path)
        }));
        updateState(filterData);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = async (url) => {
    setLoading(true);
    try {
      let response = await requestApi.deleteFile({ url });
      console.log(response, 'deleteFile');
      return response;
    } catch (error) {
      console.log('Error deleting file:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { uploadFiles, uploadFile, loading, deleteFile };
};

export default useFileUpload;

import { useEffect, useState } from 'react';
import { Box, Drawer, IconButton, CircularProgress } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useLocation } from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';
import ApprovalActionContent from './ApprovalActionContent';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { toast } from 'react-toastify';
import BackDropLoader from 'src/components/BackDropLoader';

const ApprovalActionBottomSheet = ({ data }) => {
  const [isDrawerMinimized, setIsDrawerMinimized] = useState(true);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id: userId } = useSelector(selectLoggedUserData);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerMinimized(!isDrawerMinimized);
  };

  const searchParams = new URLSearchParams(location.search);
  const approvReqId = searchParams.get('approveReqId') || null;
  useEffect(() => {
    const fetchData = async () => {
      if (approvReqId) {
        setIsLoading(true);
        try {
          const payload = {
            endPoint: 'approval_request',
            query: {
              _id: approvReqId,
              approval: data.approval_id.id,
              approvers: { $in: [userId] }
            }
          };
          const response = await requestApi.getData(payload);
          if (response?.data?.data?.length) {
            const approvalReq = response?.data?.data[0];
            if (approvalReq?.isActive) {
              setDrawerOpen(true);
            } else {
              toast.info('This document has already been approved.');
            }
          }
          console.log('Approval request data:', response.data);
        } catch (error) {
          console.error('Error fetching approval request:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [approvReqId, userId]);

  return (
    <>
      <BackDropLoader isShow={isLoading}>
        <CircularProgress color="inherit" />
      </BackDropLoader>

      <Drawer
        anchor="bottom"
        variant="persistent"
        open={isDrawerOpen}
        PaperProps={{
          style: {
            overflow: 'visible',
            transition: 'none'
          }
        }}
      >
        <Box
          sx={{
            height: isDrawerMinimized ? '37vh' : '2vh',
            width: {
              xs: '100%',
              lg: 'calc(100% - 297px)'
            },
            transition: 'height 0.3s ease',
            position: 'relative',
            ml: 'auto'
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'absolute',
              top: -16,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10
            }}
          >
            <IconButton color="primary" size="small" onClick={toggleDrawer}>
              <ExpandCircleDownIcon
                fontSize="small"
                style={{
                  transform: isDrawerMinimized
                    ? 'rotate(0deg)'
                    : 'rotate(180deg)',
                  transition: 'transform 0.3s ease'
                }}
              />
            </IconButton>
          </Box>
          <Scrollbar>
            {isDrawerOpen && (
              <ApprovalActionContent data={data} approvReqId={approvReqId} />
            )}
          </Scrollbar>
        </Box>
      </Drawer>
    </>
  );
};

export default ApprovalActionBottomSheet;

/* eslint-disable react/prop-types */
import { FiberManualRecord } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import { updateDataAsync } from 'src/slices/dynamicSlice';
import { ListItemWrapper } from '../Styled';

const formatTimeAgo = (date) => {
  return moment(date).fromNow();
};

const NotificationComponent = ({ notificationItem, onClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { menuItems } = useSelector(selectAppConfigData);

  const handleNavigateView = (entity, id) => {
    const viewLink = () => {
      console.log(menuItems, 'menuItems');

      if (!menuItems.length) {
        return ''; // Return an empty string if menuItems is empty
      }

      // Find the menu item with the matching entity
      const foundMenuItem = menuItems
        .flatMap((menuItem) => menuItem.items)
        .find((item) => item.entity === entity);

      // Return the constructed link if found, otherwise return an empty string
      return foundMenuItem ? `/${foundMenuItem.link}/view/${id}` : '';
    };
    navigate(viewLink());
  };

  const handleActionClick = (action) => () => {
    const { entity, id, payload } = action;
    switch (action?.crud) {
      case 'UPDATE':
        dispatch(
          updateDataAsync({
            endPoint: entity,
            data: payload,
            id: id
          })
        );
        break;
      default:
        handleNavigateView(entity, id);
        break;
    }
  };

  return (
    <>
      <ListItemWrapper
        sx={{ alignItems: 'flex-start', p: 1, gap: 2, borderRadius: 1 }}
        key={notificationItem?.id}
        onClick={onClick}
      >
        <Avatar
          src="/avatar2.jpg"
          sx={{ marginTop: '2px', width: 36, height: 36 }}
        />
        <Box width={'100%'}>
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Box>
              <Typography variant="h3" fontSize={16}>
                {t(notificationItem?.title)}
              </Typography>
              <Typography variant="subtitle2">
                {t(notificationItem?.from ? 'from' : '')}{' '}
                {notificationItem?.from && (
                  <Typography component="span" fontWeight={'bold'}>
                    {t(notificationItem?.from)}
                  </Typography>
                )}
              </Typography>
            </Box>
            <Box marginTop={0.5}>
              {!notificationItem?.is_read ? (
                <FiberManualRecord sx={{ color: 'blue', fontSize: 'small' }} />
              ) : (
                ''
              )}
            </Box>
          </Box>
          <Typography
            variant="caption"
            sx={{
              textTransform: 'lowercase'
            }}
            gutterBottom
          >
            {t(formatTimeAgo(notificationItem?.createdAt))}
            <FiberManualRecord
              sx={{ color: 'text.caption', fontSize: '6px', mx: 0.5 }}
            />
            {t(notificationItem?.type)}
          </Typography>
          <Typography component="div" variant="body2" color="text.secondary">
            {t(notificationItem?.message)}
          </Typography>
          <Box sx={{ mt: 1 }}>
            {notificationItem?.actions?.length > 0 && (
              <Stack gap={0.5} direction="row">
                {notificationItem?.actions?.map((action, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{ borderRadius: 1, p: 0.5 }}
                    onClick={handleActionClick(action)}
                  >
                    {t(action?.name)}
                  </Button>
                ))}
              </Stack>
            )}
          </Box>
        </Box>
      </ListItemWrapper>
      <Divider variant="inset" sx={{ my: 1 }} component="li" />
    </>
  );
};

export default NotificationComponent;

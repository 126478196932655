import React from 'react';
import { Grid, Card, CardHeader, Divider, CardContent } from '@mui/material';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import { useMemo } from 'react';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

const IndirectChargeCard = () => {
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();
  const { itemSchema, itemTableHeads } = useMemo(() => {
    let itemSchema = schema?.['indirect_charges'].schema;

    const itemTableHeads = Object.keys(itemSchema).filter((key) => {
      let options = getFieldOptions(key, itemSchema);
      return key !== '_id' && key !== 'id' && !options?.hidden;
    });

    return {
      itemSchema,
      itemTableHeads
    };
  }, [schema]);

  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Indirect Charges" />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            {itemTableHeads.map((itemTableHead) => (
              <Grid item xs={12} md={4} key={itemTableHead}>
                <GetFieldBasedOnSchema
                  name={`indirect_charges.${itemTableHead}`}
                  fieldName={itemTableHead}
                  entitySetting={entitySetting}
                  schema={itemSchema}
                  value={values?.indirect_charges?.[itemTableHead]}
                  error={errors?.indirect_charges?.[itemTableHead]}
                  touched={touched?.indirect_charges?.[itemTableHead]}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default IndirectChargeCard;

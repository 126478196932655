import {
  Checkbox,
  TableCell,
  TableRow,
  TableHead,
  useTheme
} from '@mui/material';
import { useSelector } from 'react-redux';
import TableHeadcell from './TableHeadCell';
import {
  selectColumnFilterFieldname,
  selectDynamicPageConfig
} from 'src/slices/dynamicSlice';

const TableHeaders = ({
  fieldNames,
  handleSort,
  sort,
  handleSelectAllRows,
  isAllRowsSelected,
  selectedSomeRows
}) => {
  const theme = useTheme();
  const columnFilterFieldname = useSelector(selectColumnFilterFieldname);
  const { entitySetting } = useSelector(selectDynamicPageConfig);

  if (entitySetting?.show_fields && entitySetting.show_fields.length > 0) {
    fieldNames = entitySetting.show_fields;
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            size="small"
            checked={isAllRowsSelected}
            indeterminate={selectedSomeRows}
            onChange={handleSelectAllRows}
          />
        </TableCell>
        {fieldNames
          .filter((fieldName) => columnFilterFieldname?.includes(fieldName))
          .map((availableField, index) => (
            <TableHeadcell
              key={`fieldName_${index}`}
              availableField={availableField}
              handleSort={handleSort}
              sort={sort}
            />
          ))}
        <TableCell
          sx={{
            width: 3,
            position: 'sticky',
            right: 0,
            zIndex: 10,
            bgcolor: theme.palette.background.paper
          }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;

import React from 'react';
import {
  CardHeader,
  CardContent,
  Card,
  Divider,
  Typography,
  Stack
} from '@mui/material';
import { useFormikContext } from 'formik';
import MultipleFileUpload from 'src/components/CustomFields/MultipleFileUpload';

const ReceiptCard = () => {
  const { values } = useFormikContext();
  const files = values['files'] || [];

  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Receipts" />
        <Divider />
        <CardContent>
          <Stack direction={'row'} alignItems={'center'}>
            <MultipleFileUpload
              fieldName={'files'}
              name={'files'}
              value={files}
              isFile={true}
            />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default ReceiptCard;

import { Autocomplete, Drawer, Skeleton } from '@mui/material';
import AddOptions from './AddOptions';
import CommonModal from 'src/components/CommonModal.js';
import useAutocompleteLogic from './useAutoCompleteLogic';

const DTAutocomplete = ({
  fieldName,
  itemTableHead,
  label,
  name,
  isMultiple,
  value,
  schema,
  onChange,
  placeholder = '',
  entitySetting,
  size = 'medium',
  onBlur = () => {},
  error,
  touched,
  required = false,
  readOnly = false
}) => {
  const {
    options,
    query,
    drawerOpen,
    openModal,
    fieldShow,
    refEntity,
    selectedInputValue,
    handleDrawer,
    handleModal,
    setOptions,
    handleQueryChange,
    handOnOpen,
    handleFilterOption,
    handlChange,
    handleRenderOption,
    handleRenderInput
  } = useAutocompleteLogic({
    fieldName,
    itemTableHead,
    schema,
    isMultiple,
    value,
    entitySetting,
    onChange,
    onBlur,
    error,
    touched,
    required,
    label,
    name,
    readOnly,
    placeholder
  });
  if (!fieldShow)
    return (
      <Skeleton
        variant="rounded"
        width="100%"
        height={itemTableHead ? 40 : 56}
      />
    );
  return (
    <>
      <Autocomplete
        fullWidth
        selectOnFocus
        size={size}
        // clearOnBlur
        freeSolo
        filterOptions={handleFilterOption}
        multiple={isMultiple}
        disableCloseOnSelect={isMultiple}
        options={options}
        filterSelectedOptions={isMultiple ? true : false}
        value={value || (isMultiple ? [] : null)}
        inputValue={query}
        onOpen={handOnOpen}
        onChange={handlChange}
        onInputChange={handleQueryChange}
        getLimitTagsText={(more) => `+${more}`}
        limitTags={2}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => {
          if (option?.inputValue) {
            return option.inputValue;
          }
          return option?.[fieldShow] || option.id || '';
        }}
        getOptionKey={(option) => option?.id}
        renderOption={handleRenderOption}
        renderInput={handleRenderInput}
      />

      <Drawer anchor="right" open={drawerOpen} onClose={() => handleDrawer()}>
        <AddOptions
          entity={refEntity}
          handleDrawer={() => handleDrawer()}
          inputValue={selectedInputValue.current}
          isModal={openModal}
          fieldShow={fieldShow}
          handleValue={(createdOption) =>
            onChange(isMultiple ? [...value, createdOption] : createdOption)
          }
          setOptions={setOptions}
        />
      </Drawer>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={`Add ${refEntity}`}
        isForm={true}
      >
        <AddOptions
          entity={refEntity}
          handleDrawer={() => handleDrawer()}
          handleModal={handleModal}
          inputValue={selectedInputValue.current}
          isModal={openModal}
          fieldShow={fieldShow}
          handleValue={(createdOption) =>
            onChange(isMultiple ? [...value, createdOption] : createdOption)
          }
          setOptions={setOptions}
        />
      </CommonModal>
    </>
  );
};

export default DTAutocomplete;

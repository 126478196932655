import React, { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { ChromePicker } from 'react-color';

const ColorPickerComponent = ({ color, onChange, onBlur, title }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorBoxClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleChangeComplete = (color) => {
    onChange(color);
    console.log(color);
  };

  return (
    <>
      <Tooltip title={title} placement="top">
        <Box
          sx={{
            width: 36,
            height: 36,
            backgroundColor: color
              ? `rgba(${color.r},${color.g},${color.b},${color.a})`
              : '#fff',
            cursor: 'pointer',
            border: '1px solid #ccc',
            borderRadius: 0.5
          }}
          onClick={handleColorBoxClick}
        />
      </Tooltip>
      {displayColorPicker && (
        <Box sx={{ position: 'absolute', zIndex: 2 }}>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
            onClick={() => setDisplayColorPicker(false)}
          />
          <ChromePicker
            color={color || { r: 255, g: 255, b: 255, a: 1 }}
            onChange={handleChangeComplete}
            onBlur={onBlur}
          />
        </Box>
      )}
    </>
  );
};

export default ColorPickerComponent;

// import React, { useEffect, useState } from 'react';
// import { Box, Tooltip } from '@mui/material';
// import { ChromePicker } from 'react-color';

// const ColorPickerComponent = ({ color, onChange, onBlur, title }) => {
//   const [displayColorPicker, setDisplayColorPicker] = useState(false);
//   const [debouncedColor, setDebouncedColor] = useState(color);

//   const handleColorBoxClick = () => {
//     setDisplayColorPicker(!displayColorPicker);
//   };

//   // const handleChangeComplete = (color) => {
//   //   onChange(color);
//   //   console.log(color);
//   // };
//   const handleChangeComplete = (color) => {
//     setDebouncedColor(color);
//   };

//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       onChange(debouncedColor);
//       console.log(debouncedColor);
//     }, 300);

//     return () => clearTimeout(timeoutId);
//   }, [debouncedColor, onChange]);

//   return (
//     <>
//       <Tooltip title={title} placement="top">
//         <Box
//           sx={{
//             width: 36,
//             height: 36,
//             backgroundColor: color
//               ? `rgba(${color.r},${color.g},${color.b},${color.a})`
//               : '#fff',
//             cursor: 'pointer',
//             border: '1px solid #ccc',
//             borderRadius: 0.5
//           }}
//           onClick={handleColorBoxClick}
//         />
//       </Tooltip>
//       {displayColorPicker && (
//         <Box sx={{ position: 'absolute', zIndex: 2 }}>
//           <Box
//             sx={{
//               position: 'fixed',
//               top: 0,
//               right: 0,
//               bottom: 0,
//               left: 0
//             }}
//             onClick={() => setDisplayColorPicker(false)}
//           />
//           <ChromePicker
//             color={color || { r: 255, g: 255, b: 255, a: 1 }}
//             onChange={handleChangeComplete}
//             onBlur={onBlur}
//           />
//         </Box>
//       )}
//     </>
//   );
// };

// export default ColorPickerComponent;

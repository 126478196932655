import React from 'react';
import { Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const TypographyComponent = ({ sx = {}, props, value, id = null }) => {
  const location = useLocation();
  if (id) {
    return (
      <Link
        style={{ textDecoration: 'none' }}
        to={`/${location.pathname.split('/')[1]}/view/${id}`}
      >
        {value}
      </Link>
    );
  }
  return (
    <>
      <Typography sx={sx} {...props}>
        {value}
      </Typography>
    </>
  );
};

export default TypographyComponent;

import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TableCell
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  deleteDataAsync,
  selectApiSuccess,
  selectApprovalSetting,
  selectDynamicPageConfig,
  selectStatus,
  setData
} from 'src/slices/dynamicSlice';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useSelector } from 'react-redux';
import RowAction from './RowAction';
import PinAction from './PinAction';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';
import DuplicateDocument from './DuplicateDocument';
import CustomMenu from 'src/components/CustomMenu';
import { useEffect } from 'react';

const ActionTableCell = ({ row }) => {
  const { endPoint } = useParams();
  const theme = useTheme();
  const isApiSuccess = useSelector(selectApiSuccess);
  const status = useSelector(selectStatus);
  const approvalSetting = useSelector(selectApprovalSetting);
  const { entity, entitySetting } = useSelector(selectDynamicPageConfig);
  const [isSendApproval, setSendApproval] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const isMounted = useRefMounted();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuOpen(null);
  };

  const handleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
    handleCloseMenu();
  };

  const handleDelete = async () => {
    await dispatch(deleteDataAsync({ endPoint: entity, id: row.id }));
    // setConfirmationModal(false);
  };

  const handleSendApproval = async () => {
    setSendApproval(true);
    try {
      const payload = {
        endPoint: 'approval',
        data: {
          entity,
          document_id: row.id
        }
      };
      const response = await requestApi.createData(payload);
      if (response?.data)
        dispatch(
          setData({
            ...row,
            approval_id: response.data,
            approval_status: 'PENDING'
          })
        );
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setSendApproval(false);
    }
  };

  // row actions
  const rowActions =
    entitySetting?.actions?.filter(
      (action) => action.actionLocation === 'more'
    ) || [];

  // pinned actions
  const pinActions =
    entitySetting?.actions?.filter(
      (action) => action.actionLocation === 'pin'
    ) || [];

  useEffect(() => {
    if (isApiSuccess && isMounted.current) {
      handleCloseMenu();
      setConfirmationModal(false);
    }
  }, [isApiSuccess, handleCloseMenu]);

  return (
    <>
      <TableCell
        sx={{
          position: 'sticky',
          right: 0,
          zIndex: 10,
          bgcolor:
            theme.palette.mode === 'light'
              ? 'white'
              : theme.palette.background.default
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          {endPoint === 'approved-prs' && (
            <Button
              variant="outlined"
              size="small"
              component={RouterLink}
              to={`/${location.pathname.split('/')[1]}/processRequest/${
                row.id
              }`}
              sx={{
                whiteSpace: 'nowrap'
              }}
            >
              Proccess Request
            </Button>
          )}
          {pinActions.map((action, actIndex) => (
            <PinAction
              key={`action_${actIndex}`}
              action={action}
              rowId={row.id}
            />
          ))}
          <IconButton
            size="small"
            color="primary"
            id="action-option-manu"
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Stack>
        <CustomMenu anchorEl={menuOpen} handleClose={handleCloseMenu}>
          <MenuItem
            component={RouterLink}
            to={`/${location.pathname.split('/')[1]}/edit/${row.id}`}
            disableRipple
          >
            <EditIcon />
            Edit
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={`/${location.pathname.split('/')[1]}/view/${row.id}`}
            disableRipple
          >
            <VisibilityIcon />
            View
          </MenuItem>
          <DuplicateDocument rowData={row} handleCloseMenu={handleCloseMenu} />
          <MenuItem onClick={handleConfirmationModal} disableRipple>
            <DeleteTwoToneIcon />
            Delete
          </MenuItem>
          {approvalSetting && !row.approval_id && (
            <MenuItem onClick={handleSendApproval} disableRipple>
              {isSendApproval ? (
                <CircularProgress size={'1rem'} sx={{ mr: 1.5 }} />
              ) : (
                <SendIcon fontSize="small" />
              )}
              Send For Approval
            </MenuItem>
          )}
          {rowActions.map((action, actIndex) => (
            <RowAction
              key={`action_${actIndex}`}
              action={action}
              rowId={row.id}
            />
          ))}
        </CustomMenu>
      </TableCell>
      <ConfirmationModal
        title={`Permanently Delete ${row.name}?`}
        description={
          'This will permanently remove the item. Are you sure you want to continue?'
        }
        confirmBtnConfig={{
          name: 'Delete',
          variant: 'contained',
          color: 'error'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'primary'
        }}
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        isLoading={status.deleteData}
        onConfirm={handleDelete}
      />
    </>
  );
};
export default ActionTableCell;

import React, { useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomPopover from '../CustomPopover';

const InfoAdornment = ({ info }) => {
  const [openPopover, setOpenPopover] = useState(null);

  const handlePopover = (event) => {
    setOpenPopover((prev) => (!prev ? event.currentTarget : null));
  };

  return (
    <>
      <IconButton size="small" onClick={handlePopover} color="primary">
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
      <CustomPopover
        anchorEl={openPopover}
        open={Boolean(openPopover)}
        onClose={handlePopover}
      >
        <Box p={2} maxWidth={300}>
          <Typography variant="body2">{info}</Typography>
        </Box>
      </CustomPopover>
    </>
  );
};

export default InfoAdornment;

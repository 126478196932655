import axios from 'axios';

import { emailAxios } from 'src/services/axios';
import errorHandler from './errorHandler';
import successHandler from './successHandler';

class EmailRequestApi {
  emailLogin() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `http://localhost:5292/api/session/oauth/google/login`
        );
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  sendParamsCode(code) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await emailAxios.get(
          `http://localhost:5292/api/session/oauth/google?code=${code}`
        );
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  UserEmail(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await emailAxios.post(`/session/email-thread`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  UserEmailData(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await emailAxios.post(`/session/email_thread/${id}`);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }
  sendEmailData(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await emailAxios.post(`/session/email_thread/`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  replyToEmailThread(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await emailAxios.post(
          `/session/send_email/${id}`,
          data
        );
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }
  // http://localhost:5292/api
}

export const emailRequestApi = new EmailRequestApi();

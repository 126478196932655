import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

let initialState = {
  roles: [],
  status: { getRole: false }
};
export const getRolesAsync = createAsyncThunk(
  'roles/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data?.data;
      } else {
        rejectWithValue(response?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRolesAsync.pending, (state) => {
        state.status.getRole = true;
      })
      .addCase(getRolesAsync.fulfilled, (state, action) => {
        state.status.getRole = false;
        state.roles = action?.payload || [];
      })
      .addCase(getRolesAsync.rejected, (state, action) => {
        state.status.getRole = false;
        state.message.error = action.payload;
      });
  }
});
// selectors
// export const selectEndPoints = (state) => state.roleAccessState.endPoints;
export const selectRolesData = (state) => state.roles;

export default slice.reducer;

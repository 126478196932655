import { Button, Card, CircularProgress, Grid, Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { initDynFormValues } from 'src/utils/initDynFormValues';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

const RFQForm = ({
  schema,
  formData,
  entity,
  handleModal,
  items,
  entitySetting
}) => {
  const isMounted = useRefMounted();
  const { schema: prSchema } = useSelector(selectDynamicPageConfig);
  const { currentPurchaseReq, updateCurrentPrItems } =
    useContext(ProcessReqContext);

  const fieldNames = useMemo(() => {
    if (!schema) return [];

    const allFieldNames = Object.keys(schema);
    const filteredFieldNames = allFieldNames.filter(
      (fieldName) => !REMOVE_FIELDS.includes(fieldName)
    );

    return filteredFieldNames;
  }, [schema]);

  const { initialValues, validationSchema } = useMemo(
    () =>
      initDynFormValues({
        schema,
        fieldNames,
        formData
      }),
    [schema, fieldNames]
  );

  const handleRFQCreate = async (values, { setSubmitting }) => {
    try {
      const { createData } = sanitizeFormData(values, schema, fieldNames);
      const payload = { endPoint: entity, data: createData };
      const rfqCreateRes = await requestApi.createData(payload);
      if (rfqCreateRes?.status === 'SUCCESS') {
        const createdRFQ = rfqCreateRes.data;
        // Update the Purchase Request (PR) with the new PO ID
        const updatedItems = currentPurchaseReq.items.map((item) => {
          // Check if this item is in the items array we received
          const matchedItem = items.find((i) => i.id === item.id);

          if (matchedItem) {
            const existingRFQArray = item.RFQ || [];

            return { ...item, RFQ: [...existingRFQArray, createdRFQ] };
          }
          return item;
        });

        const { createData } = sanitizeFormData(
          { items: updatedItems },
          prSchema,
          fieldNames
        );

        const prUpdateRes = await requestApi.updateData({
          endPoint: 'purchase_request',
          id: currentPurchaseReq.id,
          data: createData
        });

        if (prUpdateRes.status === 'SUCCESS') {
          // Use the context function to update the currentPrItemsPo
          updateCurrentPrItems(updatedItems);
          handleModal();
        }
      }
    } catch (error) {
      console.error('Error during PO creation:', error);
    } finally {
      if (isMounted.current) setSubmitting(false);
    }
  };
  return (
    <>
      <Card sx={{ p: 2, m: 'auto' }}>
        {!initialValues || !validationSchema ? (
          <CircularProgress />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRFQCreate}
          >
            {({ errors, handleSubmit, touched, values, isSubmitting }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    {fieldNames.map((fieldName, index) => (
                      <Grid
                        item
                        xs={12}
                        md={
                          schema[fieldName]?.instance === 'Array' &&
                          schema[fieldName]?.$embeddedSchemaType?.schema &&
                          Object.keys(
                            schema[fieldName]?.$embeddedSchemaType?.schema
                              ?.paths
                          )?.length > 0
                            ? 12
                            : schema[fieldName]?.instance === 'String' &&
                              schema[fieldName]?.options?.max === null
                            ? 12
                            : schema[fieldName]?.instance === 'Object'
                            ? 12
                            : 6
                        }
                        key={`${index}_${fieldName}_checkfield`}
                      >
                        <GetFieldBasedOnSchema
                          name={fieldName}
                          entitySetting={entitySetting}
                          fieldName={fieldName}
                          schema={schema}
                          value={values[fieldName]}
                          error={errors[fieldName]}
                          touched={touched[fieldName]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'flex-start'}
                      spacing={1}
                      mt={1}
                    >
                      <Button
                        type="submit"
                        startIcon={
                          isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </form>
              </>
            )}
          </Formik>
        )}
      </Card>
    </>
  );
};

export default RFQForm;

import { Skeleton, Stack } from '@mui/material';

const ContentLoader = () => {
  return (
    <>
      <Stack
        px={2}
        py={1}
        gap={2}
        direction={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Stack spacing={1} alignItems={'start'}>
          <Skeleton variant="text" width={140} height={30} />
          <Skeleton variant="rounded" width={300} height={200} />
        </Stack>

        <Stack spacing={1}>
          <Skeleton variant="text" width={140} height={30} />
          <Skeleton variant="rounded" width={200} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
      </Stack>
    </>
  );
};

export default ContentLoader;

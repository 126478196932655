import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useEffect, useState } from 'react';
import { requestApi } from 'src/mocks/request';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const ViewTemplateSettings = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors
}) => {
  console.log(values, 'ViewTemplateSettings');

  const [templates, setTemplates] = useState([]);

  useEffect(async () => {
    let payLoad = {
      endPoint: 'view_template',
      query: { entity: values.entity },
      pagination: true
    };
    const res = await requestApi.getData(payLoad);
    setTemplates(res?.data?.data);
  }, []);

  console.log(templates, 'templates');
  console.log(touched, 'touched');
  console.log(errors, 'errors');

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={Boolean(touched.data && errors.data)}>
              <InputLabel id={`data_default_view_template`}>
                Default View Template
              </InputLabel>
              <Select
                labelId={`data_default_view_template`}
                label="Default View Template"
                name={'default'}
                value={values?.data[0]?.default}
                onChange={(e) => {
                  setFieldValue('data[0].default', e.target.value);
                }}
                onBlur={handleBlur}
                MenuProps={MenuProps}
              >
                {templates?.map((template) => (
                  <MenuItem
                    key={`template_${template?.id}`}
                    value={template?.id}
                  >
                    {template?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewTemplateSettings;

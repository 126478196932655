import { Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import NavigationMenuItem from './item';
import menuItems from './items';

import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const MenuWrapper = styled(Box)(
  () => `
  .MuiList-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > .MuiList-root {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    width: 100%;
    .MuiList-root {
      padding: 0;
      display: flex;
      flex-direction: row;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 0 2px;
        justify-content: center;
        width: auto;

        &:last-child {
          margin-left: auto;
        }

        .MuiIconButton-root {
          display: flex;
          background-color: transparent;
          border-radius: ${theme.general.borderRadiusLg};
          justify-content: center;
          font-size: ${theme.typography.pxToRem(14)};
          padding: ${theme.spacing(1.4, 2)};
          position: relative;
          font-weight: bold;
          color: ${theme.colors.alpha.trueWhite[100]};

          .name-wrapper {
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 16px;
            top: 12px;

            .MuiBadge-badge {
              background: ${theme.colors.alpha.white[70]};
              color: ${theme.colors.alpha.black[100]};
              font-size: ${theme.typography.pxToRem(11)};
              font-weight: bold;
              text-transform: uppercase;
            }
          }

          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(24)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.colors.alpha.trueWhite[50]};
          }

          &.active,
          &:hover {
            background-color: ${theme.colors.alpha.white[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
      }
    }
`
);

function NavigationMenu() {
  const location = useLocation();

  const { companyEntitySettings } = useSelector(selectAppConfigData);
  const user = useSelector(selectLoggedUserData);

  function checkEntity(entity) {
    if (!entity) {
      return { isEntity: true, name: null };
    }

    const filterData = companyEntitySettings.find(
      (item) => item.entity_name === entity
    );

    if (filterData) {
      let isAllowed = filterData.allowed_user_types.includes(user?.userType);
      if (isAllowed) {
        const newName =
          filterData?.new_name !== '' ? filterData?.new_name : entity;
        return { isEntity: true, name: newName };
      } else {
        return false;
      }
    }
    return { isEntity: true, name: null };
  }

  const renderNavigationMenuItems = ({ items, path }) => (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
  );

  const reduceChildRoutes = ({ ev, path, item }) => {
    const key = uuidv4();

    const exactMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: true
          },
          path
        )
      : false;

    if (item.items) {
      const partialMatch = item.link
        ? !!matchPath(
            {
              path: item.link,
              end: false
            },
            path
          )
        : false;

      ev.push(
        <NavigationMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
        >
          {renderNavigationMenuItems({
            path,
            items: item.items
          })}
        </NavigationMenuItem>
      );
    } else {
      ev.push(
        <NavigationMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
        />
      );
    }

    return ev;
  };

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper key={uuidv4()}>
          <List component="div">
            {renderNavigationMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      ))}
    </>
  );
}

export default NavigationMenu;

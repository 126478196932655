import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import StageButtons from './StageButtons';
import { useEffect, useState } from 'react';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';
import ApprovalStatusRow from './ApprovalStatusRow';
import ApprovalStatusLoader from './ApprovalStatusLoader';
import RejectedCard from './RejectedCard';

// Component to render approval status details
const ApprovalStatusDetails = ({ approval }) => {
  let { total_stages, current_stage, status } = approval;
  const isMounted = useRefMounted();
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [rejectedStage, setRejectedStage] = useState(null);
  const [isFetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchAllReq = async () => {
      try {
        const payload = {
          endPoint: 'approval_request',
          populate: 'approvers approval_by',
          query: { approval: approval.id }
        };
        const response = await requestApi.getData(payload);
        if (response?.data?.data?.length) {
          const approvalReq = response.data.data;
          const accumulatedData = [];

          const rejectedStages = approvalReq.find(
            (req) => req.decision === 'REJECTED'
          );
          setRejectedStage(rejectedStages);
          if (!rejectedStages) {
            for (let i = 0; i < total_stages; i++) {
              const stageData = approvalReq.filter(
                (req) => req.stage === i + 1
              );

              accumulatedData.push({
                stage: i + 1,
                waitingFor: stageData
                  .filter((req) => !req.approval_by)
                  .map((req) => req.approvers),
                approvedBy: stageData
                  .map((req) => req.approval_by)
                  .filter(Boolean)
                  .flat()
              });
            }

            if (status === 'APPROVED') {
              setApprovalRequests(accumulatedData);
            } else {
              const sortedData = accumulatedData.sort((a, b) => {
                if (a.stage === current_stage) return -1; // Current stage first
                if (b.stage === current_stage) return 1;
                if (a.approvedBy.length > 0 && b.approvedBy.length === 0)
                  return 1; // Locked stages
                if (b.approvedBy.length > 0 && a.approvedBy.length === 0)
                  return -1;
                return a.stage - b.stage; // Sort by stage
              });

              setApprovalRequests(sortedData);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (isMounted.current) setFetching(false);
      }
    };
    fetchAllReq();
  }, []);
  if (isFetching) return <ApprovalStatusLoader />;
  return (
    <Stack spacing={2}>
      <StageButtons
        totalStages={total_stages}
        currentStage={current_stage}
        // rejectedStage={rejectedStage?.stage || null}
        status={status}
      />

      {status === 'REJECTED' ? (
        <RejectedCard rejectedStage={rejectedStage} />
      ) : (
        <TableContainer component={Paper} variant="outlined" elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Stages</TableCell>
                <TableCell>Waiting For</TableCell>
                <TableCell>Approved By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalRequests.map((request, index) => (
                <ApprovalStatusRow
                  key={index}
                  stage={request.stage}
                  waitingFor={request.waitingFor}
                  approvedBy={request.approvedBy}
                  isCurrentStage={request.stage === current_stage}
                  isCompleted={request.stage < current_stage}
                  status={status}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default ApprovalStatusDetails;

import { Button, CircularProgress, Stack } from '@mui/material';
import { Formik } from 'formik';

import OrderInformation from './OrderInformation';
import ItemDetails from './ItemDetails';
import { selectStatus } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useDynamicFormLogic from 'src/hooks/useDynamicFormLogic';
const GrnFormBody = ({ currentItem = null, activeTab, handleActiveTab }) => {
  const status = useSelector(selectStatus);
  const { initialValues, validationSchema, handleFormSubmit } =
    useDynamicFormLogic({
      currentItem,
      isCustomForm: true
    });
  if (!initialValues || !validationSchema) return <CircularProgress />;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, values }) => {
        if (activeTab === 'product' && !values?.purchase_order) {
          toast.warning(
            'Please select a purchase order before switching to products tab.'
          );
          handleActiveTab('order');
        }
        return (
          <>
            <form onSubmit={handleSubmit}>
              {activeTab === 'order' ? <OrderInformation /> : <ItemDetails />}
              <Stack alignItems={'center'} mt={2}>
                {activeTab === 'product' && (
                  <Button
                    type="submit"
                    startIcon={
                      currentItem ? (
                        status.updateData ? (
                          <CircularProgress size="1rem" />
                        ) : null
                      ) : status.createData ? (
                        <CircularProgress size="1rem" />
                      ) : null
                    }
                    disabled={
                      currentItem ? status.updateData : status.createData
                    }
                    variant="contained"
                  >
                    {currentItem ? 'Upadte GRN' : 'Create Grn'}
                  </Button>
                )}
              </Stack>
            </form>
            <Stack alignItems={'center'}>
              {activeTab === 'order' && (
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => handleActiveTab('product')}
                >
                  Go To Products
                </Button>
              )}
            </Stack>
          </>
        );
      }}
    </Formik>
  );
};

export default GrnFormBody;

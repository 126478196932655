import { Suspense, lazy } from 'react';
import Authenticated from 'src/components/Authenticated';
import SuspenseLoader from 'src/components/SuspenseLoader';
import LandingPage from 'src/content/pages/landing-page/LandingPage';

// eslint-disable-next-line react/display-name
const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);
const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status403 = Loader(lazy(() => import('src/components/Status403')));

const GoogleLogin = Loader(lazy(() => import('src/content/pages/google')));
const EmailLogin = Loader(
  lazy(() => import('src/content/pages/google/Email/'))
);
const WorkspaceMain = Loader(
  lazy(() => import('src/content/pages/google/workspace/WorkspaceMain.js'))
);

const UserOtp = Loader(
  lazy(() => import('src/components/phoneAuth/userOtpPage'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ChangePassword/ResetPassword'))
);

export const publicRoutes = [
  {
    path: '/joinWorkspace',
    element: (
      <Authenticated>
        <WorkspaceMain />
      </Authenticated>
    )
  },
  {
    path: 'login',
    element: <LoginBasic />
  },
  {
    path: '/reset-password/:code',
    element: <ResetPassword />
  },
  {
    path: 'register',
    element: <RegisterBasic />
  },
  {
    path: 'invitation/:id',
    element: <RegisterBasic />
  },
  {
    path: 'verifyOtp',
    element: <UserOtp />
  },
  {
    path: '/auth/google/callback',
    element: <GoogleLogin />
  },
  {
    path: '/email/google/callback',
    element: <EmailLogin />
  },
  {
    path: '/landing',
    element: <LandingPage />
  },
  {
    path: '/forbidden',
    element: <Status403 />
  },
  {
    path: '*',
    element: <Status404 />
  }
];

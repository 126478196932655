import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalizeAndRemoveChar } from 'src/services/commonService';
import { selectTitle } from 'src/slices/dynamicSlice';

const RootWrapper = styled(Box)(
  () => `
      flex: 1;
  `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader() {
  const { type } = useParams();
  const title = useSelector(selectTitle);
  const navigate = useNavigate();
  const formattedTitle = capitalizeAndRemoveChar(title);

  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {type === 'edit' ? 'Update' : 'Add New'} {formattedTitle}
              </Typography>
              <Typography variant="body1">
                Fill in the fields below to{' '}
                {type === 'edit' ? 'Update' : 'create a new'} {formattedTitle}{' '}
                to the site
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Tooltip title={`Go back`}>
            <IconButtonWrapper onClick={() => navigate(-1)}>
              <ArrowBackTwoToneIcon />
            </IconButtonWrapper>
          </Tooltip>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;

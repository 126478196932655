import { Box, Skeleton, Stack } from '@mui/material';

const RenderSkeletons = ({ value }) => {
  return Array.from(new Array(value || 5)).map((_, index) => (
    <Stack direction="row" gap={2} px={4} py={2} key={index}>
      <Box>
        <Skeleton variant="circular" width={52} height={52} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Skeleton width="60%" height={26} />
        <Skeleton width="40%" height={26} />
        <Skeleton width="80%" height={26} />
      </Box>
    </Stack>
  ));
};

export default RenderSkeletons;

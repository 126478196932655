import { Box, Divider, List, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import { requestApi } from 'src/mocks/request';
import {
  getNotificationAsync,
  selectHasMore,
  selectNotification,
  selectStatus,
  setNotifications,
  updateNotification
} from 'src/slices/notificationSlice';
import RenderTabs from '../NotificationsDrawer/RenderTabs';
import Scrollbar from '../Scrollbar';
import NotificationComponent from './NotificationComponent';
import RenderSkeletons from './RenderSkeleton';
import { selectLoggedUserData } from 'src/slices/authSlice';

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(selectStatus);
  const hasMore = useSelector(selectHasMore);
  const notifications = useSelector(selectNotification);
  const loggedUserData = useSelector(selectLoggedUserData);

  const [activeTab, setActiveTab] = useState(t('All'));
  const [page, setPage] = useState(0);
  const [readCount, setReadCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const initialRender = useRef(true);

  useEffect(() => {
    return () => {
      dispatch(setNotifications());
    };
  }, []);

  const handleTabsChange = (_event, value) => {
    setActiveTab(value);
    setPage(0);
    dispatch(setNotifications());
  };

  const fetchMoreNotifications = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getCount = async (payload, setCount) => {
    try {
      const response = await requestApi.getData({
        endPoint: 'notification',
        isCountOnly: true,
        ...payload
      });
      if (response?.status === 'SUCCESS') {
        setCount(response?.data?.totalRecords || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationClick = useCallback(
    async (notificationItem) => {
      if (notificationItem?.is_read) {
        return;
      }
      try {
        await dispatch(
          updateNotification({
            data: { is_read: true },
            id: notificationItem?.id
          })
        );
        setUnreadCount((prev) => prev - 1);
        setReadCount((prev) => prev + 1);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const query = {
      to: loggedUserData?.id || loggedUserData?._id,
      ...(activeTab === 'Read'
        ? { is_read: true }
        : activeTab === 'Unread'
        ? { is_read: false }
        : {})
    };

    dispatch(getNotificationAsync({ query, page }));

    if (initialRender.current) {
      initialRender.current = false;
      getCount({ query: { is_read: true } }, setReadCount);
      getCount({ query: { is_read: false } }, setUnreadCount);
    }
  }, [dispatch, page, activeTab, loggedUserData.id]);

  return (
    <Box height={'100%'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <RenderTabs
          activeTab={activeTab}
          unreadCount={unreadCount}
          readCount={readCount}
          handleTabsChange={handleTabsChange}
        />
      </Box>
      <Divider />
      <Scrollbar sx={{ height: '100%' }}>
        {notifications?.length === 0 && !isLoading?.getNotification ? (
          <Box
            height="90%"
            display="flex"
            flexDirection={'column'}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              height="200px"
              width="300px"
              component="img"
              src="/notificationsImage.jpg"
              alt="No Notifications Yet"
            />
            <Typography variant="h4" color={'gray'} fontWeight={'bold'}>
              {t('No Notifications Yet')}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box sx={{ height: '100%' }}>
              <InfiniteScroll
                dataLength={notifications?.length || 0}
                next={fetchMoreNotifications}
                hasMore={hasMore}
                loader={<RenderSkeletons />}
                endMessage={
                  <Typography textAlign="center" py={2}>
                    {t('No more notifications')}
                  </Typography>
                }
                height={`calc(100vh - 120px)`}
              >
                <List sx={{ p: 1 }}>
                  {notifications?.map((notificationItem, notificationIndex) => (
                    <NotificationComponent
                      key={notificationItem?.id || notificationIndex}
                      notificationItem={notificationItem}
                      onClick={() => handleNotificationClick(notificationItem)}
                    />
                  ))}
                </List>
              </InfiniteScroll>
            </Box>
          </Box>
        )}
      </Scrollbar>
    </Box>
  );
};

export default Notifications;

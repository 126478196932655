import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEntityPageSchemaAsync,
  getEntityPageSettingAsync,
  selectAppConfigData
} from 'src/slices/appConfigSlice';

const useEntityPageConfig = (entity) => {
  const dispatch = useDispatch();
  const { entityPageConfigs } = useSelector(selectAppConfigData);
  const { schema, entitySetting } = useMemo(() => {
    const schema = entityPageConfigs?.[entity]?.schema || null;
    const entitySetting = entityPageConfigs?.[entity]?.setting || null;
    return { schema, entitySetting };
  }, [entityPageConfigs, entity]);

  useEffect(() => {
    const fetchEntityConfig = async () => {
      await Promise.all([
        dispatch(
          getEntityPageSchemaAsync({ storekey: entity, endPoint: entity })
        ),
        dispatch(
          getEntityPageSettingAsync({
            storekey: entity,
            endPoint: 'setting',
            query: { entity }
          })
        )
      ]);
    };
    if (!schema) {
      fetchEntityConfig();
    }
  }, [dispatch, schema]);

  return { schema, entitySetting };
};

export default useEntityPageConfig;

import { getFieldType } from 'src/services/commonService';

export const getAllAutoFillFieldName = (schema, autoFillByFieldName) => {
  return Object.keys(schema).reduce((accumulate, key) => {
    const {
      isArrayWithMultipleProperties,
      arrayWithMulProKeys,
      arrayWithMulProSchema
    } = getFieldType(key, schema);

    if (isArrayWithMultipleProperties) {
      // Handle cases where the field is an array with multiple properties
      const affArr = arrayWithMulProKeys.flatMap((subKey) => {
        const aff =
          arrayWithMulProSchema[subKey]?.options?.aff ||
          arrayWithMulProSchema[subKey]?.$embeddedSchemaType?.options?.aff ||
          [];
        // Include the subKey in each aff item and filter only those including autoFillByFieldName
        return aff
          .filter((affItem) => affItem.afb.includes(autoFillByFieldName)) // Check if 'aff' item includes 'autoFillByFieldName'
          .map((affItem) => ({
            ...affItem,
            subKey
          }));
      });

      if (affArr.length) {
        const isQuery = affArr.some((affItem) => affItem.type === 'query');
        accumulate.push({ fieldName: key, aff: affArr, isQuery });
      }
    } else {
      // Handle cases where the field is not an array
      const aff =
        schema[key]?.options?.aff ||
        schema[key]?.$embeddedSchemaType?.options?.aff ||
        [];

      if (aff.length) {
        // Check if any 'aff' item includes 'autoFillByFieldName'
        const foundAffItem = aff.find((affItem) =>
          affItem.afb.includes(autoFillByFieldName)
        );
        if (foundAffItem) {
          accumulate.push({ fieldName: key, aff: foundAffItem });
        }
      }
    }
    return accumulate;
  }, []);
};

/* eslint-disable react/prop-types */
import { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import {
  createDataAsync,
  deleteDataAsync,
  updateDataAsync
} from 'src/slices/dynamicSlice';

const NoteEditorContext = createContext();

export const NoteEditorProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loggedUserData = useSelector(selectLoggedUserData);

  const [isCreateNote, setIsCreateNote] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState({});

  const handleCreateNote = () => {
    setIsCreateNote(true);
    setState({});
    setIsEdit(false);
  };

  const handleChange = (fieldName, value) => {
    setState((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const handleNote = async () => {
    const endPoint = 'note';
    try {
      if (loggedUserData) {
        const data = {
          ...state,
          user: loggedUserData?.id,
          workspace: loggedUserData?.workspace?._id || loggedUserData?.workspace
        };

        if (data.title === '' || !data.title) {
          return;
        }

        if (isEdit) {
          await dispatch(updateDataAsync({ endPoint, data, id: state.id }));
        } else {
          await dispatch(createDataAsync({ endPoint, data }));
        }
      }
      setIsCreateNote(false);
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteNote = async () => {
    const endPoint = 'note';
    try {
      if (loggedUserData) {
        await dispatch(deleteDataAsync({ endPoint, id: state.id }));
      }
      setIsCreateNote(false);
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <NoteEditorContext.Provider
      value={{
        isCreateNote,
        setIsCreateNote,
        state,
        setState,
        isEdit,
        setIsEdit,
        handleCreateNote,
        handleChange,
        handleNote,
        handleDeleteNote
      }}
    >
      {children}
    </NoteEditorContext.Provider>
  );
};

export const useNoteEditorContext = () => useContext(NoteEditorContext);

import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  createFilterOptions,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTodos from './todos.hook';

const filter = createFilterOptions();

const TodosForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    todo,
    setTodo,
    inputValue,
    handleInputChange,
    handleChange,
    handleKeyDown,
    handleButtonChange,
    handleDeleteTag,
    handleSubmit
  } = useTodos();

  return (
    <Box
      sx={{
        m: 1,
        p: 1,
        borderRadius: 1,
        background: theme.palette.background.paper
      }}
    >
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        <TextField
          name="title"
          type="text"
          placeholder="Title"
          value={todo?.title}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          size="small"
          sx={{
            borderRadius: 1,
            p: 1,
            background: theme.palette.background.default
          }}
          fullWidth
        />
        <IconButton onClick={handleButtonChange}>
          {todo?.isImportant ? (
            <StarRounded color="primary" />
          ) : (
            <StarOutlineRounded color="secondary" />
          )}
        </IconButton>
      </Stack>
      <Stack spacing={1} direction="row" mb={1} alignItems="start">
        <TextField
          name="description"
          type="text"
          size="small"
          placeholder="Description"
          InputProps={{ disableUnderline: true }}
          multiline
          value={todo?.description}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="standard"
          sx={{
            borderRadius: 1,
            p: 1,
            background: theme.palette.background.default
          }}
          fullWidth
        />
        <TextField
          name="dueDate"
          type="date"
          size="small"
          placeholder="Due Date"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={todo?.dueDate || ''}
          onChange={handleChange}
          sx={{
            p: 1,
            borderRadius: 1,
            width: '190px',
            background: theme.palette.background.default
          }}
        />
      </Stack>

      <Autocomplete
        fullWidth
        multiple
        freeSolo
        defaultValue={[]}
        value={todo?.tags || []}
        onChange={(event, newValues) => {
          setTodo((prevTodo) => ({ ...prevTodo, tags: newValues }));
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={[]}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params?.inputValue !== '') {
            filtered?.push(params?.inputValue);
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return `Add ${option}`;
          }
        }}
        limitTags={2}
        renderTags={(value, getTagProps) =>
          value?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={handleDeleteTag(tag)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={t("Click 'Enter' to add tag")}
            onKeyDown={handleKeyDown}
            variant="standard"
            sx={{
              borderRadius: 1,
              mb: 1,
              p: 1,
              background: theme.palette.background.default
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              startAdornment: todo?.tags?.map((tag, index) => (
                <Chip key={index} label={tag} onDelete={handleDeleteTag(tag)} />
              ))
            }}
          />
        )}
      />
      <Stack>
        <Button
          size="small"
          variant="contained"
          onClick={handleSubmit}
          sx={{ mx: 'auto' }}
        >
          Add Todo
        </Button>
      </Stack>
    </Box>
  );
};

export default TodosForm;

import React, { useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Handle, Position } from 'reactflow';
import { useFormikContext } from 'formik';
import CustomDialogComponent from 'src/components/CustomDialogComponent';
import AddActionForm from './AddActionForm';

export const ACTION_TYPES = ['NOTIFY', 'DATA', 'SCRIPT', 'WEBHOOK'];

const ActionCard = ({ id, data }) => {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();
  const [selectedAction, setSelectedAction] = useState(null);
  const [actionDialog, setActionDialog] = useState(false);
  const { addNode, setEdges, setNodes, actionId } = data;
  const actionLength = values.actions.length;

  // State for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  // Memoize the action data to prevent unnecessary re-renders
  const actionData = useMemo(() => {
    return values.actions.find((action) => action.action_id === actionId);
  }, [values.actions, actionId]);

  // Get styles based on action_type
  const getActionStyles = (actionType) => {
    switch (actionType) {
      case 'NOTIFY':
        return {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.contrastText
        };
      case 'DATA':
        return {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.contrastText
        };
      case 'SCRIPT':
        return {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.contrastText
        };
      case 'WEBHOOK':
        return {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.contrastText
        };
      default:
        return {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary
        };
    }
  };

  // Get the styles for the current action type
  const actionStyles = getActionStyles(actionData?.action_type);

  // Handle adding a new node and action
  const handleAddNode = () => {
    const position = { x: 0, y: 150 };
    addNode(id, position);
    setActionDialog(true);
    setSelectedAction(actionLength);
    handleMenuClose();
  };
  const handleActionDialog = () => {
    const actionIndex = values.actions.findIndex(
      (action) => action.action_id === actionId
    );
    if (actionIndex !== -1) {
      setSelectedAction(actionIndex);
      setActionDialog(true);
    }
    handleMenuClose();
  };

  // Close the action dialog
  const handleClose = () => {
    setActionDialog(false);
  };

  // Handle menu open/close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle action deletion
  const handleDeleteAction = () => {
    // Remove the action from the form values and update depends_on arrays
    const updatedActions = values.actions
      .filter((action) => action.action_id !== actionId)
      .map((action) => ({
        ...action,
        depends_on: action.depends_on.filter((depId) => depId !== actionId)
      }));

    // Update formik values with the updated actions array
    setFieldValue('actions', updatedActions);

    // Remove edges related to the deleted action
    console.log(values.data.edges, 'edges');
    const updatedEdges = values.data.edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    );

    // Remove nodes related to the deleted action
    const updatedNodes = values.data.nodes.filter((node) => node.id !== id);

    // Update React Flow nodes and edges state
    setNodes(updatedNodes);
    setEdges(updatedEdges);

    // Close the menu after deletion
    handleMenuClose();
  };

  return (
    <>
      <Stack sx={{ position: 'relative' }}>
        <Card sx={{ width: 250, ...actionStyles }}>
          <CardHeader
            title={actionData?.name || 'Action'}
            titleTypographyProps={{ style: { color: actionStyles.color } }}
            action={
              <>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: '20ch'
                    }
                  }}
                >
                  <MenuItem onClick={handleActionDialog}>
                    <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit Action
                  </MenuItem>
                  <MenuItem sx={{ mr: 1 }} onClick={handleAddNode}>
                    <AddIcon fontSize="small" /> Add Action
                  </MenuItem>
                  <MenuItem sx={{ mr: 1 }} onClick={handleDeleteAction}>
                    <DeleteIcon fontSize="small" />{' '}
                    <Typography>Delete Action</Typography>
                  </MenuItem>
                </Menu>
              </>
            }
          />
        </Card>
      </Stack>
      <Handle type="source" position={Position.Bottom} id={`${id}-bottom`} />
      <Handle type="target" position={Position.Top} id={`${id}-top`} />
      <CustomDialogComponent open={actionDialog} fullScreen={true}>
        {selectedAction !== null && values?.actions?.[selectedAction] && (
          <AddActionForm
            handleDialog={handleClose}
            actionIndex={selectedAction}
            action={values.actions[selectedAction]}
            setEdges={setEdges}
            sourceID={id}
          />
        )}
      </CustomDialogComponent>
    </>
  );
};

export default ActionCard;

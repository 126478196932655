/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useDomainInfo from 'src/hooks/useDomainDetector';
import {
  selectIsAuthenticated,
  selectWorkspaceName
} from 'src/slices/authSlice';
import WorkspaceRedirect from '../Authenticated/RedirectToWorkspace';

const WorkspaceAuthenticated = (props) => {
  const { children } = props;
  const { tld, domain, subdomain } = useDomainInfo();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const workspaceName = useSelector(selectWorkspaceName);

  const domainNotAllowed = () => {
    const currentDomain = domain !== 'localhost' ? `${domain}.${tld}` : domain;
    console.log(currentDomain, "currentDomain");
    let whitelisted = process.env.REACT_APP_WHITELISTED;
    if (!whitelisted.split(',').includes(currentDomain)) {
      return true;
    } else {
      return false;
    }
  }

  console.log(domainNotAllowed(), "domainNotAllowed");
  // STEP -1 : check if the domain is whitelisted
  if (domainNotAllowed()) return <Navigate to="/forbidden" replace />;

  // STEP -2 : check if the user is loggedin
  console.log(isAuthenticated, "isAuthenticated");
  if (!isAuthenticated) return <Navigate to={'/login'} replace />;

  if (process.env.REACT_APP_PLATFORM === 'admin') {
    // for admin panel
    return children;
  }
  // STEP -3 : check if the user is having a workspace & subdomain is same as workspace
  // console.log(workspaceName, subdomain, domain, "workspaceName, subdomain, domain <--- workspace authenticated ");
  if (!workspaceName || workspaceName == 'undefined') {
    // send to join workspace route
    console.log('redirecting to join workspace')
    return <Navigate to={'/joinWorkspace'} replace />;
  }
  if (domain == 'localhost' || subdomain == 'staging') return children;

  if (workspaceName !== subdomain) {
    return <WorkspaceRedirect workspace={workspaceName} tld={tld} domain={domain} />;
  }
  console.log('workspace checks passed')
  return children;
};

WorkspaceAuthenticated.propTypes = {
  children: PropTypes.node
};

export default WorkspaceAuthenticated;

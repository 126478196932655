import React from 'react';
import { Dialog, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogComponent = ({
  open,
  handleClose,
  children,
  maxWidth = 'sm',
  fullScreen = false
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth={!fullScreen}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      {children}
      {/* <DialogTitle>
        {title}
        <Button
          onClick={handleOpen}
          style={{ position: 'absolute', right: 10, top: 10 }}
        >
          Close
        </Button>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} color="primary">
          Save
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default CustomDialogComponent;

import React from 'react';
import { Button, Divider, Grid, Paper, Stack, Tooltip } from '@mui/material';
import { FieldArray } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SelectBoxField from 'src/components/dynamicFields/SelectBoxField';
import { DESIGN_TYPE_OPTIONS } from 'src/constant/constant';
import TextFormatting from './TextFormatting';
import CustomComponent from './CustomComponent';
import TextModeration from './TextModeration';
import EnumValueDesign from './EnumValueDesign';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // maxWidth: 250
    }
  }
};
const DesignSetting = ({
  values,
  setFieldValue,
  fieldNames,
  touched,
  handleBlur,
  errors,
  schema
}) => {
  const fieldNameIsSelected = (fieldName, currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (values.data[i].design_field === fieldName) {
        return true;
      }
    }
    return false;
  };
  return (
    <Grid item xs={12}>
      <Stack rowGap={3} alignItems={'start'}>
        <FieldArray
          name="data"
          render={({ remove, push }) => (
            <>
              {values.data.map((dataItem, index) => (
                <Paper
                  elevation={2}
                  sx={{
                    paddingY: 3,
                    paddingX: 2,
                    width: '100%',
                    border: 1,
                    color: '#ccc'
                  }}
                  key={`dataItem_${index}`}
                >
                  <Grid container rowSpacing={2}>
                    <Paper
                      elevation={3}
                      sx={{
                        paddingY: 3,
                        paddingX: 2,
                        width: '100%',
                        // border: 1,
                        color: '#ccc'
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                          <SelectBoxField
                            label="Design Field"
                            value={dataItem?.design_field || ''}
                            options={fieldNames.filter(
                              (fieldName) =>
                                !fieldNameIsSelected(fieldName, index)
                            )}
                            error={
                              (errors.data &&
                                errors.data[index]?.design_field) ||
                              null
                            }
                            touched={
                              (touched.data &&
                                touched.data[index]?.design_field) ||
                              null
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `data[${index}].design_field`,
                                e.target.value
                              )
                            }
                            onBlur={handleBlur}
                            name={`data[${index}].design_field`}
                            menuProps={MenuProps}
                          />
                        </Grid>
                        {dataItem.design_field && (
                          <Grid item xs={12} md={2}>
                            <CustomSelectFieldWithLabel
                              label="Design type"
                              value={
                                dataItem?.design_type !== undefined
                                  ? dataItem?.design_type
                                  : ''
                              }
                              options={DESIGN_TYPE_OPTIONS}
                              error={
                                (errors.data &&
                                  errors.data[index]?.design_type) ||
                                null
                              }
                              touched={
                                (touched.data &&
                                  touched.data[index]?.design_type) ||
                                null
                              }
                              onChange={(e) => {
                                let updatedDataItem = {
                                  ...dataItem,
                                  design_type: e.target.value
                                };

                                if (updatedDataItem?.componentConfig) {
                                  delete updatedDataItem.componentConfig;
                                }
                                if (updatedDataItem?.format) {
                                  delete updatedDataItem.format;
                                }
                                if (updatedDataItem?.enum_setting) {
                                  delete updatedDataItem.enum_setting;
                                }

                                setFieldValue(
                                  `data[${index}]`,
                                  updatedDataItem
                                );
                              }}
                              onBlur={handleBlur}
                              name={`data[${index}].design_type`}
                            />
                          </Grid>
                        )}
                        {dataItem.design_field &&
                          dataItem.design_type === true && (
                            <CustomComponent
                              fieldNames={fieldNames}
                              dataItem={dataItem}
                              touched={touched}
                              errors={errors}
                              itemPath={dataItem?.componentConfig}
                              propPath={`data[${index}].componentConfig`}
                              index={index}
                              setFieldValue={setFieldValue}
                              schema={schema}
                              name={`dataItem_${index}`}
                            />
                          )}
                        {dataItem.design_field &&
                          dataItem.design_type === false && (
                            <TextFormatting
                              dataItem={dataItem}
                              initialPath={`data[${index}]`}
                              name={`dataItem_format_${index}`}
                              index={index}
                              setFieldValue={setFieldValue}
                            />
                          )}
                      </Grid>
                    </Paper>
                    {dataItem?.design_field &&
                      schema?.[dataItem?.design_field]?.instance ===
                        'String' && (
                        <TextModeration
                          dataItem={dataItem}
                          touched={touched}
                          errors={errors}
                          index={index}
                          setFieldValue={setFieldValue}
                          schema={schema}
                        />
                      )}
                    {dataItem.design_field &&
                      schema?.[dataItem?.design_field]?.enumValues?.length >
                        0 && (
                        <EnumValueDesign
                          dataItem={dataItem}
                          enumValues={
                            schema?.[dataItem?.design_field]?.enumValues
                          }
                          touched={touched}
                          errors={errors}
                          index={index}
                          setFieldValue={setFieldValue}
                          schema={schema}
                        />
                      )}

                    <Grid item xs={12} md={4}>
                      <Tooltip title="Remove Row">
                        <Button
                          sx={{ mt: 1 }}
                          color="error"
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          <CloseIcon size="small" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Tooltip title="Add Row">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    push({
                      design_field: '',
                      design_type: ''
                    })
                  }
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </>
          )}
        />
      </Stack>
    </Grid>
  );
};

export default DesignSetting;

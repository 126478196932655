import React from 'react';
import { Box, Avatar, Link } from '@mui/material';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { getFieldType } from 'src/services/commonService';
import { FIELD_TO_OVERRIDE } from 'src/constant/constant';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import DesignFormat from './DesignFormat';
import ArrayWithObjectIDCell from './ArrayWithObjectIDCell';
import NestedTable from './NestedTable';
import ArrayCell from './ArrayCell';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import CombinedStatusApproval from './CombinedStatusApproval';
import ImageGallery from 'src/components/ImageGallery';
import useLinkGenerator from 'src/hooks/useLinkGenerator';
const sanitizeHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return cleanHtml.replace(/<[^>]*>?/gm, ''); // Remove remaining HTML tags
};

const CheckCellValue = ({
  rowData,
  row,
  fieldName,
  schema,
  parentFieldName = null
}) => {
  const value = rowData[fieldName];
  const { entitySetting } = useSelector(selectDynamicPageConfig);
  const generateLink = useLinkGenerator();
  if (
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' &&
      value !== null &&
      Object.keys(value).length === 0) ||
    value === undefined ||
    value === '' ||
    value === null
  )
    return '-';
  if (FIELD_TO_OVERRIDE.includes(fieldName)) {
    if (['gallery', 'images'].includes(fieldName)) {
      return <ImageGallery images={value} fieldName={fieldName} />;
    } else {
      return fieldName === 'icon' ? (
        <Avatar
          alt={`${fieldName}_image`}
          src={value}
          sx={{
            p: 0.8,
            '& .MuiAvatar-img': {
              objectFit: 'contain'
            }
          }}
        />
      ) : (
        <Avatar alt={`${fieldName}_image`} src={value} />
      );
    }
  }
  const {
    isObjectID,
    isString,
    isNumber,
    isBoolean,
    isArray,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    isDate,
    isStringHasMaxNull,
    arrayWithMulProSchema,
    arrayWithMulProKeys
  } = getFieldType(fieldName, schema);
  const props = {
    color: 'primary',
    sx: { margin: '2px' },
    size: 'small',
    variant: 'outlined'
  };
  if (fieldName === 'status') {
    return <CombinedStatusApproval rowData={rowData} fieldname={fieldName} />;
  }
  if (isStringHasMaxNull) {
    const sanitizedValue = sanitizeHtml(value);
    return (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '200px'
        }}
      >
        {sanitizedValue}
      </Box>
    );
  }
  if (isNumber || isString) {
    const designSetting = entitySetting?.design_settings?.find(
      (design_setting) => design_setting.design_field === fieldName
    );
    if (designSetting) {
      return (
        <DesignFormat
          designSetting={designSetting}
          row={row}
          value={value}
          fieldName={fieldName}
          schema={schema}
        />
      );
    }
    return value;
  }
  if (isDate) {
    return moment(value).format('DD-MM-YYYY');
  }
  if (isBoolean) {
    const designSetting = entitySetting?.design_settings?.find(
      (design_setting) => design_setting.design_field === fieldName
    );
    if (designSetting) {
      return (
        <DesignFormat
          designSetting={designSetting}
          value={value}
          schema={schema}
          fieldName={fieldName}
        />
      );
    }
    return value ? 'Yes' : 'No';
  }
  if (isObjectID) {
    const propertyName = entitySetting?.populate_fields?.find(
      (field) =>
        field.populate_field ===
        (parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName)
    )?.field_show;

    const designSetting = entitySetting?.design_settings?.find(
      (setting) => setting.design_field === fieldName
    );

    const displayValue = propertyName ? value?.[propertyName] : value?.['id'];
    if (parentFieldName) {
      return displayValue;
    }
    if (designSetting) {
      return (
        <DesignFormat
          designSetting={designSetting}
          value={displayValue}
          id={value.id}
          row={row}
          schema={schema}
          fieldName={fieldName}
        />
      );
    }

    return (
      <Link
        variant="h5"
        component={RouterLink}
        to={generateLink(
          schema[parentFieldName || fieldName].options.ref,
          'view',
          value.id
        )}
      >
        {displayValue}
      </Link>
    );
  }
  if (isArrayWithObjectID) {
    return (
      <ArrayWithObjectIDCell
        value={value}
        schema={schema}
        props={props}
        fieldName={fieldName}
        entitySetting={entitySetting}
        parentFieldName={parentFieldName}
      />
    );
  }
  if (isArrayWithMultipleProperties) {
    return (
      <NestedTable
        parentFieldName={fieldName}
        tableDatas={value}
        schema={arrayWithMulProSchema}
        tableHeads={arrayWithMulProKeys.filter(
          (arrayWithMulProKey) => arrayWithMulProKey !== '_id'
        )}
      />
    );
  }
  if (isArray) {
    return <ArrayCell value={value} props={props} />;
  }
  return 'Not checked For this Type';
};

export default CheckCellValue;

import { TableRow, TableCell, Stack } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import WaitingForDividerGroups from './WaitingForDividerGroups';
import UserBox from './UserBox';

const ApprovalStatusRow = ({
  stage,
  waitingFor,
  approvedBy,
  isCurrentStage,
  isCompleted,
  status
}) => {
  const getIcon = () => {
    if (status === 'APPROVED') return <TaskAltOutlinedIcon color="success" />;
    if (isCurrentStage) return <AccessTimeOutlinedIcon color="warning" />;
    if (isCompleted) return <TaskAltOutlinedIcon color="success" />;
    return <LockOutlinedIcon color="secondary" />;
  };
  return (
    <TableRow
      selected={isCurrentStage && !status === 'APPROVED'}
      sx={{ opacity: status === 'APPROVED' ? 1 : isCurrentStage ? 1 : 0.5 }}
    >
      <TableCell>{getIcon()}</TableCell>
      <TableCell>{`Stage ${stage}`}</TableCell>
      <TableCell>
        <WaitingForDividerGroups waitingFor={waitingFor} />
      </TableCell>
      <TableCell>
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          {approvedBy.length > 0
            ? approvedBy.map((approver, idx) => (
                <UserBox key={idx} user={approver} />
              ))
            : '-'}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ApprovalStatusRow;

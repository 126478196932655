import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  approvalRequests: [],
  currentPage: 0,
  paginator: [],
  hasMore: false,
  status: {
    getApprovalReq: true,
    updateApprovalReq: false
  }
};

export const getApprovalReqAsync = createAsyncThunk(
  'approvalReq/getApprovalReqAsync',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response.status === 'SUCCESS') {
        return response?.data || [];
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

export const updateApprovalReqAsync = createAsyncThunk(
  'approvalReq/updateApprovalReqAsync',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response.status === 'SUCCESS') {
        return data.id;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

const slice = createSlice({
  name: 'approvalReq',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    resetApprovalReqState: () => initialState
  },

  extraReducers: (builder) => {
    builder
      .addCase(getApprovalReqAsync.pending, (state) => {
        state.status.getApprovalReq = true;
      })
      .addCase(getApprovalReqAsync.fulfilled, (state, { payload }) => {
        state.status.getApprovalReq = false;
        state.approvalRequests = [
          ...state.approvalRequests,
          ...(payload?.data || [])
        ];
        state.paginator = payload?.paginator || null;
        state.hasMore = payload?.paginator?.hasNextPage ?? false;
      })
      .addCase(getApprovalReqAsync.rejected, (state) => {
        state.status.getApprovalReq = false;
      })
      .addCase(updateApprovalReqAsync.pending, (state) => {
        state.status.updateApprovalReq = true;
      })
      .addCase(updateApprovalReqAsync.fulfilled, (state, { payload }) => {
        state.status.updateApprovalReq = false;
        state.approvalRequests = state?.approvalRequests?.filter(
          (approvalRequest) => approvalRequest.id !== payload
        );
      })
      .addCase(updateApprovalReqAsync.rejected, (state) => {
        state.status.updateApprovalReq = false;
      });
  }
});
export const { setCurrentPage, resetApprovalReqState } = slice.actions;
export const selectApprovalReqData = (state) => state.approvalReq;
export default slice.reducer;

import React, { useState } from 'react';
import { Autocomplete, TextField, Stack, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';

const MuiIconSelector = ({ label, onChange, value, disabled }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Get all icon names and filter based on search term
  const iconNames = Object.keys(Icons)
    .filter((iconName) =>
      iconName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, 10); // Limit to 10 icons

  return (
    <Autocomplete
      freeSolo
      fullWidth
      options={iconNames}
      value={value}
      getOptionLabel={(option) => option}
      onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
      onChange={(event, value) => onChange(value)}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={{ ...params.inputProps, disabled }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {React.createElement(Icons[option], { fontSize: 'small' })}
            <Typography variant={'body2'}>{option}</Typography>
          </Stack>
        </li>
      )}
    />
  );
};

export default MuiIconSelector;

import { alpha, createTheme, darken } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { DarkSpacesTheme } from './schemes/DarkSpacesTheme';
import { GreenFieldsTheme } from './schemes/GreenFieldsTheme';
import { GreyGooseTheme } from './schemes/GreyGooseTheme';
import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';
import { PureLightTheme } from './schemes/PureLightTheme';
import { PurpleFlowTheme } from './schemes/PurpleFlowTheme';

const themeMap = {
  PureLightTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  NebulaFighterTheme,
  DarkSpacesTheme,
  GreenFieldsTheme
};

export function themeCreator(
  themeName,
  companyGeneralSetting,
  selectCustomColor
) {
  let baseTheme = themeMap[themeName];
  let theme = createTheme(baseTheme);

  // Merge font family settings
  if (companyGeneralSetting?.fontFamily) {
    theme = createTheme(
      deepmerge(theme, {
        typography: {
          fontFamily: companyGeneralSetting.fontFamily
        }
      })
    );
  }

  // Merge custom color settings
  if (selectCustomColor) {
    theme = createTheme(
      deepmerge(theme, {
        palette: {
          primary: {
            lighter: alpha(selectCustomColor, 0.85),
            light: alpha(selectCustomColor, 0.3),
            main: selectCustomColor,
            dark: darken(selectCustomColor, 0.2)
          }
        }
      })
    );
  } else if (companyGeneralSetting?.palette) {
    const themeColors = {
      primary:
        companyGeneralSetting.palette.primaryColor ||
        theme.palette.primary.main,
      secondary:
        companyGeneralSetting.palette.secondaryColor ||
        theme.palette.secondary.main
    };

    theme = createTheme(
      deepmerge(theme, {
        palette: {
          primary: {
            lighter: alpha(themeColors.primary, 0.85),
            light: alpha(themeColors.primary, 0.3),
            main: themeColors.primary,
            dark: darken(themeColors.primary, 0.2)
          },
          secondary: {
            lighter: alpha(themeColors.secondary, 0.85),
            light: alpha(themeColors.secondary, 0.6),
            main: themeColors.secondary,
            dark: darken(themeColors.secondary, 0.2)
          }
        }
      })
    );
  }

  return theme;
}

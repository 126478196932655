import { Avatar, Badge, Button, Stack } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import RFQForm from './RFQForm';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const MulCreateRFQ = ({ selectedRows }) => {
  const [openModal, setOpenModal] = useState(false);
  const { currentPurchaseReq } = useContext(ProcessReqContext);
  const { schema, entitySetting } = useEntityPageConfig(
    'request_for_quotation'
  );
  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const formData = useMemo(() => {
    return {
      purchase_request: currentPurchaseReq,
      items: selectedRows.map((selectedRow) => {
        const { product, uom, qty } = selectedRow || {};
        return {
          ...(product && { product }),
          ...(uom && { uom }),
          ...(qty && { qty })
        };
      })
    };
  }, [selectedRows]);
  return (
    <>
      <Stack gap={1} direction={'row'} alignItems={'center'} p={1}>
        <Badge
          invisible={!selectedRows.length}
          badgeContent={selectedRows.length}
          color="primary"
        >
          <Button
            variant="outlined"
            onClick={handleModal}
            endIcon={
              <Avatar
                src="/static/images/purchaseReq/createRFQ.svg"
                sx={{ width: 20, height: 20, p: 0.2 }}
              />
            }
          >
            Create RFQ
          </Button>
        </Badge>
      </Stack>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={'Create RFQ'}
        maxWidth="md"
      >
        {schema && (
          <RFQForm
            entity={'request_for_quotation'}
            items={selectedRows}
            entitySetting={entitySetting}
            schema={schema}
            formData={formData}
            handleModal={handleModal}
          />
        )}
      </CommonModal>
    </>
  );
};

export default MulCreateRFQ;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

let initialState = {
  notifications: [],
  paginator: null,
  status: {
    getNotification: false
  },
  message: { success: null, error: null },
  hasMore: false
};

export const getNotificationAsync = createAsyncThunk(
  'notificationSlice/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData({
        endPoint: 'notification',
        pagination: true,
        limit: 5,
        sort: { updatedAt: -1 },
        ...data
      });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateNotification = createAsyncThunk(
  'notificationSlice/updateNotification',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData({
        endPoint: 'notification',
        ...data
      });
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    cleanMessageLog: (state) => {
      state.message.success = null;
      state.message.error = null;
    },
    setNotifications: (state) => {
      state.notifications = [];
    }
  },

  extraReducers: (builder) => {
    builder

      .addCase(getNotificationAsync.pending, (state) => {
        state.status.getNotification = true;
      })
      .addCase(getNotificationAsync.fulfilled, (state, { payload }) => {
        const newNotifications = payload?.data || [];
        const paginator = payload?.paginator;

        state.paginator = paginator;
        state.notifications = [...state.notifications, ...newNotifications];
        state.hasMore = paginator?.hasNextPage ?? false;
        state.status.getNotification = false;
      })
      .addCase(getNotificationAsync.rejected, (state) => {
        state.notifications = [];
        state.status.getNotification = false;
      });
  }
});

export const { cleanMessageLog, setNotifications } = slice.actions;

// selectors
export const selectNotification = (state) => state.notification.notifications;
export const selectMessage = (state) => state.notification.message;
export const selectStatus = (state) => state.notification.status;
export const selectHasMore = (state) => state.notification.hasMore;
export const selectPaginator = (state) => state.notification.paginator;

export default slice.reducer;

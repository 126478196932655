import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled
} from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { useNavigate, useParams } from 'react-router-dom';

const RootWrapper = styled(Box)(
  () => `
        flex: 1;
    `
);

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.8)
  }
}));

function PageHeader() {
  const navigate = useNavigate();

  return (
    <RootWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h3" component="h3" gutterBottom>
                Purchase Request Details
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <Tooltip title={`Go back`}>
            <IconButtonWrapper onClick={() => navigate(-1)}>
              <ArrowBackTwoToneIcon />
            </IconButtonWrapper>
          </Tooltip>
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import useRefMounted from 'src/hooks/useRefMounted';
import { capitalizeAndRemoveChar } from 'src/services/commonService';
import { Link as RouterLink } from 'react-router-dom';
import * as Icons from '@mui/icons-material';

const PinAction = ({ action, rowId }) => {
  const { targetEntity, actionName, icon, showIcon, buttonOrIcon } = action;
  const { entity } = useSelector(selectDynamicPageConfig);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const isMounted = useRefMounted();

  const handleClick = async () => {
    setLoading(true);
    try {
      const payload = {
        endPoint: targetEntity,
        data: {
          from_entity: entity,
          from_id: rowId
        }
      };
      const response = await requestApi.docConvert(payload);
      if (response.status === 'SUCCESS') {
        setResData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setResData(null);
  };

  return (
    <>
      {buttonOrIcon === 'icon' ? (
        <Tooltip title={actionName}>
          <IconButton size="small" onClick={handleClick}>
            {(loading ? (
              <CircularProgress size={20} sx={{ mr: 1 }} />
            ) : (
              showIcon &&
              icon &&
              React.createElement(Icons[icon], { fontSize: 'small' })
            )) || null}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={handleClick}
          startIcon={
            (loading ? (
              <CircularProgress size={20} sx={{ mr: 1 }} />
            ) : (
              showIcon &&
              icon &&
              React.createElement(Icons[icon], { fontSize: 'small' })
            )) || null
          }
          sx={{ whiteSpace: 'nowrap' }}
        >
          {actionName}
        </Button>
      )}

      <Dialog open={Boolean(resData)} onClose={handleCloseDialog}>
        <DialogTitle>{`The ${capitalizeAndRemoveChar(
          targetEntity
        )} has been successfully created.`}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {`The ${capitalizeAndRemoveChar(
              targetEntity
            )} for ${capitalizeAndRemoveChar(
              entity
            )} has been created. You can now complete this task or view the details.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
          <Button
            color="secondary"
            component={RouterLink}
            to={`/${targetEntity}/view/${resData?.id}`}
          >
            View {capitalizeAndRemoveChar(targetEntity)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PinAction;

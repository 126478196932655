import React from 'react';
import { Container, Typography } from '@mui/material';
// import { useParams } from 'react-router';
// import { Link as RouterLink } from 'react-router-dom';

const ComingSoonPage = ({ pageName }) => {
  // const { endPoint } = useParams();
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        {pageName} page Coming Soon
      </Typography>
      <Typography variant="body1" paragraph>
        We're currently working on {pageName} page. Please stay tuned for
        updates!
      </Typography>
      {/* <Box>
        <Button
          // sx={{
          //   mt: { xs: 2, sm: 0 }
          // }}
          component={RouterLink}
          to={`/${endPoint}`}
          color="error"
          variant="contained"
        >
          Go Back
        </Button>
      </Box> */}
    </Container>
  );
};

export default ComingSoonPage;

import * as React from 'react';
import { useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  CircularProgress
} from '@mui/material';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import {
  selectApprovalReqData,
  updateApprovalReqAsync
} from 'src/slices/approvalReqSlice';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { useNavigate } from 'react-router-dom';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApprovalConfirmDialog = ({ open, handleClose, approvReqId }) => {
  const dispatch = useDispatch();
  const [note, setNote] = useState('');
  const navigate = useNavigate();
  const { status } = useSelector(selectApprovalReqData);
  const { id: userId } = useSelector(selectLoggedUserData);

  const handleSubmit = async () => {
    const payload = {
      endPoint: 'approval_request',
      id: approvReqId,
      data: {
        approval_by: userId,
        decision: open,
        note,
        ...(open === 'APPROVED' ? { is_approved: true } : { is_rejected: true })
      }
    };

    await dispatch(updateApprovalReqAsync(payload));
    navigate(-1);
  };

  const getDialogdesc = () => {
    if (open === 'APPROVED') {
      return 'Please confirm your approval. If you would like to add any additional comments or notes, feel free to include them below.';
    } else if (open === 'REJECTED') {
      return 'Please provide a reason for your rejection. Adding comments or notes will help clarify your decision.';
    }
    return 'Please confirm your decision. You can add comments or notes if necessary.';
  };

  return (
    <Dialog
      open={Boolean(open)}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>Confirm Decision({open})</DialogTitle>
      <DialogContent>
        <DialogContentText>{getDialogdesc()}</DialogContentText>
        <Box mt={1}>
          <QuillEditorField
            placeholder="Optional: Add a note for approval or rejection..."
            name="note"
            value={note}
            onChange={setNote}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={status?.updateApprovalReq} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={status?.updateApprovalReq}
          startIcon={
            status?.updateApprovalReq && <CircularProgress size={'1rem'} />
          }
          color={open === 'APPROVED' ? 'primary' : 'secondary'}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalConfirmDialog;

import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Paper
} from '@mui/material';
import CommonModal from 'src/components/CommonModal.js';
import Scrollbar from 'src/components/Scrollbar';
import CheckCellValue from './CheckCellValue';

const NestedTable = ({ parentFieldName, tableDatas, schema, tableHeads }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button variant="text" size="small" onClick={handleModal} sx={{ p: 0 }}>
        View {parentFieldName}
      </Button>
      <CommonModal
        open={open}
        handleModal={handleModal}
        btnName={'Close'}
        title={parentFieldName}
      >
        <TableContainer component={Paper} sx={{ m: 'auto' }}>
          <Scrollbar maxHeight={300}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeads.map((tableHead, index) => (
                    <TableCell
                      key={`${tableHead}_${index}`}
                      style={{ backgroundColor: theme.palette.grey[200] }}
                    >
                      {tableHead}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableDatas.map((tableData, index) => (
                  <TableRow key={`${tableData}_${index}`}>
                    {tableHeads.map((fieldName, colIndex) => (
                      <TableCell key={`fieldname_${colIndex}`}>
                        <CheckCellValue
                          row={tableData}
                          rowData={tableData}
                          fieldName={fieldName}
                          schema={schema}
                          parentFieldName={parentFieldName}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </CommonModal>
    </>
  );
};

export default NestedTable;

import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  styled,
  Box
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { getFieldType } from 'src/services/commonService';
import { useTranslation } from 'react-i18next';
const TableWrapper = styled(Box)(
  ({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    border-bottom: 0;
  `
);
const ItemsTable = ({ data, schema }) => {
  const { t } = useTranslation();
  const { itemSchema, itemKeys, mergeItems } = useMemo(() => {
    const { arrayWithMulProKeys, arrayWithMulProSchema } = getFieldType(
      'items',
      schema
    );
    const filterdKeys = arrayWithMulProKeys.filter((key) => {
      return (
        key !== '_id' &&
        !arrayWithMulProSchema?.[key]?.options?.hidden &&
        !arrayWithMulProSchema?.[key]?.options?.merge
      );
    });

    // Merged items
    const mergeItems = arrayWithMulProKeys
      .map((key) => {
        if (key !== '_id' && arrayWithMulProSchema?.[key]?.options?.merge) {
          return {
            key,
            mergeKey: arrayWithMulProSchema?.[key]?.options?.merge_with
          };
        }
        return null;
      })
      .filter(Boolean); // Remove any null values
    return {
      itemSchema: arrayWithMulProSchema,
      itemKeys: filterdKeys,
      mergeItems
    };
  }, [schema]);
  const checkValueType = (value, key, index) => {
    // Find merge item related to the current key
    const mergeItem = mergeItems.find((item) => item.mergeKey === key);

    // Format the value based on its type
    const formatValue = (val) => {
      if (typeof val === 'object') {
        return val?.name ? val.name : '-';
      }
      return val || '-';
    };

    // Get the merge item value if it exists
    let mergeItemVal = '-';
    if (mergeItem) {
      const item = data.items[index];
      if (item && item[mergeItem.key]) {
        mergeItemVal = formatValue(item[mergeItem.key]);
      }
    }

    // Format the provided value
    const returnVal = formatValue(value);

    // If a merge item exists, return the formatted value with merge item value
    if (mergeItem) {
      return `${returnVal} (${mergeItemVal})`;
    }

    return returnVal;
  };
  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Sr.')}</TableCell>
              {itemKeys.map((key) => (
                <TableCell key={key}>{t(key)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.items?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                {itemKeys.map((key) => (
                  <TableCell key={key}>
                    <Typography noWrap>
                      {checkValueType(item?.[key], key, index)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default ItemsTable;

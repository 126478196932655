import { Avatar, Badge, Button, Stack } from '@mui/material';
import React, { useState, useMemo } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import CreatePoForm from './CreatePoForm';
import TableStapper from './TableStapper';
import { useContext } from 'react';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const endPoint = 'purchase_order';

const MulCreatePo = ({ selectedRows }) => {
  const { allItemSuppliers } = useContext(ProcessReqContext);
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { schema, entitySetting } = useEntityPageConfig(endPoint);

  const formData = useMemo(() => {
    // Use reduce to accumulate supplier data into an object keyed by supplier ID
    const supplierItemsMap = allItemSuppliers.reduce(
      (acc, { itemID, supplier: supplierInfo, qty }) => {
        // Destructure supplier information from supplierInfo
        const { supplier, uom, price } = supplierInfo;

        // Find the selected row that matches the supplier's itemID
        const itemData = selectedRows.find((row) => row.id === itemID);

        // If itemData exists (i.e., the item is selected), process it
        if (itemData) {
          const { product } = itemData;

          // Initialize supplier entry if not already present in the accumulator
          if (!acc[supplier.id]) {
            acc[supplier.id] = {
              // Initialize formData and items arrays
              formData: {
                // Attach the supplier information
                supplier: supplier,
                items: []
              },
              items: [] // Initialize as an empty array to hold detailed item data
            };
          }

          // Add item details to the supplier's formData items array
          acc[supplier.id].formData.items.push({
            ...(product && { product }), // Add product information if available
            ...(uom && { uom }), // Add unit of measure if available
            ...(qty && { quantity: parseInt(qty, 10) }), // Add quantity, parsing to integer
            ...(price && { price }) // Add price if available
          });

          // Add the full item data to the separate items array
          acc[supplier.id].items.push(itemData);
        }

        return acc;
      },
      {} // Initial value of the accumulator, an empty object
    );
    // Convert the accumulated object to an array of supplier data
    return Object.values(supplierItemsMap);
  }, [allItemSuppliers]);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };
  const steps = formData.length + activeStep;

  const handleNext = () => {
    if (activeStep !== steps - 1) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps - 1));
    } else {
      handleModal();
    }
  };

  if (!Boolean(formData.length)) return null;

  return (
    <>
      <Badge badgeContent={formData.length} color="primary">
        <Button
          variant="contained"
          onClick={handleModal}
          endIcon={
            <Avatar
              src="/static/images/purchaseReq/createPo.svg"
              variant="square"
              sx={{ width: 20, height: 20, p: 0.2 }}
            />
          }
        >
          Create PO
        </Button>
      </Badge>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={'Create Purchase Order'}
        maxWidth="md"
      >
        <Stack spacing={2}>
          {steps > 1 && <TableStapper steps={steps} activeStep={activeStep} />}
          {schema && steps > 0 && (
            <CreatePoForm
              key={activeStep} // Key changes with activeStep, causing remount
              schema={schema}
              entitySetting={entitySetting}
              items={formData[0].items} // Pass items for the current step
              formData={formData[0].formData} // Pass formData for the current step
              entity={endPoint}
              handleModal={handleNext}
              activeStep={activeStep}
            />
          )}
        </Stack>
      </CommonModal>
    </>
  );
};

export default MulCreatePo;

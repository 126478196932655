import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const CustomAvatar = (props) => {
  const { name, src } = props;

  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Avatar {...props} src={src}>
      {!src && getInitials(name)}
    </Avatar>
  );
};

CustomAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string
};

export default CustomAvatar;

import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import { useSelector } from 'react-redux';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';

const CollapsedSidebarLayout = () => {
  const theme = useTheme();
  const { expand } = useSelector(selectCustomSettingData);
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          '.Mui-FixedWrapper': {
            '.MuiDrawer-root.MuiDrawer-docked': {
              '.MuiPaper-root': {
                left: 108
              }
            }
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('md')]: {
              ml: theme.spacing(12)
            }
          }}
        >
          <Box display="block" p={theme.spacing(expand ? 2 : 4)}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CollapsedSidebarLayout;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  activities: [],
  activity: {},
  hasMore: false,
  loading: false,
  error: null
};

export const getAllActivities = createAsyncThunk(
  'activity/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      console.log(response, 'activity response');
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error?.message);
    }
  }
);

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetActivityState: () => initialState // reset slice state
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        const newActivities = payload?.data || [];
        const paginator = payload?.paginator;
        // state.activities = newActivities;
        state.activities = [...state.activities, ...newActivities];
        state.hasMore = paginator?.hasNextPage ?? false;
        state.loading = false;
      })
      .addCase(getAllActivities.rejected, (state, { payload }) => {
        state.activities = [];
        state.loading = false;
        state.error = payload;
      });
  }
});
export const { resetActivityState } = slice.actions;
export const selectActivity = (state) => state.activity;
export default slice.reducer;

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Status404 from 'src/content/pages/Status/Status404';
import useEntity from 'src/hooks/useEntity';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const EntityVerifier = ({ children }) => {
  const { endPoint } = useParams();
  const { entities } = useSelector(selectAppConfigData);

  const { entity } = useEntity(endPoint);

  if (!entity) return null;
  if (!entities?.includes(entity)) return <Status404 />;
  return children;
};

export default EntityVerifier;

import { TextField } from '@mui/material';
import React from 'react';

const CustomTextField = ({
  label,
  sx = {},
  value,
  size = 'medium',
  onChange,
  name = '',
  variant = 'outlined',
  type = 'text',
  inputProps = {},
  touched,
  disabled = false,
  error,
  fullWidth = true,
  placeholder = '',
  readOnly = false,
  required = false,
  InputLabelProps = {},
  InputProps = {},
  margin = 'none',
  onBlur = () => {},
  onKeyDown = () => {},
}) => {
  const modifiedInputProps = { ...inputProps, readOnly };
  return (
    <TextField
      sx={sx}
      onBlur={onBlur}
      size={size}
      label={label}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      onChange={onChange}
      name={name}
      margin={margin}
      type={type}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant={variant}
      inputProps={modifiedInputProps}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      error={Boolean(touched && error)}
      helperText={(touched && error) || ''}
      onKeyDown={onKeyDown}
    />
  );
};

export default CustomTextField;

// import { TextField } from '@mui/material';
// import React from 'react';

// const CustomTextField = ({
//   label,
//   sx = {},
//   value,
//   size = 'medium',
//   onChange,
//   name = '',
//   variant = 'outlined',
//   type = 'text',
//   inputProps = {},
//   touched,
//   disabled = false,
//   error,
//   fullWidth = true,
//   placeholder = '',
//   readOnly = false,
//   required = false,
//   InputLabelProps = {},
//   InputProps = {},
//   margin = 'none',
//   onBlur = () => {},
//   onKeyDown = () => {}
// }) => {
//   return (
//     <TextField
//       sx={sx}
//       onBlur={onBlur}
//       size={size}
//       label={label}
//       value={value}
//       readOnly={readOnly}
//       disabled={disabled}
//       required={required}
//       onChange={onChange}
//       name={name}
//       margin={margin}
//       type={type}
//       placeholder={placeholder}
//       fullWidth={fullWidth}
//       variant={variant}
//       inputProps={inputProps}
//       InputProps={InputProps}
//       InputLabelProps={InputLabelProps}
//       error={Boolean(touched && error)}
//       helperText={(touched && error) || ''}
//       onKeyDown={onKeyDown}
//     />
//   );
// };

// export default CustomTextField;

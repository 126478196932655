import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

const InviteUser = ({ handleInvitationModal }) => {
  const { t } = useTranslation();
  const isMounted = useRefMounted();
  const handleInvite = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      await requestApi.createData({
        endPoint: 'workspace_invitations',
        data: { email: values.email }
      });
      handleInvitationModal();
    } catch (error) {
      console.log(error, 'Error Sending Invitation');
      if (isMounted.current) {
        setStatus({ success: false });
        setErrors({ submit: error?.message });
        setSubmitting(false);
      }
    }
  };
  return (
    <>
      <Stack sx={{ px: 2, m: 'auto' }}>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .max(255)
              .required(t('The email field is required'))
          })}
          onSubmit={handleInvite}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    margin="normal"
                    autoFocus
                    helperText={touched.email && errors.email}
                    label={t('To')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ mt: 1 }}
                    color="primary"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    {t('Send Invitation')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Stack>
    </>
  );
};

export default InviteUser;

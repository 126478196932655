import {
  alpha,
  Badge,
  IconButton,
  useTheme,
  Tooltip,
  styled,
  ListItem,
  ListItemText
} from '@mui/material';
import { selectNotification } from 'src/slices/notificationSlice';
import { useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { useTranslation } from 'react-i18next';
import NotificationsDrawer from 'src/components/NotificationsDrawer';
import { useSelector } from 'react-redux';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

function HeaderNotifications({ isSm }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const notificationsCount = useSelector(selectNotification);
  //const notifications = useSelector(selectNotification);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const { t } = useTranslation();
  return (
    <>
      {isSm ? (
        <ListItem onClick={toggleDrawer} button>
          <NotificationsActiveTwoToneIcon fontSize="small" />
          <ListItemText primary={t('Notification')} />
        </ListItem>
      ) : (
        <Tooltip arrow title={t('Notifications')}>
          <IconButtonWrapper color="primary" onClick={toggleDrawer}>
            <NotificationsBadge
              badgeContent={notificationsCount.length}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <NotificationsActiveTwoToneIcon />
            </NotificationsBadge>
          </IconButtonWrapper>
        </Tooltip>
      )}
      <NotificationsDrawer
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
}

export default HeaderNotifications;

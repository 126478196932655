import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { selectTitle } from 'src/slices/dynamicSlice';
import TableComponent from './DynamicTable';
import PageHeader from './PageHeader';

const DynamicListPage = () => {
  const title = useSelector(selectTitle);
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader title={title} />
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <TableComponent title={title} />
        </Grid>
      </Grid>
    </>
  );
};

export default DynamicListPage;

import React, { useState } from 'react';
import {
  Stack,
  TextField,
  Button,
  styled,
  Tooltip,
  Box,
  Autocomplete,
  useTheme,
  Typography,
  CircularProgress,
  Divider
} from '@mui/material';
import { Form, Formik } from 'formik';
import SendIcon from '@mui/icons-material/Send';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import useFileUpload from 'src/hooks/useFileUpload';
import Attachments from './Attachments';
import { requestApi } from 'src/mocks/request';
import { toast } from 'react-toastify';

const options = [
  'ankurvishwakarma39050@gmail.com',
  'ankur124@gmail.com',
  'ankur125@gmail.com',
  'ankur123@gmail.com',
  'gyan@gmail.com',
  'rahul@gmail.com',
  'dev@havenify.in'
];

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0',
    paddingRight: '0',
    borderRadius: '0',
    '& fieldset': {
      border: 'none'
    },
    '& input': {
      paddingLeft: '0',
      paddingTop: '10px',
      paddingRight: '10px',
      paddingBottom: '10px'
    }
  }
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: '0',
    borderRadius: '0',
    '& fieldset': {
      border: 'none'
    },
    '& .MuiAutocomplete-input': {
      padding: 0
    }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '0',
  minWidth: 'auto',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

const SendMailBody = ({ fileBlob, handleModal, file, setFile }) => {
  const theme = useTheme();
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const {
    uploadFiles,
    uploadFile,
    deleteFile,
    loading: isUploading
  } = useFileUpload();
  const handleSendEmail = async (values, { setSubmitting }) => {
    try {
      const response = await requestApi.sendMail({ data: values });
      if (response.status) {
        toast.success('Email sent successfully!');
        handleModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          from: 'dev@havenify.in',
          to: [],
          cc: [],
          bcc: [],
          subject: '',
          attachments: [],
          htmlContent: ''
        }}
        onSubmit={handleSendEmail}
      >
        {({ setFieldValue, handleChange, values, isSubmitting }) => (
          <Form>
            <Stack spacing={1}>
              <Autocomplete
                fullWidth
                options={options}
                value={values.from}
                onChange={(event, newValue) => setFieldValue('from', newValue)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label={'From'} />
                )}
              />
              <Box sx={{ borderBottom: 1, borderColor: theme.palette.divider }}>
                <Stack spacing={1} direction={'row'} pb={1}>
                  <Typography
                    variant="subtitle2"
                    pt={values.to.length ? 0.5 : null}
                  >
                    To
                  </Typography>
                  <StyledAutocomplete
                    fullWidth
                    multiple
                    options={options}
                    value={values.to}
                    onChange={(event, newValue) =>
                      setFieldValue('to', newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {!(showCc || showBcc) && (
                    <Stack direction="row" spacing={0.5}>
                      <Tooltip title="Add Cc recipients">
                        <StyledButton
                          size="small"
                          onClick={() => setShowCc(!showCc)}
                        >
                          Cc
                        </StyledButton>
                      </Tooltip>
                      <Tooltip title="Add Bcc recipients">
                        <StyledButton
                          size="small"
                          onClick={() => setShowBcc(!showBcc)}
                        >
                          Bcc
                        </StyledButton>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
                {showCc && (
                  <Stack spacing={1} direction={'row'} pb={1}>
                    <Typography
                      variant="subtitle2"
                      pt={values.cc.length ? 0.5 : null}
                    >
                      Cc
                    </Typography>
                    <StyledAutocomplete
                      multiple
                      fullWidth
                      options={options}
                      value={values.cc}
                      onChange={(event, newValue) =>
                        setFieldValue('cc', newValue)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                    {!showBcc && (
                      <Tooltip title="Add Bcc recipients">
                        <StyledButton
                          size="small"
                          onClick={() => setShowBcc(!showBcc)}
                        >
                          Bcc
                        </StyledButton>
                      </Tooltip>
                    )}
                  </Stack>
                )}
                {showBcc && (
                  <Stack spacing={1} direction={'row'} pb={1}>
                    <Typography
                      variant="subtitle2"
                      pt={values.bcc.length ? 0.5 : null}
                    >
                      Bcc
                    </Typography>
                    <StyledAutocomplete
                      multiple
                      fullWidth
                      options={options}
                      value={values.bcc}
                      onChange={(event, newValue) =>
                        setFieldValue('bcc', newValue)
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                    {!showCc && (
                      <Tooltip title="Add Cc recipients">
                        <StyledButton
                          size="small"
                          onClick={() => setShowCc(!showCc)}
                        >
                          Cc
                        </StyledButton>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </Box>
              <Box sx={{ borderBottom: 1, borderColor: theme.palette.divider }}>
                <StyledTextField
                  fullWidth
                  name="subject"
                  placeholder="Subject"
                  value={values.subject}
                  onChange={handleChange}
                />
              </Box>
              <QuillEditorField
                onChange={(value) => setFieldValue('htmlContent', value)}
                value={values.htmlContent}
              />
              {/* <Divider /> */}
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems={'center'}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Attachments name="images">
                    <AddPhotoAlternateIcon fontSize="small" />
                  </Attachments>
                  <Divider orientation="vertical" flexItem />
                  <Attachments
                    fileBlob={fileBlob}
                    setFile={setFile}
                    file={file}
                    name="documents"
                  >
                    <AttachFileIcon fontSize="small" />
                  </Attachments>
                </Stack>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={'1rem'} /> : null
                  }
                  endIcon={isSubmitting ? null : <SendIcon />}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SendMailBody;

import React from 'react';
import { Grid, Button, IconButton, Stack } from '@mui/material';
import { FieldArray } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ProgressColorSettings from './ProgressColorSettings';
import ColorPickerComponent from 'src/components/dynamicFields/ColorPickerComponent';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const AddPropComponent = ({
  propsOptions,
  setFieldValue,
  propPath,
  itemPath,
  handleBlur
}) => {
  const isOptionSelected = (option, propIndex) => {
    for (let i = 0; i < propIndex; i++) {
      if (itemPath?.props[i]?.name === option.name) {
        return true;
      }
    }
    return false;
  };
  const propsArray = itemPath?.props || [];

  return (
    <FieldArray name={`${propPath}.props`}>
      {({ push, remove }) => (
        <>
          {propsArray.map((prop, propIndex) => {
            const propOptions =
              propsOptions.find((p) => p.name === prop.name)?.options || [];
            return (
              <Grid item xs={12} key={propIndex}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} md={2}>
                    <CustomSelectFieldWithLabel
                      label="Select Prop"
                      value={prop?.name || ''}
                      options={propsOptions
                        .filter(
                          (propOption) =>
                            !isOptionSelected(propOption, propIndex)
                        )
                        .map((filteredPropOption) => ({
                          label: filteredPropOption.name,
                          value: filteredPropOption.name
                        }))}
                      onChange={(e) => {
                        setFieldValue(
                          `${propPath}.props[${propIndex}].name`,
                          e.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      name={`propName_${propIndex}`}
                    />
                  </Grid>
                  {itemPath?.props[propIndex].name == 'color' &&
                    itemPath?.component === 'progress' && (
                      <ProgressColorSettings
                        prop={prop}
                        setFieldValue={setFieldValue}
                        propPath={propPath}
                        propIndex={propIndex}
                        handleBlur={handleBlur}
                      />
                    )}
                  {itemPath?.props[propIndex].name === 'color' &&
                    itemPath?.component !== 'progress' && (
                      <Grid item xs={12} md={2} display={'flex'} columnGap={2}>
                        <ColorPickerComponent
                          title={'Select Background Color'}
                          color={
                            prop?.backgroundColor?.rgb || {
                              r: 255,
                              g: 255,
                              b: 255,
                              a: 1
                            }
                          }
                          onChange={(color) =>
                            setFieldValue(
                              `${propPath}.props[${propIndex}].backgroundColor`,
                              { ...color }
                            )
                          }
                          onBlur={handleBlur}
                        />
                        <ColorPickerComponent
                          title={'Select Text Color'}
                          color={
                            prop?.textColor?.rgb || {
                              r: 255,
                              g: 255,
                              b: 255,
                              a: 1
                            }
                          }
                          onChange={(color) =>
                            setFieldValue(
                              `${propPath}.props[${propIndex}].textColor`,
                              { ...color }
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                  {itemPath?.props[propIndex].name !== '' &&
                    itemPath?.props[propIndex].name !== 'color' && (
                      <Grid item xs={12} md={2}>
                        <CustomSelectFieldWithLabel
                          label="Prop Value"
                          value={prop?.value || ''}
                          options={propOptions.map((option) => ({
                            label: option,
                            value: option
                          }))}
                          onChange={(e) => {
                            setFieldValue(
                              `${propPath}.props[${propIndex}].value`,
                              e.target.value
                            );
                          }}
                          onBlur={handleBlur}
                          name={`${propPath}.props[${propIndex}].value`}
                        />
                      </Grid>
                    )}

                  <Grid item xs={12} md={1}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      {prop.specificRange === false && prop.name === 'color' && (
                        <>
                          <ColorPickerComponent
                            title="Select Bar Color"
                            color={prop?.barColor || ''}
                            onChange={(color) =>
                              setFieldValue(
                                `${propPath}.props[${propIndex}].barColor`,
                                color.hex
                              )
                            }
                            onBlur={handleBlur}
                          />
                          <ColorPickerComponent
                            title="Select Root Color"
                            color={prop?.rootColor || ''}
                            onChange={(color) =>
                              setFieldValue(
                                `${propPath}.props[${propIndex}].rootColor`,
                                color.hex
                              )
                            }
                            onBlur={handleBlur}
                          />
                        </>
                      )}
                      <IconButton
                        color="secondary"
                        onClick={() => remove(propIndex)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              disabled={itemPath?.props?.length === propsOptions.length}
              startIcon={<AddIcon />}
              onClick={() => push({ name: '' })}
            >
              Add Prop
            </Button>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default AddPropComponent;

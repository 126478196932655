import React from 'react';
import { Grid, TextField } from '@mui/material';
const SettingTextfield = ({
  handleBlur,
  setFieldValue,
  touched,
  errors,
  inputName,
  index,
  value
}) => {
  return (
    <>
      <Grid item xs={12} md={4}>
        <TextField
          label="Icon Url"
          onBlur={handleBlur}
          variant="outlined"
          fullWidth
          name={`data[${index}].${inputName}`}
          error={Boolean(
            touched.data &&
              touched.data[index]?.icon &&
              errors.data &&
              errors.data[index]?.icon
          )}
          helperText={
            touched.data &&
            touched.data[index] &&
            touched.data[index][inputName] &&
            errors.data &&
            errors.data[index] &&
            errors.data[index][inputName]
          }
          value={value}
          onChange={(e) =>
            setFieldValue(`data[${index}].${inputName}`, e.target.value)
          }
        />
      </Grid>
    </>
  );
};

export default SettingTextfield;

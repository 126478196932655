import {
  AccountBoxTwoTone,
  ExpandMoreTwoTone,
  InboxTwoTone,
  LockOpenTwoTone
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useRef, useState } from 'react';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, selectLoggedUserData } from 'src/slices/authSlice';
import LanguageSwitcher from './LanguageSwitcher';
import HeaderNotifications from '../Buttons/Notifications';
import CustomThemeSettings from '../Buttons/CustomThemeSettings';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.spacing(7)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

// const UserBoxDescription = styled(Typography)(
//   ({ theme }) => `
//         color: ${theme.palette.secondary.light}
// `
// );

function HeaderUserBox() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    employee = null,
    name = '',
    username = '',
    avatar = ''
  } = useSelector(selectLoggedUserData) || {};
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={name || username} src={avatar}>
          {(!avatar && name.charAt(0).toUpperCase()) ||
            username.charAt(0).toUpperCase()}
        </Avatar>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{name || username}</UserBoxLabel>
            {/* <UserBoxDescription variant="body2">
              {loggedUserData?.jobtitle}
            </UserBoxDescription> */}
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoTone
            sx={{
              ml: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        sx={{ zIndex: 100 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={name || username} src={avatar}>
            {(!avatar && name.charAt(0).toUpperCase()) ||
              username.charAt(0).toUpperCase()}
          </Avatar>
          <UserBoxText>
            <UserBoxLabel variant="body1">{name || username}</UserBoxLabel>
            {/* <UserBoxDescription variant="body2">
              {loggedUserData?.jobtitle}
            </UserBoxDescription> */}
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/management/user/profile`}
            component={NavLink}
          >
            <AccountBoxTwoTone fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleClose();
            }}
            to="applications/mailbox/inbox"
            component={NavLink}
          >
            <InboxTwoTone fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <LanguageSwitcher />
          {employee && employee.is_workspace_admin && (
            <ListItem
              button
              onClick={() => {
                handleClose();
              }}
              to="workspace-setting"
              component={NavLink}
            >
              <AdminPanelSettingsIcon fontSize="small" />
              <ListItemText primary={t('Admin Console')} />
            </ListItem>
          )}
          {isSm && (
            <>
              <ListItem
                onClick={() => {
                  handleClose();
                }}
                button
                to={`/projects`}
                component={NavLink}
              >
                <AccountTreeTwoToneIcon fontSize="small" />
                <ListItemText primary={t('Projects')} />
              </ListItem>
              <HeaderNotifications handleClose={handleClose} isSm={isSm} />
              <CustomThemeSettings handleClose={handleClose} isSm={isSm} />
            </>
          )}
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoTone sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserBox;

// import {
//   AccountBoxTwoTone,
//   AccountTreeTwoTone,
//   ExpandMoreTwoTone,
//   InboxTwoTone,
//   LockOpenTwoTone
// } from '@mui/icons-material';
// import {
//   Avatar,
//   Box,
//   Button,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
//   Popover,
//   Typography,
//   styled
// } from '@mui/material';
// import { useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import { logout, selectLoggedUserData } from 'src/slices/authSlice';
// import { useSelector, useDispatch } from 'react-redux';
// import LanguageSwitcher from './LanguageSwitcher';

// const UserBoxButton = styled(Button)(
//   ({ theme }) => `
//         padding-left: ${theme.spacing(1)};
//         padding-right: ${theme.spacing(1)};
// `
// );

// const MenuUserBox = styled(Box)(
//   ({ theme }) => `
//         background: ${theme.colors.alpha.black[5]};
//         padding: ${theme.spacing(2)};
// `
// );

// const UserBoxText = styled(Box)(
//   ({ theme }) => `
//         text-align: left;
//         padding-left: ${theme.spacing(1)};
// `
// );

// const UserBoxLabel = styled(Typography)(
//   ({ theme }) => `
//         font-weight: ${theme.typography.fontWeightBold};
//         color: ${theme.palette.secondary.main};
//         display: block;
// `
// );

// const UserBoxDescription = styled(Typography)(
//   ({ theme }) => `
//         color: ${theme.palette.secondary.light}
// `
// );

// function HeaderUserBox() {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const loggedUserData = useSelector(selectLoggedUserData);

//   const ref = useRef(null);
//   const [isOpen, setOpen] = useState(false);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleLogout = () => {
//     try {
//       //console.log("logout in collaspe sidebar")
//       handleClose();
//       dispatch(logout());
//       navigate('/');
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   return (
//     <>
//       <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
//         <Avatar
//           variant="rounded"
//           alt={loggedUserData.name}
//           src={loggedUserData.avatar}
//         />
//         <Box
//           component="span"
//           sx={{
//             display: { xs: 'none', md: 'inline-block' }
//           }}
//         >
//           <UserBoxText>
//             <UserBoxLabel variant="body1">{loggedUserData.name}</UserBoxLabel>
//             <UserBoxDescription variant="body2">
//               {loggedUserData?.jobtitle}
//             </UserBoxDescription>
//           </UserBoxText>
//         </Box>
//         <Box
//           component="span"
//           sx={{
//             display: { xs: 'none', sm: 'inline-block' }
//           }}
//         >
//           <ExpandMoreTwoTone
//             sx={{
//               ml: 1
//             }}
//           />
//         </Box>
//       </UserBoxButton>
//       <Popover
//         disableScrollLock
//         anchorEl={ref.current}
//         onClose={handleClose}
//         open={isOpen}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right'
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right'
//         }}
//       >
//         <MenuUserBox
//           sx={{
//             minWidth: 210
//           }}
//           display="flex"
//         >
//           <Avatar
//             variant="rounded"
//             alt={loggedUserData.name}
//             src={loggedUserData.avatar}
//           />
//           <UserBoxText>
//             <UserBoxLabel variant="body1">{loggedUserData.name}</UserBoxLabel>
//             <UserBoxDescription variant="body2">
//               {loggedUserData.jobtitle}
//             </UserBoxDescription>
//           </UserBoxText>
//         </MenuUserBox>
//         <Divider
//           sx={{
//             mb: 0
//           }}
//         />
//         <List
//           sx={{
//             p: 1
//           }}
//           component="nav"
//         >
//           <ListItem
//             onClick={() => {
//               handleClose();
//             }}
//             button
//             to={`/${location.pathname.split('/')[1]}/management/users/single/1`}
//             component={NavLink}
//           >
//             <AccountBoxTwoTone fontSize="small" />
//             <ListItemText primary={t('Profile')} />
//           </ListItem>
//           <ListItem
//             onClick={() => {
//               handleClose();
//             }}
//             button
//             to={`/${
//               location.pathname.split('/')[1]
//             }/applications/mailbox/inbox`}
//             component={NavLink}
//           >
//             <InboxTwoTone fontSize="small" />
//             <ListItemText primary={t('Inbox')} />
//           </ListItem>
//           <LanguageSwitcher />
//           {/* <ListItem
//             onClick={() => {
//               handleClose();
//             }}
//             button
//             to={`/${
//               location.pathname.split('/')[1]
//             }/applications/projects-board`}
//             component={NavLink}
//           >
//             <AccountTreeTwoTone fontSize="small" />
//             <ListItemText primary={t('Projects')} />
//           </ListItem> */}
//         </List>
//         <Divider />
//         <Box m={1}>
//           <Button color="primary" fullWidth onClick={handleLogout}>
//             <LockOpenTwoTone sx={{ mr: 1 }} />
//             {t('Sign out')}
//           </Button>
//         </Box>
//       </Popover>
//     </>
//   );
// }

// export default HeaderUserBox;

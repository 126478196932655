/* eslint-disable no-prototype-builtins */
import {
  CloseRounded,
  DoneTwoTone,
  FileUploadRounded,
  InfoTwoTone
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { requestApi } from 'src/mocks/request';
import {
  createBulkDataAsync,
  selectDynamicPageConfig
} from 'src/slices/dynamicSlice';
import * as XLSX from 'xlsx';
import ImportPreviewTable from './ImportPreviewTable';
import InsertionSummaryModal from './InsertionSummaryModal';
import NonExistingDocumentsPreview from './NonExistingDocumentsPreview';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const excludeHeaders = [
  'updatedAt',
  'createdAt',
  'updatedBy',
  'isDeleted',
  'isActive',
  'addedBy',
  'id',
  '_id',
  'version',
  '_v'
];

const UploadDialogBody = ({ open, handleModal }) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [importData, setImportData] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorPreviewOpen, setErrorPreviewOpen] = useState(false);
  const [isFieldDirty, setIsFieldDirty] = useState(false);

  const { fieldNames, entitySetting, schema, entity } = useSelector(
    selectDynamicPageConfig
  );

  const handleClose = () => setErrorPreviewOpen(false);
  const handleOpen = () => setErrorPreviewOpen(true);

  // filter imported data to preview
  const getFilteredData = useCallback(
    (data) => {
      if (!data) return { headers: [], filteredData: null };
      let includeHeaders = [];

      const createFields = entitySetting?.create_fields;
      if (Array.isArray(createFields) && createFields.length > 0) {
        includeHeaders = [...createFields];
      }
      const headers = new Set();

      const filteredData = data?.map((item) => {
        const filteredItem = {};
        Object.keys(item)?.forEach((key) => {
          const field = schema[key];

          if (field?.instance === 'Array' || field?.instance === 'Object') {
            // Exclude this key if its instance is 'Array' or 'Object'
            return;
          }

          if (createFields?.length === 0) {
            console.warn('Missing setting data');
            if (!excludeHeaders?.includes(key)) {
              headers.add(key);
              filteredItem[key] = item[key];
            }
          } else {
            if (includeHeaders?.includes(key)) {
              headers.add(key);
              filteredItem[key] = item[key];
            }
          }
        });
        return filteredItem;
      });
      return { headers: Array.from(headers), filteredData };
    },
    [entitySetting]
  );

  const handleFileChange = useCallback(
    (event) => {
      setImportData(null);
      setFieldErrors(null);

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const tableHeaders = jsonData[0];
          const formattedData = jsonData?.slice(1).map((row) => {
            const obj = {};
            tableHeaders?.forEach((key, index) => {
              const cellValue = row[index];
              if (cellValue !== undefined && cellValue !== '') {
                obj[key] = cellValue;
              }
            });
            return obj;
          });

          const { headers, filteredData } = getFilteredData(formattedData);
          console.log(headers, 'table headers');
          console.log(filteredData, 'filtered data');

          setTableHeaders(headers);
          setImportData(filteredData || null);
        };

        reader.readAsArrayBuffer(file);
      } else {
        console.warn('No file selected');
        setImportData(null);
      }
    },
    [getFilteredData]
  );

  const getObjectId = async (endPoint) => {
    try {
      console.log(entitySetting);
      const propertyName = entitySetting?.populate_fields?.find(
        (populate_field) => populate_field?.populate_field === endPoint
      );
      console.log(propertyName);
      const uniqueItems = [
        ...new Set(
          importData?.map((item) => item[propertyName?.populate_field])
        )
      ];

      const response = await requestApi.getData({
        endPoint,
        select: [propertyName?.field_show, 'id'],
        query: {
          [propertyName?.field_show]: {
            $in: uniqueItems
          }
        }
      });
      return response?.data?.data || response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const processImportData = useCallback(async () => {
    const objectIdFields = Object.values(schema)
      ?.filter((item) => item?.instance === 'ObjectID')
      ?.map((item) => item?.path);
    console.log(objectIdFields);

    const objectIdHeaderEndPoints = tableHeaders
      .filter((header) => objectIdFields.includes(header))
      .map((header) => ({
        header,
        endpoint: schema[header]?.options?.ref
      }));
    console.log(objectIdHeaderEndPoints);

    const objectIds = await Promise.all(
      objectIdHeaderEndPoints?.map(async ({ header, endpoint }) => {
        let data = await getObjectId(endpoint);
        return { header, data };
      })
    ).then((res) =>
      res?.reduce((acc, { header, data }) => {
        acc[header] = data;
        return acc;
      }, {})
    );
    console.log(objectIds, 'objectIds');

    let errors = [];

    console.log('importData', importData);
    const processedData = importData
      ?.map((item, rowIndex) => {
        const processedItem = { ...item };
        const booleanValues = { Yes: true, No: false };

        for (const key of Object.keys(processedItem)) {
          // Handle boolean values
          if (booleanValues.hasOwnProperty(processedItem[key])) {
            processedItem[key] = booleanValues[processedItem[key]];
          }

          const propertyName = entitySetting?.populate_fields?.find(
            (populate_field) => populate_field?.populate_field === key
          );

          // Handle ObjectId fields
          if (objectIdFields?.includes(key) && objectIds.hasOwnProperty(key)) {
            console.log(processedItem, 'processedItem');

            if (processedItem[key] === '') {
              delete processedItem[key];
            }

            const matchedObject = objectIds[key]?.find((obj) => {
              return (
                processedItem[key]?.trim() ===
                  obj[propertyName.field_show]?.trim() ||
                processedItem[key] === obj.id ||
                processedItem[key] === ''
              );
            });

            if (matchedObject) {
              processedItem[key] = matchedObject.id;
            } else {
              const errorObj = {
                rowIndex,
                value: processedItem[key]
              };

              // Find existing error object for this field or create a new one
              let fieldError = errors.find((error) => error.fieldName === key);
              if (!fieldError) {
                fieldError = {
                  fieldName: key,
                  endPoint: schema[key]?.options?.ref,
                  errors: []
                };
                errors.push(fieldError);
              }

              // Add error to the field error object
              fieldError.errors.push(errorObj);
            }
          }
        }

        return Object.keys(processedItem).length ? processedItem : null;
        // return processedItem;
      })
      .filter((item) => item);

    const filterErrors = (data) => {
      return data.filter((item) => {
        // Check if the `errors` array is not empty
        if (!item.errors || item.errors.length === 0) {
          return false;
        }

        // Check if all `errors` items have valid `value`
        return item.errors.every((error) => {
          return (
            error.value !== '' &&
            error.value !== undefined &&
            error.value !== null
          );
        });
      });
    };

    const filteredErrors = filterErrors(errors);
    console.log(processedData, 'processedData');

    return { processedData, errors: filteredErrors };
  }, [importData, schema, tableHeaders]);

  const checkFieldErrors = async () => {
    try {
      const { processedData, errors } = await processImportData();
      setFieldErrors(errors);
      console.log(errors, 'errors');

      if (errors?.length > 0) {
        handleOpen();
        console.warn(
          'There is mismatch in the upload data. Please resolve them before uploading.'
        );
        return;
      }
      setIsFieldDirty(false);
      setUploadData(processedData);
    } catch (error) {
      console.error('Error processing data:', error);
    }
  };

  const handleUpload = async () => {
    try {
      setUploading(true);

      await dispatch(
        createBulkDataAsync({ endPoint: entity, data: { data: uploadData } })
      );

      setImportData(null);
      setUploadData(null);
    } catch (error) {
      setIsSummaryDialogOpen(true);
      setErrorMessage(error);
      console.error('Error uploading data:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleSampleFile = useCallback(() => {
    // Example headers for the sample file
    const headers = fieldNames;
    const filteredHeaders = headers?.filter(
      (item) => !REMOVE_FIELDS.includes(item)
    );
    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet([filteredHeaders]);
    // Generate sample data
    const sampleData = [];
    for (let i = 0; i < 5; i++) {
      const rowData = [];
      filteredHeaders.forEach((header) => {
        const type = schema[header].instance;
        let value;
        switch (type) {
          case 'String':
            value = 'String Value';
            break;
          case 'Number':
            value = Math.floor(Math.random() * 100) + 1;
            break;
          case 'ObjectID':
            value = '663dd3f8ec554f707085b8ea';
            break;
          case 'Array':
            value = ['Item 1', 'Item 2', 'Item 3']; // Example array
            break;
          case 'Boolean':
            value = Math.random() < 0.5 ? 'Yes' : 'No'; // Random boolean
            break;
          case 'Date': {
            const startDate = new Date('2000-01-01');
            const endDate = new Date();
            const randomDate = new Date(
              startDate.getTime() +
                Math.random() * (endDate.getTime() - startDate.getTime())
            );
            value = randomDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
            break;
          }
          default:
            value = ''; // Default to empty string
        }
        rowData.push(value);
      });
      sampleData.push(rowData);
    }

    // Add sample data to the worksheet
    XLSX.utils.sheet_add_aoa(ws, sampleData, { origin: -1 }); // -1 means append after the existing data
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'SampleData');
    // Write the workbook to a file
    XLSX.writeFile(wb, 'sample.csv');
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setIsSummaryDialogOpen(true);
    }
  }, [errorMessage]);

  const isUploadDisabled = !(
    importData &&
    fieldErrors?.length === 0 &&
    !isFieldDirty
  );

  const isValidateDisabled =
    !fieldErrors || fieldErrors?.length > 0 || isFieldDirty;

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        open={open}
        onClose={handleModal}
      >
        <AppBar sx={{ position: 'relative', px: 3, py: 1 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleModal}>
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
              Upload CSV File
            </Typography>
            <Button autoFocus color="inherit" onClick={handleModal}>
              Save
            </Button>
          </Toolbar>
        </AppBar>

        <Box sx={{ width: '100%', p: 4 }}>
          <Paper
            sx={{
              width: '100%',
              maxWidth: { md: '1340px' },
              marginX: 'auto',
              padding: 4
            }}
          >
            <Stack direction="row" gap={2} alignItems="center">
              <TextField
                fullWidth
                id="csv-file"
                type="file"
                inputProps={{ accept: '.csv' }}
                onChange={handleFileChange}
                sx={{ flex: 1 }}
              />
              <LoadingButton
                fullWidth
                loading={uploading}
                loadingPosition="start"
                startIcon={<FileUploadRounded />}
                variant="contained"
                size="large"
                disabled={isUploadDisabled}
                onClick={handleUpload}
                sx={{
                  heigh: '100% !important',
                  minWidth: '140px',
                  width: 1 / 4
                }}
              >
                Upload
              </LoadingButton>
            </Stack>

            <Box>
              <Button
                variant="text"
                color="secondary"
                sx={{
                  marginBottom: 2,
                  '&:hover': {
                    backgroundColor: 'transparent' // Remove hover effect
                  }
                }}
                onClick={handleSampleFile}
              >
                Download Sample File (Click to download a sample CSV file)
              </Button>
            </Box>

            <Chip
              label={isValidateDisabled ? 'Validate Fields' : 'Validated'}
              color={isValidateDisabled ? 'primary' : 'success'}
              disabled={!importData}
              icon={isValidateDisabled ? <InfoTwoTone /> : <DoneTwoTone />}
              onClick={checkFieldErrors}
            />

            {importData && (
              <ImportPreviewTable
                tableHeaders={tableHeaders}
                importData={importData}
                setImportData={setImportData}
                setIsFieldDirty={setIsFieldDirty}
                errors={fieldErrors}
              />
            )}
          </Paper>
        </Box>
      </Dialog>
      {fieldErrors && fieldErrors?.length > 0 && (
        <NonExistingDocumentsPreview
          open={errorPreviewOpen}
          handleClose={handleClose}
          errors={fieldErrors}
        />
      )}
      <InsertionSummaryModal
        errorMessage={errorMessage}
        setIsSummaryDialogOpen={setIsSummaryDialogOpen}
        isSummaryDialogOpen={isSummaryDialogOpen}
      />
    </>
  );
};

export default UploadDialogBody;

/* eslint-disable react/prop-types */
import { DeleteTwoTone } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { REMOVE_FIELDS } from 'src/constant/constant';
import { getFieldType } from 'src/services/commonService';
import {
  selectDynamicPageConfig,
  selectStatus,
  updateBulkDataAsync
} from 'src/slices/dynamicSlice';
import { sanitizeFormData } from 'src/utils/sanitizeFormData';

const BulkEdit = ({ handleBulkEditPopover, selectedItems }) => {
  const { schema, entity, entitySetting, fieldNames } = useSelector(
    selectDynamicPageConfig
  );
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  const filterFieldNames = useMemo(() => {
    // Step 1: Filter out fields listed in REMOVE_FIELDS
    const filteredFields = fieldNames.filter(
      (fieldName) => !REMOVE_FIELDS.includes(fieldName)
    );

    // Step 2: Apply additional conditions using getFieldType
    return filteredFields.filter((fieldName) => {
      const { isArrayWithMultipleProperties } = getFieldType(fieldName, schema);
      return !isArrayWithMultipleProperties;
    });
  }, [fieldNames, getFieldType]);

  // Function to get available field names
  const getAvailableFieldNames = useCallback(
    (selectedFieldNames, currentFieldName) => {
      return filterFieldNames.filter(
        (field) =>
          field === currentFieldName || !selectedFieldNames.includes(field)
      );
    },
    [filterFieldNames]
  );

  const handlePushData = (push, selectedFieldNames) => {
    // Get the first available field name
    const availableFields = getAvailableFieldNames(selectedFieldNames, '');
    push({
      fieldName: availableFields[0],
      fieldValue: ''
    });
  };

  const handleFieldChange = (index, event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(`data[${index}].fieldName`, value);
    // Clear fieldValue when fieldName changes
    setFieldValue(`data[${index}].fieldValue`, '');
  };

  // Determine if "Add more" button should be disabled
  const isAddMoreDisabled = (values) => {
    const selectedFieldNames = values.data.map((item) => item.fieldName);
    const availableFields = getAvailableFieldNames(selectedFieldNames, '');
    return availableFields.length === 0;
  };

  const handleBulkUpdate = async (values) => {
    // Prepare the filter object to select the items for update
    const selectedItemsIds = selectedItems.map(
      (selectedItem) => selectedItem.id
    );
    const filter = {
      _id: { $in: selectedItemsIds }
    };

    // Construct the data object where each fieldName maps to its fieldValue
    const data = values.data.reduce((acc, item) => {
      acc[item.fieldName] = item.fieldValue;
      return acc;
    }, {});
    const { createData } = sanitizeFormData(data, schema, Object.keys(data));

    // Assuming the API endpoint and method to update data
    const payload = {
      endPoint: entity,
      data: { filter, data: createData },
      localUpdate: { data, ids: selectedItemsIds }
    };
    await dispatch(updateBulkDataAsync(payload));
    handleBulkEditPopover(null);
  };

  return (
    <Formik
      initialValues={{
        data: [
          {
            fieldName: filterFieldNames[0] || '',
            fieldValue: ''
          }
        ]
      }}
      onSubmit={handleBulkUpdate}
    >
      {({ values, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
        const selectedFieldNames = values.data.map((item) => item.fieldName);

        return (
          <form onSubmit={handleSubmit}>
            <FieldArray name="data">
              {({ insert, remove, push }) => (
                <Stack
                  spacing={2}
                  p={3}
                  alignItems="start"
                  maxWidth={800}
                  overflow="auto"
                  width="100%"
                >
                  {values.data.map((dataItem, index) => (
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      key={index}
                    >
                      <Box sx={{ width: 250 }}>
                        <CustomSelectField
                          label="Select Field"
                          name={`data[${index}].fieldName`}
                          value={dataItem.fieldName}
                          options={getAvailableFieldNames(
                            selectedFieldNames,
                            dataItem.fieldName
                          )}
                          onChange={(e) =>
                            handleFieldChange(index, e, setFieldValue)
                          }
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box sx={{ width: 250 }}>
                        <GetFieldBasedOnSchema
                          name={`data[${index}].fieldValue`}
                          label="Field Value"
                          fieldName={dataItem.fieldName}
                          entitySetting={entitySetting}
                          schema={schema}
                          value={dataItem.fieldValue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteTwoTone />
                      </IconButton>
                    </Stack>
                  ))}

                  <Stack direction="row" spacing={2} mt={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={isAddMoreDisabled(values) || status.updateBulk}
                      onClick={() => handlePushData(push, selectedFieldNames)}
                    >
                      Add more
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={status.updateBulk}
                      startIcon={
                        status.updateBulk && <CircularProgress size={'1rem'} />
                      }
                    >
                      Update
                    </Button>
                  </Stack>
                </Stack>
              )}
            </FieldArray>
          </form>
        );
      }}
    </Formik>
  );
};

export default BulkEdit;

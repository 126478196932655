import React from 'react';
import { DialogActions, Button, CircularProgress, Grid } from '@mui/material';

const DialogActionBody = ({
  handleDialog,
  isUpdatingOrCreating,
  selectedRowData
}) => {
  return (
    <DialogActions
      sx={{
        p: 3
      }}
    >
      <Grid container justifyContent={'center'} spacing={3}>
        <Grid
          item
          xs={12}
          lg={10}
          display={'flex'}
          justifyContent={'center'}
          gap={1}
        >
          <Button
            disabled={isUpdatingOrCreating}
            variant="contained"
            onClick={() => handleDialog()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            startIcon={
              isUpdatingOrCreating ? <CircularProgress size="1rem" /> : null
            }
            disabled={isUpdatingOrCreating}
          >
            {selectedRowData ? 'Update' : 'Add New'} Setting
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default DialogActionBody;

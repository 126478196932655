import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTodo, selectTodos } from 'src/slices/todosSlice';

const initialState = {
  title: '',
  isImportant: false,
  description: '',
  isComplete: false,
  tags: [],
  dueDate: ''
};

const useTodos = () => {
  const dispatch = useDispatch();
  const todos = useSelector(selectTodos);
  const [addForm, setAddForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const [todo, setTodo] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTodo((prevTodo) => ({
      ...prevTodo,
      [name]: value
    }));
  };

  const handleButtonChange = () => {
    setTodo((prevTodo) => ({ ...prevTodo, isImportant: !todo.isImportant }));
  };

  const handleSubmit = () => {
    dispatch(createTodo({ data: todo }));
    setTodo(initialState);
    setAddForm(false);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newTag = inputValue.trim();
      if (!todo?.tags.includes(newTag)) {
        const newTags = [...todo.tags, newTag];
        setTodo((prevTodo) => ({ ...prevTodo, tags: newTags }));
      }
      setInputValue('');
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    const newTags = todo?.tags?.filter((tag) => tag !== tagToDelete);
    setTodo((prevTodo) => ({ ...prevTodo, tags: newTags }));
  };

  return {
    todo,
    setTodo,
    inputValue,
    handleInputChange,
    activeTab,
    setActiveTab,
    setAddForm,
    todos,
    searchQuery,
    setSearchQuery,
    addForm,
    handleChange,
    handleSubmit,
    handleButtonChange,
    handleDeleteTag,
    handleKeyDown
  };
};

export default useTodos;

import { Box, Typography, IconButton, Stack, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeExpand,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';

const Expand = () => {
  const { expand } = useSelector(selectCustomSettingData);
  const dispatch = useDispatch();

  return (
    <Stack rowGap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="subtitle1">Stretch</Typography>
        <Tooltip title="Only available at large resolutions > 1600px (xl)">
          <InfoOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} />
        </Tooltip>
      </Stack>
      <Box
        onClick={() => dispatch(changeExpand())}
        width="100%"
        height="60px"
        border="1px solid lightGray"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
      >
        {expand ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <ChevronLeftIcon fontSize="small" />
            <Box>----------</Box>
            <ChevronRightIcon fontSize="small" />
          </Box>
        ) : (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronRightIcon fontSize="small" />
            <Box>----</Box>
            <ChevronLeftIcon fontSize="small" />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Expand;

// //import { useState } from 'react'
// import { Box, Typography, IconButton, Stack } from '@mui/material';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import InfoIcon from '@mui/icons-material/Info';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   changeExpand,
//   selectColor,
//   selectExpand
// } from 'src/slices/CustomThemeSettingSlice';
// const Expand = () => {
//   //const [expand,setExpand] = useState(false)
//   const expand = useSelector(selectExpand);
//   const dispatch = useDispatch();
//   const fontcolor = useSelector(selectColor);
//   return (
//     <Box>
//       <Stack direction={'row'} gap={1}>
//         <Typography variant="subtitle1">Stretch</Typography>
//         <IconButton fontSize={'small'}>
//           <InfoIcon />
//         </IconButton>
//       </Stack>
//       <Box
//         onClick={() => dispatch(changeExpand())}
//         width={'100%'}
//         height={'70px'}
//         border={'1px solid lightGray'}
//         display={'flex'}
//         justifyContent={'center'}
//         alignItems={'center'}
//         sx={{ cursor: 'pointer' }}
//       >
//         {expand && (
//           <Typography
//             component={'div'}
//             display={'flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             sx={{ color: fontcolor }}
//           >
//             <ChevronLeftIcon />{' '}
//             <Typography sx={{ color: fontcolor }} mb={0.2}>
//               -------
//             </Typography>
//             <ChevronRightIcon />
//           </Typography>
//         )}
//         {!expand && (
//           <Typography
//             component={'div'}
//             display={'flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             sx={{ color: fontcolor }}
//           >
//             <ChevronRightIcon />{' '}
//             <Typography sx={{ color: fontcolor }} mb={0.2}>
//               ---------------
//             </Typography>{' '}
//             <ChevronLeftIcon />
//           </Typography>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default Expand;

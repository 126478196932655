import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';
const getModuleIds = (data) => {
  const moduleId = data.data.modules.map((module) => module.id);
  return {
    ...data,
    data: {
      ...data.data,
      modules: moduleId
    }
  };
};
const initialState = {
  departments: [],
  status: {
    createDepartment: false,
    deleteDepartment: false,
    updateDepartment: false,
    getDepartment: false
  },
  isSubmitted: false //Add isSubmitted flag to track async operation done successfully
};

export const createDepartmentAsync = createAsyncThunk(
  'department/createData',
  async (data, { rejectWithValue }) => {
    try {
      const newData = getModuleIds(data);
      const response = await requestApi.createData(newData);
      if (response?.status === 'SUCCESS') {
        return { ...data, response: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDepartmentAsync = createAsyncThunk(
  'department/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDepartmentAsync = createAsyncThunk(
  'department/updateData',
  async (data, { rejectWithValue }) => {
    console.log(data, 'updateData');
    try {
      const newData = getModuleIds(data);
      const response = await requestApi.updateData(newData);
      if (response?.status === 'SUCCESS') {
        return { ...data, response: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteDepartmentAsync = createAsyncThunk(
  'department/deleteData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteData(data);
      if (response?.status === 'SUCCESS') {
        return data.id; // Returning the deleted task ID
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    refreshDepartmentSlice: (state) => {
      state.isSubmitted = false;
    },
    resetDepartmentState: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDepartmentAsync.pending, (state) => {
        state.status.createDepartment = true;
      })
      .addCase(createDepartmentAsync.fulfilled, (state, action) => {
        state.status.createDepartment = false;
        const addData = {
          ...action.payload.response,
          modules: action.payload.data.modules
        };
        state.departments.unshift(addData);
        state.isSubmitted = true;
      })
      .addCase(createDepartmentAsync.rejected, (state, action) => {
        state.status.createDepartment = false;
      })
      .addCase(getDepartmentAsync.pending, (state) => {
        state.status.getDepartment = true;
      })
      .addCase(getDepartmentAsync.fulfilled, (state, action) => {
        state.status.getDepartment = false;
        state.departments = action?.payload || [];
      })
      .addCase(getDepartmentAsync.rejected, (state) => {
        state.departments = [];
        state.status.getDepartment = false;
      })
      .addCase(updateDepartmentAsync.pending, (state) => {
        state.status.updateDepartment = true;
      })
      .addCase(updateDepartmentAsync.fulfilled, (state, action) => {
        state.status.updateDepartment = false;
        const replaceData = {
          ...action.payload.response,
          modules: action.payload.data.modules
        };
        state.departments = state.departments.map((department) =>
          department.id === replaceData.id ? replaceData : department
        );
        state.isSubmitted = true;
      })
      .addCase(updateDepartmentAsync.rejected, (state) => {
        state.status.updateDepartment = false;
      })
      .addCase(deleteDepartmentAsync.pending, (state) => {
        state.status.deleteDepartment = true;
      })
      .addCase(deleteDepartmentAsync.fulfilled, (state, action) => {
        state.status.deleteDepartment = false;
        state.departments = state.departments.filter(
          (department) => department.id !== action.payload
        ); // Removing the deleted department
      })
      .addCase(deleteDepartmentAsync.rejected, (state) => {
        state.status.deleteDepartment = false;
      });
  }
});

// selectors
export const { refreshDepartmentSlice, resetDepartmentState } = slice.actions;
export const selectDepartmentData = (state) => state.department;
export default slice.reducer;

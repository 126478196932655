/* eslint-disable react/prop-types */
import { Box, Divider, Drawer, Tab, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNoteEditorContext } from '../../contexts/NoteEditorContext';
import NoteEditor from '../Notes/NoteEditor';
import Notes from '../Notes/Notes';
import Notifications from '../Notifications/Notifications';
import { TabsWrapper } from '../Styled';
import Todos from '../Todos/Todos';

const NotificationsDrawer = ({ drawerOpen, toggleDrawer }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isCreateNote, setIsCreateNote } = useNoteEditorContext();
  const [currentTab, setCurrentTab] = useState('notifications');
  const tabs = [
    { value: 'notifications', label: t('Notifications') },
    { value: 'todos', label: t('Todos') },
    { value: 'notes', label: t('Notes') }
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
    setIsCreateNote(false);
  };

  return (
    <Drawer
      transitionDuration={300}
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          overflow: 'hidden'
        }}
      >
        {isCreateNote && <NoteEditor />}
        <Box sx={{ width: '440px', height: '100%' }}>
          <Box p={2}>
            <TabsWrapper
              centered
              onChange={handleTabsChange}
              value={currentTab}
              variant="fullWidth"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs?.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Box>
          <Divider />

          {currentTab === 'notifications' && <Notifications />}
          {currentTab === 'todos' && <Todos />}
          {currentTab === 'notes' && <Notes />}
        </Box>
      </Box>
    </Drawer>
  );
};

export default NotificationsDrawer;

import {
  Dialog,
  IconButton,
  Slide,
  TableCell,
  Tooltip,
  Typography
} from '@mui/material';
import React, { forwardRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useState } from 'react';
import { deleteSettingsAsync, selectStatus } from 'src/slices/settingSlice';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import SettingForm from 'src/content/setting/SettingForm';
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SettingTableActionCell = ({ settingData, selectedEntity }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const selectedRowData = useRef(null);
  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const handleDelete = () => {
    dispatch(
      deleteSettingsAsync({
        endPoint: 'setting',
        id: settingData.id,
        selectedEntity,
        deleteType: 'delete',
        setting_type: settingData.setting_type
      })
    );
  };
  const handleDialog = (id = null) => {
    if (id) {
      selectedRowData.current = settingData;
    }
    if (selectedRowData.current && !id) {
      selectedRowData.current = null;
    }
    setShowDialog(!showDialog);
  };
  const onClose = () => {
    //setSettingTitle("")
    setOpenConfirmationModal(false);
  };
  return (
    <>
      <TableCell>
        <Typography noWrap>
          <Tooltip title="Update">
            <IconButton
              color="primary"
              onClick={() => handleDialog(settingData.id)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="primary" onClick={handleConfirmationModal}>
              <DeleteTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
      </TableCell>
      <ConfirmationModal
        title={`Permanently Delete ${settingData.entity} setting ?`}
        description={
          'This will permanently remove the item. Are you sure you want to continue?'
        }
        open={openConfirmationModal}
        onClose={onClose}
        confirmBtnConfig={{
          name: 'Delete',
          variant: 'contained',
          color: 'error'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'primary'
        }}
        isLoading={status.deleteData}
        onConfirm={handleDelete}
      />
      <Dialog
        open={showDialog}
        sx={{ zIndex: '1000' }}
        TransitionComponent={Transition}
        fullScreen
      >
        <SettingForm
          selectedEntity={selectedEntity}
          selectedRowData={selectedRowData.current}
          handleDialog={handleDialog}
        />
      </Dialog>
    </>
  );
};

export default SettingTableActionCell;

import {
  CircularProgress,
  createFilterOptions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getAutoCompleteConfig,
  getOption
} from 'src/services/AutoCompleteService';
import useRefMounted from 'src/hooks/useRefMounted';
import { getAllRequiredFields } from 'src/services/commonService';
import { requestApi } from 'src/mocks/request';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const useAutocompleteLogic = ({
  fieldName,
  itemTableHead,
  schema,
  isMultiple,
  value,
  onChange,
  entitySetting,
  onBlur,
  error,
  touched,
  required,
  label,
  name,
  readOnly,
  placeholder
}) => {
  const filter = createFilterOptions();
  const [options, setOptions] = useState(() => {
    if (isMultiple) {
      return value ? value : [];
    } else {
      return value ? [value] : [];
    }
  });
  const [query, setQuery] = useState('');
  const isTyping = useRef(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addOptLoading, setAddOptLoading] = useState(false);
  const selectedInputValue = useRef('');
  const isMounted = useRefMounted();
  const refEntity = isMultiple
    ? schema[itemTableHead || fieldName]?.$embeddedSchemaType?.options?.ref
    : schema[itemTableHead || fieldName]?.options?.ref;
  const { schema: refSchema } = useEntityPageConfig(refEntity);
  const {
    query: defaultQuery,
    fieldShow,
    populateString
  } = useMemo(() => {
    if (refSchema) {
      return getAutoCompleteConfig({
        fieldName,
        entitySetting,
        itemTableHead,
        refSchema,
        schema
      });
    } else {
      return { query: {}, fieldShow: null, populateString: '' };
    }
  }, [fieldName, itemTableHead, refSchema, entitySetting, schema]);

  const handleFetchOption = useCallback(() => {
    if (!isTyping.current) return;
    if (refEntity && fieldShow) {
      getOption({
        endPoint: refEntity,
        populateString,
        fieldShow,
        defaultQuery,
        value,
        setLoading,
        setOptions,
        isMounted,
        query: query.trim()
      });
    }
  }, [
    refEntity,
    fieldShow,
    defaultQuery,
    value,
    setLoading,
    setOptions,
    isMounted,
    query
  ]);

  useEffect(() => {
    let clearTime = setTimeout(() => {
      handleFetchOption();
    }, 500);
    return () => {
      clearTimeout(clearTime);
    };
  }, [query]);

  const handleFocus = () => {
    isTyping.current = true;
  };

  const handleBlur = (e) => {
    isTyping.current = false;
    onBlur(e);
  };

  const handleQueryChange = (event, value) => {
    setLoading(true);
    setQuery(value);
    const trimmedValue = value.trim().toLowerCase();
    const currentQuery = query.trim().toLowerCase();
    if (trimmedValue === currentQuery) {
      isTyping.current = false;
      setLoading(false);
    } else if (trimmedValue !== currentQuery && !isTyping.current) {
      isTyping.current = true;
    }
  };

  const handOnOpen = () => {
    isTyping.current = true;
    handleFetchOption();
  };

  const handleDrawer = (query = null, event = null) => {
    if (event) {
      event.stopPropagation();
    }
    selectedInputValue.current = query;
    setDrawerOpen(!drawerOpen);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const addOption = async () => {
    setAddOptLoading(true);
    const payLoad = {
      refEntity,
      data: {
        [fieldShow]: query.trim()
      }
    };
    try {
      const response = await requestApi.createData(payLoad);
      if (response.status === 'SUCCESS') {
        setOptions((prevOptions) => [...prevOptions, response.data]);
        onChange(isMultiple ? [...value, response.data] : response.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setAddOptLoading(false);
    }
  };

  const CheckRequiredFields = () => {
    selectedInputValue.current = query.trim();
    // let refSchema = allSchemaRef.current[endPoint];
    let requiredFields = getAllRequiredFields(
      Object.keys(refSchema),
      refSchema
    );
    if (requiredFields.length === 1) {
      if (requiredFields[0] === fieldShow) {
        addOption();
      } else {
        handleModal();
      }
    } else if (requiredFields.length > 1) {
      handleModal();
    } else {
      handleDrawer();
    }
  };

  const handleFilterOption = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === option[fieldShow]
    );
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        isNew: true
      });
    }

    return filtered;
  };

  const handlChange = (event, newValue) => {
    isTyping.current = false;
    setLoading(false);
    if (isMultiple && newValue.some((item) => item && item.inputValue)) {
      CheckRequiredFields();
    } else if (newValue?.inputValue) {
      // Handle case when newValue is a new single object with inputValue
      CheckRequiredFields();
    } else {
      // Handle case when newValue is a single existing option
      onChange(newValue);
    }
  };

  const handleRenderOption = (props, option, { index }) => {
    if (loading && index !== 0) {
      return null; // Skip rendering for non-first options when loading
    }

    return (
      <li {...props}>
        {index === 0 && loading && 'Loading options...'}
        {!loading &&
          (option.isNew ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AddIcon fontSize="small" />
                <span>{`Add "${option.inputValue}"`}</span>
              </Stack>
              <Tooltip title={`Fill all ${refEntity} details`}>
                <IconButton
                  size="small"
                  onClick={(event) => handleDrawer(query, event)}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            option[fieldShow]
          ))}
      </li>
    );
  };

  const handleRenderInput = (params) => (
    <TextField
      sx={{ p: 0 }}
      {...params}
      label={label}
      placeholder={placeholder ? placeholder : 'Search...'}
      inputProps={{ ...params.inputProps, name, readOnly }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      required={required}
      error={Boolean(touched && error?.id)}
      helperText={(touched && error?.id) || ''}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {addOptLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : (
              params.InputProps.endAdornment
            )}
          </>
        ),
        style: {
          paddingRight: addOptLoading ? 10 : 60
        }
      }}
    />
  );

  return {
    options,
    query,
    drawerOpen,
    openModal,
    fieldShow,
    refEntity,
    selectedInputValue,
    handleDrawer,
    handleModal,
    setOptions,
    handleQueryChange,
    handOnOpen,
    handleFilterOption,
    handlChange,
    handleRenderOption,
    handleRenderInput
  };
};

export default useAutocompleteLogic;

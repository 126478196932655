import React from 'react';
import { CardHeader, CardContent, Card, Divider } from '@mui/material';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

const WarehouseCard = () => {
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();
  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Warehouse" />
        <Divider />
        <CardContent>
          <GetFieldBasedOnSchema
            name={'warehouse'}
            fieldName={'warehouse'}
            entitySetting={entitySetting}
            schema={schema}
            value={values['warehouse']}
            error={errors['warehouse']}
            touched={touched['warehouse']}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default WarehouseCard;

import { requestApi } from 'src/mocks/request';
import { escapeRegexSpecialChars, getPopulateString } from './commonService';

export const getOption = async ({
  endPoint,
  defaultQuery,
  populateString,
  fieldShow,
  setLoading,
  setOptions,
  isMounted,
  value,
  query
}) => {
  try {
    setLoading(true);
    let queryObject = {
      ...defaultQuery,
      [fieldShow]: { $regex: escapeRegexSpecialChars(query), $options: 'i' }
    };
    const response = await requestApi.getData({
      endPoint,
      query: queryObject,
      populate: populateString,
      pagination: true
    });

    if (isMounted.current) {
      if (response?.status === 'SUCCESS') {
        setOptions((prevOptions) => {
          let newOptions = response?.data?.data || [];
          if (value && Array.isArray(value)) {
            let filterOption = newOptions.filter(
              (newOption) =>
                !value.some(
                  (existingOption) => existingOption.id === newOption.id
                )
            );
            newOptions = [...filterOption, ...value];
          }

          return newOptions;
        });
      } else {
        if (isMounted.current) {
          setOptions((preValue) =>
            value && Array.isArray(value) ? value : []
          );
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (isMounted.current) {
      setLoading(false);
    }
  }
};

export const getAutoCompleteConfig = ({
  fieldName,
  entitySetting,
  itemTableHead = null,
  refSchema,
  schema
}) => {
  const foundData = entitySetting?.populate_fields?.find(
    (populateFieldItem) => {
      return populateFieldItem?.populate_field?.includes('.')
        ? populateFieldItem?.populate_field === `${fieldName}.${itemTableHead}`
        : populateFieldItem?.populate_field === fieldName;
    }
  );

  //find defaulQuery
  const query = schema?.[itemTableHead || fieldName]?.options?.query || {};
  // Extract schema keys
  const schemaKeys = Object.keys(refSchema);
  const populateString = getPopulateString(refSchema, schemaKeys);

  // If foundData has field_show, return it immediately
  if (foundData?.field_show) {
    return {
      query,
      populateString,
      fieldShow: foundData.field_show
    };
  }

  // Default fieldShow if no specific field found
  const defaultFieldShow = 'id';

  // Determine fieldShow based on schema keys
  const foundFieldShow = schemaKeys.includes('name')
    ? 'name'
    : schemaKeys.includes('title')
    ? 'title'
    : defaultFieldShow;

  // Return the configuration
  return {
    query,
    fieldShow: foundFieldShow,
    populateString
  };
};

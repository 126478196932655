import { Box, Stack, Typography, useTheme, ButtonBase } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeMode,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';

const Mode = () => {
  const { color, mode: activeMode } = useSelector(selectCustomSettingData);
  const dispatch = useDispatch();
  const theme = useTheme();

  const mode = [
    {
      icon: LightModeIcon,
      modeType: 'PureLightTheme'
    },
    {
      icon: NightsStayIcon,
      modeType: 'DarkSpacesTheme'
    }
  ];

  return (
    <Stack rowGap={1}>
      <Typography variant="subtitle1">Mode</Typography>
      <Box gap={1} display="flex">
        {mode.map((each, index) => (
          <ButtonBase
            key={`mode_${index}`}
            onClick={() => dispatch(changeMode(each.modeType))}
            sx={{
              width: '50%',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              // backgroundColor:
              //   each.modeType === activeMode
              //     ? `rgba(${parseInt(color.substring(1, 3), 16)}, ${parseInt(
              //         color.substring(3, 5),
              //         16
              //       )}, ${parseInt(color.substring(5, 7), 16)}, 0.1)`
              //     : 'transparent',
              border: `1px solid ${theme.palette.divider}`
              // transition: 'background-color 0.3s ease'
            }}
          >
            <each.icon
              sx={{
                color:
                  each.modeType === activeMode
                    ? color
                    : theme.palette.text.disabled
              }}
              fontSize="medium"
            />
          </ButtonBase>
        ))}
      </Box>
    </Stack>
  );
};

export default Mode;

import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  Slide,
  useTheme
} from '@mui/material';
import AccordianGrid from './AccordianGrid';
import AccordianTable from './AccordianTable';
import { forwardRef } from 'react';
import DialogBody from './DialogBody';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AccordionForm = ({
  fieldName,
  schema,
  value,
  touched,
  error,
  name,
  entitySetting
}) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };
  const { itemSchema, itemTableHeads } = useMemo(() => {
    let itemSchema = {};
    if (schema?.[fieldName]?.instance === 'Object') {
      itemSchema = schema?.[fieldName].schema;
    } else {
      itemSchema = schema[fieldName]?.$embeddedSchemaType?.schema?.paths;
    }

    const itemTableHeads = Object.keys(itemSchema).filter((key) => {
      let options = getFieldOptions(key, itemSchema);
      return key !== '_id' && key !== 'id' && !options?.hidden;
    });

    return {
      itemSchema,
      itemTableHeads
    };
  }, [fieldName, schema]);
  return (
    <>
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ pb: 0 }}>
          <CardHeader
            sx={{ background: theme.colors.alpha.black[5] }}
            title={
              <Typography variant="h5" textTransform={'capitalize'}>
                {capitalizeAndRemoveChar(fieldName)}
              </Typography>
            }
            action={
              <Button size="small" variant="text" onClick={handleDialog}>
                Bulk Action
              </Button>
            }
          />
          <Divider />
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            {schema?.[fieldName]?.instance === 'Object' ? (
              <AccordianGrid
                itemTableHeads={itemTableHeads}
                fieldName={fieldName}
                entitySetting={entitySetting}
                value={value}
                schema={itemSchema}
                touched={touched}
                error={error}
              />
            ) : (
              <AccordianTable
                itemTableHeads={itemTableHeads}
                schema={itemSchema}
                entitySetting={entitySetting}
                value={value}
                fieldName={fieldName}
                touched={touched}
                error={error}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        open={openDialog}
        onClose={handleDialog}
      >
        <DialogBody
          handleDialog={handleDialog}
          fieldName={fieldName}
          itemSchema={itemSchema}
          name={name}
          value={value}
        />
      </Dialog>
    </>
  );
};

export default AccordionForm;

/* eslint-disable react/prop-types */
import { Box, Tab } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabsWrapper } from '../Styled';

const TodosTabs = ({ activeTab, setActiveTab, setPage, isFetchMore }) => {
  const { t } = useTranslation();
  const tabs = [
    { value: 0, label: t('All') },
    { value: 1, label: t('Pending') },
    { value: 2, label: t('Done') }
  ];

  const handleTabsChange = (_event, value) => {
    setActiveTab(value);
    setPage(0);
    isFetchMore.current = false;
  };

  return (
    <Box>
      <TabsWrapper
        centered
        onChange={handleTabsChange}
        value={activeTab}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs?.map((tab) => (
          <Tab key={tab?.value} label={tab?.label} value={tab?.value} />
        ))}
      </TabsWrapper>
    </Box>
  );
};

export default TodosTabs;

import { getFieldType } from 'src/services/commonService';

/**
 * Retrieves the options object for a given field name in the schema.
 *
 * @param {string} fieldName - The name of the field to retrieve options for.
 * @param {object} schema - The schema object containing field definitions.
 * @returns {object|null} - Returns the options object if it exists, otherwise null.
 */
export const getFieldOptions = (fieldName, schema) => {
  // Retrieve the schema definition for the given field name
  const fieldSchema = schema[fieldName];

  // Return null if the field schema is not defined
  if (!fieldSchema) {
    return null;
  }

  // Return the options object based on the field type
  switch (fieldSchema.instance) {
    case 'String':
    case 'Number':
    case 'Boolean':
    case 'Date':
    case 'ObjectID':
      return fieldSchema.options || null;

    case 'Array': {
      // Get details about the array type
      const { isArray } = getFieldType(fieldName, schema);
      if (isArray) {
        // Return options for the embedded schema type if available
        return fieldSchema.$embeddedSchemaType?.options || null;
      }
      return null;
    }

    default:
      return null;
  }
};

import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ItemsTable from 'src/components/ViewComponents/ItemTable';
import AddressBlock from 'src/components/ViewComponents/AddressBlock';
import LabelValueDisplay from 'src/components/ViewComponents/LableValueDisplay';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ViewFooter from 'src/components/ViewComponents/ViewFooter';
import TermsAndCondition from 'src/components/ViewComponents/TermsAndCondition';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const PurchaseOrderView = ({ data }) => {
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const { t } = useTranslation();
  const {
    number: docNumber,
    date: orderDate,
    total: grandTotal,
    subTotal,
    taxTotal,
    items = [],
    payment_terms,
    delivery_terms,
    country_code,
    supplier,
    warehouse,
    workspace
  } = data;
  return (
    <Stack rowGap={2}>
      <Typography textAlign={'center'} color={'primary'} variant={'h3'} mb={1}>
        {capitalizeAndRemoveChar(`${entity} Order`)}
      </Typography>
      <Grid container spacing={2}>
        {workspace?.company?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              addressTitle={'Buyer Address'}
              countryCode={country_code}
              address={data?.workspace?.company?.address}
            />
          </Grid>
        )}
        {supplier?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              countryCode={country_code}
              addressTitle={'Supplier Address'}
              address={data?.supplier?.address}
            />
          </Grid>
        )}
        {warehouse?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              addressTitle={'Shipping Address'}
              countryCode={country_code}
              address={data?.warehouse?.address}
            />
          </Grid>
        )}
      </Grid>
      <Stack spacing={1}>
        <Typography variant="subtitle1" gutterBottom>
          {t('Order Details')}
          {' : '}
        </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          rowGap={1}
        >
          {docNumber && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay label={t('Order No')} value={docNumber} />
            </Box>
          )}
          {items?.length && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('No of items')}
                value={items.length}
              />
            </Box>
          )}
          {orderDate && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('Order Date')}
                value={moment(orderDate).format('DD-MM-YYYY')}
              />
            </Box>
          )}
          {payment_terms && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('Payment Terms')}
                value={payment_terms?.payment_type}
              />
            </Box>
          )}
          {delivery_terms && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('Delivery Terms')}
                value={delivery_terms?.delivery_method}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      {items.length && <ItemsTable schema={schema} data={data} />}
      {subTotal !== undefined &&
        subTotal !== null &&
        taxTotal !== undefined &&
        taxTotal !== null &&
        grandTotal !== undefined &&
        grandTotal !== null && (
          <ViewFooter
            subTotal={subTotal}
            taxTotal={taxTotal}
            grandTotal={grandTotal}
          />
        )}
      {(payment_terms || delivery_terms) && (
        <TermsAndCondition
          payment_terms={payment_terms}
          delivery_terms={delivery_terms}
        />
      )}
    </Stack>
  );
};

export default PurchaseOrderView;

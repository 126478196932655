import { Autocomplete, Checkbox, Grid, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import SelectedChips from './SelectedChips';
import { getAllRequiredFields } from 'src/services/commonService';
import { REMOVE_FIELDS } from 'src/constant/constant';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FieldSelector = ({ fieldNames, setting_type, schema }) => {
  const { touched, errors, values, setFieldValue, handleBlur } =
    useFormikContext();
  const { filterFieldNames, requiredFieldNames } = useMemo(() => {
    let filterFieldNames = fieldNames;
    if (['create_fields', 'edit_fields'].includes(setting_type)) {
      filterFieldNames = fieldNames.filter(
        (fieldName) => !REMOVE_FIELDS.includes(fieldName)
      );
    }
    const requiredFieldNames = getAllRequiredFields(fieldNames, schema);
    return { filterFieldNames, requiredFieldNames };
  }, [fieldNames]);
  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          multiple
          value={values.data}
          onChange={(event, newValue) => setFieldValue('data', newValue)}
          options={filterFieldNames}
          disableCloseOnSelect
          limitTags={2}
          getLimitTagsText={(number) => `+${number}`}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
              {requiredFieldNames.includes(option) && '*'}
            </li>
          )}
          renderTags={(value, getTagProps) => (
            <SelectedChips
              requiredFieldNames={requiredFieldNames}
              value={value}
              getTagProps={getTagProps}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={setting_type}
              placeholder="Search fieldName..."
              onBlur={handleBlur}
              error={Boolean(touched?.data && errors?.data)}
              helperText={touched?.data && errors?.data ? errors.data : ''}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default FieldSelector;

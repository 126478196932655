import { Box, Stack, Typography, useTheme, ButtonBase } from '@mui/material';
import ContrastIcon from '@mui/icons-material/Contrast';
import FlakyIcon from '@mui/icons-material/Flaky';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeContrast,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';

const Contrast = () => {
  const { color, contrast: activeContrast } = useSelector(
    selectCustomSettingData
  );
  const dispatch = useDispatch();
  const theme = useTheme();

  const contrast = [
    {
      icon: ContrastIcon,
      type: 'low'
    },
    {
      icon: FlakyIcon,
      type: 'high'
    }
  ];

  return (
    <Stack rowGap={1}>
      <Typography variant="subtitle1">Contrast</Typography>
      <Box gap={1} display="flex">
        {contrast.map((each, index) => (
          <ButtonBase
            key={`contrast_${index}`}
            onClick={() => dispatch(changeContrast(each.type))}
            sx={{
              width: '50%',
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              // backgroundColor:
              //   each.type === activeContrast
              //     ? theme.palette.common.white
              //     : 'transparent',
              border: `1px solid ${theme.palette.divider}`
              // boxShadow:
              //   each.type === activeContrast ? theme.shadows[4] : 'none',
              // transition: 'box-shadow 0.3s ease, background-color 0.3s ease',
              // '&:hover': {
              //   backgroundColor: theme.palette.action.hover
              // }
            }}
          >
            <each.icon
              sx={{
                color:
                  each.type === activeContrast
                    ? color
                    : theme.palette.text.disabled
              }}
              fontSize="medium"
            />
          </ButtonBase>
        ))}
      </Box>
    </Stack>
  );
};

export default Contrast;

// //import { useState } from "react"
// import ContrastIcon from '@mui/icons-material/Contrast';
// import FlakyIcon from '@mui/icons-material/Flaky';
// import { Box, Stack, Typography } from '@mui/material';
// import {
//   selectContrast,
//   changeContrast,
//   selectColor
// } from 'src/slices/CustomThemeSettingSlice';
// import { useSelector, useDispatch } from 'react-redux';
// const Contrast = () => {
//   //const [activeContrast,setActiveContrast] = useState("low")
//   const activeContrast = useSelector(selectContrast);
//   const color = useSelector(selectColor);
//   const dispatch = useDispatch();
//   const contrast = [
//     {
//       id: 1,
//       icon: ContrastIcon,
//       type: 'low'
//     },
//     {
//       id: 2,
//       icon: FlakyIcon,
//       type: 'high'
//     }
//   ];
//   return (
//     <Stack rowGap={1}>
//       <Typography variant="subtitle1">Contrast</Typography>
//       <Box gap={1} display={'flex'}>
//         {contrast.map((each) => (
//           <Box
//             key={each.id}
//             width={'50%'}
//             height={'90px'}
//             display={'flex'}
//             justifyContent={'center'}
//             alignItems={'center'}
//             borderRadius={1}
//             sx={{
//               backgroundColor:
//                 each.type === activeContrast ? 'white' : 'whiteSmoke',
//               border: '1px solid lightGray'
//             }}
//             onClick={() => dispatch(changeContrast(each.type))}
//           >
//             {
//               <each.icon
//                 sx={{ color: each.type === activeContrast ? color : '' }}
//                 fontSize="medium"
//               />
//             }
//           </Box>
//         ))}
//       </Box>
//     </Stack>
//   );
// };

// export default Contrast;

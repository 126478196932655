import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import React from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

const CreateEditPageLoader = () => {
  return (
    <>
      <PageTitleWrapper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={4} sm={6}>
            <Skeleton variant="text" sx={{ width: '60%' }} />
            <Skeleton variant="text" sx={{ width: '100%' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={'flex'}
            justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
          >
            <Skeleton variant="rounded" width={150} height={40} />
          </Grid>
        </Grid>
      </PageTitleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                {Array.from({ length: 11 }).map((_, index) => (
                  <Grid item xs={12} md={index === 10 ? 12 : 6} key={index}>
                    <Skeleton
                      variant="rounded"
                      width={'100%'}
                      height={index === 10 ? 100 : 56}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateEditPageLoader;

import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from 'src/App';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { NoteEditorProvider } from 'src/contexts/NoteEditorContext';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import ScrollTop from 'src/hooks/useScrollTop';
import 'src/mocks';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';

ReactDOM.render(
  <HelmetProvider>
    <ToastContainer
      // enableMultiContainer
      autoClose={2000}
      // containerId="first"
      position="top-center"
    />
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AuthProvider>
            <NoteEditorProvider>
              {/* <ToastContainer> */}
              <App />
              {/* </ToastContainer> */}
            </NoteEditorProvider>
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
      {/* </PersistGate> */}
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Divider,
  Tooltip,
  IconButton,
  Badge,
  Typography
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ColumnFilterCheckbox from './ColumnFilterCheckbox';
import {
  selectColumnFilterFieldname,
  selectDynamicPageConfig,
  setColumnFilterFieldname
} from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import CustomPopover from 'src/components/CustomPopover';
import Scrollbar from 'src/components/Scrollbar';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

const ColumnFilter = () => {
  const { endPoint } = useParams();
  const dispatch = useDispatch();
  const columnFilterFieldname = useSelector(selectColumnFilterFieldname);
  const [anchorEl, setAnchorEl] = useState(null);
  const { fieldNames, entitySetting } = useSelector(selectDynamicPageConfig);
  const [searchText, setSearchText] = useState('');

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const fieldsToDisplay = () => {
    if (entitySetting?.show_fields?.length > 0) {
      return fieldNames.filter((field) =>
        entitySetting.show_fields.includes(field)
      );
    }
    return fieldNames;
  };

  const filteredColumns = fieldsToDisplay().filter((fieldName) =>
    fieldName.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleColumnSwitchChange = (e) => {
    const fieldName = e.target.value;
    const isChecked = e.target.checked;
    const prevFilteredFieldNames = columnFilterFieldname;
    let updatedFilteredFieldNames;
    if (isChecked) {
      updatedFilteredFieldNames = [...prevFilteredFieldNames, fieldName];
    } else {
      updatedFilteredFieldNames = prevFilteredFieldNames.filter(
        (name) => name !== fieldName
      );
    }

    localStorage.setItem(
      `filteredFieldNames_${endPoint}`,
      JSON.stringify(updatedFilteredFieldNames)
    );
    dispatch(setColumnFilterFieldname(updatedFilteredFieldNames));
  };

  const handleSelectAll = () => {
    if (allSelected) {
      dispatch(setColumnFilterFieldname([]));
      localStorage.setItem(
        `filteredFieldNames_${endPoint}`,
        JSON.stringify([])
      );
    } else {
      dispatch(setColumnFilterFieldname(filteredColumns));
      localStorage.setItem(
        `filteredFieldNames_${endPoint}`,
        JSON.stringify(filteredColumns)
      );
    }
  };

  useEffect(() => {
    const storedFilteredFieldNames = localStorage.getItem(
      `filteredFieldNames_${endPoint}`
    );
    if (storedFilteredFieldNames) {
      const parsedFilteredFieldNames = JSON.parse(storedFilteredFieldNames);

      dispatch(setColumnFilterFieldname(parsedFilteredFieldNames));
    } else {
      dispatch(setColumnFilterFieldname(filteredColumns));
    }
  }, [fieldNames, setColumnFilterFieldname]);
  let allSelected = columnFilterFieldname.length === filteredColumns.length;
  let anyColumnUnchecked =
    columnFilterFieldname.length < filteredColumns.length;
  return (
    <>
      <Badge
        color="error"
        variant="dot"
        invisible={!anyColumnUnchecked} // Show badge if any column is unchecked
      >
        <Tooltip title="Column Filter">
          <IconButton color="primary" onClick={handlePopoverOpen}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Badge>
      <CustomPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handlePopoverClose}
      >
        <Box p={2}>
          <TextField
            id="outlined-basic"
            label="Find Column"
            variant="outlined"
            autoComplete="false"
            placeholder="Column Title"
            value={searchText}
            onChange={handleSearchInputChange}
          />
          <Scrollbar maxHeight={300}>
            <Stack mt={1}>
              {filteredColumns.length === 0 ? (
                <Typography variant="body2" color="error">
                  No columns found.
                </Typography>
              ) : (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allSelected}
                        onChange={handleSelectAll}
                      />
                    }
                    label={allSelected ? 'Remove All' : 'Select All'}
                  />
                  <Divider />
                  {filteredColumns.map((fieldName, index) => (
                    <ColumnFilterCheckbox
                      key={`fieldName_${index}`}
                      fieldName={fieldName}
                      handleColumnSwitchChange={handleColumnSwitchChange}
                      index={index}
                    />
                  ))}
                </>
              )}
            </Stack>
          </Scrollbar>
        </Box>
      </CustomPopover>
    </>
  );
};

export default ColumnFilter;

import { AddCircleTwoTone, CloseTwoTone } from '@mui/icons-material';
import { Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchTodos, setTodos } from 'src/slices/todosSlice';
import Scrollbar from '../Scrollbar';
import RenderTodos from './RenderTodos';
import SearchBar from './SearchBar';
import TodosForm from './TodosForm';
import TodosTabs from './TodosTabs';
import useTodos from './todos.hook';

const Todos = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const isFetchMore = useRef();

  const {
    activeTab,
    setActiveTab,
    addForm,
    searchQuery,
    setAddForm,
    setSearchQuery
  } = useTodos();

  useEffect(() => {
    const queryObject = {
      $or: [
        { title: { $regex: searchQuery, $options: 'i' } },
        { description: { $regex: searchQuery, $options: 'i' } },
        { tags: { $regex: searchQuery, $options: 'i' } }
      ]
    };

    const queryBasedOnTab =
      activeTab === 1
        ? { isComplete: false }
        : activeTab === 2
        ? { isComplete: true }
        : {};

    dispatch(
      fetchTodos({ query: { ...queryObject, ...queryBasedOnTab }, page })
    );
    return () => {
      if (!isFetchMore.current) {
        dispatch(setTodos());
      }
    };
  }, [dispatch, page, activeTab, searchQuery]);

  const fetchMore = () => {
    isFetchMore.current = true;
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Box height={'100%'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <TodosTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setPage={setPage}
          isFetchMore={isFetchMore}
        />
        <Tooltip arrow placement="left" title={t('Add new todo')}>
          <IconButton
            color="primary"
            onClick={() => setAddForm((prev) => !prev)}
            sx={{
              backgroundColor: `${theme.colors.primary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`,
              '&:hover': {
                backgroundColor: `${theme.colors.primary.dark}`
              },
              '.MuiSvgIcon-root': {
                transition: `${theme.transitions.create(['color'])}`
              }
            }}
          >
            {addForm ? <CloseTwoTone /> : <AddCircleTwoTone />}
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <Box m={1}>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setPage={setPage}
        />
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Scrollbar sx={{ height: '100%' }}>
        {addForm && <TodosForm />}
        <RenderTodos fetchMore={fetchMore} searchQuery={searchQuery} />
      </Scrollbar>
    </Box>
  );
};

export default Todos;

/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useDomainInfo from 'src/hooks/useDomainDetector';
import {
  selectIsAuthenticated,
  selectLoggedUserData,
  selectWorkspaceName
} from 'src/slices/authSlice';


// this component is used only for joinWorkspace *********
const Authenticated = (props) => {
  const { children } = props;
  const { tld, domain, subdomain } = useDomainInfo();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loggedUserData = useSelector(selectLoggedUserData);
  const workspaceName = useSelector(selectWorkspaceName);
  const currentDomain = domain !== 'localhost' ? `${domain}.${tld}` : domain;

  const domainNotAllowed = () => {
    console.log(currentDomain, "currentDomain");
    let whitelisted = process.env.REACT_APP_WHITELISTED;
    if (!whitelisted.split(',').includes(currentDomain)) {
      return true;
    } else {
      return false;
    }
  }


  // STEP - 1 check domain
  if (domainNotAllowed())
    return <Navigate to="/forbidden" replace />;

  // STEP -2 check is user is Authenticated
  console.log(isAuthenticated, "isAuthenticated");
  if (!isAuthenticated) return <Navigate to={'/login'} replace />;
  console.log(loggedUserData, "loggedUserData  <-- for workspace creation", workspaceName, "workspaceName")

  // STEP 3 - check if user is associated to any workspace
  console.log(workspaceName, "workpace name check for join workspace page")
  if (workspaceName) {
    // workspace is already attached to the user -> so should not allowed 
    return <Navigate to="/dashboard" replace />;
  }
  // add exception for localhost and staging before checking subdomain
  if (domain === 'localhost' || subdomain === 'staging') {
    console.log('localhost or staging detected')
    return children;
  }
  // if there is a subdomain we do not want user to join any workspace 
  console.log(subdomain, 'subdomain  <--- authenticated comp, so going for redirection', currentDomain, "currentDomain");

  // there is no workspace - so only root domain is allowed here
  if (!subdomain && domain === 'erpz') return children;

  return <Navigate to="/forbidden" replace />;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
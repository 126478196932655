// src/components/EntityDropdown.js
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { requestApi } from 'src/mocks/request';

const EntityDropdown = ({ entity, name, label, menuProps, setSchema }) => {
  const [entityFieldNames, setEntityFieldNames] = useState([]);

  useEffect(() => {
    if (entity) {
      const fetchFieldNames = async () => {
        try {
          const response = await requestApi.getSchema({ endPoint: entity });
          if (response?.status === 200) {
            if (setSchema) {
              setSchema(response?.data);
            }
            setEntityFieldNames(Object.keys(response?.data));
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchFieldNames();
    }
  }, [entity]);

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Field
        name={name}
        as={Select}
        labelId={`${name}-label`}
        label={label}
        fullWidth
        MenuProps={menuProps}
      >
        {entityFieldNames.map((fieldName, index) => (
          <MenuItem value={fieldName} key={index}>
            {fieldName}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export default EntityDropdown;

import { Popover } from '@mui/material';
import React from 'react';

const CustomPopover = ({
  anchorEl,
  open,
  onClose,
  children,
  sx = {},
  id = null,
  slotProps = {}
}) => {
  return (
    <Popover
      id={id}
      sx={{ ...sx }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      slotProps={slotProps}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;

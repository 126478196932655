import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Box, Divider, Drawer, styled, useTheme } from '@mui/material';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import SidebarFooter from './SidebarFooter.js';
import { useLocation } from 'react-router-dom';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import { useSelector } from 'react-redux';
import SidebarLoader from 'src/components/SkeltonLoaders/SidebarLoader.js';

const SidebarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showFooter'
})(({ theme, showFooter }) => ({
  width: theme.sidebar.width,
  minWidth: theme.sidebar.width,
  color: theme.sidebar.textColor,
  background: theme.sidebar.background,
  boxShadow: theme.sidebar.boxShadow,
  position: 'relative',
  zIndex: 7,
  height: '100%',
  paddingBottom: showFooter ? '61px' : '0px'
}));

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const location = useLocation();

  // Check if the pathname starts with 'workspace-setting'
  const showFooter = location.pathname.startsWith('/workspace-setting');
  const { status } = useSelector(selectAppConfigData);
  const { getAppConfig, getComEntitySetting } = status;
  if (getAppConfig && getComEntitySetting) return <SidebarLoader />;
  return (
    <>
      <SidebarWrapper
        showFooter={showFooter}
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                width: 52,
                mt: 2,
                mb: 3
              }}
            >
              <Logo />
            </Box>
          </TopSection>
          <SidebarMenu />
        </Scrollbar>
        {showFooter && (
          <>
            <Divider
              sx={{
                background: theme.colors.alpha.black[30]
              }}
            />
            <SidebarFooter />
          </>
        )}
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper showFooter={showFooter}>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3
                }}
              >
                <Logo />
              </Box>
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
          {showFooter && <SidebarFooter />}
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const errorHandler = (error) => {
  if (!navigator.onLine) {
    toast.error(`No internet connection`);
    return;
  }

  const { response } = error;

  if (!response) {
    toast.error(`Problem connecting to server`);
    return;
  }

  if (response?.data?.message) {
    const message = response.data && response.data.message;
    toast.error(message);
    return;
  }
};

export default errorHandler;

// import { notification } from 'antd';
// import codeMessage from './codeMessage';

// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const errorHandler = (error) => {
//   if (!navigator.onLine) {
//     notification.config({
//       duration: 15,
//       maxCount: 1
//     });

//     toast.error(`No internet connection`, { containerId: 'first' });
//     return {
//       success: false,
//       result: null,
//       message: 'Cannot connect to the server, Check your internet network'
//     };
//   }

//   const { response } = error;

//   if (!response) {
//     notification.config({
//       duration: 20,
//       maxCount: 1
//     });
//     // Code to execute when there is no internet connection
//     toast.error(`Problem connecting to server`, { containerId: 'first' });
//     return {
//       success: false,
//       result: null,
//       message:
//         'Cannot connect to the server, Contact your Account administrator'
//     };
//   }

//   if (response && response.data && response.data.jwtExpired) {
//     const result = window.localStorage.getItem('auth');
//     const jsonFile = window.localStorage.getItem('isLogout');
//     const { isLogout } = (jsonFile && JSON.parse(jsonFile)) || false;
//     window.localStorage.removeItem('auth');
//     window.localStorage.removeItem('isLogout');
//     if (result || isLogout) {
//       window.location.href = '/logout';
//     }
//   }

//   if (response && response.status) {
//     const message = response.data && response.data.message;

//     const errorText = message || codeMessage[response.status];
//     const { status } = response;

//     toast.error(message);
//     return response.data;
//   } else {
//     notification.config({
//       duration: 15,
//       maxCount: 1
//     });

//     if (navigator.onLine) {
//       toast.error(`Problem connecting to server`, { containerId: 'first' });

//       return {
//         success: false,
//         result: null,
//         message:
//           'Cannot connect to the server, Contact your Account administrator'
//       };
//     } else {
//       // Code to execute when there is no internet connection
//       toast.error(`No internet connection`, { containerId: 'first' });
//       return {
//         success: false,
//         result: null,
//         message: 'Cannot connect to the server, Check your internet network'
//       };
//     }
//   }
// };

// export default errorHandler;

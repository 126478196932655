import { Axios } from 'src/services/axios';
import errorHandler from './errorHandler';
import successHandler from './successHandler';

class RequestApi {
  createData({ endPoint, data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/${endPoint}/create`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  createBulkData({ endPoint, data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/${endPoint}/addBulk`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject(error);
      }
    });
  }

  getData({
    endPoint,
    pagination = false,
    collation = '',
    page = 0,
    limit = 10,
    populate = '',
    query = {},
    sort = { createdAt: -1 },
    select = null,
    isCountOnly = false
  }) {
    const body = {
      query: { ...query, isDeleted: { $ne: true } },
      options: {
        populate,
        collation,
        sort,
        page: page + 1,
        limit,
        pagination,
        select
      },
      isCountOnly
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/${endPoint}/list`, body);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        console.error(error);
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  updateData({ endPoint, data, id }) {
    console.log(id, 'updateData');
    if (!id) {
      return Error('id is mandatory');
    }
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(`/${endPoint}/update/${id}`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  updatePassword({ endPoint, data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(`/${endPoint}/change-password`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  updateBulk({ endPoint, data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(`/${endPoint}/updateBulk`, data);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  deleteData({ endPoint, id, deleteType = 'softDelete' }) {
    return new Promise(async (resolve, reject) => {
      try {
        let response;
        if (deleteType === 'softDelete') {
          response = await Axios.put(`/${endPoint}/${deleteType}/${id}`);
        } else {
          response = await Axios.delete(`/${endPoint}/${deleteType}/${id}`);
        }
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  deleteMany({ endPoint, ids }) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await Axios.put(`/${endPoint}/softDeleteMany`, ids);
        successHandler(response, {
          notifyOnSuccess: true,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  getSchema({ endPoint }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(`/schemas/${endPoint}`);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  uploadFile(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/upload`, body, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  deleteFile(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/file-delete`, body);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  docConvert({ endPoint, data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/${endPoint}/by_convert`, data);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  getAppConfig() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(`/schemas/`);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  getDomain(body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/workspace/domain`, body);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }

  sendMail({ data }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(`/email/send`, data);
        successHandler(response, {
          notifyOnSuccess: false,
          notifyOnFailed: true
        });
        resolve(response?.data);
      } catch (error) {
        errorHandler(error);
        reject('Internal server error');
      }
    });
  }
}

export const requestApi = new RequestApi();

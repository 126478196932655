import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import CreateRFQ from './CreateRFQ';
import CreatePo from './CreatePo';
import CustomMenu from 'src/components/CustomMenu';

const ProReqMoreAction = ({ item, selectedSuppliers }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl((prevVal) => (!prevVal ? event.currentTarget : null));
  };

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreVertIcon color="primary" fontSize="small" />
      </IconButton>
      <CustomMenu anchorEl={anchorEl} handleClose={handleMenu}>
        <CreateRFQ item={item} />
        <CreatePo item={item} selectedSuppliers={selectedSuppliers} />
      </CustomMenu>
    </>
  );
};

export default ProReqMoreAction;

import { Button, Paper, Stack, Typography } from '@mui/material';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { useState, useEffect } from 'react';
import { requestApi } from 'src/mocks/request';
import ApprovalConfirmDialog from './ApprovalConfirmDialog';
import useRefMounted from 'src/hooks/useRefMounted';
import ApprovalHistory from './ApprovalHistory';
import ContentLoader from './ContentLoader';

const ApprovalActionContent = ({ data, approvReqId }) => {
  const { approval_id: approval } = data;
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [approverComment, setApproverComment] = useState(null);
  const [isFetching, setFetching] = useState(true);
  const [dialogType, setDialogType] = useState(null);
  const isMounted = useRefMounted();

  const handleDialogOpen = (type) => setDialogType(type);
  const handleDialogClose = () => setDialogType(null);

  useEffect(() => {
    const fetchApprovalRequests = async () => {
      setFetching(true);
      try {
        const payload = {
          endPoint: 'approval_request',
          populate: 'approval_by',
          query: {
            approval: approval?.id,
            $or: [
              { stage: { $lt: approval?.current_stage } },
              {
                stage: approval?.current_stage,
                // approval_by: { $ne: null }
                isActive: false
              }
            ]
          }
        };

        const response = await requestApi.getData(payload);
        if (response?.data?.data?.length && isMounted.current) {
          setApprovalRequests(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching approval requests:', error);
      } finally {
        if (isMounted.current) setFetching(false);
      }
    };

    fetchApprovalRequests();
  }, [approval?.id, approval?.current_stage, isMounted]);

  if (isFetching) return <ContentLoader />;

  return (
    <>
      <Stack
        px={2}
        py={1}
        gap={2}
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Stack spacing={1} alignItems="start">
          <Typography variant="h4">Approval History</Typography>
          {approvalRequests.length > 0 ? (
            <Stack direction={'row'} gap={1}>
              <Paper variant="outlined" p={1}>
                <ApprovalHistory
                  approvalRequests={approvalRequests}
                  setApproverComment={setApproverComment}
                />
              </Paper>
              {Boolean(approverComment) && (
                <Paper variant="outlined" p={1}>
                  {approverComment}
                </Paper>
              )}
            </Stack>
          ) : (
            <Typography variant="body2">No approval comments found.</Typography>
          )}
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h4">Do you want to?</Typography>
          <Button
            variant="outlined"
            color="success"
            startIcon={<DoneTwoToneIcon />}
            onClick={() => handleDialogOpen('APPROVED')}
          >
            Approve document
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<ClearTwoToneIcon />}
            onClick={() => handleDialogOpen('REJECTED')}
          >
            Reject document
          </Button>
        </Stack>
      </Stack>
      <ApprovalConfirmDialog
        open={dialogType}
        approvReqId={approvReqId}
        handleClose={handleDialogClose}
      />
    </>
  );
};

export default ApprovalActionContent;

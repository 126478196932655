import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  docNumConfigs: [],
  currentDocNumConfig: null,
  paginator: null,
  status: {
    updateDocNumConfig: false,
    getDocNumConfig: false
  }
};

export const getAllDocNumConfig = createAsyncThunk(
  'docNumConfig/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return { ...data, response: response.data };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocNumConfig = createAsyncThunk(
  'docNumConfig/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      if (response?.status === 'SUCCESS') {
        return data.updateData;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const slice = createSlice({
  name: 'docNumConfig',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllDocNumConfig.pending, (state) => {
        state.status.getDocNumConfig = true;
      })
      .addCase(getAllDocNumConfig.fulfilled, (state, { payload }) => {
        state.status.getDocNumConfig = false;
        if (payload.isSingleEmployee) {
          state.currentDocNumConfig = payload?.response?.data?.[0] || null;
        } else {
          state.docNumConfigs = payload?.response?.data || [];
          state.paginator = payload?.response?.paginator || null;
        }
      })
      .addCase(getAllDocNumConfig.rejected, (state) => {
        state.docNumConfigs = [];
        state.status.getDocNumConfig = false;
      })
      .addCase(updateDocNumConfig.pending, (state) => {
        state.status.updateDocNumConfig = true;
      })
      .addCase(updateDocNumConfig.fulfilled, (state, action) => {
        state.status.updateDocNumConfig = false;
        const replaceData = action.payload;
        state.docNumConfigs = state.docNumConfigs.map((docNumConfig) =>
          docNumConfig.id === replaceData.id ? replaceData : docNumConfig
        );
      })
      .addCase(updateDocNumConfig.rejected, (state) => {
        state.status.updateDocNumConfig = false;
      });
  }
});

export const selectDocNumConfig = (state) => state.docNumConfig;
export default slice.reducer;

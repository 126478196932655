import { Box, Skeleton, Stack, Typography } from '@mui/material';

const TodosCardSkeleton = ({ value }) => {
  return (
    <Stack gap={1} p={1}>
      {Array.from(new Array(value || 6)).map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1
          }}
        >
          <Box>
            <Skeleton variant="circular" width={32} height={32} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <Skeleton variant="text" width={120} />
            </Stack>
            <Typography component="span" variant="body2">
              <Skeleton variant="text" width="80%" />
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={1}>
                <Skeleton variant="text" width={60} />
              </Stack>
            </Stack>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default TodosCardSkeleton;

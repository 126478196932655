import { DeleteRounded, Info } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const ImportPreviewTable = ({
  tableHeaders,
  importData,
  setImportData,
  setIsFieldDirty,
  errors,
  path
}) => {
  const { schema } = useSelector(selectDynamicPageConfig);
  const [paginatedData, setPaginatedData] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(importData?.length || 0);

  const filteredTableHeaders = useMemo(() => {
    return tableHeaders?.filter((header) => {
      const field = schema?.[path]?.schema?.paths[header];
      return field && field.instance !== 'Array' && field.instance !== 'Object';
    });
  }, [schema, tableHeaders, path]);

  const enumTypeFields = useMemo(() => {
    return Object.values(schema)?.filter(
      (item) => item?.enumValues?.length > 0 || item.instance === 'Boolean'
    );
  }, [schema]);

  const getEnumValues = (header) => {
    const field = enumTypeFields.find(({ path }) => path === header);
    if (field?.instance === 'Boolean') {
      return ['Yes', 'No'];
    } else {
      return field?.enumValues || null;
    }
  };

  const handleChange = (e, rowIndex, header) => {
    setIsFieldDirty(true);
    const newData = [...importData];
    newData[rowIndex][header] = e.target.value;
    setImportData(newData);
  };

  const handleDeleteRow = (rowIndex) => {
    const newData = importData.filter((_, index) => index !== rowIndex);
    setImportData(newData);
  };

  useEffect(() => {
    if (importData) {
      setPageCount(importData.length);
      const startIndex = (page - 0) * limit;
      const endIndex = startIndex + limit;
      const currentPageData = importData?.slice(startIndex, endIndex);
      setPaginatedData(currentPageData);
    }
  }, [importData, page, limit]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const renderInputCell = (item, rowIndex, header) => {
    const enumValues = getEnumValues(header);

    const fieldErrors = errors?.find(
      (error) => error.fieldName === header
    )?.errors;
    const error = fieldErrors?.find((error) => error.rowIndex === rowIndex);

    return (
      <TextField
        key={header}
        fullWidth
        select={!!enumValues}
        variant="outlined"
        size="small"
        InputProps={{
          // disableUnderline: true,
          // sx: {
          //   paddingY: 1,
          //   '& .MuiInputBase-root': {
          //     border: 'none',
          //   }
          // },
          endAdornment: error && (
            <InputAdornment position="end">
              <Tooltip title="Item not found!">
                <IconButton size="small" color="error" edge="end">
                  <Info fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        value={item[header] || ''}
        onChange={(e) => handleChange(e, rowIndex, header)}
        error={!!error}
      >
        {enumValues &&
          enumValues.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
      </TextField>
    );
  };

  return (
    <>
      {paginatedData && (
        <Box>
          <TableContainer
            sx={{
              overflow: 'auto',
              paddingTop: 1.5,
              borderRadius: '8px'
            }}
          >
            <Table stickyHeader aria-label="preview table">
              <TableHead>
                <TableRow>
                  {filteredTableHeaders?.map((header) => (
                    <TableCell
                      sx={{
                        minWidth: '200px',
                        paddingX: 2.3,
                        paddingY: 1,
                        paddingTop: 3
                      }}
                      key={header}
                    >
                      {header}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      paddingX: 2,
                      paddingY: 1,
                      paddingTop: 3
                    }}
                    align="right"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((item, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {filteredTableHeaders?.map((header) => (
                      <TableCell
                        key={header}
                        sx={{ minWidth: '200px', padding: 0.8 }}
                      >
                        {renderInputCell(item, rowIndex, header)}
                      </TableCell>
                    ))}
                    <TableCell sx={{ padding: 0.8 }} align="center">
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteRow(rowIndex)}
                      >
                        <DeleteRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {pageCount > 0 && (
            <Box p={2}>
              <TablePagination
                component="div"
                count={pageCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 20]}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ImportPreviewTable;

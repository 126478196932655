import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import CommonModal from 'src/components/CommonModal.js';
import SendMailModal from './SendMailModal';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const ViewAction = ({ componentRef }) => {
  const { id: viewId } = useParams();
  const { entity } = useSelector(selectDynamicPageConfig);
  const [file, setFile] = useState({});
  const [fileBlob, setFileBlob] = useState(null); // State to store the PDF Blob
  const location = useLocation();
  const [openModal, setModal] = useState(false);
  const { t } = useTranslation();

  const handleExport = (action) => {
    if (!componentRef.current) {
      console.error('Component reference is not valid.');
      return;
    }

    const input = componentRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = imgProps.width;
      const imgHeight = imgProps.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth * ratio, imgHeight * ratio);
      // Create a Blob from the PDF and store it in the state
      const pdfOutput = pdf.output('blob');
      if (action === 'blob') {
        setFileBlob(pdfOutput);
      } else {
        pdf.save(`${entity}.pdf`);
      }

      // pdf.save(`${entity}.pdf`);
    });
  };

  const handleModal = () => {
    setModal(!openModal);
    handleExport('blob');
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: entity,
    onBeforePrint: () => console.log('Preparing to print...'),
    onAfterPrint: () => console.log('Printing completed.')
  });

  return (
    <>
      <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
        <Button
          variant="outlined"
          component={RouterLink}
          to={`/${location.pathname.split('/')[1]}/edit/${viewId}`}
          size="small"
          startIcon={<EditOutlinedIcon />}
        >
          {t('Edit')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<EmailOutlinedIcon />}
          onClick={handleModal}
        >
          {t('Email')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<DownloadOutlinedIcon />}
          onClick={handleExport}
        >
          {t('Export')}
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<PrintOutlinedIcon />}
          onClick={handlePrint}
        >
          {t('Print')}
        </Button>
      </Stack>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={`New Message`}
        isForm={true}
        maxWidth="md"
      >
        <SendMailModal
          fileBlob={fileBlob}
          handleModal={handleModal}
          entity={entity}
          file={file}
          setFile={setFile}
        />
      </CommonModal>
    </>
  );
};

export default ViewAction;

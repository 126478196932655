import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectData, selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import ProcessRequest from 'src/content/ProcessRequest';
import useGetItemById from 'src/hooks/useGetItemById';
import GrnForm from './GrnForm';
import DynamicForm from './DynamicForm';
import WorkflowForm from './WorkflowForm';
import ViewPage from 'src/content/management/ViewPage';
import ViewPageLoader from 'src/components/SkeltonLoaders/ViewPageLoader';
import CreateEditPageLoader from 'src/components/SkeltonLoaders/CreateEditPageLoader';
const DynamicEntityForm = () => {
  const { id, type } = useParams();
  const data = useSelector(selectData);
  const { populateString, entity } = useSelector(selectDynamicPageConfig);
  const { currentItem, isFetching } = useGetItemById(
    id,
    {
      endPoint: entity,
      populate: populateString,
      query: { _id: id }
    },
    data
  );
  if (isFetching) {
    if (type === 'view') return <ViewPageLoader />;
    return <CreateEditPageLoader />;
  }
  if (type !== 'create' && !currentItem) return null;
  if (type == 'view') return <ViewPage currentItem={currentItem} />;
  if (type === 'processRequest')
    return <ProcessRequest selectedRowData={currentItem} entity={entity} />;
  if (entity === 'workflows') return <WorkflowForm currentItem={currentItem} />;
  if (entity === 'grn') return <GrnForm currentItem={currentItem} />;
  return <DynamicForm currentItem={currentItem} />;
};

export default DynamicEntityForm;

import { Grid } from '@mui/material';
import React from 'react';
import RangeFields from './RangeFields';
import ColorPickerComponent from 'src/components/dynamicFields/ColorPickerComponent';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const SPECIFY_RANGE_OPTIONS = [
  { label: 'Yes, specify a range', value: true },
  { label: 'No, do not specify a range', value: false }
];

const ProgressColorSettings = ({
  prop,
  setFieldValue,
  propPath,
  propIndex,
  handleBlur
}) => {
  return (
    <>
      <Grid item xs={12} md={2}>
        <CustomSelectFieldWithLabel
          label="Specify Range"
          value={prop?.specificRange !== undefined ? prop?.specificRange : ''}
          options={SPECIFY_RANGE_OPTIONS}
          onChange={(e) => {
            const newProps = { name: prop.name, specificRange: e.target.value }; // Create a new props object with only the specificRange property
            setFieldValue(`${propPath}.props[${propIndex}]`, newProps); // Update the props object
          }}
          onBlur={handleBlur}
          name={`${propPath}.props[${propIndex}].specificRange`}
        />
      </Grid>
      {prop.specificRange && (
        <>
          <Grid item xs={12} md={2}>
            <ColorPickerComponent
              title={'Select Root Color'}
              color={
                prop?.rootColor?.rgb || {
                  r: 255,
                  g: 255,
                  b: 255,
                  a: 1
                }
              }
              onChange={(color) =>
                setFieldValue(`${propPath}.props[${propIndex}].rootColor`, {
                  ...color
                })
              }
              onBlur={handleBlur}
            />
          </Grid>
          <RangeFields
            propPath={propPath}
            propIndex={propIndex}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            ranges={prop.ranges}
          />
        </>
      )}
    </>
  );
};

export default ProgressColorSettings;

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

const CustomSelectField = ({
  label,
  value,
  options,
  error,
  touched,
  disabled = false,
  onChange,
  onBlur = () => {},
  name = '',
  placeholder = '',
  size = 'medium',
  sx = {},
  fullWidth = true,
  inputProps = {},
  readOnly = false,
  required = false
}) => {
  // Check if touched and error are present
  const hasError = touched && error;
  const modifiedInputProps = { ...inputProps, readOnly };

  return (
    <FormControl fullWidth={fullWidth} error={hasError} required={required}>
      <InputLabel id={`${name}_Label`}>{label}</InputLabel>
      <Select
        sx={sx}
        labelId={`${name}_Label`}
        value={value || ''}
        label={label}
        placeholder={placeholder}
        size={size}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        inputProps={modifiedInputProps}
        MenuProps={MenuProps}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={`${name}_${index}`}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {/* Display error message if both touched and error are present */}
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelectField;

import React from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CustomIconButton = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent' // No background color on hover
  },
  '&:active': {
    backgroundColor: 'transparent' // No background color on active
  },
  '.MuiTouchRipple-root': {
    display: 'none' // Remove the ripple effect
  }
}));

const SearchInput = ({
  query,
  handleQueryChange,
  label,
  open,
  onToggle,
  id
}) => (
  <Box
    aria-describedby={id}
    display="flex"
    gap={1}
    alignItems="center"
    width={'100%'}
    maxWidth={250}
  >
    <TextField
      value={query}
      onChange={handleQueryChange}
      label={label}
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 58px)' // Adjust to fit the end adornment
        }
      }}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CustomIconButton onClick={onToggle}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </CustomIconButton>
          </InputAdornment>
        )
      }}
    />
  </Box>
);

export default SearchInput;

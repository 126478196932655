import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { IconButton, Stack, Typography } from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import CommentIcon from '@mui/icons-material/Comment';
import moment from 'moment';

const RenderTimelineItem = ({ approval, setApproverComment }) => {
  const { approval_by, updatedAt, createdAt, note } = approval;
  const approverName = approval_by.name || approval_by.username;
  const formattedDate = moment(updatedAt || createdAt).format(
    'MMMM D, YYYY [at] h:mm A'
  );
  const approverComment = note || null;

  return (
    <TimelineItem key={approval_by.id}>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Stack spacing={0.5}>
          <Typography variant="body2">Approved by @{approverName}</Typography>
          {approverComment && (
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">
                @{approverName} has added a comment
              </Typography>
              <IconButton
                size="small"
                onClick={() => setApproverComment(approverComment)}
                aria-label="View Comment"
              >
                <CommentIcon fontSize="small" />
              </IconButton>
            </Stack>
          )}
          <Typography variant="body2">{formattedDate}</Typography>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

const ApprovalHistory = ({ approvalRequests, setApproverComment }) => {
  return (
    <Scrollbar maxHeight={200}>
      <Timeline>
        {approvalRequests?.map((approval) => (
          <RenderTimelineItem
            key={approval.approval_by.id}
            approval={approval}
            setApproverComment={setApproverComment}
          />
        ))}
      </Timeline>
    </Scrollbar>
  );
};

export default ApprovalHistory;

import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ItemsTable from 'src/components/ViewComponents/ItemTable';
import AddressBlock from 'src/components/ViewComponents/AddressBlock';
import { useTranslation } from 'react-i18next';
import TermsAndCondition from 'src/components/ViewComponents/TermsAndCondition';
import VerticalLabelValue from 'src/components/ViewComponents/VerticalLabelValue';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const ReqForQuoView = ({ data }) => {
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const { t } = useTranslation();
  const {
    items = [],
    payment_terms,
    delivery_terms,
    country_code,
    supplier,
    warehouse,
    workspace
  } = data;
  return (
    <Stack rowGap={2}>
      <Typography textAlign={'center'} color={'primary'} variant={'h3'} mb={1}>
        {capitalizeAndRemoveChar(entity)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <VerticalLabelValue label={'RFQ Number'} value={'RFQ-00000001'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <VerticalLabelValue label={'Request Date'} value={'1 Aug 2024'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <VerticalLabelValue
            label={'Request Deadline'}
            value={'26 Aug 2024'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {workspace?.company?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              addressTitle={'Buyer Address'}
              countryCode={country_code}
              address={data?.workspace?.company?.address}
            />
          </Grid>
        )}
        {supplier?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              countryCode={country_code}
              addressTitle={'Supplier Address'}
              address={data?.supplier?.address}
            />
          </Grid>
        )}
        {warehouse?.address && (
          <Grid item xs={12} md={4}>
            <AddressBlock
              addressTitle={'Shipping Address'}
              countryCode={country_code}
              address={data?.warehouse?.address}
            />
          </Grid>
        )}
      </Grid>
      {items.length && <ItemsTable schema={schema} data={data} />}
      {(payment_terms || delivery_terms) && (
        <TermsAndCondition
          payment_terms={payment_terms}
          delivery_terms={delivery_terms}
        />
      )}
    </Stack>
  );
};

export default ReqForQuoView;

import React, { useMemo } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import ActionTableCell from '../ActionTableCell';
import { useSelector } from 'react-redux';
import {
  selectColumnFilterFieldname,
  selectData,
  selectDynamicPageConfig
} from 'src/slices/dynamicSlice';
import CheckCellValue from './CheckCellValue';

const getAllRows = (
  rowData,
  fieldNames,
  title,
  selectedRows,
  handleSelectOneRow,
  schema
) => {
  return (
    fieldNames.length > 0 &&
    rowData.map((row) => {
      const isRowSelected = selectedRows.some(
        (selectedRow) => selectedRow.id === row.id
      );
      return (
        <TableRow key={row.id}>
          <TableCell padding="checkbox">
            <Checkbox
              size="small"
              checked={isRowSelected}
              onChange={(event) => handleSelectOneRow(event, row)}
            />
          </TableCell>
          {fieldNames.map((fieldName, colIndex) => (
            <TableCell key={`fieldname_${colIndex}`}>
              <CheckCellValue
                rowData={row}
                row={row}
                fieldName={fieldName}
                schema={schema}
              />
            </TableCell>
          ))}
          <ActionTableCell row={row} />
        </TableRow>
      );
    })
  );
};

const GenerateRows = ({
  fieldNames,
  title,
  selectedRows,
  handleSelectOneRow
}) => {
  const data = useSelector(selectData);
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const columnFilterFieldname = useSelector(selectColumnFilterFieldname);
  if (entitySetting?.show_fields && entitySetting.show_fields.length > 0) {
    fieldNames = entitySetting?.show_fields;
  }
  const allFieldName = fieldNames.filter((fieldName) =>
    columnFilterFieldname?.includes(fieldName)
  );
  const rowsData = useMemo(() => {
    return data && fieldNames
      ? getAllRows(
          data,
          allFieldName,
          title,
          selectedRows,
          handleSelectOneRow,
          schema
        )
      : [];
  }, [
    selectedRows,
    data,
    fieldNames,
    columnFilterFieldname,
    schema,
    entitySetting
  ]);

  return (
    <>
      {rowsData.length === 0 ? (
        <TableRow>
          <TableCell colSpan={fieldNames.length + 1}>No Data Found</TableCell>
        </TableRow>
      ) : (
        rowsData
      )}
    </>
  );
};

export default GenerateRows;

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Button, Collapse, ListItem, styled, useTheme } from '@mui/material';
import { default as clsx } from 'clsx';
import { default as PropTypes } from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

const SidebarMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const theme = useTheme();
  const [menuToggle, setMenuToggle] = useState(openParent);
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };
  const IconWrapper = styled('img')({
    width: '20px',
    height: '20px',
    fill: theme.palette.primary.main,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  });
  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ active: menuToggle })}
          startIcon={
            <img
              src={Icon ? Icon : ''}
              alt="Icon"
              style={{
                width: '20px',
                height: '20px',
                // filter:
                //   'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'

                filter: `${
                  theme.palette.mode === 'light'
                    ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                    : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
                }`
              }}
            />
          }
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <>
      <ListItem component="div" key={name} {...rest}>
        <Button
          disableRipple
          component={RouterLink}
          onClick={closeSidebar}
          to={link}
          startIcon={
            <img
              src={Icon ? Icon : ''}
              alt="Icon"
              style={{
                width: '20px',
                height: '20px',
                // filter:
                //   'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
                filter: `${
                  theme.palette.mode === 'light'
                    ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                    : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
                }`
              }}
            />
          }
        >
          {t(name)}
        </Button>
      </ListItem>
    </>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;

import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import ItemsTable from 'src/components/ViewComponents/ItemTable';
import LabelValueDisplay from 'src/components/ViewComponents/LableValueDisplay';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { useSelector } from 'react-redux';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const MaterialReqView = ({ data }) => {
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const { t } = useTranslation();
  const { number: docNumber, createdAt: orderDate, items = [] } = data;
  return (
    <Stack rowGap={2}>
      <Typography textAlign={'center'} color={'primary'} variant={'h3'} mb={1}>
        {capitalizeAndRemoveChar(entity)}
      </Typography>
      <Stack spacing={1}>
        <Typography variant="subtitle1" gutterBottom>
          {t('Order Details')}
          {' : '}
        </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          rowGap={1}
        >
          {docNumber && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay label={t('Order No')} value={docNumber} />
            </Box>
          )}
          {items?.length && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('No of items')}
                value={items.length}
              />
            </Box>
          )}
          {orderDate && (
            <Box sx={{ flexBasis: { xs: '100%', md: '33.33%' } }}>
              <LabelValueDisplay
                label={t('Order Date')}
                value={moment(orderDate).format('DD-MM-YYYY')}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      {items.length && <ItemsTable schema={schema} data={data} />}
    </Stack>
  );
};

export default MaterialReqView;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';

const initialState = {
  approvalSettings: [],
  paginator: [],
  status: {
    getData: false,
    updateData: false,
    deleteData: false,
    bulkAddData: false
  }
};

export const createBulkApprovalSettingAsync = createAsyncThunk(
  'approvalSetting/createBulkData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.createBulkData(data);
      if (response?.status === 'SUCCESS') {
        return;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      console.log(error, 'isError');
      return error;
    }
  }
);

export const getApprovalSettingAsync = createAsyncThunk(
  'approvalSetting/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateApprovalSettingAsync = createAsyncThunk(
  'approvalSetting/updateData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.updateData(data);
      console.log(response, 'updateApprovalSettingAsync');
      if (response?.status === 'SUCCESS') {
        return { id: data.id, updateData: data.localUpdataData };
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBlukApprovalSettingAsync = createAsyncThunk(
  'approvalSetting/deleteMany',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.deleteMany(data);
      if (response?.status === 'SUCCESS') {
        return;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const slice = createSlice({
  name: 'approvalSetting',
  initialState,
  reducers: {
    setApprovalSettings: (state, { payload }) => {
      const { id, updateData } = payload;
      state.approvalSettings = state.approvalSettings.map((item) =>
        item.id === id ? updateData : item
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBulkApprovalSettingAsync.pending, (state) => {
        state.status.bulkAddData = true;
      })
      .addCase(createBulkApprovalSettingAsync.fulfilled, (state) => {
        state.status.bulkAddData = false;
      })
      .addCase(createBulkApprovalSettingAsync.rejected, (state) => {
        state.status.bulkAddData = false;
      })
      .addCase(getApprovalSettingAsync.pending, (state) => {
        state.status.getData = true;
      })
      .addCase(getApprovalSettingAsync.fulfilled, (state, { payload }) => {
        state.status.getData = false;
        state.approvalSettings = payload?.data || [];
        state.paginator = payload?.paginator || null;
      })
      .addCase(getApprovalSettingAsync.rejected, (state) => {
        state.approvalSettings = [];
        state.status.getData = false;
      })
      .addCase(updateApprovalSettingAsync.pending, (state) => {
        state.status.updateData = true;
      })
      .addCase(updateApprovalSettingAsync.fulfilled, (state, { payload }) => {
        state.status.updateData = false;
        // Update the specific item in the array
        const { id, updateData } = payload;
        state.approvalSettings = state.approvalSettings.map((item) =>
          item.id === id ? updateData : item
        );
      })
      .addCase(updateApprovalSettingAsync.rejected, (state) => {
        state.status.updateData = false;
      })
      .addCase(deleteBlukApprovalSettingAsync.pending, (state) => {
        state.status.deleteData = true;
      })
      .addCase(deleteBlukApprovalSettingAsync.fulfilled, (state, action) => {
        state.status.deleteData = false;
      })
      .addCase(deleteBlukApprovalSettingAsync.rejected, (state, action) => {
        state.status.deleteData = false;
      });
  }
});
export const { setApprovalSettings } = slice.actions;
export const selectApprovalSetting = (state) => state.approvalSetting;

export default slice.reducer;

import {
  Stack,
  Tooltip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress
} from '@mui/material';
import { useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MoreItemsPopover from 'src/components/MoreItemsPopover';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import useFileUpload from 'src/hooks/useFileUpload';

const Attachments = ({ name, children, file = {}, setFile, fileBlob }) => {
  const { entity } = useSelector(selectDynamicPageConfig);
  const { setFieldValue, values } = useFormikContext();
  const {
    uploadFiles,
    uploadFile,
    deleteFile,
    loading: isUploading
  } = useFileUpload();
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const updatedState = (uploadedFiles) => {
      const attachments = uploadedFiles.map((file) => ({
        path: file.path
      }));
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
      setFieldValue('attachments', [...values.attachments, ...attachments]);
    };
    await uploadFiles(Array.from(files), updatedState);
  };

  const handleDelete = async (path, index) => {
    await deleteFile(path);
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      setFieldValue(
        'attachments',
        updatedFiles.map((file) => ({ path: file.path }))
      );
      return updatedFiles;
    });
  };

  useEffect(() => {
    if (Object.keys(file).length) {
      setFiles((prevFiles) => [...prevFiles, file]);
      setFieldValue('attachments', [...values.attachments, file]);
    }
  }, [file]);

  useEffect(() => {
    if (fileBlob && !Object.keys(file).length) {
      const handleFileUpload = async (event) => {
        const updateState = (uploadedFile) => {
          setFile(uploadedFile);
        };
        await uploadFile({ file: fileBlob, updateState, entity });
      };
      handleFileUpload();
    }
  }, [fileBlob]);

  return (
    <>
      <Tooltip title={name === 'images' ? 'Attach Image' : 'Attach File'} arrow>
        <IconButton size="small" color="primary" onClick={handleFileInputClick}>
          {children}
        </IconButton>
      </Tooltip>
      {isUploading && <CircularProgress size="1rem" />}
      {!isUploading &&
        files.slice(0, 1).map((file, index) => (
          <Stack
            key={file.name}
            direction={'row'}
            spacing={0.5}
            alignItems={'center'}
          >
            <Link component={RouterLink} to={file.path} underline="none">
              {file.name}
            </Link>
            <Tooltip title="Delete File">
              <IconButton
                size="small"
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(file.path, index)}
              >
                <DeleteIcon fontSize={'10px'} />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
      {!isUploading && files.length > 1 && (
        <MoreItemsPopover items={files} btnNum={files?.length - 2}>
          <List>
            {files?.map((file, index) => {
              return (
                <ListItem key={`${file.name}_${index}`}>
                  <ListItemText primary={file.name} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Delete File">
                      <IconButton
                        size="small"
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(file.path, index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </MoreItemsPopover>
      )}
      <input
        type="file"
        ref={fileInputRef}
        hidden
        multiple
        accept={name === 'images' ? 'image/*' : 'application/*'}
        onChange={handleFileChange}
      />
    </>
  );
};

export default Attachments;

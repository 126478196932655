import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CardComponent = ({ cardData }) => {
  if (!cardData) return null; // Return null or some fallback if cardData is not provided

  const { avatarProps = {}, titleProps = {}, descriptionProps = {} } = cardData;
  const { logo } = avatarProps;
  const { value: title, id: titleId, ref: titleRef } = titleProps;
  const {
    value: description,
    id: descriptionId,
    ref: descriptionRef
  } = descriptionProps;

  // Create link props conditionally for title and description
  const titleLinkProps = titleId
    ? { component: RouterLink, to: `/view/${titleRef}/${titleId}` }
    : {};
  const descriptionLinkProps = descriptionId
    ? { component: RouterLink, to: `/view/${descriptionRef}/${descriptionId}` }
    : {};

  return (
    <Box display="flex" alignItems="center">
      <Avatar sx={{ mr: 1 }} src={logo} alt="user-photo" />
      <Box>
        <Typography variant="h5" {...titleLinkProps}>
          {title}
        </Typography>
        <Typography variant="subtitle2" noWrap {...descriptionLinkProps}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardComponent;

import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@mui/material';

const CustomRadioField = ({
  label,
  row = false,
  value,
  options,
  error,
  touched,
  disabled = false,
  onChange,
  onBlur,
  name
}) => {
  // Check if touched and error are present
  const hasError = touched && error;

  return (
    <FormControl fullWidth error={hasError}>
      {label && <FormLabel id={`${name}_Label`}>{label}</FormLabel>}
      <RadioGroup
        row={row}
        aria-labelledby={`${name}_Label`}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={`${name}_${index}`}
            value={option.value}
            control={<Radio disabled={disabled} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {/* Display error message if both touched and error are present */}
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CustomRadioField;

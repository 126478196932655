import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectColumnFilterFieldname } from 'src/slices/dynamicSlice';

const ColumnFilterCheckbox = ({ handleColumnSwitchChange, fieldName }) => {
  const columnFilterFieldname = useSelector(selectColumnFilterFieldname);
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleColumnSwitchChange}
                checked={Boolean(columnFilterFieldname.includes(fieldName))}
                value={fieldName}
              />
            }
            label={fieldName}
          />
        </FormGroup>
      </Stack>
    </>
  );
};

export default ColumnFilterCheckbox;

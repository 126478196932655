import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiColorInput } from 'mui-color-input';
import ThemeSettings from 'src/components/ThemeSettings';
import ModifiedTheme from 'src/components/ThemeSettings/ModifiedTheme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const LayoutOptions = [
  'AccentHeaderLayout',
  'AccentSidebarLayout',
  'BaseLayout',
  'BottomNavigationLayout',
  'BoxedSidebarLayout',
  'CollapsedSidebarLayout',
  'DocsLayout',
  'ExtendedSidebarLayout',
  'TopNavigationLayout'
];
const Themes = [
  'PureLightTheme',
  'GreyGooseTheme',
  'PurpleFlowTheme',
  'NebulaFighterTheme',
  'DarkSpacesTheme',
  'GreenFieldsTheme'
];

const PalletDialog = ({
  open,
  handleClose,
  handleClickOpen,
  values,
  setFieldValue,
  handleChange,
  handleReset
}) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            sx={{ textAlign: 'center' }}
          >
            Select palette Color
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
          >
            <Box>
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                // sx={{ textAlign: 'center' }}
              >
                Primary Color
              </Typography>
              <MuiColorInput
                value={values?.companyGeneralSettings?.palette?.primaryColor}
                format="hex"
                onChange={(color) => {
                  setFieldValue(
                    'companyGeneralSettings.palette.reset',
                    'false'
                  );
                  setFieldValue(
                    'companyGeneralSettings.palette.primaryColor',
                    color
                  );
                }}
              />
            </Box>
            <Box>
              <Typography variant="h6" component="h6" gutterBottom>
                Secondary Color
              </Typography>
              <MuiColorInput
                value={values.companyGeneralSettings.palette.secondaryColor}
                format="hex"
                onChange={(color) => {
                  setFieldValue(
                    'companyGeneralSettings.palette.secondaryColor',
                    color
                  );
                  setFieldValue(
                    'companyGeneralSettings.palette.reset',
                    'false'
                  );
                }}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={1}>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button onClick={handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button type="button" onClick={handleClose}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
};

const fontFamily = [
  'Inter',
  'apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol'
];
const CompanyGeneralSettings = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched
}) => {
  const [themeShow, setThemeShow] = useState(false);
  const [color, setColor] = React.useState('#ffffff');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (color) => {
    setFieldValue('companyGeneralSettings.palette.primaryColor', color);
    setFieldValue('companyGeneralSettings.palette.reset', false);
  };

  const handleReset = () => {
    setFieldValue('companyGeneralSettings.palette.reset', 'true');
    setFieldValue('companyGeneralSettings.palette.secondaryColor', '#FF0000');
    setFieldValue('companyGeneralSettings.palette.primaryColor', '#0000ff');
  };
  // console.log('values', values);

  return (
    <Grid item xs={12}>
      <Stack rowGap={3} alignItems={'start'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Logo"
              variant="outlined"
              fullWidth
              onBlur={handleBlur}
              error={Boolean(
                touched?.companyGeneralSettings &&
                  touched?.companyGeneralSettings?.companyLogo &&
                  errors?.companyGeneralSettings &&
                  errors?.companyGeneralSettings?.companyLogo
              )}
              helperText={
                touched?.companyGeneralSettings &&
                touched?.companyGeneralSettings?.companyLogo &&
                errors?.companyGeneralSettings &&
                errors?.companyGeneralSettings?.companyLogo
              }
              name="companyGeneralSettings.companyLogo"
              value={values?.companyGeneralSettings?.companyLogo || ''}
              onChange={(e) =>
                setFieldValue(
                  'companyGeneralSettings.companyLogo',
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Name"
              variant="outlined"
              onBlur={handleBlur}
              error={Boolean(
                touched?.companyGeneralSettings &&
                  touched?.companyGeneralSettings?.companyName &&
                  errors?.companyGeneralSettings &&
                  errors?.companyGeneralSettings?.companyName
              )}
              helperText={
                touched?.companyGeneralSettings &&
                touched?.companyGeneralSettings?.companyName &&
                errors?.companyGeneralSettings &&
                errors?.companyGeneralSettings?.companyName
              }
              fullWidth
              name="companyGeneralSettings.companyName"
              value={values?.companyGeneralSettings?.companyName || ''}
              onChange={(e) =>
                setFieldValue(
                  'companyGeneralSettings.companyName',
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id={values?.companyGeneralSettings?.layouts}>
                Layout
              </InputLabel>
              <Select
                labelId={values?.companyGeneralSettings?.layouts}
                value={values?.companyGeneralSettings?.layouts || ''}
                onBlur={handleBlur}
                name="companyGeneralSettings.layouts"
                label="Layout"
                fullWidth
                onChange={(e) => {
                  setFieldValue(
                    `companyGeneralSettings.layouts`,
                    e.target.value
                  );
                }}
                MenuProps={MenuProps}
              >
                {LayoutOptions.length > 0 &&
                  LayoutOptions.map((filteredEntityName, index) => (
                    <MenuItem
                      key={`filteredEntityName_${index}`}
                      value={filteredEntityName}
                    >
                      {filteredEntityName}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {touched.companyGeneralSettings &&
                  touched.companyGeneralSettings.layouts &&
                  errors.companyGeneralSettings &&
                  errors.companyGeneralSettings.layouts}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                gap: 2,
                alignItems: 'center'
              }}
            >
              <Box id={`companyGeneralSettings.theme`}>
                <ModifiedTheme values={values} setFieldValue={setFieldValue} />
              </Box>
              <Button
                id={`companyGeneralSettings.palette`}
                color="primary"
                variant="contained"
                onClick={handleClickOpen}
              >
                Selct Colors
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id={`companyGeneralSettings.fontFamily`}>
                Select Font Family
              </InputLabel>
              <Select
                labelId={`companyGeneralSettings.fontFamily`}
                value={values.companyGeneralSettings.fontFamily || ''}
                onBlur={handleBlur}
                name="companyGeneralSettings.fontFamily"
                label="Select Font Family"
                fullWidth
                onChange={(e) => {
                  setFieldValue(
                    `companyGeneralSettings.fontFamily`,
                    e.target.value
                  );
                }}
                MenuProps={MenuProps}
              >
                {fontFamily.length > 0 &&
                  fontFamily.map((filteredEntityName, index) => (
                    <MenuItem
                      key={`filteredEntityName_${index}`}
                      value={filteredEntityName}
                    >
                      {filteredEntityName}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {touched.companyGeneralSettings &&
                  touched.companyGeneralSettings.fontFamily &&
                  errors.companyGeneralSettings &&
                  errors.companyGeneralSettings.fontFamily}
              </FormHelperText>
            </FormControl>
          </Grid>
          {open && (
            <PalletDialog
              values={values}
              setFieldValue={setFieldValue}
              open={open}
              handleClickOpen={handleClickOpen}
              handleChange={handleChange}
              handleClose={handleClose}
              handleReset={handleReset}
            />
          )}
        </Grid>
      </Stack>
    </Grid>
  );
};

export default CompanyGeneralSettings;

import { Paper, Stack, Typography } from '@mui/material';
import SupplierRow from './SupplierRow';

const SupplierList = ({ suppliers, title, selectedSuppliers, item }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2">{title}</Typography>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        {suppliers?.map((supplier, supplierIdx) => (
          <SupplierRow
            key={supplier.id}
            supplier={supplier}
            item={item}
            supplierIdx={supplierIdx}
            selectedSuppliers={selectedSuppliers}
            supplierLength={suppliers.length}
          />
        ))}
      </Paper>
    </Stack>
  );
};

export default SupplierList;

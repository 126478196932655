import React, { useState } from 'react';
import {
  ImageList,
  ImageListItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ImageGrid = ({ images, fieldName, handleDelete, loading }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Grid item xs={12} md={6}>
      <ImageList
        cols={3}
        rowHeight={100}
        sx={{ maxHeight: 300, height: '100%' }}
      >
        {images.map((image, index) => (
          <ImageListItem
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            sx={{ position: 'relative' }}
          >
            <img
              src={image.path}
              alt={`${fieldName}_Image ${index + 1}`}
              loading="lazy"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
            {image?.upload && (
              <Backdrop
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={image.upload}
              >
                {loading && <CircularProgress color="inherit" />}
              </Backdrop>
            )}
            <IconButton
              size="small"
              aria-label={`delete image ${index + 1}`}
              onClick={() => handleDelete(image, index)}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: 10000,
                display: hoveredIndex === index && !loading ? 'block' : 'none'
              }}
            >
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );
};

export default ImageGrid;

import { Stack, Typography } from '@mui/material';
import React from 'react';
import ItemsTable from 'src/components/ViewComponents/ItemTable';
import { useTranslation } from 'react-i18next';
import VerticalLabelValue from 'src/components/ViewComponents/VerticalLabelValue';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { capitalizeAndRemoveChar } from 'src/services/commonService';

const PurchaseReqView = ({ data }) => {
  const { entity, schema } = useSelector(selectDynamicPageConfig);
  const { t } = useTranslation();
  const { number: docNumber, createdAt: orderDate, items = [] } = data;
  return (
    <Stack rowGap={2}>
      <Typography textAlign={'center'} color={'primary'} variant={'h3'} mb={1}>
        {capitalizeAndRemoveChar(entity)}
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        rowGap={1}
      >
        <VerticalLabelValue label={t('Order No')} value={docNumber} />
        <VerticalLabelValue label={t('No of items')} value={items.length} />
        <VerticalLabelValue
          label={t('Order Date')}
          value={moment(orderDate).format('DD-MM-YYYY')}
        />
      </Stack>
      {items.length && <ItemsTable schema={schema} data={data} />}
    </Stack>
  );
};

export default PurchaseReqView;

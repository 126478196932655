/**
 * Transforms a flat schema with dot notation keys into a nested object structure.
 *
 * @param {Object} schema - The flat schema object where keys may contain dot notation
 *                           representing nested properties.
 * @returns {Object} - The nested schema object where keys are structured into
 *                      hierarchical levels based on the dot notation in the input schema.
 */
export const structureSchema = (schema) => {
  const result = {}; // Initialize an empty object to hold the nested schema structure.

  // Iterate over each key in the flat schema object.
  Object.keys(schema).forEach((key) => {
    const keyParts = key.split('.'); // Split the key into parts based on dot notation.

    if (keyParts.length === 1) {
      // If the key does not contain any dots, add it directly to the result.
      result[key] = schema[key];
    } else {
      // If the key contains dots, transform it into a nested structure.
      let current = result; // Start with the root of the result object.

      // Iterate over each part of the key.
      keyParts.forEach((part, index) => {
        if (index === 0) {
          // For the first part of the key, ensure the property exists as an object.
          if (!current[part]) {
            current[part] = { instance: 'Object', schema: {} }; // Initialize the nested structure.
          }
          current = current[part].schema; // Move deeper into the nested structure.
        } else {
          // For all subsequent parts, add the value to the final key.
          current[part] = schema[key];
        }
      });
    }
  });

  return result; // Return the transformed nested schema object.
};

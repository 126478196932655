import { useDispatch, useSelector } from 'react-redux';
import {
  changeLayout,
  selectCustomSettingData
} from 'src/slices/CustomThemeSettingSlice';
import {
  Typography,
  Stack,
  Divider,
  Grid,
  Skeleton,
  useTheme,
  Tooltip
} from '@mui/material';

const Layouts = () => {
  const { color, layout: selectedLayout } = useSelector(
    selectCustomSettingData
  );
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Stack rowGap={1}>
      <Typography variant="subtitle1">Layouts</Typography>
      <Grid container columnSpacing={1}>
        <Grid item xs={4}>
          <Tooltip title="AccentSidebarLayout">
            <Stack
              height={70}
              spacing={0.5}
              direction={'row'}
              p={0.5}
              onClick={() => dispatch(changeLayout('AccentSidebarLayout'))}
              sx={{
                border: '1px solid ' + theme.palette.divider,
                cursor: 'pointer'
              }}
              borderRadius={1}
            >
              <Stack spacing={0.5} width={'40%'}>
                <Skeleton
                  animation={false}
                  variant="circular"
                  sx={{
                    bgcolor:
                      selectedLayout === 'AccentSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'AccentSidebarLayout' ? 0.9 : 1
                  }}
                  width={15}
                  height={15}
                />
                <Skeleton
                  animation={false}
                  variant="rounded"
                  width={'100%'}
                  sx={{
                    bgcolor:
                      selectedLayout === 'AccentSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'AccentSidebarLayout' ? 0.7 : 1
                  }}
                  height={4}
                />
                <Skeleton
                  animation={false}
                  variant="rounded"
                  sx={{
                    bgcolor:
                      selectedLayout === 'AccentSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'AccentSidebarLayout' ? 0.5 : 1
                  }}
                  width={'80%'}
                  height={4}
                />
              </Stack>
              <Divider orientation={'vertical'} />
              <Skeleton
                animation={false}
                variant="rounded"
                sx={{
                  bgcolor:
                    selectedLayout === 'AccentSidebarLayout' ? color : '',
                  opacity: selectedLayout === 'AccentSidebarLayout' ? 0.4 : 1
                }}
                width={'60%'}
                height={'100%'}
              />
            </Stack>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="BoxedSidebarLayout">
            <Stack
              height={70}
              spacing={0.5}
              p={0.5}
              onClick={() => dispatch(changeLayout('BoxedSidebarLayout'))}
              sx={{
                border: '1px solid ' + theme.palette.divider,
                cursor: 'pointer'
              }}
              borderRadius={1}
            >
              <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                <Skeleton
                  animation={false}
                  variant="circular"
                  sx={{
                    bgcolor:
                      selectedLayout === 'BoxedSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'BoxedSidebarLayout' ? 0.9 : 1
                  }}
                  width={15}
                  height={15}
                />
                <Skeleton
                  animation={false}
                  variant="rounded"
                  width={20}
                  height={4}
                  sx={{
                    bgcolor:
                      selectedLayout === 'BoxedSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'BoxedSidebarLayout' ? 0.7 : 1
                  }}
                />
                <Skeleton
                  animation={false}
                  variant="rounded"
                  width={10}
                  height={4}
                  sx={{
                    bgcolor:
                      selectedLayout === 'BoxedSidebarLayout' ? color : '',
                    opacity: selectedLayout === 'BoxedSidebarLayout' ? 0.5 : 1
                  }}
                />
              </Stack>
              <Divider />
              <Skeleton
                animation={false}
                variant="rounded"
                width={'100%'}
                height={'100%'}
                sx={{
                  bgcolor: selectedLayout === 'BoxedSidebarLayout' ? color : '',
                  opacity: selectedLayout === 'BoxedSidebarLayout' ? 0.4 : 1
                }}
              />
            </Stack>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="CollapsedSidebarLayout">
            <Stack
              height={70}
              spacing={0.5}
              direction={'row'}
              p={0.5}
              onClick={() => dispatch(changeLayout('CollapsedSidebarLayout'))}
              sx={{
                border: '1px solid ' + theme.palette.divider,
                cursor: 'pointer'
              }}
              borderRadius={1}
            >
              <Stack spacing={0.5} width={'25%'} alignItems={'center'}>
                <Skeleton
                  animation={false}
                  variant="circular"
                  width={15}
                  height={15}
                  sx={{
                    bgcolor:
                      selectedLayout === 'CollapsedSidebarLayout' ? color : '',
                    opacity:
                      selectedLayout === 'CollapsedSidebarLayout' ? 0.9 : 1
                  }}
                />
                <Skeleton
                  animation={false}
                  variant="circular"
                  width={8}
                  height={8}
                  sx={{
                    bgcolor:
                      selectedLayout === 'CollapsedSidebarLayout' ? color : '',
                    opacity:
                      selectedLayout === 'CollapsedSidebarLayout' ? 0.7 : 1
                  }}
                />
                <Skeleton
                  animation={false}
                  variant="circular"
                  width={8}
                  height={8}
                  sx={{
                    bgcolor:
                      selectedLayout === 'CollapsedSidebarLayout' ? color : '',
                    opacity:
                      selectedLayout === 'CollapsedSidebarLayout' ? 0.5 : 1
                  }}
                />
              </Stack>
              <Divider orientation={'vertical'} />
              <Skeleton
                animation={false}
                variant="rounded"
                width={'75%'}
                height={'100%'}
                sx={{
                  bgcolor:
                    selectedLayout === 'CollapsedSidebarLayout' ? color : '',
                  opacity: selectedLayout === 'CollapsedSidebarLayout' ? 0.4 : 1
                }}
              />
            </Stack>
          </Tooltip>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Layouts;

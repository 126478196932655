import React from 'react';
import { Chip } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';

const DraggableChip = ({ option, getTagProps, index, requiredFieldNames }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: option });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition
  };

  // Check if option is in requiredFieldNames and modify label accordingly
  const label = requiredFieldNames.includes(option) ? `${option} *` : option;

  return (
    <Chip
      ref={setNodeRef}
      style={style}
      label={label}
      {...attributes}
      {...listeners}
      {...getTagProps({ index })}
    />
  );
};

export default DraggableChip;

import { Skeleton, TableCell, TableRow } from '@mui/material';

const TableLoader = ({ rows, cols }) => {
  return (
    <>
      {[...Array(rows)].map((_, index) => (
        <TableRow key={index}>
          {[...Array(cols)].map((_, innerIndex) => (
            <TableCell key={innerIndex}>
              <Skeleton variant="rounded" width={'100%'} height={20} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableLoader;

import React from 'react';
import { Avatar, Button, Chip, Stack, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const SupplierActions = ({
  selectedSuppliers,
  filteredSuppliers,
  handleModal
}) => {
  return (
    <Stack direction={'row'} gap={0.5} alignItems={'center'}>
      {selectedSuppliers?.map((supplier) => (
        <SupplierChip key={supplier.id} supplier={supplier} />
      ))}
      {!filteredSuppliers.length ? (
        <>
          <Button
            component={RouterLink}
            to={`/supplier_quotes/create`}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Add Supplier
          </Button>
        </>
      ) : Boolean(filteredSuppliers.length) &&
        !Boolean(selectedSuppliers?.length) ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={
            <Avatar
              src="/static/images/purchaseReq/selectSupplier.svg"
              sx={{ width: 20, height: 20, p: 0.2 }}
            />
          }
          sx={{ whiteSpace: 'nowrap' }}
          onClick={handleModal}
        >
          Select Supplier
        </Button>
      ) : (
        <Tooltip title={'Change Supplier'} arrow>
          <Avatar
            src="/static/images/purchaseReq/changeSupplier.svg"
            sx={{ width: 20, height: 20, p: 0.2, cursor: 'pointer' }}
            onClick={handleModal}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default SupplierActions;

function SupplierChip({ supplier }) {
  const { supplier: supplierDetails } = supplier;
  return (
    <Chip
      size="small"
      variant="outlined"
      m={0.5}
      label={supplierDetails?.name}
    />
  );
}

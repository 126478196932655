/* eslint-disable react/prop-types */
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TablePagination
} from '@mui/material';
import { useSelector } from 'react-redux';
import DynamicTableLoader from 'src/components/SkeltonLoaders/DynamicTableLoader';
import TableFilters from 'src/components/Tablefilters';
import {
  selectDynamicPageConfig,
  selectPaginator,
  selectStatus
} from 'src/slices/dynamicSlice';
import GenerateRows from './GenerateRows';
import TableHeadComponent from './TableHeaders';
import useDynamicTableLogic from './useDynamicTableLogic';
import BulkActions from './BulkActions';

const TableComponent = ({ title }) => {
  const { entity, fieldNames } = useSelector(selectDynamicPageConfig);
  const {
    handlePageChange,
    handleLimitChange,
    handleSelectAllRows,
    handleSelectOneRow,
    handleClearSelectedRow,
    handleSort,
    selectedRows,
    data,
    limit,
    sort,
    page
  } = useDynamicTableLogic(entity);

  const paginator = useSelector(selectPaginator);
  const { getData: isDataLoading } = useSelector(selectStatus);

  const isAllRowsSelected = selectedRows.length === data.length;
  const selectedSomeRows =
    selectedRows.length > 0 && selectedRows.length < data.length;
  const selectedBulkActions = selectedRows.length > 0;
  return (
    <>
      <Card variant="outlined">
        {!selectedBulkActions && <TableFilters />}
        {selectedBulkActions && (
          <BulkActions
            selectedItems={selectedRows}
            handleClearSelectedRow={handleClearSelectedRow}
          />
        )}
        <Divider />
        <TableContainer>
          <Table size="small">
            <TableHeadComponent
              fieldNames={fieldNames}
              handleSort={handleSort}
              sort={sort}
              isAllRowsSelected={isAllRowsSelected}
              selectedSomeRows={selectedSomeRows}
              handleSelectAllRows={handleSelectAllRows}
            />
            <TableBody>
              {isDataLoading ? (
                <DynamicTableLoader rowsNum={5} fieldNames={fieldNames} />
              ) : (
                <GenerateRows
                  endPoint={entity}
                  selectedRows={selectedRows}
                  title={title}
                  fieldNames={fieldNames}
                  handleSelectOneRow={handleSelectOneRow}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginator && (
          <Box p={2}>
            <TablePagination
              component="div"
              count={paginator?.itemCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[1, 3, 5, 10, 20]}
            />
          </Box>
        )}
      </Card>
    </>
  );
};

export default TableComponent;

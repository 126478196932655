import React, { createContext, useCallback, useEffect, useState } from 'react';

// Create the context
const ProcessReqContext = createContext();
const ProcessReqProvider = ({ children }) => {
  const [currentPurchaseReq, setCurrentPurchaseReq] = useState(null);
  const [allItemSuppliers, setItemSuppliers] = useState([]);

  useEffect(() => {
    if (currentPurchaseReq) {
      const { items = [] } = currentPurchaseReq;
      setItemSuppliers((prevItemSuppliers) => {
        const updatedItemSuppliers = prevItemSuppliers
          .map((prevItem) => {
            const { supplier, itemID } = prevItem;
            const foundItem = items.find((item) => item.id === itemID);
            if (foundItem) {
              const purchaseOrders = foundItem?.PO || [];
              const isSupplierInPurchaseOrders = purchaseOrders.some(
                (purchaseOrder) =>
                  purchaseOrder.supplier === supplier.supplier.id
              );
              return isSupplierInPurchaseOrders ? null : prevItem;
            }
            return prevItem;
          })
          .filter(Boolean); // Remove null values

        return updatedItemSuppliers;
      });
    }
  }, [currentPurchaseReq]);

  const updateCurrentPurchaseReq = useCallback((purchaseReq) => {
    setCurrentPurchaseReq(purchaseReq);
  }, []);

  const handleItemSuppliers = useCallback((supplier, itemID, qty) => {
    if (qty) {
      // Update the quantity if the supplier and item are already selected
      setItemSuppliers((prevSelected) =>
        prevSelected.some(
          (data) => data.supplier.id === supplier.id && data.itemID === itemID
        )
          ? prevSelected.map((prevItem) =>
              prevItem.supplier.id === supplier.id && prevItem.itemID === itemID
                ? { ...prevItem, qty }
                : prevItem
            )
          : [...prevSelected, { supplier, itemID, qty }]
      );
    } else {
      // Remove the supplier-item pair if no quantity is provided
      setItemSuppliers((prevSelected) =>
        prevSelected.filter(
          (data) =>
            !(data.supplier.id === supplier.id && data.itemID === itemID)
        )
      );
    }
  }, []);

  const updateCurrentPrItems = useCallback((updatedItems) => {
    setCurrentPurchaseReq((prevReq) => ({ ...prevReq, items: updatedItems }));
  }, []);

  return (
    <ProcessReqContext.Provider
      value={{
        allItemSuppliers,
        currentPurchaseReq,
        handleItemSuppliers,
        updateCurrentPurchaseReq,
        updateCurrentPrItems
      }}
    >
      {children}
    </ProcessReqContext.Provider>
  );
};

export { ProcessReqContext, ProcessReqProvider };

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  Chip,
  MenuItem
} from '@mui/material';

const CustomMultiSelectFieldWithLabel = ({
  name = '',
  value,
  onChange,
  options,
  label,
  error = null,
  touched = null
}) => {
  return (
    <FormControl fullWidth variant="outlined" error={touched && !!error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        multiple
        value={value || []}
        onChange={onChange}
        labelId={`${name}-label`}
        label={label}
      >
        {options?.map((option, index) => (
          <MenuItem key={`option_${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelectFieldWithLabel;

import { Divider, Stack, useTheme } from '@mui/material';
import React from 'react';
import UserBox from './UserBox';

const WaitingForDividerGroups = ({ waitingFor }) => {
  const theme = useTheme();
  return waitingFor.length > 0 ? (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {waitingFor.map((subArray, subIndex) => (
        <React.Fragment key={subIndex}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {subArray.map((approver, idx) => (
              <UserBox key={idx} user={approver} />
            ))}
          </Stack>
          {/* Add a divider between groups but not after the last group */}
          {subIndex < waitingFor.length - 1 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                width: 2,
                backgroundColor: theme.palette.primary.main
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Stack>
  ) : (
    '-'
  );
};
export default WaitingForDividerGroups;

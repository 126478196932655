import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { requestApi } from 'src/mocks/request';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import useRefMounted from 'src/hooks/useRefMounted';
import * as Icons from '@mui/icons-material';

const DynamicBulkActions = ({
  action,
  selectedItems,
  handleClearSelectedRow
}) => {
  const { targetEntity, actionName, icon, showIcon, buttonOrIcon } = action;
  const { entity } = useSelector(selectDynamicPageConfig);
  const [loading, setLoading] = useState(false);
  const isMounted = useRefMounted();

  const handleClick = async () => {
    setLoading(true);
    try {
      const payload = {
        endPoint: targetEntity,
        data: {
          from_entity: entity,
          from_id: selectedItems.map((selectedItem) => selectedItem.id)
        }
      };
      const response = await requestApi.docConvert(payload);
      if (response.status === 'SUCCESS') {
        handleClearSelectedRow();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <>
      {buttonOrIcon === 'icon' ? (
        <Tooltip title={actionName}>
          <IconButton onClick={handleClick}>
            {(loading ? (
              <CircularProgress size={20} sx={{ mr: 1 }} />
            ) : (
              showIcon &&
              icon &&
              React.createElement(Icons[icon], { fontSize: 'small' })
            )) || null}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={
            (loading ? (
              <CircularProgress size={20} sx={{ mr: 1 }} />
            ) : (
              showIcon &&
              icon &&
              React.createElement(Icons[icon], { fontSize: 'small' })
            )) || null
          }
          sx={{ whiteSpace: 'nowrap' }}
        >
          {actionName}
        </Button>
      )}
    </>
  );
};

export default DynamicBulkActions;

import { onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getPushNotification,
  selectLoggedUserData
} from 'src/slices/authSlice';
import {
  getAppConfigAsync,
  getCompanyEntitySettingAsync
  // getCompanyGeneralSettingAsync
} from 'src/slices/appConfigSlice';
import { getAllProjects } from 'src/slices/projectSlice';
import { generateToken, messaging } from 'src/utils/firebase';

const WorkspaceInitialization = ({ children }) => {
  const dispatch = useDispatch();
  const loggedUserData = useSelector(selectLoggedUserData);
  const [fcmToken, setFcmToken] = useState(null);
  console.log(
    loggedUserData,
    'loggedUserData in WorkspaceInitialization , called only after Workspace checks are passed'
  );
  // this will be used in protected route
  useEffect(async () => {
    const FCM_TOKEN = await generateToken();
    setFcmToken(FCM_TOKEN);
    onMessage(messaging, (payload) => {
      toast(payload.notification.body);
    });
  }, []);

  useEffect(() => {
    // Execute all actions in parallel
    Promise.all([
      dispatch(getAppConfigAsync()),
      // dispatch(getAllProjects({ endPoint: 'project' })),
      dispatch(
        getCompanyEntitySettingAsync({
          endPoint: 'setting',
          query: { setting_type: 'company_entity_settings' }
        })
      )
      // dispatch(
      //   getCompanyGeneralSettingAsync({
      //     endPoint: 'setting',
      //     query: { setting_type: 'company_general_settings' }
      //   })
      // )
    ]);
  }, [dispatch]);

  useEffect(() => {
    if (fcmToken)
      dispatch(
        getPushNotification({
          userId: loggedUserData?.id,
          playerId: fcmToken,
          deviceId: localStorage.getItem('deviceId')
        })
      );
  }, [fcmToken, dispatch]);

  return children;
};
export default WorkspaceInitialization;

import React from 'react';

const CustomSwitch = ({ checked, onChange }) => {
  const trackColor = checked ? '#4caf50' : '#f44336'; // Green when on, red when off
  const thumbColor = '#fff'; // White thumb color

  const trackStyle = {
    backgroundColor: trackColor,
    borderRadius: '15px', // Reduced border radius
    width: '40px', // Reduced width
    height: '20px', // Reduced height
    padding: '2px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.3s ease'
  };

  const thumbStyle = {
    width: '16px', // Reduced width
    height: '16px', // Reduced height
    backgroundColor: thumbColor,
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    transition: 'transform 0.3s ease',
    left: checked ? 'calc(100% - 18px)' : '2px' // Adjusted to move to the right end when on
  };

  const iconStyle = {
    width: '12px',
    height: '12px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-80%)'
  };

  const checkmarkIcon = (
    <div
      style={{ ...iconStyle, left: '4px', display: checked ? 'block' : 'none' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="12"
        width="12"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
        />
      </svg>
    </div>
  );

  const crossIcon = (
    <div
      style={{
        ...iconStyle,
        right: '4px',
        display: checked ? 'none' : 'block'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="12"
        width="12"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
        />
      </svg>
    </div>
  );

  return (
    <div style={trackStyle} onClick={onChange}>
      {checkmarkIcon}
      {crossIcon}
      <div style={thumbStyle}></div>
    </div>
  );
};

const SwitchComponent = ({ value }) => {
  return (
    <>
      <CustomSwitch checked={value} />
    </>
  );
};
export default SwitchComponent;

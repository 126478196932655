import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

const menuItems = [
  {
    heading: 'General',
    items: [
      {
        name: 'Dashboards',
        icon: SmartToyTwoToneIcon,
        link: '/extended-sidebar/dashboards',
        items: [
          {
            name: 'Overview',
            link: 'dashboards/reports',
            // badge: '',
            badgeTooltip: 'Dot indicator with example tooltip'
          }
        ]
      },
      {
        name: 'Management',
        icon: SmartToyTwoToneIcon,
        link: '/extended-sidebar/dashboards',
        items: [
          {
            name: 'Reports',
            link: 'dashboards/reports',
            // badge: '',
            badgeTooltip: 'Dot indicator with example tooltip'
          },
          {
            name: 'Healthcare',
            link: '/extended-sidebar/dashboards/healthcare',
            items: [
              {
                name: 'Doctors',
                link: 'dashboards/healthcare/doctor'
              },
              {
                name: 'Hospital',
                link: 'dashboards/healthcare/hospital'
              }
            ]
          }
        ]
      }
    ]
  }
];

export default menuItems;

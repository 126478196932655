import React from 'react';
import {
  Grid,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Stack,
  Button,
  CardActions
} from '@mui/material';
import { useFormikContext, FieldArray } from 'formik';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
import { useSelector } from 'react-redux';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import MuiIconSelector from './MuiIconSelector';

const actionTypes = [
  { label: 'Custom', value: 'custom' },
  { label: 'API', value: 'api' }
];
const actionLocation = [
  { label: 'Bulk', value: 'bulk' },
  { label: 'More', value: 'more' },
  { label: 'Pin', value: 'pin' }
];

const buttonOrIconOptions = [
  { label: 'Button', value: 'button' },
  { label: 'Icon', value: 'icon' }
];

const ActionSetting = () => {
  const { entities } = useSelector(selectAppConfigData);
  const { setFieldValue, values, handleChange, handleBlur } =
    useFormikContext();

  const handleActionLocationChange = (index, value) => {
    setFieldValue(`data[${index}].actionLocation`, value);

    if (!['pin', 'bulk'].includes(value.toLowerCase())) {
      // Reset fields if location is not 'Pin' or 'bulk'
      setFieldValue(`data[${index}].buttonOrIcon`, '');
      setFieldValue(`data[${index}].icon`, '');
      setFieldValue(`data[${index}].actionName`, '');
    }
  };
  return (
    <FieldArray name="data">
      {({ remove, push }) => (
        <Grid item xs={12}>
          <Stack spacing={1}>
            {values.data.map((dataItem, index) => (
              <Card key={index} variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelectFieldWithLabel
                        label={'Action Type'}
                        name={`data[${index}].actionType`}
                        value={dataItem.actionType || ''}
                        options={actionTypes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelectField
                        label={'Target Entity'}
                        name={`data[${index}].targetEntity`}
                        value={dataItem?.targetEntity || ''}
                        options={entities}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomSelectFieldWithLabel
                        label={'Action Location'}
                        name={`data[${index}].actionLocation`}
                        value={dataItem.actionLocation || ''}
                        options={actionLocation}
                        onChange={(event) =>
                          handleActionLocationChange(index, event.target.value)
                        }
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {dataItem.actionLocation && (
                      <>
                        {['pin', 'bulk'].includes(
                          dataItem.actionLocation.toLowerCase()
                        ) && (
                          <Grid item xs={12} sm={6} md={3}>
                            <CustomSelectFieldWithLabel
                              label={'Show As'}
                              name={`data[${index}].buttonOrIcon`}
                              value={dataItem?.buttonOrIcon || ''}
                              options={buttonOrIconOptions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={3}>
                          <CustomTextField
                            label={'Action Name'}
                            name={`data[${index}].actionName`}
                            value={dataItem.actionName || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={0.5}
                          >
                            <MuiIconSelector
                              label={'Select Icon'}
                              name={`data[${index}].icon`}
                              value={dataItem?.icon || ''}
                              onChange={(value) =>
                                setFieldValue(`data[${index}].icon`, value)
                              }
                              disabled={!dataItem.showIcon}
                              onBlur={handleBlur}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={dataItem.showIcon || false}
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue(
                                      `data[${index}].showIcon`,
                                      isChecked
                                    );
                                    if (!isChecked) {
                                      setFieldValue(`data[${index}].icon`, '');
                                    }
                                  }}
                                  color="primary"
                                />
                              }
                              sx={{ margin: 0 }}
                            />
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    size="small"
                    onClick={() => remove(index)}
                    color="error"
                  >
                    Remove Action
                  </Button>
                </CardActions>
              </Card>
            ))}

            <Button
              variant="text"
              size="small"
              sx={{ alignSelf: 'flex-start' }}
              color="primary"
              onClick={() =>
                push({
                  actionType: '',
                  actionLocation: '',
                  actionName: '',
                  targetEntity: '',
                  icon: '',
                  showIcon: true,
                  buttonOrIcon: ''
                })
              }
            >
              {values?.data?.length ? 'Add more action' : 'Add action'}
            </Button>
          </Stack>
        </Grid>
      )}
    </FieldArray>
  );
};

export default ActionSetting;

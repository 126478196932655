import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';

const Scrollbar = ({
  className,
  maxHeight = null,
  children,
  horizontal,
  ...rest
}) => {
  const theme = useTheme();

  const scrollbarStyles = {
    width: horizontal ? 'auto' : 5,
    height: horizontal ? 5 : 'auto',
    background: theme.palette.action.hover, // Adjust as per your theme
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('background'),

    '&:hover': {
      background: theme.palette.action.hover
    }
  };

  const scrollbar = <Box sx={scrollbarStyles} />;

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      renderThumbVertical={!horizontal ? () => scrollbar : undefined}
      renderThumbHorizontal={horizontal ? () => scrollbar : undefined}
      autoHeight={horizontal || maxHeight ? true : false}
      autoHeightMax={maxHeight ? maxHeight : 0}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  horizontal: PropTypes.bool
};

export default Scrollbar;

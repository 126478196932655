import { ListSubheader, Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from './item';
import { useSelector } from 'react-redux';
import { selectLoggedUserData } from 'src/slices/authSlice';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(1.5)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemIconColor};
      padding: ${theme.spacing(1, 3.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(5.5)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 0;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 4)};

          &:after {
            content: '';
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
            width: 0;
            opacity: 0;
            transition: ${theme.transitions.create(['opacity', 'width'])};
            background: ${theme.sidebar.menuItemColorActive};
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
          }

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          .MuiButton-endIcon {
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

          &.active {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(8)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.7, 4, 0.7, 4.25)};

              .MuiBadge-root {
                right: ${theme.spacing(5.5)};
              }

              &:before {
                content: ' ';
                background: ${theme.sidebar.menuItemIconColorActive};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }

                &:after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { companyEntitySettings, menuItems, workspaceMenuItems } =
    useSelector(selectAppConfigData);
  const user = useSelector(selectLoggedUserData);
  const isWorkspaceRoute = location.pathname.startsWith('/workspace-setting');
  function checkEntity(entity) {
    if (!entity) {
      return { isEntity: true, name: null };
    }

    const filterData = companyEntitySettings.find(
      (item) => item.entity_name === entity
    );

    if (filterData) {
      let isAllowed = filterData.allowed_user_types.includes(user?.userType);
      if (isAllowed) {
        const newName =
          filterData?.new_name !== '' ? filterData?.new_name : entity;
        return { isEntity: true, name: newName };
      } else {
        return false;
      }
    }
    return { isEntity: true, name: null };
  }
  const renderSidebarMenuItems = ({ items, path }) => (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
  );
  const reduceChildRoutes = ({ ev, path, item }) => {
    const key = item.name;
    let exactMatch = item?.link
      ? !!matchPath(
          {
            path: item?.link,
            end: true
          },
          path
        )
      : false;

    const entityName = !item?.items ? checkEntity(item?.entity) : false;
    if (entityName?.isEntity) {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={entityName.name ? entityName.name : item.name}
          link={
            isWorkspaceRoute
              ? `/workspace-setting/${item.entity}`
              : `/${item.link}`
          }
          icon={item?.icon}
        />
      );
    }
    if (item?.items) {
      const partialMatch = item?.link
        ? !!matchPath(
            {
              path: item?.link,
              end: false
            },
            path
          )
        : false;
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item?.icon}
          link={
            isWorkspaceRoute
              ? `/workspace-setting/${item.entity}`
              : `/${item.link}`
          }
        >
          {renderSidebarMenuItems({
            path,
            items: item?.items
          })}
        </SidebarMenuItem>
      );
    }
    return ev;
  };
  if (isWorkspaceRoute) {
    return workspaceMenuItems.map((section) => (
      <MenuWrapper key={section.heading}>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t(section.heading)}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </List>
      </MenuWrapper>
    ));
  } else {
    return menuItems.map((section) => (
      <MenuWrapper key={section.heading}>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t(section.heading)}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </List>
      </MenuWrapper>
    ));
  }
}

export default SidebarMenu;

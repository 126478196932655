import React from 'react';
import { Grid } from '@mui/material';
import FileItem from './FileItem';

const FileGrid = ({ files, handleDelete, loading }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {files.map((file, index) => (
          <FileItem
            key={index}
            index={index}
            file={file}
            handleDelete={handleDelete}
            loading={loading}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default FileGrid;

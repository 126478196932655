import { useFormikContext } from 'formik';
import React, { useMemo, useRef } from 'react';
import {
  capitalizeAndRemoveChar,
  getFieldType
} from 'src/services/commonService';
import CustomMultiSelectField from 'src/components/CustomFields/CustomMultiSelectField';
import CustomSelectFieldWithLabel from '../CustomFields/CustomSelectFieldWithLabel';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import FlatArryField from 'src/components/CustomFields/FlatArrayField';
import QuillEditorField from 'src/components/CustomFields/QuillEditorField';
import AccordionForm from './Accordian';
import DTAutocomplete from './DTAutocomplete';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import { getFieldOptions } from 'src/utils/getFieldOptions';
import InfoAdornment from './InfoAdornment';
import { useEffect } from 'react';
import { processAutoFillFields } from 'src/utils/processAutoFillFields';
import { getAllAutoFillFieldName } from 'src/utils/getAllAutoFillFieldName';
import FieldOverrideHandler from './FieldOverrideHandler';
import { useState } from 'react';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { FIELD_TO_OVERRIDE } from 'src/constant/constant';
import CustomDateField from 'src/components/CustomFields/CustomDateField';

const GetFieldBasedOnSchema = ({
  fieldName,
  schema,
  name,
  value,
  itemTableHead = null,
  label = null,
  touched,
  error,
  entitySetting,
  index
}) => {
  const {
    setFieldValue,
    handleChange,
    handleBlur,
    values: allValues
  } = useFormikContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isInitialRender = useRef(true);

  const {
    fieldType,
    isRequired,
    fieldLabel,
    fieldPlaceholder,
    isFieldDisabled,
    fieldInfo,
    autoFillByFieldName,
    allAutoFillFieldName
  } = useMemo(() => {
    let fieldType = getFieldType(itemTableHead || fieldName, schema);
    let fieldLabel = capitalizeAndRemoveChar(
      label || itemTableHead || fieldName
    );
    let options = getFieldOptions(itemTableHead || fieldName, schema);
    let isRequired = Boolean(options?.required);
    let fieldPlaceholder = options?.placeholder ?? null;
    let fieldInfo = options?.info ?? null;
    let isFieldDisabled = Boolean(options?.readOnly);
    let autoFillByFieldName = options?.afb ?? null;
    let allAutoFillFieldName = autoFillByFieldName
      ? getAllAutoFillFieldName(schema, autoFillByFieldName)
      : [];
    return {
      fieldType,
      isRequired,
      fieldLabel,
      fieldPlaceholder,
      isFieldDisabled,
      fieldInfo,
      autoFillByFieldName,
      allAutoFillFieldName
    };
  }, [schema, label, itemTableHead, fieldName]);

  const {
    isBoolean,
    isNumber,
    isString,
    isStringHasMaxNull,
    isDate,
    isStringEnum,
    isNumberEnum,
    isObjectID,
    isObject,
    isArray,
    isArrayEnum,
    isArrayWithObjectID,
    isArrayWithMultipleProperties,
    enumValues
  } = fieldType;

  // Effect to handle opening of the modal
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on initial render
    }
    const savedFieldName = localStorage.getItem('autoFillBy');
    localStorage.removeItem('autoFillBy');
    if (
      autoFillByFieldName &&
      value &&
      allAutoFillFieldName.length &&
      (!savedFieldName || savedFieldName === autoFillByFieldName)
    ) {
      setModalOpen(true);
    }
  }, [autoFillByFieldName, value, allAutoFillFieldName]);

  // Handle confirmation logic
  const handleConfirm = async () => {
    localStorage.setItem('autoFillBy', autoFillByFieldName);
    try {
      setLoading(true);
      await processAutoFillFields(
        value,
        allAutoFillFieldName,
        index,
        fieldName,
        itemTableHead,
        setFieldValue,
        allValues
      );
      setModalOpen(false); // Close the modal after processing
    } catch (error) {
      console.error('Error processing auto-fill fields:', error);
      // Optionally, handle the error by showing a notification or setting an error state
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  // Common props for form fields
  const commonProps = {
    name,
    ...(!itemTableHead && { label: fieldLabel }),
    placeholder: fieldPlaceholder || (itemTableHead ? `Add ${fieldLabel}` : ''),
    size: itemTableHead ? 'small' : 'medium',
    required: isRequired,
    readOnly: isFieldDisabled,
    touched: touched,
    error: error,
    ...(fieldInfo && {
      InputProps: {
        endAdornment: <InfoAdornment info={fieldInfo} />
      }
    })
  };

  /* Handle field overrides */
  if (FIELD_TO_OVERRIDE.includes(itemTableHead || fieldName)) {
    return (
      <FieldOverrideHandler
        fieldName={itemTableHead || fieldName}
        name={name}
        value={value}
      />
    );
  }
  return (
    <>
      {/* Render different form fields based on fieldType */}
      {isBoolean && (
        <CustomSelectFieldWithLabel
          {...commonProps}
          value={value}
          onBlur={handleBlur}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
          onChange={handleChange}
        />
      )}

      {((isString && !isStringEnum && !isStringHasMaxNull) ||
        (isNumber && !isNumberEnum)) && (
        <CustomTextField
          {...commonProps}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ type: isNumber ? 'number' : 'text' }}
          InputLabelProps={isDate ? { shrink: true } : {}}
        />
      )}
      {isDate && (
        <CustomDateField
          {...commonProps}
          value={value}
          onBlur={handleBlur}
          onChange={(date) => setFieldValue(name, date)}
        />
      )}
      {(isStringEnum || isNumberEnum) && (
        <CustomSelectField
          {...commonProps}
          value={value}
          options={enumValues}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
      {isArray &&
        !isArrayEnum &&
        !isArrayWithObjectID &&
        !isArrayWithMultipleProperties && (
          <FlatArryField
            {...commonProps}
            value={value || []}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue(name, value)}
          />
        )}
      {isArrayEnum && !isArrayWithObjectID && !isArrayWithMultipleProperties && (
        <CustomMultiSelectField
          {...commonProps}
          value={value}
          options={enumValues}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          onBlur={handleBlur}
        />
      )}
      {(isObjectID || isArrayWithObjectID) && (
        <DTAutocomplete
          {...commonProps}
          fieldName={fieldName}
          itemTableHead={itemTableHead}
          isMultiple={isArrayWithObjectID}
          value={value}
          schema={schema}
          onChange={(value) => setFieldValue(name, value || '')}
          entitySetting={entitySetting}
          onBlur={handleBlur}
        />
      )}
      {isStringHasMaxNull && (
        <QuillEditorField
          {...commonProps}
          onChange={(value) => setFieldValue(name, value)}
          value={value}
          onBlur={handleBlur}
        />
      )}
      {(isArrayWithMultipleProperties || isObject) && (
        <AccordionForm
          entitySetting={entitySetting}
          fieldName={fieldName}
          schema={schema}
          name={name}
          value={value}
          touched={touched}
          error={error}
        />
      )}

      <ConfirmationModal
        title="Confirm Auto-Fill"
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        isLoading={isLoading}
        description="Are you sure you want to proceed with auto-filling this field? This action will override the current value."
        confirmBtnConfig={{
          name: 'Yes, Proceed',
          variant: 'contained',
          color: 'primary'
        }}
        wayOutBtnConfig={{
          name: 'Cancel',
          variant: 'outlined',
          color: 'secondary'
        }}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default GetFieldBasedOnSchema;

import React, { useRef } from 'react';
import { Container, Box, Paper } from '@mui/material';
import PurchaseOrderView from './PurchaseOrderView';
import PurchaseReqView from './PurchaseReqView';
import MaterialReqView from './MaterialReqView';
import ReqForQuoView from './ReqForQuoView';
import GRNview from './GRNview';
import { useSelector } from 'react-redux';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import ViewAction from '../ViewAction';

// Function to render the appropriate view based on the entity type
const renderViewContent = (entity, data) => {
  switch (entity) {
    case 'purchase_request':
      return <PurchaseReqView data={data} />;
    case 'material_request':
      return <MaterialReqView data={data} />;
    case 'request_for_quotation':
      return <ReqForQuoView data={data} />;
    case 'grn':
      return <GRNview data={data} />;
    case 'purchase':
      return <PurchaseOrderView data={data} />;
    // Add more cases as needed
    default:
      return <p>Content not available for this entity.</p>;
  }
};

const CustomViewPage = ({ data }) => {
  const { entity } = useSelector(selectDynamicPageConfig);
  const componentRef = useRef(null);

  return (
    <Container maxWidth="md">
      <ViewAction componentRef={componentRef} />
      <Paper variant="outlined" sx={{ mt: 0.5 }}>
        <Box p={5} ref={componentRef}>
          {renderViewContent(entity, data)}
        </Box>
      </Paper>
    </Container>
  );
};

export default CustomViewPage;

import React, { useEffect, useState } from 'react';
import CardFieldSelect from './CardFieldSelect';
import { requestApi } from 'src/mocks/request';
import useRefMounted from 'src/hooks/useRefMounted';

const CheckFieldInstance = ({
  schema,
  fieldname,
  setFieldValue,
  propPath,
  handleBlur,
  cardEleItem,
  cardEleIndex
}) => {
  const isMountedRef = useRefMounted();
  const [nestedFieldNames, setNestedFieldNames] = useState([]);
  const [nestedSchema, setNestedSchema] = useState({});

  useEffect(() => {
    const checkField = async () => {
      const fieldInstance = schema[fieldname]?.instance;
      if (fieldInstance === 'String') {
        setFieldValue(
          `${propPath}.cardDesign[${cardEleIndex}].avatar`,
          fieldname
        );
      } else if (fieldInstance === 'ObjectID') {
        try {
          const response = await requestApi.getSchema({
            endPoint: schema[fieldname].options.ref
          });
          if (isMountedRef.current && response?.statusText === 'OK') {
            const schemaData = response?.data;
            setNestedSchema(schemaData);
            setNestedFieldNames(Object.keys(schemaData));
          } else {
            console.log(response?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    checkField();
  }, [fieldname, schema, setFieldValue, propPath, cardEleIndex, isMountedRef]);

  return nestedFieldNames.length > 0 ? (
    <CardFieldSelect
      fieldNames={nestedFieldNames}
      cardEleItem={cardEleItem}
      cardEleIndex={cardEleIndex}
      schema={nestedSchema}
      propPath={propPath}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      isNested={true}
    />
  ) : null;
};

export default CheckFieldInstance;

import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';
import { useSelector } from 'react-redux';

const PageTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expand'
})(
  ({ theme, expand }) => `
    padding-bottom: ${theme.spacing(expand ? 2 : 3)};
`
);

const PageTitleWrapper = ({ children }) => {
  const { expand } = useSelector(selectCustomSettingData);
  return (
    <>
      <PageTitle expand={expand} className="MuiPageTitle-wrapper">
        {children}
      </PageTitle>
    </>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageTitleWrapper;

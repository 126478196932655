import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomAvatar from 'src/components/CustomAvatar';

const RejectedCard = ({ rejectedStage }) => {
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Rejected By</Typography>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <CustomAvatar name={'no name k j k'} src={''} />
              <Typography variant="body2">John Doe, Workspace Admin</Typography>
            </Stack>
            <Box>
              <Typography variant="subtitle1">Remarks</Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet consectetur adipisci Lorem ipsum
                dolor sit amet consectetur adipisci Lorem ipsum dolor sit amet
                consectetur adipisci
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default RejectedCard;

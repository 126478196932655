import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';
const initialState = {
  modules: [],
  status: {
    getModule: false
  }
};

export const getModulesAsync = createAsyncThunk(
  'module/getModuledata',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      if (response?.status === 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    resetModuleState: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModulesAsync.pending, (state) => {
        state.status.getModule = true;
      })
      .addCase(getModulesAsync.fulfilled, (state, action) => {
        state.status.getModule = false;
        state.modules = action?.payload || [];
      })
      .addCase(getModulesAsync.rejected, (state) => {
        state.modules = [];
        state.status.getModule = false;
      });
  }
});

// selectors
export const { resetModuleState } = slice.actions;
export const selectModules = (state) => state.modules;
export default slice.reducer;

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import ActionCard from './ActionCard';
import TriggerCard from './TriggerCard';

const ReactWorkFlowComponent = () => {
  const { setFieldValue, values } = useFormikContext();

  // const nodeId = useRef(values.data.nodes.length);
  const lastActionId = useRef(values.data.nodes.length); // Track the last actionId used

  const [nodes, setNodes] = useState(values.data.nodes);
  const [edges, setEdges] = useState(values.data.edges);

  const nodeTypes = useMemo(
    () => ({
      trigger: TriggerCard,
      action: ActionCard
    }),
    []
  );

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        const newNodes = applyNodeChanges(changes, nds);
        setFieldValue('data.nodes', newNodes);
        return newNodes;
      });
    },
    [setFieldValue]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        const newEdges = applyEdgeChanges(changes, eds);
        setFieldValue('data.edges', newEdges);
        return newEdges;
      });
    },
    [setFieldValue]
  );

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      console.log(source, target, 'ok');

      const sourceNode = nodes.find((node) => node.id === source);
      const targetNode = nodes.find((node) => node.id === target);

      if (!sourceNode || !targetNode) {
        console.warn('Source or target node not found');
        return;
      }

      const sourceActionId = sourceNode.data.actionId;
      const targetActionId = targetNode.data.actionId;

      const actionIndex = values.actions.findIndex(
        (action) => action.action_id === targetActionId
      );

      if (actionIndex !== -1) {
        const updatedTargetAction = {
          ...values.actions[actionIndex],
          depends_on: [
            ...values.actions[actionIndex].depends_on,
            sourceActionId
          ]
        };

        const fieldPath = `actions[${actionIndex}]`;

        setFieldValue(fieldPath, updatedTargetAction);
      }

      setEdges((eds) => {
        const newEdges = addEdge({ ...params, animated: true }, eds);
        setFieldValue('data.edges', newEdges);
        return newEdges;
      });
    },
    [nodes, setFieldValue, values.actions]
  );

  const addNode = useCallback(
    (sourceId, position) => {
      const newActionId = ++lastActionId.current; // Increment and use the new actionId
      const newNodeId = `${newActionId}`; // Use actionId as node id

      setNodes((nds) => {
        const sourceNode = nds.find((node) => node.id === sourceId);
        if (!sourceNode) return nds;

        const newNode = [
          ...nds,
          {
            id: newNodeId,
            data: { actionId: newActionId, addNode, setEdges, setNodes },
            position: {
              x: sourceNode.position.x + position.x,
              y: sourceNode.position.y + position.y
            },
            type: 'action'
          }
        ];
        setFieldValue('data.nodes', newNode);
        return newNode;
      });

      setEdges((eds) => {
        const newEdges = [
          ...eds,
          {
            id: `e${sourceId}-${newNodeId}`,
            source: sourceId,
            target: newNodeId,
            animated: true
          }
        ];
        setFieldValue('data.edges', newEdges);
        return newEdges;
      });

      // Add the new action to the actions array
      let newAction = {
        action_id: newActionId,
        run_action: '',
        name: '',
        action_type: '',
        entity: '',
        crud_type: '',
        query: [],
        payload: [],
        conditions: [],
        depends_on: []
      };
      if (sourceId !== 'trigger') {
        newAction.depends_on = [parseInt(sourceId)];
        newAction.run_action = 'condition';
      }
      if (sourceId === 'trigger') {
        newAction.run_action = 'always';
      }
      setFieldValue(`actions[${values.actions.length}]`, newAction);
    },
    [setFieldValue, setEdges, setNodes, values.actions.length]
  );

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        data: { ...node.data, addNode, setEdges, setNodes }
      }))
    );
  }, [addNode, setEdges, setNodes]);

  return (
    <Box sx={{ height: '80vh', width: '75vw', border: '1px solid red' }}>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
    </Box>
  );
};

export default ReactWorkFlowComponent;

// import React, {
//   useState,
//   useCallback,
//   useEffect,
//   useRef,
//   useMemo
// } from 'react';
// import ReactFlow, {
//   Controls,
//   Background,
//   applyNodeChanges,
//   applyEdgeChanges,
//   addEdge
// } from 'reactflow';
// import 'reactflow/dist/style.css';
// import { Box } from '@mui/material';
// import { useFormikContext } from 'formik';
// import TriggerCard from './TriggerCard';

// const ReactWorkFlowComponent = () => {
//   const { setFieldValue, values } = useFormikContext();

//   // const nodeId = useRef(values.data.nodes.length);
//   const lastActionId = useRef(values.actions.length); // Track the last actionId used

//   const [nodes, setNodes] = useState(values.data.nodes);
//   const [edges, setEdges] = useState(values.data.edges);

//   const nodeTypes = useMemo(
//     () => ({
//       trigger: TriggerCard
//       // action: ActionCard
//     }),
//     []
//   );

//   const onNodesChange = useCallback(
//     (changes) => {
//       setNodes((nds) => {
//         const newNodes = applyNodeChanges(changes, nds);
//         setFieldValue('data.nodes', newNodes);
//         return newNodes;
//       });
//     },
//     [setFieldValue]
//   );

//   const onEdgesChange = useCallback(
//     (changes) => {
//       setEdges((eds) => {
//         const newEdges = applyEdgeChanges(changes, eds);
//         setFieldValue('data.edges', newEdges);
//         return newEdges;
//       });
//     },
//     [setFieldValue]
//   );

//   const onConnect = useCallback(
//     (params) => {
//       const { source, target } = params;
//       console.log(source, target, 'ok');

//       const sourceNode = nodes.find((node) => node.id === source);
//       const targetNode = nodes.find((node) => node.id === target);

//       if (!sourceNode || !targetNode) {
//         console.warn('Source or target node not found');
//         return;
//       }

//       const sourceActionId = sourceNode.data.actionId;
//       const targetActionId = targetNode.data.actionId;

//       const actionIndex = values.actions.findIndex(
//         (action) => action.action_id === targetActionId
//       );

//       if (actionIndex !== -1) {
//         const updatedTargetAction = {
//           ...values.actions[actionIndex],
//           depends_on: [
//             ...values.actions[actionIndex].depends_on,
//             sourceActionId
//           ]
//         };

//         const fieldPath = `actions[${actionIndex}]`;

//         setFieldValue(fieldPath, updatedTargetAction);
//       }

//       setEdges((eds) => {
//         const newEdges = addEdge({ ...params, animated: true }, eds);
//         setFieldValue('data.edges', newEdges);
//         return newEdges;
//       });
//     },
//     [nodes, setFieldValue, values.actions]
//   );

//   const addNode = useCallback(
//     (sourceId, position) => {
//       const newActionId = ++lastActionId.current; // Increment and use the new actionId
//       const newNodeId = `${newActionId}`; // Use actionId as node id

//       setNodes((nds) => {
//         const sourceNode = nds.find((node) => node.id === sourceId);
//         if (!sourceNode) return nds;

//         const newNode = {
//           id: newNodeId,
//           data: { actionId: newActionId, addNode, setEdges, setNodes },
//           position: {
//             x: sourceNode.position.x + position.x,
//             y: sourceNode.position.y + position.y
//           },
//           type: 'action'
//         };

//         return [...nds, newNode];
//       });

//       setEdges((eds) => {
//         const newEdges = [
//           ...eds,
//           {
//             id: `e${sourceId}-${newNodeId}`,
//             source: sourceId,
//             target: newNodeId,
//             animated: true
//           }
//         ];
//         setFieldValue('data.edges', newEdges);
//         return newEdges;
//       });

//       // Add the new action to the actions array
//       let newAction = {
//         action_id: newActionId,
//         name: '',
//         action_type: '',
//         entity: '',
//         crud_type: '',
//         query: [],
//         payload: [],
//         conditions: [],
//         depends_on: []
//       };
//       if (sourceId !== 'trigger') {
//         newAction.depends_on = [parseInt(sourceId)];
//       }
//       setFieldValue(`actions[${values.actions.length}]`, newAction);
//     },
//     [setFieldValue, setEdges, setNodes, values.actions.length]
//   );

//   useEffect(() => {
//     setNodes((nds) =>
//       nds.map((node) => ({
//         ...node,
//         data: { ...node.data, addNode, setEdges, setNodes }
//       }))
//     );
//   }, [addNode, setEdges, setNodes]);

//   return (
//     <Box sx={{ height: '80vh', width: '75vw', border: '1px solid red' }}>
//       <ReactFlow
//         nodes={nodes}
//         onNodesChange={onNodesChange}
//         edges={edges}
//         onEdgesChange={onEdgesChange}
//         onConnect={onConnect}
//         nodeTypes={nodeTypes}
//         fitView
//       >
//         <Background />
//         <Controls />
//       </ReactFlow>
//     </Box>
//   );
// };

// export default ReactWorkFlowComponent;

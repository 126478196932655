import { useSelector } from 'react-redux';
import WorkspaceAuthenticated from 'src/components/WorkspaceAuthenticated';
import WorkspaceInitialization from 'src/components/WorkspaceInitialization';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import { selectCustomSettingData } from 'src/slices/CustomThemeSettingSlice';
import { workspaceRoutes } from './workspaceRoutes';
import { publicRoutes } from './publicRoutes';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const SelectedLayout = () => {
  const { companyGeneralSetting } = useSelector(selectAppConfigData);
  const { layout: selectedLayoutData } = useSelector(selectCustomSettingData);
  const layout = companyGeneralSetting?.layouts; // Default if not found in API

  switch (selectedLayoutData || layout) {
    case 'AccentHeaderLayout':
      return <AccentHeaderLayout />;
    case 'TopNavigationLayout':
      return <TopNavigationLayout />;
    case 'DocsLayout':
      return <DocsLayout />;
    case 'CollapsedSidebarLayout':
      return <CollapsedSidebarLayout />;
    case 'AccentSidebarLayout':
      return <AccentSidebarLayout />;
    case 'BaseLayout':
      return <BaseLayout />;
    // ... cases for other layouts
    case 'ExtendedSidebarLayout':
      return <ExtendedSidebarLayout />;
    // ... cases for other layouts
    case 'BottomNavigationLayout':
      return <BottomNavigationLayout />;
    // ... cases for other layouts
    case 'BoxedSidebarLayout':
      return <BoxedSidebarLayout />;

    default:
      // Handle any unexpected layout values
      return <AccentSidebarLayout />; // Or any appropriate fallback
  }
};

// authenticated and has workspace
export const routes = [
  {
    path: '',
    element: (
      <WorkspaceAuthenticated>
        <WorkspaceInitialization>
          <SelectedLayout />
        </WorkspaceInitialization>
      </WorkspaceAuthenticated>
    ),

    children: workspaceRoutes
  },
  ...publicRoutes
];

import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography, Tooltip } from '@mui/material';

const ProgressComponent = ({ value, ...props }) => {
  const customProps = {
    ...props,
    sx: { ...(props.sx || {}), height: 10, cursor: 'pointer' }
  };

  return (
    <>
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
      <Box sx={{ width: '100%' }}>
        <Tooltip title={`${Math.round(value)}%`} aria-label="progress">
          <LinearProgress
            variant="determinate"
            value={value}
            {...customProps}
          />
        </Tooltip>
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box> */}
      {/* </Box> */}
    </>
  );
};

export default ProgressComponent;

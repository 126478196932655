import { ForwardToInboxTwoTone } from '@mui/icons-material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal.js';
import Setting from 'src/content/setting';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import InviteUser from './InviteUser';
import ModalForm from './ModalForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PageHeader = ({ title }) => {
  const location = useLocation();
  const { requiredFieldNames, entity } = useSelector(selectDynamicPageConfig);
  const navigate = useNavigate();
  const [showSettingModel, setShowSettingModel] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const handleSettingModel = () => {
    setShowSettingModel((prev) => !prev);
  };

  const handleInvitationModal = () => {
    setShowInvitationModal((prev) => !prev);
  };
  const handleModal = () => {
    setCreateModal(!createModal);
  };
  const handleCreate = () => {
    if (requiredFieldNames.length > 0) {
      handleModal();
    } else {
      navigate(`/${location.pathname.split('/')[1]}/create`);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {entity === 'user' && (
              <Button
                variant="outlined"
                endIcon={<ForwardToInboxTwoTone fontSize="small" />}
                onClick={handleInvitationModal}
              >
                Invite user
              </Button>
            )}

            <Button
              size="small"
              variant="outlined"
              endIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={handleCreate}
            >
              Create {title}
            </Button>
            <Tooltip title={'Table Setting'}>
              <IconButton onClick={handleSettingModel} color="primary">
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Dialog
            open={showSettingModel}
            onClose={handleSettingModel}
            sx={{ zIndex: '1000' }}
            TransitionComponent={Transition}
            fullScreen
          >
            <Grid container justifyContent={'center'} p={4}>
              <Grid item xs={12} md={10}>
                <Setting
                  selectedEntity={entity}
                  handleSettingModel={handleSettingModel}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      </Grid>
      <CommonModal
        open={showInvitationModal}
        handleModal={handleInvitationModal}
        title={`Invite user to workspace`}
        isForm={true}
      >
        <InviteUser handleInvitationModal={handleInvitationModal} />
      </CommonModal>
      <CommonModal
        open={createModal}
        handleModal={handleModal}
        title={`Add new ${title}`}
        isForm={true}
      >
        <ModalForm handleModal={handleModal} />
      </CommonModal>
    </>
  );
};
export default PageHeader;

import { useMemo } from 'react';

const useDomainInfo = () => {
  const domainInfo = useMemo(() => {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    const topLevelDomain = domainParts.pop();
    const secondLevelDomain = domainParts.pop();
    const subdomain = domainParts.join('.');

    if (topLevelDomain === 'localhost') {
      return {
        subdomain: null,
        domain: 'localhost',
        tld: null
      };
    }

    return {
      subdomain: subdomain || null,
      domain: secondLevelDomain || null,
      tld: topLevelDomain || null
    };
  }, [window.location.hostname]); // Dependency array includes hostname

  return domainInfo;
};

export default useDomainInfo;

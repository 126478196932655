import { Axios, OpenAxios } from 'src/services/axios';
import errorHandler from './errorHandler';
import successHandler from './successHandler';

class AuthApi {
  async login({ email, password }) {
    try {
      const obj = { username: email, password };
      const response = await OpenAxios.post(`/login`, obj);
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true
      });
      const { data } = response;
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }

  async register(obj) {
    try {
      const response = await OpenAxios.post('/register', obj);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true
      });
      const { data } = response;

      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }
  async registerAsGoogle() {
    try {
      const response = await OpenAxios.get('/login/google');
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true
      });
      const { data } = response;
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }

  async me(token) {
    try {
      console.log('me api before');
      // set token in header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await Axios.get('/user/me');
      console.log(response, 'auth.js 56')
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true
      });
      const { data } = response;
      console.log('me api after', data);
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error(error.message);
    }
  }
  async pushNotification(body) {
    try {
      const response = await OpenAxios.post(
        `/push-notification/addPlayerId`,
        body
      );
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true
      });
      const { data } = response;
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }
  async forgetPassword({ email }) {
    try {
      const obj = { email };
      const response = await OpenAxios.post(`/forgot-password`, obj);
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true
      });
      const { data } = response;
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }
  async resetPassword(obj) {
    try {
      const response = await OpenAxios.put(`/reset-password`, obj);
      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: true
      });
      const { data } = response;
      return data;
    } catch (error) {
      errorHandler(error);
      throw new Error('Internal server error');
    }
  }
}

export const authApi = new AuthApi();

import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import TableLoader from 'src/components/TableLoader';
import EditIcon from '@mui/icons-material/Edit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ItemsTable = ({
  items,
  isFetching,
  currentIndex,
  setCurrentIndex,
  isMobile
}) => {
  return (
    <Card variant="outlined">
      {/* sx={{ position: 'sticky', top: 140 }} */}
      <CardHeader title="Items" />
      <Divider />
      <CardContent>
        <TableContainer>
          <Table aria-label="items table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sr</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>UOM</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching && <TableLoader rows={3} cols={4} />}
              {items?.map((item, index) => (
                <TableRow
                  key={item.id}
                  // onClick={() => setCurrentIndex(index)}
                  selected={index === currentIndex && !isMobile}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.product?.name}</TableCell>
                  <TableCell>{item?.uom?.name}</TableCell>
                  <TableCell>{item?.quantity}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        index === currentIndex && !isMobile
                          ? 'Editing...'
                          : 'Edit Item'
                      }
                      placement="left"
                      arrow
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => setCurrentIndex(index)}
                      >
                        {index === currentIndex && !isMobile ? (
                          <ChevronRightIcon fontSize="small" />
                        ) : (
                          <EditIcon fontSize="small" /> // Default edit icon
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ItemsTable;

import { Stack, Typography, Popper, Paper } from '@mui/material';
import React, { useState } from 'react';
import CustomAvatar from 'src/components/CustomAvatar';

const UserBox = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { name, username, avatar, email, employee } = user || {};
  const displayName = name || username;

  return (
    <>
      <CustomAvatar
        name={displayName}
        src={avatar}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ cursor: 'pointer' }}
      />
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        sx={{ zIndex: 9999 }}
      >
        <Paper elevation={0} variant="outlined">
          <Stack direction="row" alignItems="start" spacing={1} p={1}>
            <CustomAvatar name={displayName} src={avatar} />
            <Stack>
              <Typography variant="h5">{displayName}</Typography>
              <Typography variant="subtitle2">{email}</Typography>
              {employee?.designation && (
                <Typography variant="subtitle1">
                  {employee.designation}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Paper>
      </Popper>
    </>
  );
};

export default UserBox;

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getEntityPageSchemaAsync,
  getEntityPageSettingAsync,
  selectAppConfigData
} from 'src/slices/appConfigSlice';

const useEntitySchemaAndSetting = () => {
  const { entityPageConfigs } = useSelector(selectAppConfigData);
  const dispatch = useDispatch();
  const getEntityConfig = async (entity) => {
    let refSchema = entityPageConfigs?.[entity]?.schema || null;

    if (refSchema) return refSchema;
    // Fetch schema and setting by dispatching the thunks and unwrap the results
    const [schemaResponse, settingResponse] = await Promise.all([
      dispatch(
        getEntityPageSchemaAsync({
          storekey: entity,
          endPoint: entity
        })
      ).unwrap(),
      dispatch(
        getEntityPageSettingAsync({
          storekey: entity,
          endPoint: 'setting',
          query: { entity: entity }
        })
      ).unwrap()
    ]);

    // Return the fetched schema if you need to use it locally
    return schemaResponse.response;
  };
  return { getEntityConfig };
};

export default useEntitySchemaAndSetting;

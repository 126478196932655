import React from 'react';
import { Stack, Typography } from '@mui/material';

const LabelValueDisplay = ({
  label,
  value,
  labelVariant = 'subtitle1',
  valueVariant = 'h5'
}) => (
  <Stack
    direction={'row'}
    alignItems={'center'}
    spacing={0.5}
    flexWrap={'wrap'}
  >
    <Typography variant={labelVariant}>{label}</Typography>
    <span>{' : '}</span>
    <Typography variant={valueVariant}>{value}</Typography>
  </Stack>
);

export default LabelValueDisplay;

import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import SettingTextfield from './settingFields/SettingTextField';
import { selectAppConfigData } from 'src/slices/appConfigSlice';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const CompanyEntitySettings = ({
  values,
  setFieldValue,
  touched,
  handleBlur,
  errors
}) => {
  const {
    entities,
    userTypes: userTypesObj,
    platform
  } = useSelector(selectAppConfigData);
  let userTypes = Object.entries(userTypesObj);
  let platformTypes = Object.entries(platform);
  const entityNameIsSelected = (entityName, currentIndex) => {
    for (let i = 0; i < currentIndex; i++) {
      if (values.data[i].entity_name === entityName) {
        return true;
      }
    }
    return false;
  };
  const { allPlatformValues, allUserValues } = useMemo(() => {
    const allUserValues = userTypes.map(
      ([propertyName, propertyValue]) => propertyValue
    );
    const allPlatformValues = platformTypes.map(
      ([propertyName, propertyValue]) => propertyValue
    );
    return { allUserValues, allPlatformValues };
  }, []);
  const handleCheckAll = (e, index) => {
    if (e.target.checked) {
      setFieldValue(`data[${index}].allowed_user_types`, allUserValues);
      setFieldValue(`data[${index}].allowed_platform_types`, allPlatformValues);
    } else {
      setFieldValue(`data[${index}].allowed_user_types`, []);
      setFieldValue(`data[${index}].allowed_platform_types`, []);
    }
  };

  return (
    <>
      {entities.length > 0 && userTypes && platformTypes && (
        <Grid item xs={12}>
          <Stack rowGap={3} alignItems={'start'}>
            <FieldArray
              name="data"
              render={({ insert, remove, push }) => (
                <>
                  {values.data.map((dataItem, index) => (
                    <Paper
                      elevation={2}
                      sx={{
                        paddingY: 3,
                        paddingX: 2,
                        width: '100%',
                        border: 1,
                        color: '#ccc'
                      }}
                      key={`dataItem_${index}`}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.data &&
                                touched.data[index]?.entity_name &&
                                errors.data &&
                                errors.data[index]?.entity_name
                            )}
                          >
                            <InputLabel id={`data_${index}_entity_name`}>
                              Entity Name
                            </InputLabel>
                            <Select
                              labelId={`data_${index}_entity_name`}
                              value={dataItem.entity_name}
                              onBlur={handleBlur}
                              name={`data[${index}].entity_name`}
                              label="Entity Name"
                              onChange={(e) => {
                                setFieldValue(
                                  `data[${index}].entity_name`,
                                  e.target.value
                                );
                              }}
                              MenuProps={MenuProps}
                            >
                              {entities.length > 0 &&
                                entities
                                  .filter(
                                    (entityName) =>
                                      !entityNameIsSelected(entityName, index)
                                  )
                                  .map((filteredEntityName, index) => (
                                    <MenuItem
                                      key={`filteredEntityName_${index}`}
                                      value={filteredEntityName}
                                    >
                                      {filteredEntityName}
                                    </MenuItem>
                                  ))}
                            </Select>
                            <FormHelperText>
                              {touched.data &&
                                touched.data[index]?.entity_name &&
                                errors.data &&
                                errors.data[index]?.entity_name}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="New Name"
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.data &&
                                touched.data[index]?.new_name &&
                                errors.data &&
                                errors.data[index]?.new_name
                            )}
                            helperText={
                              touched.data &&
                              touched.data[index]?.new_name &&
                              errors.data &&
                              errors.data[index]?.new_name
                            }
                            variant="outlined"
                            fullWidth
                            name={`data[${index}].new_name`}
                            value={dataItem.new_name}
                            onChange={(e) =>
                              setFieldValue(
                                `data[${index}].new_name`,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <SettingTextfield
                          handleBlur={handleBlur}
                          index={index}
                          errors={errors}
                          inputName={'icon'}
                          value={dataItem.icon}
                          setFieldValue={setFieldValue}
                          touched={touched}
                        />
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.data &&
                                touched.data[index]?.allowed_user_types &&
                                errors.data &&
                                errors.data[index]?.allowed_user_types
                            )}
                          >
                            <InputLabel id={`data_${index}_allowed_user_types`}>
                              Allowed User Type
                            </InputLabel>
                            <Select
                              labelId={`data_${index}_allowed_user_types`}
                              multiple
                              name={`data[${index}].allowed_user_types`}
                              onBlur={handleBlur}
                              value={dataItem.allowed_user_types}
                              onChange={(e) =>
                                setFieldValue(
                                  `data[${index}].allowed_user_types`,
                                  e.target.value
                                )
                              }
                              input={
                                <OutlinedInput label="Allowed User Type" />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={`userTypes_${value}`}
                                      label={
                                        userTypes &&
                                        userTypes.find(
                                          (item) => item[1] === value
                                        )[0]
                                      }
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {userTypes &&
                                userTypes.map(
                                  ([propertyName, propertyValue]) => (
                                    <MenuItem
                                      key={`userTypes_${propertyName}`}
                                      value={propertyValue}
                                    >
                                      <Checkbox
                                        checked={Boolean(
                                          dataItem.allowed_user_types.includes(
                                            propertyValue
                                          )
                                        )}
                                      />
                                      <ListItemText primary={propertyName} />
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                            <FormHelperText>
                              {touched.data &&
                                touched.data[index]?.allowed_user_types &&
                                errors.data &&
                                errors.data[index]?.allowed_user_types}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl
                            fullWidth
                            error={Boolean(
                              touched.data &&
                                touched.data[index]?.allowed_platform_types &&
                                errors.data &&
                                errors.data[index]?.allowed_platform_types
                            )}
                          >
                            <InputLabel
                              id={`data_${index}_allowed_platform_types`}
                            >
                              Allowed Platform Type
                            </InputLabel>
                            <Select
                              labelId={`data_${index}_allowed_platform_types`}
                              multiple
                              onBlur={handleBlur}
                              name={`data[${index}].allowed_platform_types`}
                              value={dataItem.allowed_platform_types}
                              onChange={(e) =>
                                setFieldValue(
                                  `data[${index}].allowed_platform_types`,
                                  e.target.value
                                )
                              }
                              input={
                                <OutlinedInput label="Allowed Platform Type" />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={`platformTypes_${value}`}
                                      label={
                                        platformTypes &&
                                        platformTypes.find(
                                          (item) => item[1] === value
                                        )[0]
                                      }
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {platformTypes &&
                                platformTypes.map(
                                  ([propertyName, propertyValue]) => (
                                    <MenuItem
                                      key={`platformTypes_${propertyName}`}
                                      value={propertyValue}
                                    >
                                      <Checkbox
                                        checked={Boolean(
                                          dataItem.allowed_platform_types.includes(
                                            propertyValue
                                          )
                                        )}
                                      />
                                      <ListItemText primary={propertyName} />
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                            <FormHelperText>
                              {touched.data &&
                                touched.data[index]?.allowed_platform_types &&
                                errors.data &&
                                errors.data[index]?.allowed_platform_types}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          alignItems={'center'}
                          display={'flex'}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  dataItem.allowed_user_types.length ===
                                    userTypes.length &&
                                  dataItem.allowed_platform_types.length ===
                                    platformTypes.length
                                }
                                onChange={(e) => handleCheckAll(e, index)}
                              />
                            }
                            label="Check All"
                          />
                          <Tooltip title="Remove Row">
                            <Button
                              color="error"
                              variant="contained"
                              onClick={() => remove(index)}
                            >
                              <CloseIcon size="small" />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                  <Tooltip title="Add Row">
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        push({
                          entity_name: '',
                          new_name: '',
                          icon: '',
                          allowed_user_types: allUserValues,
                          allowed_platform_types: allPlatformValues
                        })
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </>
              )}
            />
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default CompanyEntitySettings;

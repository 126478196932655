import { Button, Stack } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// Helper function to determine button icon based on stage
const getBtnIcon = (stage, currentStage, isRejected, isApproved) => {
  if (isRejected) {
    return <CancelOutlinedIcon />;
  } else if (isApproved || stage < currentStage) {
    return <TaskAltOutlinedIcon />;
  } else if (stage > currentStage) {
    return <LockOutlinedIcon />;
  } else {
    return <AccessTimeOutlinedIcon />;
  }
};

// Function to render a single stage button
const ButtonItems = ({ stage, currentStage, status }) => {
  const isRejected = status === 'REJECTED' && currentStage === stage;
  const isApproved = status === 'APPROVED';
  return (
    <Button
      color={
        isRejected
          ? 'error'
          : isApproved || stage < currentStage
          ? 'success'
          : stage === currentStage
          ? 'warning'
          : 'secondary'
      }
      variant={stage <= currentStage ? 'contained' : 'outlined'}
      endIcon={getBtnIcon(stage, currentStage, isRejected, isApproved)}
      sx={{ cursor: 'default' }}
    >
      Stage {stage}
    </Button>
  );
};

const StageButtons = ({ totalStages, currentStage, status }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      {Array.from({ length: totalStages || 0 }).map((_, index) => (
        <ButtonItems
          key={`stage_${index}`}
          stage={index + 1}
          currentStage={currentStage}
          status={status}
        />
      ))}
    </Stack>
  );
};

export default StageButtons;

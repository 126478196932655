import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestApi } from 'src/mocks/request';
const initialState = {
  widgets: [],
  status: {
    getWidget: true
  }
};

export const getWidgetAsync = createAsyncThunk(
  'widget/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestApi.getData(data);
      console.log(response, 'widget response');
      if (response?.status === 'SUCCESS') {
        return response?.data?.data;
      }
      return rejectWithValue(response?.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    resetWidgetState: () => initialState, // reset slice state
    deleteWidgetById: (state, action) => {
      const widgetIdToDelete = action.payload;
      state.widgets = state.widgets.filter(
        (widget) => widget.id !== widgetIdToDelete
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetAsync.pending, (state) => {
        state.status.getWidget = true;
      })
      .addCase(getWidgetAsync.fulfilled, (state, action) => {
        state.status.getWidget = false;
        state.widgets = action?.payload || [];
      })
      .addCase(getWidgetAsync.rejected, (state) => {
        state.widgets = [];
        state.status.getWidget = false;
      });
  }
});
export const { resetWidgetState, deleteWidgetById } = slice.actions;
export const selectWidget = (state) => state.widgets;
export default slice.reducer;

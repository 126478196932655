import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Button,
  CircularProgress,
  Box
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import GetFieldBasedOnSchema from 'src/components/GetFieldBasedOnSchema';
import Scrollbar from 'src/components/Scrollbar';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { getFieldOptions } from 'src/utils/getFieldOptions';

const AddItemForm = ({ items, setCurrentIndex, currentIndex }) => {
  const { schema, entitySetting } = useSelector(selectDynamicPageConfig);
  const { values, errors, touched } = useFormikContext();
  const [loading, setLoading] = useState(true);

  const { itemSchema, itemTableHeads } = useMemo(() => {
    let itemSchema = schema?.items?.$embeddedSchemaType?.schema?.paths;

    const itemTableHeads = Object.keys(itemSchema).filter((key) => {
      let options = getFieldOptions(key, itemSchema);
      return key !== '_id' && key !== 'id' && !options?.hidden;
    });

    return {
      itemSchema,
      itemTableHeads
    };
  }, [schema]);

  const currentItem = values?.items?.[currentIndex];

  // Handle loader display on item change
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  if (loading)
    return (
      <Box
        sx={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Card variant="outlined">
      <CardHeader title={`Edit ${currentItem?.product?.name}`} />
      <Divider />
      <CardContent>
        <Scrollbar maxHeight={'45vh'}>
          <Grid container spacing={1} py={1}>
            {itemTableHeads.map((itemTableHead) => (
              <Grid item xs={12} key={itemTableHead}>
                <GetFieldBasedOnSchema
                  name={`items[${currentIndex}].${itemTableHead}`}
                  fieldName={itemTableHead}
                  entitySetting={entitySetting}
                  schema={itemSchema}
                  value={currentItem?.[itemTableHead]}
                  error={errors?.items?.[currentIndex]?.[itemTableHead]}
                  touched={touched?.items?.[currentIndex]?.[itemTableHead]}
                />
              </Grid>
            ))}
          </Grid>
        </Scrollbar>
        <Stack direction="row" mt={2} spacing={1} justifyContent="center">
          <Button
            size="small"
            variant="outlined"
            onClick={() => setCurrentIndex(currentIndex - 1)}
            disabled={currentIndex === 0}
          >
            Prev
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={() => setCurrentIndex(currentIndex + 1)}
            disabled={currentIndex >= items?.length - 1}
          >
            Next
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AddItemForm;

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'formik';
import GetFieldBasedOnSchema from '..';

const AccordianTable = ({
  itemTableHeads,
  schema,
  entitySetting,
  fieldName,
  value,
  touched,
  error
}) => {
  const theme = useTheme();
  return (
    <>
      <FieldArray
        name={fieldName}
        render={({ remove, push }) => (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {itemTableHeads.map((itemTableHead, index) => (
                      <TableCell
                        sx={{
                          // minWidth: 270,
                          // background: theme.colors.alpha.black[5]
                          background: theme.colors.alpha.white[100]
                        }}
                        key={`${fieldName}_header_${index}`}
                      >
                        {itemTableHead}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        // background: theme.colors.alpha.black[5]
                        background: theme.colors.alpha.white[100]
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.map((dataItem, valueIndex) => (
                    <TableRow key={`${fieldName}_row_${valueIndex}`}>
                      {itemTableHeads.map((itemTableHead, itemIndex) => (
                        <TableCell key={`${fieldName}_field_${itemIndex}`}>
                          <GetFieldBasedOnSchema
                            name={`${fieldName}.${[
                              valueIndex
                            ]}.${itemTableHead}`}
                            entitySetting={entitySetting}
                            fieldName={fieldName}
                            itemTableHead={itemTableHead}
                            schema={schema}
                            index={valueIndex}
                            value={dataItem[itemTableHead]}
                            touched={touched?.[valueIndex]?.[itemTableHead]}
                            error={error?.[valueIndex]?.[itemTableHead]}
                          />
                        </TableCell>
                      ))}

                      <TableCell>
                        <Tooltip title="Remove Row">
                          <IconButton
                            sx={{ mt: 1 }}
                            color="error"
                            onClick={() => remove(valueIndex)}
                          >
                            <CloseIcon size="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box px={2} p={1}>
              <Tooltip title="Add Row">
                <IconButton
                  color="primary"
                  onClick={() => {
                    const newItem = itemTableHeads.reduce(
                      (accumulator, itemTableHead) => {
                        accumulator[itemTableHead] = '';
                        return accumulator;
                      },
                      {}
                    );

                    push(newItem);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default AccordianTable;

import { Avatar, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import RFQForm from './RFQForm';
import { useMemo } from 'react';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
import { useContext } from 'react';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';
const endPoint = 'request_for_quotation';
const CreateRFQ = ({ item }) => {
  const theme = useTheme();
  const { currentPurchaseReq } = useContext(ProcessReqContext);
  const [openModal, setOpenModal] = useState(false);
  const { schema } = useEntityPageConfig(endPoint);
  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const formData = useMemo(() => {
    const { product, uom, qty } = item || {};
    return {
      purchase_request: currentPurchaseReq,
      items: [
        {
          ...(product && { product }),
          ...(uom && { uom }),
          ...(qty && { qty })
        }
      ]
    };
  }, [item]);

  return (
    <>
      <MenuItem onClick={handleModal} disableRipple>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            src="/static/images/purchaseReq/createRFQ.svg"
            sx={{
              width: 20,
              height: 20,
              p: 0.2,
              filter: `${
                theme.palette.mode === 'light'
                  ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                  : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
              }`
            }}
          />
          <Typography variant="h6">Create RFQ</Typography>
        </Stack>
      </MenuItem>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={'Create RFQ'}
        maxWidth="md"
      >
        {schema && (
          <RFQForm
            entity={endPoint}
            items={[item]}
            schema={schema}
            formData={formData}
            handleModal={handleModal}
          />
        )}
      </CommonModal>
    </>
  );
};

export default CreateRFQ;

import { InputAdornment, Stack, Typography } from '@mui/material';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useMemo } from 'react';
import SupplierList from './SupplierList';

const ChooseSupplier = ({ suppliers, selectedSuppliers, item }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter suppliers based on name and email
  const filteredSuppliers = useMemo(() => {
    return suppliers.filter((supplier) => {
      const nameMatch = supplier?.supplier?.name
        ?.toLowerCase()
        ?.includes(searchTerm.toLowerCase());
      const emailMatch = supplier?.supplier?.email
        ?.toLowerCase()
        ?.includes(searchTerm.toLowerCase());
      return nameMatch || emailMatch;
    });
  }, [searchTerm, suppliers]);

  // Determine which suppliers to show in "Selected Suppliers"
  const filteredSelSuppliers = filteredSuppliers.filter((supplier) =>
    selectedSuppliers.some(
      (selectedSupplier) => selectedSupplier.id === supplier.id
    )
  );

  // Determine which suppliers to show in "Other Suppliers"
  const availableSuppliers = filteredSuppliers.filter(
    (supplier) =>
      !selectedSuppliers.some(
        (selectedSupplier) => selectedSupplier.id === supplier.id
      )
  );

  // Determine if there are no suppliers matching the search term
  const noSuppliersFound = searchTerm && filteredSuppliers.length === 0;

  return (
    <Stack spacing={2}>
      <CustomTextField
        size="small"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search Suppliers..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="secondary" />
            </InputAdornment>
          )
        }}
      />
      {Boolean(filteredSelSuppliers.length) && (
        <SupplierList
          title={'Selected Suppliers'}
          item={item}
          selectedSuppliers={selectedSuppliers}
          suppliers={filteredSelSuppliers}
        />
      )}
      {Boolean(availableSuppliers.length) && (
        <SupplierList
          title={
            Boolean(selectedSuppliers.length)
              ? 'Other Suppliers'
              : 'Available Suppliers'
          }
          item={item}
          selectedSuppliers={selectedSuppliers}
          suppliers={availableSuppliers}
        />
      )}
      {noSuppliersFound && (
        <Typography variant="body2" color="error">
          No suppliers match the search query "{searchTerm}".
        </Typography>
      )}
    </Stack>
  );
};

export default ChooseSupplier;

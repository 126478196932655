import {
  createSettingsAsync,
  updateSettingAsync
} from 'src/slices/settingSlice';

export const handleSubmitForm = async ({
  dispatch,
  selectedRowData,
  values,
  resetForm,
  setStatus,
  setSubmitting,
  setErrors,
  selectedEntity,
  isMountedRef,
  toast
}) => {
  try {
    if (selectedRowData) {
      if (values.setting_type === 'company_general_settings') {
        await dispatch(
          updateSettingAsync({
            endPoint: 'setting',
            selectedEntity,
            data: {
              entity: values.entity,
              setting_type: values.setting_type,
              data: values.companyGeneralSettings
            },
            id: selectedRowData?.id
          })
        );
        // window.location.reload(false);
      } else if (values.setting_type === 'actions') {
        await checkEntityCaseAndAlert(
          values,
          selectedRowData,
          dispatch,
          toast,
          selectedEntity
        );
      } else {
        await dispatch(
          updateSettingAsync({
            endPoint: 'setting',
            selectedEntity,
            data: {
              entity: values.entity,
              setting_type: values.setting_type,
              data: values.data
            },
            id: selectedRowData?.id
          })
        );
      }
    } else {
      if (values.setting_type === 'company_general_settings') {
        await dispatch(
          createSettingsAsync({
            endPoint: 'setting',
            selectedEntity,
            data: {
              entity: values.entity,
              setting_type: values.setting_type,
              data: values.companyGeneralSettings
            }
          })
        );
        // window.location.reload(false);
      } else if (values.setting_type === 'actions') {
        await checkEntityCaseAndAlert(
          values,
          selectedRowData,
          dispatch,
          toast,
          selectedEntity
        );
      } else {
        await dispatch(
          createSettingsAsync({
            endPoint: 'setting',
            selectedEntity,
            data: {
              entity: values.entity,
              setting_type: values.setting_type,
              data: values.data
            }
          })
        );
      }
    }
    if (isMountedRef.current) {
      setStatus({ success: true });
      setSubmitting(false);
      resetForm();
    }
  } catch (err) {
    console.error(err);
    if (isMountedRef.current) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  }
};

function checkTargetEntity(mapping, entity, depth) {
  if (!mapping) {
    return false; // No mapping, no mismatch
  }

  const targetField = depth === 1 ? 'target_field_1' : `target_field_${depth}`;
  return mapping[targetField] && mapping[targetField].toLowerCase() !== entity;
}

async function checkEntityCaseAndAlert(
  values,
  selectedRowData,
  dispatch,
  toast,
  selectedEntity
) {
  const entity = values.entity.toLowerCase();
  const matchingData = values.data.find(
    (item) =>
      ((item?.crud?.toLowerCase() === 'create' ||
        item?.crud?.toLowerCase() === 'bulk create') &&
        checkTargetEntity(item.payload?.mapping, entity, 1)) ||
      (item.payload?.mapping.depth === 2 &&
        checkTargetEntity(item.payload?.mapping, entity, 2))
  );

  if (matchingData) {
    toast.error(
      'Operation not possible: Entity case mismatch for create/bulk create actions.'
    );
    return;
  }
  if (selectedRowData) {
    await dispatch(
      updateSettingAsync({
        endPoint: 'setting',
        selectedEntity,
        data: {
          entity: values.entity,
          setting_type: values.setting_type,
          data: values.data
        },
        id: selectedRowData?.id
      })
    );
  } else {
    await dispatch(
      createSettingsAsync({
        endPoint: 'setting',
        selectedEntity,
        data: {
          entity: values.entity,
          setting_type: values.setting_type,
          data: values.data
        }
      })
    );
  }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectLoggedUserData } from 'src/slices/authSlice';

const WorkspaceAdminAuth = ({ element }) => {
  const { employee = null } = useSelector(selectLoggedUserData) || {};
  if (!employee || !employee.is_workspace_admin) {
    return <Navigate to="/dashboard" replace />;
  }

  return element;
};

export default WorkspaceAdminAuth;

import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConditionFields from '../ConditionField';
import CustomSelectFIeld from 'src/components/CustomFields/CustomSelectField';
const ActionCheck = ({ action, conditionPath, btnName = null }) => {
  const conditionArr = action.conditions;
  const { setFieldValue, values } = useFormikContext();

  const handleActionChange = (event, conditionIndex) => {
    const actionId = event.target.value;
    setFieldValue(`${conditionPath}.${conditionIndex}.action`, actionId);
    if (actionId === 'trigger') {
      setFieldValue(
        `${conditionPath}.${conditionIndex}.entity`,
        values?.entity
      );
    } else {
      const selectedAction = values.actions.find(
        (action) => action.action_id === actionId
      );
      setFieldValue(
        `${conditionPath}.${conditionIndex}.entity`,
        selectedAction?.entity
      );
    }
  };
  const handlFieldNameChange = (event, conditionIndex, schema) => {
    const value = event.target.value;
    const payload = {
      ...conditionArr[conditionIndex],
      field_name: value,
      field_operator: '',
      field_value: '',
      field_join: '',
      schema: schema[value]
    };
    setFieldValue(`${conditionPath}.${conditionIndex}`, payload);
  };
  return (
    <FieldArray name={conditionPath}>
      {({ push, remove }) => (
        <>
          {conditionArr?.map((condition, conditionIndex) => (
            <Grid item xs={12} key={conditionIndex}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={2}>
                  <CustomSelectFIeld
                    name={`${conditionPath}.${conditionIndex}.action`}
                    label="Select Action"
                    value={condition?.action || ''}
                    options={action.depends_on}
                    onChange={(event) =>
                      handleActionChange(event, conditionIndex)
                    }
                  />
                </Grid>
                {condition.entity && (
                  <ConditionFields
                    conditionPath={conditionPath}
                    conditionIndex={conditionIndex}
                    condition={condition}
                    handlFieldNameChange={handlFieldNameChange}
                    conditionArr={conditionArr}
                    entity={condition.entity}
                  />
                )}
                <Grid item xs={1}>
                  <IconButton onClick={() => remove(conditionIndex)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Tooltip title={btnName || 'Add Condition'}>
              <IconButton
                onClick={() =>
                  push({
                    action: '',
                    entity: '',
                    field_name: '',
                    field_operator: '',
                    field_value: '',
                    field_join: ''
                  })
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ActionCheck;

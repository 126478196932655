import { Avatar, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import React, { useState, useMemo } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import { toast } from 'react-toastify';
import CreatePoForm from './CreatePoForm';
import TableStapper from './TableStapper';
import useEntityPageConfig from 'src/hooks/useEntityPageConfig';

const endPoint = 'purchase_order';

const CreatePo = ({ item, selectedSuppliers }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { schema, entitySetting } = useEntityPageConfig(endPoint);

  // Calculate formData for each step
  const formData = useMemo(() => {
    return selectedSuppliers.map((selectedSupplier) => {
      const { product, uom } = item || {};
      const { supplier, qty, price } = selectedSupplier || {};
      return {
        supplier,
        items: [
          {
            ...(product && { product }),
            ...(uom && { uom }),
            ...(qty && { quantity: parseInt(qty, 10) }),
            ...(price && { price })
          }
        ]
      };
    });
  }, [item, selectedSuppliers]);
  const steps = formData.length + activeStep;

  const handleModal = () => {
    if (!openModal && steps === 0) {
      toast.warning('At least one supplier must be selected to create a PO.');
    } else {
      setOpenModal((prev) => !prev);
    }
  };
  // Handle step navigation
  const handleNext = () => {
    if (activeStep !== steps - 1) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps - 1));
    } else {
      handleModal();
    }
  };

  return (
    <>
      <MenuItem onClick={() => handleModal()} disableRipple>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            src="/static/images/purchaseReq/createPo.svg"
            sx={{
              width: 20,
              height: 20,
              p: 0.2,
              filter: `${
                theme.palette.mode === 'light'
                  ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                  : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
              }`
            }}
          />
          <Typography variant="h6">Create PO</Typography>
        </Stack>
      </MenuItem>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        title={'Create Purchase Order'}
        maxWidth="md"
      >
        <Stack spacing={2}>
          {steps > 1 && <TableStapper steps={steps} activeStep={activeStep} />}
          {schema && steps > 0 && (
            <CreatePoForm
              key={activeStep} // Key changes with activeStep, causing remount
              schema={schema}
              entitySetting={entitySetting}
              items={[item]}
              formData={formData[0]}
              entity={endPoint}
              handleModal={handleNext}
              activeStep={activeStep}
            />
          )}
        </Stack>
      </CommonModal>
    </>
  );
};

export default CreatePo;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CommonModal = ({
  open,
  handleModal,
  // btnName,
  title,
  children,
  maxWidth = 'sm'
  // isForm = false
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={handleModal}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h4" gutterBottom>
            {t(title)}
          </Typography>
        </DialogTitle>
        <Tooltip title={'Close'}>
          <IconButton
            aria-label="close"
            onClick={handleModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <DialogContent dividers>{children}</DialogContent>
        {/* {!isForm && (
          <DialogActions>
            <Button autoFocus onClick={handleModal}>
              {btnName}
            </Button>
          </DialogActions>
        )} */}
      </Dialog>
    </>
  );
};
export default CommonModal;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  IconButton,
  Typography,
  Badge,
  useTheme,
  CircularProgress,
  Backdrop
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormikContext } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import useFileUpload from 'src/hooks/useFileUpload';

const SingleImageUpload = ({ fieldName, value: image, name }) => {
  const { setFieldValue } = useFormikContext();
  const [ishovered, setHovered] = useState(true);
  const { uploadFile, loading, deleteFile } = useFileUpload();
  const theme = useTheme();
  const updateImage = (uploadedImage) => {
    setFieldValue(name, uploadedImage.path);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        uploadFile({ file, updateState: updateImage, image });
      }
    },
    [uploadFile, updateImage, image]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg']
    },
    maxFiles: 1,
    multiple: false,
    disabled: loading
  });

  const handleDelete = async (event) => {
    event.stopPropagation(); // Prevent the click event from propagating
    await deleteFile(image);
    setFieldValue(name, '');
  };

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: image ? 120 : '100%',
        height: 120,
        border: image ? 'none' : '2px dashed #ccc',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        pointerEvents: loading ? 'none' : 'auto', // Disable pointer events when loading
        opacity: loading ? 0.5 : 1 // Reduce opacity when loading
      }}
    >
      <input {...getInputProps()} disabled={loading} />{' '}
      {/* Disable input when loading */}
      {image ? (
        <Box
          sx={{ position: 'relative', width: '100%', height: '100%' }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <img
            src={image}
            alt={`${fieldName}_uploaded`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Backdrop
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <IconButton
            size="small"
            onClick={handleDelete}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: 10000,
              display: ishovered && !loading ? 'block' : 'none'
            }}
          >
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
          <Badge
            badgeContent={
              <IconButton
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '50%',
                  padding: 1
                }}
              >
                <FileUploadIcon />
              </IconButton>
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          />
        </Box>
      ) : (
        <>
          {loading ? (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <CloudUploadIcon sx={{ fontSize: 50, mb: 1 }} />
              <Typography
                variant="body1"
                color="textSecondary"
                textAlign="center"
              >
                {isDragActive
                  ? `Drop the ${fieldName} file here...`
                  : `Drag 'n' drop an ${fieldName} file here,`}
              </Typography>
              {!isDragActive && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  textAlign="center"
                >
                  or{' '}
                  <Typography
                    component="span"
                    variant="body1"
                    color="primary"
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    click to select one
                  </Typography>
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default SingleImageUpload;

import { Grid, Paper, TextField } from '@mui/material';
import React from 'react';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import SelectBoxFieldWithLabel from 'src/components/dynamicFields/SelectBoxFieldWithLabel';
import { TEXT_MODERATION } from 'src/constant/constant';

const TextModeration = ({
  dataItem,
  errors,
  touched,
  setFieldValue,
  index,
  handleBlur
}) => {
  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        sx={{
          paddingY: 3,
          paddingX: 2,
          width: '100%',
          // border: 1,
          color: '#ccc'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <CustomSelectFieldWithLabel
              label="Moderation Type"
              value={dataItem?.text_moderation?.moderation_type || ''}
              options={TEXT_MODERATION}
              error={
                (errors.data &&
                  errors.data[index]?.text_moderation?.moderation_type) ||
                null
              }
              touched={
                (touched.data &&
                  touched.data[index]?.text_moderation?.moderation_type) ||
                null
              }
              onChange={(e) =>
                setFieldValue(
                  `data[${index}].text_moderation.moderation_type`,
                  e.target.value
                )
              }
              onBlur={handleBlur}
              name={`data[${index}].text_moderation.moderation_type`}
            />
          </Grid>
          {dataItem?.text_moderation?.moderation_type && (
            <Grid item xs={12} md={2}>
              <CustomTextField
                label="Moderation Value"
                variant="outlined"
                onBlur={handleBlur}
                fullWidth
                name={`data[${index}].moderation_value`}
                value={dataItem?.text_moderation?.moderation_value || ''}
                onChange={(e) =>
                  setFieldValue(
                    `data[${index}].text_moderation.moderation_value`,
                    e.target.value
                  )
                }
                // error={Boolean(
                //   touched.data &&
                //     touched.data[index]?.text_moderation?.moderation_value &&
                //     errors.data &&
                //     errors.data[index]?.text_moderation?.moderation_value
                // )}
                // helperText={
                //   touched.data &&
                //   touched.data[index]?.text_moderation?.moderation_value &&
                //   errors.data &&
                //   errors.data[index]?.text_moderation?.moderation_value
                // }
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TextModeration;

import { Badge, Stack, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal.js';
import ApprovalStatusDetails from './ApprovalStatusDetails';

// Determine the badge color and tooltip background color based on the approval status
const getBadgeColor = (status) => {
  switch (status) {
    case 'PENDING':
      return 'warning'; // Yellow/orange color for pending
    case 'APPROVED':
      return 'success'; // Green color for approved
    case 'REJECTED':
      return 'error'; // Red color for rejected
    default:
      return 'secondary'; // Default color for other statuses
  }
};
const CombinedStatusApproval = ({ rowData, fieldname }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  if (!Boolean(rowData?.['approval_id'])) return rowData[fieldname];
  return (
    <>
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <span style={{ whiteSpace: 'nowrap' }}>{rowData[fieldname]}</span>
        <Tooltip title={rowData?.['approval_status']} arrow>
          <Badge
            sx={{
              cursor: 'pointer',
              '& .MuiBadge-dot': {
                width: 8, // Increased width on hover
                height: 8, // Increased height on hover
                borderRadius: '50%', // Ensures the dot is circular
                transition: 'width 0.3s, height 0.3s', // Smooth transition
                '&:hover': {
                  width: 12, // Increased width on hover
                  height: 12 // Increased height on hover
                }
              }
            }}
            color={getBadgeColor(rowData?.['approval_status'])}
            variant="dot"
            onClick={handleModal}
          />
        </Tooltip>
      </Stack>
      <CommonModal
        open={openModal}
        handleModal={handleModal}
        btnName={'Close'}
        title={'Approval Status'}
      >
        <ApprovalStatusDetails approval={rowData['approval_id']} />
      </CommonModal>
    </>
  );
};

export default CombinedStatusApproval;

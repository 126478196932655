import { Box, ListItem, Tabs, alpha, styled } from '@mui/material';

export const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
      position: absolute;
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      bottom: -${theme.spacing(1)};
      right: -${theme.spacing(1)};
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
        padding: 0;
  
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `
);

export const TabsWrapper = styled(Tabs)(
  () => `
        overflow: visible !important;

        .MuiTabs-scroller {
            overflow: visible !important;
        }
    `
);

export const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
        position: relative;
        transition: ${theme.transitions.create(['background'])};
  
        &:hover {
            background: ${alpha(theme.colors.secondary.main, 0.15)};
        }
    `
);

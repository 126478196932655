import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icon for Word and Excel
import TableChartIcon from '@mui/icons-material/TableChart';

// Function to determine the icon based on file extension
const getFileTypeIcon = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();

  switch (extension) {
    case 'pdf':
      return PictureAsPdfIcon; // Icon for PDF files
    case 'doc':
    case 'docx':
      return InsertDriveFileIcon; // Icon for Word documents
    case 'xls':
    case 'xlsx':
      return InsertDriveFileIcon; // Icon for Excel files
    case 'csv':
      return TableChartIcon; // Icon for CSV files
    default:
      return AttachFileIcon; // Default icon for other file types
  }
};

const FileItem = ({ file, handleDelete, loading, index }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const FileIcon = getFileTypeIcon(file?.file?.name || file?.name); // Determine icon based on file type

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      key={index}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
      sx={{ position: 'relative' }}
    >
      <Stack alignItems="center" justifyContent="center" gap={1}>
        <FileIcon sx={{ fontSize: 60 }} />
        <Typography variant="caption" align="center">
          {file?.file?.name || file?.name} {/* Show file name */}
        </Typography>
      </Stack>
      {file?.upload && (
        <Backdrop
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={file.upload}
        >
          {loading && <CircularProgress color="inherit" />}
        </Backdrop>
      )}
      <IconButton
        size="small"
        aria-label={`delete file ${index + 1}`}
        onClick={() => handleDelete(file, index)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 10000,
          display: hoveredIndex === index && !loading ? 'block' : 'none'
        }}
      >
        <DeleteIcon color="error" fontSize="small" />
      </IconButton>
    </Grid>
  );
};

export default FileItem;

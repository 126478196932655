import clsx from 'clsx';
import { useContext, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import {
  Badge,
  Button,
  Collapse,
  ListItem,
  Tooltip,
  styled,
  tooltipClasses,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.black[100],
    color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.black[100]
  }
}));

const SidebarMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { closeSidebar } = useContext(SidebarContext);
  const [menuToggle, setMenuToggle] = useState(openParent);

  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };
  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ active: menuToggle })}
          // startIcon={Icon && <Icon />}
          startIcon={
            <img
              src={Icon ? Icon : ''}
              alt="Icon"
              style={{
                width: '20px',
                height: '20px',
                filter: `${
                  theme.palette.mode === 'light'
                    ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                    : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
                }`
              }}
            />
          }
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {/* {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )} */}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }
  // console.log(link, name, badgeTooltip, 'link');
  return (
    <>
      <ListItem component="div" key={name} {...rest}>
        <Button
          disableRipple
          component={RouterLink}
          onClick={closeSidebar}
          to={link}
          startIcon={
            <img
              src={Icon ? Icon : ''}
              alt="Icon"
              style={{
                width: '20px',
                height: '20px',
                filter: `${
                  theme.palette.mode === 'light'
                    ? 'brightness(100) saturate(50%) invert(50%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(0%) contrast(60%)'
                    : 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(1000%) contrast(60%)'
                }`
              }}
            />
          }
        >
          {t(name)}
          {/* {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )} */}
        </Button>
      </ListItem>
    </>
  );
};
SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;

import React, { useEffect, useState } from 'react';
import { Badge, Button, IconButton, Stack, Tooltip } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterRow from './FilterRow';
import CustomPopover from 'src/components/CustomPopover';
import useSortFilter from 'src/hooks/useSortFilter';

const SortFilter = ({ filteredFieldNames }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    filters,
    setFilters,
    totalAppliedFilter,
    availableFieldNames,
    handleRemoveRow,
    schema,
    getOperators,
    updateQuery,
    queryData
  } = useSortFilter(filteredFieldNames);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if (filters.length === 0) {
      const defaultColumn = availableFieldNames[0];
      const defaultOperators = getOperators(schema, defaultColumn);
      setFilters([
        {
          column: defaultColumn,
          operator: defaultOperators[0],
          value: ''
        }
      ]);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAddRow = () => {
    const availableColumns = availableFieldNames.filter(
      (fieldName) => !filters.some((filter) => filter.column === fieldName)
    );

    if (availableColumns.length > 0) {
      const newColumn = availableColumns[0];
      const newOperators = getOperators(schema, newColumn);

      setFilters((prevFilters) => [
        ...prevFilters,
        { column: newColumn, operator: newOperators[0], value: '' }
      ]);
    }
  };

  useEffect(() => {
    if (filters.length === 0) {
      setAnchorEl(null);
    }
  }, [filters]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={'Filter'}>
        <Badge
          invisible={totalAppliedFilter <= 0}
          color="error"
          badgeContent={totalAppliedFilter}
        >
          <IconButton color="primary" onClick={handlePopoverOpen}>
            <FilterAltIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <CustomPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
      >
        <Stack
          spacing={1}
          p={3}
          alignItems={'start'}
          maxWidth={800}
          overflow={'auto'}
          width={'100%'}
        >
          {filters.length > 0 &&
            filters.map((filter, filterIdx) => (
              <FilterRow
                key={filterIdx}
                filter={filter}
                filterIdx={filterIdx}
                filters={filters}
                setFilters={setFilters}
                getOperators={getOperators}
                availableFieldNames={availableFieldNames}
                handleRemoveRow={handleRemoveRow}
                updateQuery={updateQuery}
                queryData={queryData}
              />
            ))}
          {/* {filters.length < availableFieldNames.length && ( */}
          <Button variant="contained" onClick={handleAddRow}>
            Add More
          </Button>
          {/* )} */}
        </Stack>
      </CustomPopover>
    </>
  );
};

export default SortFilter;

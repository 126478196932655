import {
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import { ProcessReqContext } from 'src/contexts/ProcessReqContext';
const SupplierRow = ({
  supplier,
  supplierIdx,
  item,
  supplierLength,
  selectedSuppliers
}) => {
  const {
    supplier: supplierDetails,
    price,
    capacity,
    uom,
    payment_terms
  } = supplier;
  const { handleItemSuppliers } = useContext(ProcessReqContext);
  const [qty, setQty] = useState(() => {
    const foundSupplier = selectedSuppliers.find(
      (selectedSupplier) => selectedSupplier.id === supplier.id
    );
    return foundSupplier ? foundSupplier.qty : '';
  });
  const handleQuantityChange = (event) => {
    setQty(event.target.value);
  };
  const handleOnBlur = () => {
    const foundSupplier = selectedSuppliers.find(
      (selectedSupplier) => selectedSupplier.id === supplier.id
    );

    // Handle API call based on conditions
    if (qty === '' && foundSupplier) {
      // If qty is blank and supplier exists in selectedSuppliers, remove it
      handleItemSuppliers(supplier, item.id, null);
    } else if (qty && qty !== foundSupplier?.qty) {
      // If qty is different from the current qty, update it
      handleItemSuppliers(supplier, item.id, qty);
    }
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} md={4} lg={2}>
        <Typography variant="h6">{supplierDetails?.name}</Typography>
      </Grid>
      <Divider />
      <Grid item xs={12} md={4} lg={6}>
        <Stack alignItems={{ lg: 'center', xs: 'flex-start' }}>
          Per {uom?.name}: &#8377; {price} <br />
          Cap: {capacity}, {payment_terms?.payment_type}
        </Stack>
      </Grid>
      <Divider />
      <Grid item xs={12} md={4} lg={4}>
        <CustomTextField
          placeholder="Quantity"
          size="small"
          type="number"
          value={qty}
          onChange={handleQuantityChange}
          onBlur={handleOnBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body1">{uom?.name}</Typography>
              </InputAdornment>
            )
          }}
          sx={{ maxWidth: '100%' }}
        />
      </Grid>
      {supplierIdx < supplierLength - 1 && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};

export default SupplierRow;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataAsync,
  selectApiSuccess,
  selectData,
  selectDefaultQuery,
  selectDynamicPageConfig,
  selectPage,
  selectQuery,
  setApiSuccess,
  setPage
} from 'src/slices/dynamicSlice';
const isEmptyObject = (obj) => Object.keys(obj).length === 0;
const useDynamicTableLogic = (endPoint) => {
  const dispatch = useDispatch();
  const page = useSelector(selectPage);
  const data = useSelector(selectData);
  const query = useSelector(selectQuery);
  const isApiSuccess = useSelector(selectApiSuccess);
  const defaultQuery = useSelector(selectDefaultQuery);
  const { entity } = useSelector(selectDynamicPageConfig);
  const { populateString } = useSelector(selectDynamicPageConfig);
  const [selectedRows, setSelectedRows] = useState([]);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({ fieldName: null, direction: null });

  const handlePageChange = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setPage(0));
    setLimit(parseInt(event.target.value));
  };

  const handleSelectAllRows = (event) => {
    setSelectedRows(event.target.checked ? data.map((item) => item) : []);
  };
  const handleClearSelectedRow = () => {
    setSelectedRows([]);
  };

  const handleSelectOneRow = (_event, rowData) => {
    if (!selectedRows.some((selectedRow) => selectedRow.id === rowData.id)) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowData]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (prevSelectedRow) => prevSelectedRow.id !== rowData.id
        )
      );
    }
  };

  const handleSort = (fieldName) => {
    setSort((prevSort) => {
      if (prevSort.fieldName === fieldName) {
        return { ...prevSort, direction: prevSort.direction === 1 ? -1 : 1 };
      } else {
        return { fieldName, direction: 1 };
      }
    });
  };

  const handleFetchData = () => {
    const requestPayload = {
      // select: object.keys(schema),
      endPoint,
      limit,
      page,
      populate: populateString,
      pagination: true,
      query: isEmptyObject(query) ? defaultQuery : query
    };
    if (sort.fieldName) {
      requestPayload.sort = { [sort.fieldName]: sort.direction };
      requestPayload.collation = { locale: 'en', strength: 2 }; // Adding collation for case-insensitive sorting
    }
    dispatch(getDataAsync(requestPayload));
  };

  useEffect(() => {
    handleFetchData();
  }, [page, limit, sort, defaultQuery, entity]);

  useEffect(() => {
    if (isApiSuccess) {
      handleFetchData();
      dispatch(setApiSuccess());
    }
  }, [isApiSuccess]);

  useEffect(() => {
    return () => {
      setSort({ fieldName: null, direction: null });
    };
  }, [query]);

  return {
    handleFetchData,
    handlePageChange,
    handleLimitChange,
    handleSelectAllRows,
    handleSelectOneRow,
    handleClearSelectedRow,
    handleSort,
    selectedRows,
    data,
    limit,
    sort,
    page
  };
};

export default useDynamicTableLogic;

export const getSubdomain = () => {

  const hostname = window.location.hostname;
  const domainParts = hostname.split('.');
  const topLevelDomain = domainParts.pop();
  const secondLevelDomain = domainParts.pop();
  const subdomain = domainParts.join('.');

  if (topLevelDomain === 'localhost') {
    return {
      subdomain: null,
      domain: 'localhost',
      tld: null
    };
  }

  return {
    subdomain: subdomain || null,
    domain: secondLevelDomain || null,
    tld: topLevelDomain || null
  };
};


import React from 'react';
import { Grid } from '@mui/material';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';

const CardFieldSelect = ({
  fieldNames,
  cardEleItem,
  cardEleIndex,
  propPath,
  schema,
  setFieldValue,
  handleBlur,
  isNested = false
}) => {
  const filteredFieldNames = fieldNames.filter((fieldName) => {
    const fieldInstance = schema[fieldName]?.instance;
    if (isNested) {
      return fieldInstance === 'String';
    }
    return fieldInstance === 'String' || fieldInstance === 'ObjectID';
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const fieldPath = isNested
      ? `${propPath}.cardDesign[${cardEleIndex}].nestedField`
      : `${propPath}.cardDesign[${cardEleIndex}].fieldName`;
    setFieldValue(fieldPath, value);
  };

  return (
    <Grid item xs={12} md={2}>
      <CustomSelectFieldWithLabel
        label={isNested ? 'Select Nested Field' : 'Select Field'}
        value={
          isNested
            ? cardEleItem?.nestedField || ''
            : cardEleItem?.fieldName || ''
        }
        options={filteredFieldNames.map((fieldName) => ({
          value: fieldName,
          label: fieldName
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        name={
          isNested
            ? `${propPath}.cardDesign[${cardEleIndex}].nestedField`
            : `${propPath}.cardDesign[${cardEleIndex}].fieldName`
        }
      />
    </Grid>
  );
};

export default CardFieldSelect;

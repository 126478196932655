import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useRefMounted from 'src/hooks/useRefMounted';
import { requestApi } from 'src/mocks/request';
const getFieldWithObjectID = (schema, key) => {
  let keyName = key;
  if (key.includes('.')) {
    keyName = key.split('.')[0];
  }

  const isObjectID = schema[keyName]?.instance === 'ObjectID';
  const isArray = schema[keyName]?.instance === 'Array';
  const isArrayWithObjectID =
    isArray && schema[keyName]?.$embeddedSchemaType?.instance === 'ObjectID';
  const isArrayWithMultipleProperties =
    !isArrayWithObjectID &&
    schema[keyName]?.$embeddedSchemaType?.schema &&
    Object.keys(schema[keyName]?.$embeddedSchemaType?.schema?.paths)?.length >
      0;
  if (isObjectID) {
    return schema[keyName]?.options?.ref || null;
  }

  if (isArrayWithObjectID) {
    return schema[keyName]?.$embeddedSchemaType?.options?.ref || null;
  }

  if (isArrayWithMultipleProperties) {
    const data = key.split('.');
    const subFieldKey = data[1];

    const nestedItems = schema[keyName]?.$embeddedSchemaType?.schema?.paths;
    if (nestedItems && nestedItems[subFieldKey]) {
      const nestedItem = nestedItems[subFieldKey];
      const isSubFieldItemObjectId = nestedItem?.instance === 'ObjectID';
      const isSubFieldItemArray = nestedItem?.instance === 'Array';
      const isSubFieldItemArrayWithObjectID =
        isSubFieldItemArray &&
        nestedItem?.$embeddedSchemaType?.instance === 'ObjectID';

      if (isSubFieldItemObjectId) {
        return nestedItem?.options?.ref || null;
      }

      if (isSubFieldItemArrayWithObjectID) {
        return nestedItem?.$embeddedSchemaType?.options?.ref || null;
      }
    }
  }

  return null;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const AllFieldToShow = ({
  schema,
  populateFieldName,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  dataItem,
  index
}) => {
  const isMountedRef = useRefMounted();
  const [allFieldShow, setAllfieldShow] = useState([]);
  useEffect(() => {
    async function getAllFieldNAmeToShow() {
      const endPoint = getFieldWithObjectID(schema, populateFieldName);
      try {
        const response = await requestApi.getSchema({
          endPoint
        });
        //   console.log(response, 'populate');

        if (isMountedRef.current) {
          if (response?.statusText === 'OK') {
            const schema = response?.data;
            setAllfieldShow(Object.keys(schema));
          } else {
            console.log(response?.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllFieldNAmeToShow();
  }, [populateFieldName]);
  return (
    <>
      {allFieldShow.length > 0 && (
        <Grid item xs={12} md={4}>
          <FormControl
            fullWidth
            error={Boolean(
              touched.data &&
                touched.data[index]?.field_show &&
                errors.data &&
                errors.data[index]?.field_show
            )}
          >
            <InputLabel id={`data_${index}_field_show`}>Field Show</InputLabel>
            <Select
              labelId={`data_${index}_field_show`}
              value={dataItem.field_show}
              name={`data[${index}].field_show`}
              label="Field Show"
              onBlur={handleBlur}
              onChange={(e) =>
                setFieldValue(`data[${index}].field_show`, e.target.value)
              }
              MenuProps={MenuProps}
            >
              {allFieldShow.map((fieldNameShow) => (
                <MenuItem
                  key={`fieldNameShow_${fieldNameShow}`}
                  value={fieldNameShow}
                >
                  {fieldNameShow}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {touched.data &&
                touched.data[index]?.field_show &&
                errors.data &&
                errors.data[index]?.field_show}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
    </>
  );
};

export default AllFieldToShow;

// import {
//   FormControl,
//   FormHelperText,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Select
// } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import useRefMounted from 'src/hooks/useRefMounted';
// import { requestApi } from 'src/mocks/request';
// const getFieldWithObjectID = (schema, key) => {
//   if (schema[key]?.instance === 'ObjectID') {
//     return schema[key].options.ref;
//   }
//   if (
//     schema[key]?.instance === 'Array' &&
//     Array.isArray(schema[key]?.options?.type) &&
//     schema[key]?.options?.type[0]?.ref
//   ) {
//     return schema[key]?.options?.type[0]?.ref;
//   }
// };
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250
//     }
//   }
// };
// const AllFieldToShow = ({
//   schema,
//   populateFieldName,
//   errors,
//   touched,
//   setFieldValue,
//   handleBlur,
//   dataItem,
//   index
// }) => {
//   const isMountedRef = useRefMounted();
//   const [allFieldShow, setAllfieldShow] = useState([]);
//   useEffect(() => {
//     async function getAllFieldNAmeToShow() {
//       const endPoint = getFieldWithObjectID(schema, populateFieldName);
//       try {
//         const response = await requestApi.getSchema({
//           endPoint
//         });
//         //   console.log(response, 'populate');

//         if (isMountedRef.current) {
//           if (response?.statusText === 'OK') {
//             const schema = response?.data;
//             setAllfieldShow(Object.keys(schema));
//           } else {
//             console.log(response?.message);
//           }
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     }
//     getAllFieldNAmeToShow();
//   }, [populateFieldName]);
//   return (
//     <>
//       {allFieldShow.length > 0 && (
//         <Grid item xs={12} md={4}>
//           <FormControl
//             fullWidth
//             error={Boolean(
//               touched.data &&
//                 touched.data[index]?.field_show &&
//                 errors.data &&
//                 errors.data[index]?.field_show
//             )}
//           >
//             <InputLabel id={`data_${index}_field_show`}>Field Show</InputLabel>
//             <Select
//               labelId={`data_${index}_field_show`}
//               value={dataItem.field_show}
//               name={`data[${index}].field_show`}
//               label="Field Show"
//               onBlur={handleBlur}
//               onChange={(e) =>
//                 setFieldValue(`data[${index}].field_show`, e.target.value)
//               }
//               MenuProps={MenuProps}
//             >
//               {allFieldShow.map((fieldNameShow) => (
//                 <MenuItem
//                   key={`fieldNameShow_${fieldNameShow}`}
//                   value={fieldNameShow}
//                 >
//                   {fieldNameShow}
//                 </MenuItem>
//               ))}
//             </Select>
//             <FormHelperText>
//               {touched.data &&
//                 touched.data[index]?.field_show &&
//                 errors.data &&
//                 errors.data[index]?.field_show}
//             </FormHelperText>
//           </FormControl>
//         </Grid>
//       )}
//     </>
//   );
// };

// export default AllFieldToShow;

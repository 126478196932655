import React from 'react';
import { getFieldType } from 'src/services/commonService';
import { useSelector } from 'react-redux';
import CustomTextField from 'src/components/CustomFields/CustomTextField';
import CustomSelectField from 'src/components/CustomFields/CustomSelectField';
import CustomMultiSelectField from 'src/components/CustomFields/CustomMultiSelectField';
import FlatArrayField from 'src/components/CustomFields/FlatArrayField';
import CustomSelectFieldWithLabel from 'src/components/CustomFields/CustomSelectFieldWithLabel';
import { Box } from '@mui/material';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';

const RenderValueField = ({ filter, label, handleValueChange, disabled }) => {
  const { operator, column, value } = filter;
  const { schema } = useSelector(selectDynamicPageConfig);
  const {
    isBoolean,
    isNumber,
    isString,
    isDate,
    isObjectID,
    isStringEnum,
    isNumberEnum,
    isArray,
    isArrayEnum,
    enumValues
  } = getFieldType(column, schema);

  const renderTextField = (type = 'text', key = null) => (
    <Box sx={{ width: 200 }}>
      <CustomTextField
        label={label}
        disabled={disabled}
        value={key ? value[key] : value}
        onChange={(e) => handleValueChange(e, key)}
        fullWidth={true}
        inputProps={{ type }}
        InputLabelProps={isDate ? { shrink: true } : {}}
      />
    </Box>
  );

  const renderSelectField = (key = null) => (
    <Box sx={{ width: 200 }}>
      <CustomSelectField
        disabled={disabled}
        label={label}
        value={key ? value[key] : value}
        name={key}
        options={enumValues}
        onChange={(e) => handleValueChange(e, key)}
      />
    </Box>
  );

  const renderMultiSelectField = () => (
    <Box sx={{ width: 200 }}>
      <CustomMultiSelectField
        disabled={disabled}
        label={label}
        value={value}
        options={enumValues}
        onChange={(e) => handleValueChange(e)}
      />
    </Box>
  );

  const renderFlatArrayField = () => (
    <Box sx={{ width: 200 }}>
      <FlatArrayField
        disabled={disabled}
        label={label}
        value={value || []}
        onChange={(value) => handleValueChange(null, null, value)}
      />
    </Box>
  );

  switch (operator) {
    case 'equal':
      if (isBoolean) {
        return (
          <Box sx={{ width: 200 }}>
            <CustomSelectFieldWithLabel
              label={label}
              value={value}
              disabled={disabled}
              onChange={(e) => handleValueChange(e)}
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
              ]}
            />
          </Box>
        );
      } else if (
        (isString && !isStringEnum) ||
        (isNumber && !isNumberEnum) ||
        isObjectID
      ) {
        return renderTextField(isNumber ? 'number' : 'text');
      } else if (isStringEnum || isNumberEnum) {
        return renderSelectField();
      }
      break;

    case 'contains':
      if ((isString && !isStringEnum) || isObjectID) {
        return renderTextField();
      } else if (isStringEnum) {
        return renderSelectField();
      } else if (isArrayEnum) {
        return renderMultiSelectField();
      } else if (isArray) {
        return renderFlatArrayField();
      }
      break;

    case 'is any of':
      if (isStringEnum || isArrayEnum) {
        return renderMultiSelectField();
      } else if (isArray || isString || isObjectID) {
        return renderFlatArrayField();
      }
      break;

    case 'greater than':
    case 'less than':
      if ((isNumber && !isNumberEnum) || isDate) {
        return renderTextField(isDate ? 'date' : 'number');
      } else if (isNumberEnum) {
        return renderSelectField();
      }
      break;

    case 'in between':
      if ((isNumber && !isNumberEnum) || isDate) {
        return (
          <>
            {renderTextField(isDate ? 'date' : 'number', 'start')}
            {renderTextField(isDate ? 'date' : 'number', 'end')}
          </>
        );
      } else if (isNumberEnum) {
        return (
          <>
            {renderSelectField('start')}
            {renderSelectField('end')}
          </>
        );
      }
      break;

    case 'ends with':
    case 'starts with':
      if ((isString && !isStringEnum) || isObjectID) {
        return renderTextField();
      } else if (isStringEnum) {
        return renderSelectField();
      }
      break;

    default:
      return null;
  }
};

export default RenderValueField;

import { DoneTwoTone, InfoTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Link, Paper, Stack, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestApi } from 'src/mocks/request';
import * as XLSX from 'xlsx';
import ImportPreviewTable from './ImportPreviewTable';
import NonExistingDocumentsPreview from './NonExistingDocumentsPreview';
import { selectDynamicPageConfig } from 'src/slices/dynamicSlice';
import { selectAppConfigData } from 'src/slices/appConfigSlice';

const ImportFields = ({ schema, fieldName, handleSetValue }) => {
  const [importData, setImportData] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState(null);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [errorPreviewOpen, setErrorPreviewOpen] = useState(false);
  const [isFieldDirty, setIsFieldDirty] = useState(false);
  const { entitySetting } = useSelector(selectDynamicPageConfig);
  const { entityPageConfigs: allSchema } = useSelector(selectAppConfigData);

  const handleClose = () => setErrorPreviewOpen(false);
  const handleOpen = () => setErrorPreviewOpen(true);

  // filter imported data to preview
  const getFilteredData = useCallback(
    (data) => {
      if (!data) return { headers: [], filteredData: null };

      let includeHeaders = Object.keys(schema);
      const headers = new Set();

      const filteredData = data?.map((item) => {
        const filteredItem = {};
        Object.keys(item)?.forEach((key) => {
          if (!includeHeaders.length) {
            console.warn('Missing setting data');
            return;
          }
          if (includeHeaders?.includes(key)) {
            headers.add(key);
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });
      return { headers: Array.from(headers), filteredData };
    },
    [schema]
  );

  const handleFileChange = useCallback(
    (event) => {
      setImportData(null);
      setFieldErrors(null);

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const tableHeaders = jsonData[0];
          const formattedData = jsonData?.slice(1).map((row) => {
            const obj = {};
            tableHeaders?.forEach((key, index) => {
              const cellValue = row[index];
              if (cellValue !== undefined && cellValue !== '') {
                obj[key] = cellValue;
              }
            });
            return obj;
          });

          const { headers, filteredData } = getFilteredData(formattedData);
          setTableHeaders(headers);
          setImportData(filteredData || null);
        };

        reader.readAsArrayBuffer(file);
      } else {
        console.warn('No file selected');
        setImportData(null);
      }
    },
    [getFilteredData]
  );
  const getObjectId = useCallback(
    async (nestedField, endPoint) => {
      try {
        const propertyName = entitySetting?.populate_fields?.find(
          (populate_field) =>
            populate_field?.populate_field === `${fieldName}.${nestedField}`
        );

        if (!propertyName) {
          const endPointSchema = allSchema?.[endPoint];
          if (!endPointSchema) {
            throw new Error(`Schema not found for endpoint: ${endPoint}`);
          }

          const hasTitleOrName = ['title', 'name'].some((field) =>
            endPointSchema.hasOwnProperty(field)
          );

          if (!hasTitleOrName) {
            throw new Error(
              `Neither 'title' nor 'name' found in schema for endpoint: ${endPoint}`
            );
          }

          propertyName = {
            field_show: endPointSchema.title ? 'title' : 'name'
          };
        }

        const uniqueItems = [
          ...new Set(importData?.map((item) => item[nestedField]))
        ];
        const payload = {
          endPoint,
          query: {
            [propertyName?.field_show]: {
              $in: uniqueItems
            }
          }
        };
        if (propertyName?.field_show) {
          payload.select = [propertyName?.field_show];
        }
        const response = await requestApi.getData(payload);
        return response?.data?.data || response?.data;
      } catch (error) {
        console.error(error);
      }
    },
    [importData, fieldName, entitySetting?.populate_fields, allSchema]
  );

  const processImportData = useCallback(async () => {
    const fieldWithObjectId = Object.values(schema)
      ?.filter((item) => item?.instance === 'ObjectID')
      ?.map((item) => item?.path);

    const allObjectIdEndpoint = tableHeaders
      .filter((header) => fieldWithObjectId.includes(header))
      .map((header) => ({
        header,
        endPoint: schema[header]?.options?.ref
      }));

    const objectIdData = await Promise.all(
      allObjectIdEndpoint?.map(async ({ header, endPoint }) => {
        const data = await getObjectId(header, endPoint);
        return { header, data };
      })
    );

    objectIdData?.reduce((acc, { header, data }) => {
      acc[header] = data;
      return acc;
    }, {});

    let errors = [];

    const processedData = importData?.map((item, rowIndex) => {
      const processedItem = { ...item };
      const booleanValues = { Yes: true, No: false };

      for (const key of Object.keys(processedItem)) {
        // Handle boolean values
        if (booleanValues.hasOwnProperty(processedItem[key])) {
          processedItem[key] = booleanValues[processedItem[key]];
        }

        const propertyName = entitySetting?.populate_fields?.find(
          (populate_field) =>
            populate_field?.populate_field === `${fieldName}.${key}`
        );

        // Handle ObjectId fields
        if (
          fieldWithObjectId?.includes(key) &&
          objectIdData.hasOwnProperty(key)
        ) {
          const matchedObject = objectIdData[key]?.find(
            (obj) =>
              processedItem[key].trim() ===
                obj[propertyName.field_show]?.trim() ||
              processedItem[key] === obj.id ||
              processedItem[key] === ''
          );

          if (matchedObject) {
            processedItem[key] = matchedObject.id;
          } else {
            const errorObj = {
              rowIndex,
              value: processedItem[key]
            };

            // Find existing error object for this field or create a new one
            let fieldError = errors.find((error) => error.fieldName === key);
            if (!fieldError) {
              fieldError = {
                fieldName: key,
                endPoint: schema[key]?.options?.ref,
                errors: []
              };
              errors.push(fieldError);
            }

            // Add error to the field error object
            fieldError.errors.push(errorObj);
          }
        }
      }

      return processedItem;
    });

    return { processedData, errors };
  }, [
    importData,
    fieldName,
    schema,
    tableHeaders,
    entitySetting?.populate_fields,
    getObjectId
  ]);

  const checkFieldErrors = async () => {
    try {
      const { processedData, errors } = await processImportData();
      setFieldErrors(errors);

      if (errors?.length > 0) {
        handleOpen();
        console.warn(
          'There is mismatch in the upload data. Please resolve them before uploading.'
        );
        return;
      }
      setIsFieldDirty(false);
      setUploadData(processedData);
    } catch (error) {
      setIsFieldDirty(true);
      console.error('Error processing data:', error);
    }
  };

  const handleSampleFile = useCallback(() => {
    // currently here handled only for nested keys inside schema
    // change this function based on use case

    const headers = Object?.keys(schema);
    const ws = XLSX.utils.aoa_to_sheet([headers]);

    // Generate sample data
    let sampleData = [];
    for (let i = 0; i < 5; i++) {
      let rowData = [];
      headers?.forEach((header) => {
        const type = schema?.[header]?.instance;
        let value;
        switch (type) {
          case 'String':
            value = 'String Value';
            break;
          case 'Number':
            value = Math.floor(Math.random() * 100) + 1;
            break;
          case 'ObjectID':
            value = '663dd3f8ec554f707085b8ea';
            break;
          case 'Array':
            value = ['Item 1', 'Item 2', 'Item 3']; // Example array
            break;
          case 'Boolean':
            value = Math.random() < 0.5; // Random boolean
            break;
          case 'Date':
            const startDate = new Date('2000-01-01');
            const endDate = new Date();
            const randomDate = new Date(
              startDate.getTime() +
                Math.random() * (endDate.getTime() - startDate.getTime())
            );
            value = randomDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
            break;
          default:
            value = ''; // Default to empty string
        }
        rowData.push(value);
      });
      sampleData.push(rowData);
    }

    // Add sample data to the worksheet
    XLSX.utils.sheet_add_aoa(ws, sampleData, { origin: -1 }); // -1 means append after the existing data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SampleData');
    XLSX.writeFile(wb, 'sample.xlsx');
  }, [schema]);

  const handleImport = () => {
    handleSetValue(uploadData);
    setImportData(null);
    setUploadData(null);
  };

  const isUploadDisabled = !(
    importData &&
    fieldErrors?.length === 0 &&
    !isFieldDirty
  );

  const isValidateDisabled =
    !fieldErrors || fieldErrors?.length > 0 || isFieldDirty;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper
          sx={{
            width: '100%',
            maxWidth: { md: '1340px' },
            marginX: 'auto',
            padding: 4
          }}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <TextField
              fullWidth
              id="csv-file"
              type="file"
              inputProps={{
                accept:
                  '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }}
              onChange={handleFileChange}
              sx={{ flex: 1 }}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              disabled={isUploadDisabled}
              onClick={handleImport}
              sx={{
                heigh: '100% !important',
                minWidth: '140px',
                width: 1 / 4
              }}
            >
              Import
            </LoadingButton>
          </Stack>

          <Box my={2}>
            <Link
              sx={{ cursor: 'pointer' }}
              color="CaptionText"
              onClick={handleSampleFile}
            >
              Download Sample File (Click to download)
            </Link>
          </Box>

          <Chip
            label={isValidateDisabled ? 'Validate Fields' : 'Validated'}
            color={isValidateDisabled ? 'primary' : 'success'}
            disabled={!importData?.length || !importData}
            icon={isValidateDisabled ? <InfoTwoTone /> : <DoneTwoTone />}
            onClick={checkFieldErrors}
          />

          {importData && (
            <ImportPreviewTable
              tableHeaders={tableHeaders}
              importData={importData}
              setImportData={setImportData}
              setIsFieldDirty={setIsFieldDirty}
              errors={fieldErrors}
              path={fieldName}
            />
          )}
        </Paper>
      </Box>
      {fieldErrors && fieldErrors?.length > 0 && (
        <NonExistingDocumentsPreview
          open={errorPreviewOpen}
          handleClose={handleClose}
          errors={fieldErrors}
          path={fieldName}
        />
      )}
    </>
  );
};

export default ImportFields;

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography } from '@mui/material';
const GetLayout = ({ isModal, children, entity, handleDrawer }) => {
  return isModal ? (
    <>{children}</>
  ) : (
    <Box
      sx={{
        width: { xs: 300, sm: 400 }
      }}
    >
      <Box position={'relative'} p={1}>
        <Typography textAlign={'center'} variant="h3" gutterBottom>
          Add New Option
        </Typography>
        <Typography variant="body1" gutterBottom textAlign={'center'}>
          Fill in the fields below to create a new {entity} to the site
        </Typography>
        <IconButton
          sx={{
            position: 'absolute',
            top: 5,
            right: 10
          }}
          color="secondary"
          size="small"
          onClick={handleDrawer}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      {children}
    </Box>
  );
};
export default GetLayout;

import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDynamicPageConfig,
  setQuery,
  setStatus,
  setTableData
} from 'src/slices/dynamicSlice';
import { OPERATOR_MAPS } from 'src/constant/constant';
import { requestApi } from 'src/mocks/request';
import {
  escapeRegexSpecialChars,
  getFieldType
} from 'src/services/commonService';

const createRegex = (query) => {
  let regex = new RegExp(query, 'i');
  console.log(query, 'query');
  console.log(regex);
  return regex;
};

const useSortFilter = (filteredFieldNames) => {
  const dispatch = useDispatch();
  const { fieldNames, entitySetting, schema } = useSelector(
    selectDynamicPageConfig
  );
  const [filters, setFilters] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [totalAppliedFilter, setTotalAppliedFilter] = useState(0);
  const isInitialRender = useRef(true);

  useEffect(() => {
    setTotalAppliedFilter(queryData.length);
  }, [queryData]);

  const getOperators = (schema, column, filterIdx = null) => {
    const columnType = schema[column]?.instance;
    const allOperators = OPERATOR_MAPS[columnType] || [];

    // Get all operators already selected for this column
    const selectedOperators = filters
      .filter(
        (filter, index) => index !== filterIdx && filter.column === column
      )
      .map((filter) => filter.operator);

    // Filter out already selected operators
    const availableOperators = allOperators.filter(
      (operator) => !selectedOperators.includes(operator)
    );

    return availableOperators;
  };

  const availableFieldNames = useMemo(() => {
    const defaultFields =
      entitySetting?.search_fields?.length > 0
        ? entitySetting.search_fields
        : fieldNames;

    const fields =
      filteredFieldNames !== undefined ? filteredFieldNames : defaultFields;

    if (entitySetting?.show_fields?.length > 0) {
      return fields.filter((field) =>
        entitySetting.show_fields.includes(field)
      );
    }

    return fields;
  }, [filteredFieldNames, fieldNames, entitySetting]);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.filter((filter) =>
        availableFieldNames.includes(filter.column)
      )
    );
  }, [availableFieldNames]);

  const handleObjectIdQuery = async (endPoint, query, field) => {
    try {
      // fetchObjectIdQueryAsync
      dispatch(setStatus(true));
      const propertyName = entitySetting?.populate_fields?.find(
        (populate_field) => populate_field.populate_field === field
      );

      if (!propertyName) {
        console.error(`Property name not found for search field: ${field}`);
        return [];
      }

      const response = await requestApi.getData({
        endPoint,
        query: { [propertyName.field_show]: query }
      });

      if (response.status === 'SUCCESS' && response?.data?.data) {
        return response.data.data.map((item) => item.id);
      } else {
        console.error('Failed to fetch data', response);
        dispatch(setTableData());
        return [];
      }
    } catch (error) {
      console.error('Error fetching ObjectId data:', error);
      dispatch(setTableData());
      setStatus(false);
      return [];
    }
  };

  const updateQuery = async (filterData, filterIdx) => {
    if (!filterData) return;
    // if(previousVal.current === filterData.value) return
    let newQueryData = {};
    const {
      isObjectID,
      isArray,
      isArrayWithObjectID,
      isArrayWithMultipleProperties,
      isNumber,
      isDate,
      isBoolean
    } = getFieldType(filterData.column, schema);
    let queryValue = filterData.value;
    switch (filterData.operator) {
      case 'contains':
        if (isObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column].options.ref,
            {
              $regex: escapeRegexSpecialChars(filterData.value),
              $options: 'i'
            },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else if (isArrayWithObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column]?.$embeddedSchemaType?.options?.ref,
            { $in: filterData.value },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else if (isArray && !isArrayWithMultipleProperties) {
          newQueryData[filterData.column] = { $in: queryValue };
        } else {
          newQueryData[filterData.column] = {
            $regex: escapeRegexSpecialChars(queryValue),
            $options: 'i'
          };
        }
        break;
      case 'equal':
        if (isObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column].options.ref,
            filterData.value,
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else if (isBoolean) {
          newQueryData[filterData.column] = queryValue;
        } else {
          newQueryData[filterData.column] = queryValue;
        }
        break;
      case 'starts with':
        if (isObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column].options.ref,
            { $regex: createRegex(`^${filterData.value}`) },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else {
          newQueryData[filterData.column] = {
            $regex: createRegex(`^${filterData.value}`).toString()
          };
        }
        break;
      case 'ends with':
        if (isObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column].options.ref,
            { $regex: createRegex(`${filterData.value}$`) },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else {
          newQueryData[filterData.column] = {
            $regex: createRegex(`${filterData.value}$`)
          };
        }
        break;
      case 'is empty':
        if (isObjectID) {
          newQueryData[filterData.column] = { $exists: false };
        } else {
          newQueryData[filterData.column] = '';
        }
        break;
      case 'is not empty':
        if (isObjectID) {
          newQueryData[filterData.column] = { $exists: true, $ne: null };
        } else {
          newQueryData[filterData.column] = { $ne: '' };
        }
        break;
      case 'is any of':
        if (isObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column].options.ref,
            { $in: filterData.value },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else if (isArrayWithObjectID) {
          queryValue = await handleObjectIdQuery(
            schema[filterData.column]?.$embeddedSchemaType?.options?.ref,
            { $in: filterData.value },
            filterData.column
          );
          newQueryData[filterData.column] = { $in: queryValue };
        } else {
          newQueryData[filterData.column] = { $in: queryValue };
        }
        break;
      case 'greater than':
        if (isDate) {
          newQueryData[filterData.column] = {
            $gt: new Date(filterData.value)
          };
        } else if (isNumber) {
          newQueryData[filterData.column] = { $gt: filterData.value };
        }
        break;
      case 'less than':
        if (isDate) {
          newQueryData[filterData.column] = {
            $lt: new Date(filterData.value)
          };
        } else if (isNumber) {
          newQueryData[filterData.column] = { $lt: filterData.value };
        }
        break;
      case 'in between':
        const { start, end } = filterData.value;
        if (!start || !end) return;
        if (isNumber) {
          newQueryData[filterData.column] = {
            $gte: start,
            $lte: end
          };
        } else {
          newQueryData[filterData.column] = {
            $gte: new Date(start).toISOString(),
            $lte: new Date(end).toISOString()
          };
        }
        break;
      default:
        break;
    }
    setQueryData((prevQueryData) => {
      // Check if index already exists in array
      const foundIndex = prevQueryData.findIndex(
        (item) => item.index === filterIdx
      );

      if (foundIndex !== -1) {
        // Update existing entry
        const updatedQueryData = [...prevQueryData];
        updatedQueryData[foundIndex].query = newQueryData;
        updatedQueryData[foundIndex].filter = filterData;
        return updatedQueryData;
      } else {
        // Add new entry
        return [
          ...prevQueryData,
          { index: filterIdx, query: newQueryData, filter: filterData }
        ];
      }
    });
  };

  const handleRemoveQueryData = (filterIdx) => {
    const indexToRemove = queryData.findIndex(
      (item) => item.index === filterIdx
    );
    if (indexToRemove !== -1) {
      const updatedQueryData = [...queryData];
      updatedQueryData.splice(indexToRemove, 1); // Remove 1 item at indexToRemove
      setQueryData(updatedQueryData);
    }
  };

  // Function to handle removing a row
  const handleRemoveRow = (filterIdx) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== filterIdx));
    // Find index of filterIdx in queryData
    handleRemoveQueryData(filterIdx);
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      const filteredQueryData = queryData.map((queryItem) => queryItem.query);
      dispatch(
        setQuery({ filterType: 'filterQuery', allFilters: filteredQueryData })
      );
    } else {
      isInitialRender.current = false;
    }
  }, [queryData, dispatch]);

  return {
    filters,
    setFilters,
    totalAppliedFilter,
    availableFieldNames,
    updateQuery,
    handleRemoveRow,
    getOperators,
    schema,
    queryData
  };
};

export default useSortFilter;
